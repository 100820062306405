import { NodeType } from '../types';
import { destinationactions } from '@morf/proto/destinationactions/healthie/v1_ts_proto';
import { google } from '@morf/proto/checked_ts_proto';
import { healthie } from '@morf/proto/healthie_v1_ts_proto';
import { mockWorkflowEventPayloadFieldSchema } from './DestinationActionConfigure/SelectValuePairModal/PayloadFields/mockData';
import { sensitivity } from '@morf/proto/sensitivity_v1_ts_proto';
import { thirdparties } from '@morf/proto/thirdparties_v1_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const mockHealthieDestinationAction =
  new workflows.v1.WorkflowDestinationAction({
    healthieV1: new destinationactions.healthie.v1.DestinationAction({
      appendToPatientQuickNotes: {
        requiredThirdPartyIdMissingBehavior: 4,
        healthieEnvironment: healthie.v1.HealthieEnvironment.SANDBOX,
        params: [
          new workflow_parameters.v1.DestinationActionParameter({
            static: new workflow_parameters.v1.StaticDestinationActionParameter(
              {
                sensitivity: [sensitivity.v1.Sensitivity.Value.ID],
                fieldName: 'External ID',
                fieldType:
                  new workflow_parameters.v1.StaticDestinationActionParameterType(
                    {
                      primitiveType:
                        google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                    }
                  ),
              }
            ),
          }),
          new workflow_parameters.v1.DestinationActionParameter({
            static: {
              sensitivity: [sensitivity.v1.Sensitivity.Value.PII],
              fieldName: 'First Name',
              destinationFieldName: 'firstName',
              fieldType:
                new workflow_parameters.v1.StaticDestinationActionParameterType(
                  {
                    primitiveType:
                      google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                  }
                ),
              isRequired: true,
              objectTypeIdContext: undefined,
            },
          }),
          new workflow_parameters.v1.DestinationActionParameter({
            static: {
              sensitivity: [sensitivity.v1.Sensitivity.Value.PII],
              fieldName: 'Last Name',
              destinationFieldName: 'lastName',
              fieldType:
                new workflow_parameters.v1.StaticDestinationActionParameterType(
                  {
                    primitiveType:
                      google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                  }
                ),
              isRequired: true,
              objectTypeIdContext: undefined,
            },
          }),
          new workflow_parameters.v1.DestinationActionParameter({
            static: {
              sensitivity: [sensitivity.v1.Sensitivity.Value.PII],
              fieldName: 'Email Address',
              destinationFieldName: 'email',
              fieldType:
                new workflow_parameters.v1.StaticDestinationActionParameterType(
                  {
                    primitiveType:
                      google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                  }
                ),
              isRequired: true,
              objectTypeIdContext: undefined,
            },
          }),
          new workflow_parameters.v1.DestinationActionParameter({
            static: {
              sensitivity: [sensitivity.v1.Sensitivity.Value.PII],
              fieldName: 'Phone Number',
              destinationFieldName: 'phone',
              fieldType:
                new workflow_parameters.v1.StaticDestinationActionParameterType(
                  {
                    primitiveType:
                      google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                  }
                ),
              isRequired: true,
              objectTypeIdContext: undefined,
            },
          }),
          new workflow_parameters.v1.DestinationActionParameter({
            static: {
              sensitivity: [1],
              fieldName: 'Custom Module Form ID',
              destinationFieldName: 'formID',
              fieldType:
                new workflow_parameters.v1.StaticDestinationActionParameterType(
                  {
                    primitiveType:
                      google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                  }
                ),
              isRequired: true,
              objectTypeIdContext:
                new workflow_parameters.v1.ObjectTypeIDContext({}),
            },
          }),
          new workflow_parameters.v1.DestinationActionParameter({
            dynamic: {
              destinationFieldName: 'properties',
              sensitivity: [1],
              fieldName: 'Properties',
              isRequired: false,
              objectTypeIdContext: undefined,
              elementFieldType:
                new workflow_parameters.v1.DynamicDestinationActionParameterType(
                  {
                    mapType: {
                      keyType: new google.api.expr.v1alpha1.Type({
                        primitive: 5,
                      }),
                      valueType: new google.api.expr.v1alpha1.Type({
                        dyn: {},
                      }),
                    },
                  }
                ),
            },
          }),
          new workflow_parameters.v1.DestinationActionParameter({
            dynamic: {
              destinationFieldName: 'children',
              sensitivity: [1],
              fieldName: 'Children',
              isRequired: false,
              objectTypeIdContext: undefined,
              elementFieldType:
                new workflow_parameters.v1.DynamicDestinationActionParameterType(
                  {
                    listType:
                      new workflow_parameters.v1.DynamicDestinationActionParameterType.ListType(
                        {
                          elemType:
                            new workflow_parameters.v1.DynamicDestinationActionParameterType.ListType.ElemType(
                              {
                                primitiveType:
                                  google.api.expr.v1alpha1.Type.PrimitiveType
                                    .STRING,
                              }
                            ),
                        }
                      ),
                  }
                ),
            },
          }),
        ],
        configs: [
          new workflow_parameters.v1.DestinationActionParameterConfig({
            staticConfig: {
              parameter:
                new workflow_parameters.v1.StaticDestinationActionParameter({
                  sensitivity: [sensitivity.v1.Sensitivity.Value.ID],
                  fieldName: 'External ID',
                  fieldType:
                    new workflow_parameters.v1.StaticDestinationActionParameterType(
                      {
                        primitiveType:
                          google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                      }
                    ),
                }),
              source:
                new workflow_parameters.v1.DestinationActionParameterSource({
                  profileIdentifier: thirdparties.v1.ThirdPartyIdType.CUSTOMER,
                }),
            },
          }),
          new workflow_parameters.v1.DestinationActionParameterConfig({
            staticConfig: {
              parameter: {
                sensitivity: [sensitivity.v1.Sensitivity.Value.PII],
                fieldName: 'First Name',
                destinationFieldName: 'firstName',
                fieldType:
                  new workflow_parameters.v1.StaticDestinationActionParameterType(
                    {
                      primitiveType:
                        google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                    }
                  ),
                isRequired: true,
                objectTypeIdContext: undefined,
              },
              source:
                new workflow_parameters.v1.DestinationActionParameterSource({
                  calculatedValue: new workflow_parameters.v1.CalculatedValue({
                    celExpressionStr: 'answers.first_name',
                  }),
                }),
            },
          }),
          new workflow_parameters.v1.DestinationActionParameterConfig({
            staticConfig: {
              parameter: {
                sensitivity: [sensitivity.v1.Sensitivity.Value.PII],
                fieldName: 'Last Name',
                destinationFieldName: 'lastName',
                fieldType:
                  new workflow_parameters.v1.StaticDestinationActionParameterType(
                    {
                      primitiveType:
                        google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                    }
                  ),
                isRequired: true,
                objectTypeIdContext: undefined,
              },
              source:
                new workflow_parameters.v1.DestinationActionParameterSource({
                  eventPayloadFieldLookup:
                    new workflow_parameters.v1.EventPayloadFieldLookup({
                      celExpressionStr: 'answers.last_name',
                    }),
                }),
            },
          }),
          new workflow_parameters.v1.DestinationActionParameterConfig({
            staticConfig: {
              parameter: {
                sensitivity: [sensitivity.v1.Sensitivity.Value.PII],
                fieldName: 'Email Address',
                destinationFieldName: 'email',
                fieldType:
                  new workflow_parameters.v1.StaticDestinationActionParameterType(
                    {
                      primitiveType:
                        google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                    }
                  ),
                isRequired: true,
                objectTypeIdContext: undefined,
              },
              source:
                new workflow_parameters.v1.DestinationActionParameterSource({
                  eventPayloadFieldLookup:
                    new workflow_parameters.v1.EventPayloadFieldLookup({
                      celExpressionStr: 'answers.email_address',
                    }),
                }),
            },
          }),
          new workflow_parameters.v1.DestinationActionParameterConfig({
            staticConfig: {
              parameter: {
                sensitivity: [1],
                fieldName: 'Phone Number',
                destinationFieldName: 'phone',
                fieldType:
                  new workflow_parameters.v1.StaticDestinationActionParameterType(
                    {
                      primitiveType:
                        google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                    }
                  ),
                isRequired: true,
                objectTypeIdContext: undefined,
              },
              source:
                new workflow_parameters.v1.DestinationActionParameterSource({
                  eventPayloadFieldLookup:
                    new workflow_parameters.v1.EventPayloadFieldLookup({
                      celExpressionStr: 'answers.phone_number',
                    }),
                }),
            },
          }),
          new workflow_parameters.v1.DestinationActionParameterConfig({
            staticConfig: {
              parameter: {
                sensitivity: [1],
                fieldName: 'Custom Module Form ID',
                destinationFieldName: 'formID',
                fieldType:
                  new workflow_parameters.v1.StaticDestinationActionParameterType(
                    {
                      primitiveType:
                        google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
                    }
                  ),
                isRequired: true,
                objectTypeIdContext:
                  new workflow_parameters.v1.ObjectTypeIDContext({}),
              },
              source:
                new workflow_parameters.v1.DestinationActionParameterSource({
                  objectTypeId: new workflow_parameters.v1.ObjectTypeID({
                    id: '12342',
                    label: 'Intro Call',
                    description: 'Intro call with providers form',
                  }),
                }),
            },
          }),
        ],
      },
    }),
    parameterMissingPolicy: new workflow_parameters.v1.ParameterMissingPolicy({
      requiredParameterMissingBehavior:
        workflow_parameters.v1.ParameterMissingPolicy
          .RequiredParameterMissingBehavior
          .REQUIRED_PARAMETER_MISSING_BEHAVIOR_ERROR_AND_TERMINATE_BRANCH,
      optionalParameterMissingBehavior:
        workflow_parameters.v1.ParameterMissingPolicy
          .OptionalParameterMissingBehavior
          .OPTIONAL_PARAMETER_MISSING_BEHAVIOR_SKIP_NODE,
    }),
  });

export const destinationActionNode = {
  id: 'd26646e5-2393-49c1-8c31-ca4e6775510d',
  type: NodeType.DestinationActionNode,
  data: {
    value: {
      title: 'Destination action',
      integrationIconName: 'healthie',
      name: 'Create or Update Patient',
      configuration: mockHealthieDestinationAction,
      onCreateCustomProperty: () => console.log('onCreateCustomProperty'),
      workflowEventPayloadFieldSchemas: mockWorkflowEventPayloadFieldSchema,
    },
  },
  position: { x: 0, y: 0 },
};
