import { memo } from 'react';
import { ExtendedRowData, TableProps } from '../Table/types';
import { useTheme } from '@morf/theming';
import { Table } from '../Table/Table';

export const FullPageTable: <RowData>(
  props: TableProps<ExtendedRowData<RowData>>
) => JSX.Element = ({ ...props }) => {
  const theme = useTheme();
  return (
    <Table
      {...props}
      headCellHeight='3.5rem'
      subCellHeight='2.5rem'
      parentCellHeight='3.5rem'
      cellHeight='3.5rem'
      cellBackgroundColor={theme.colors.ui.card}
      headCellBackgroundColor={theme.colors.ui.body}
      cellHoveredBackgroundColor={theme.colors.main.light.lighter}
      cellHoveredBorderColor={theme.colors.main.light.lighter}
      cellSelectedBackgroundColor={theme.colors.main.light.normal}
      cellSelectedBorderColor={theme.colors.main.light.normal}
    />
  );
};

export const MemoizedFullPageTable = memo(
  FullPageTable
) as typeof FullPageTable;
