'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { active_campaign as active_campaign$1 } from './active_campaign_v1_ts_proto';
import { acuity as acuity$1 } from './acuity_v1_ts_proto';
import { axle_health as axle_health$1 } from './axle_health_v1_ts_proto';
import { butterfly_labs as butterfly_labs$1 } from './butterfly_labs_v1_ts_proto';
import { customerio as customerio$1 } from './customerio_v1_ts_proto';
import { google as google$1 } from './empty_ts_proto';
import { formsort as formsort$1 } from './formsort_v1_ts_proto';
import { healthie as healthie$1 } from './healthie_v1_ts_proto';
import { intakeq as intakeq$1 } from './intakeq_v1_ts_proto';
import { order_by as order_by$1 } from './order_by_v1_ts_proto';
import { pagination as pagination$1 } from './pagination_v1_ts_proto';
import { segment as segment$1 } from './segment_v1_ts_proto';
import { google as google$2 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { vital as vital$1 } from './vital_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.sources = (() => {
  const sources = {};
  sources.v1 = (() => {
    const v1 = {};
    v1.EventPayload = (() => {
      class EventPayload {
        get payload() {
          for (const key of [
            'formsortFormResponse',
            'formsortRevisionUpdate',
            'healthieEventPayload',
            'axleHealthEventPayload',
            'butterflyLabsEventPayload',
            'migration',
            'morfApiCall',
            'morfAppointment',
            'json',
            'acuityEventPayload',
            'vitalEventPayload',
            'segmentEventPayload',
            'activeCampaignEventPayload',
            'intakeqEventPayload',
            'customerIoEventPayload',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set payload(name) {
          for (const key of [
            'formsortFormResponse',
            'formsortRevisionUpdate',
            'healthieEventPayload',
            'axleHealthEventPayload',
            'butterflyLabsEventPayload',
            'migration',
            'morfApiCall',
            'morfAppointment',
            'json',
            'acuityEventPayload',
            'vitalEventPayload',
            'segmentEventPayload',
            'activeCampaignEventPayload',
            'intakeqEventPayload',
            'customerIoEventPayload',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.formsortFormResponse != null &&
            Object.hasOwnProperty.call(message, 'formsortFormResponse')
          ) {
            formsort$1.v1.FormsortFormResponse.encode(
              message.formsortFormResponse,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.formsortRevisionUpdate != null &&
            Object.hasOwnProperty.call(message, 'formsortRevisionUpdate')
          ) {
            formsort$1.v1.FormsortFormRevisionUpdate.encode(
              message.formsortRevisionUpdate,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.healthieEventPayload != null &&
            Object.hasOwnProperty.call(message, 'healthieEventPayload')
          ) {
            $root.sources.v1.HealthieEventPayload.encode(
              message.healthieEventPayload,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.axleHealthEventPayload != null &&
            Object.hasOwnProperty.call(message, 'axleHealthEventPayload')
          ) {
            $root.sources.v1.AxleHealthEventPayload.encode(
              message.axleHealthEventPayload,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.butterflyLabsEventPayload != null &&
            Object.hasOwnProperty.call(message, 'butterflyLabsEventPayload')
          ) {
            $root.sources.v1.ButterflyLabsEventPayload.encode(
              message.butterflyLabsEventPayload,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.migration != null &&
            Object.hasOwnProperty.call(message, 'migration')
          ) {
            google$1.protobuf.Empty.encode(
              message.migration,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.morfApiCall != null &&
            Object.hasOwnProperty.call(message, 'morfApiCall')
          ) {
            $root.sources.v1.MorfApiCallEventPayload.encode(
              message.morfApiCall,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.morfAppointment != null &&
            Object.hasOwnProperty.call(message, 'morfAppointment')
          ) {
            $root.sources.v1.MorfAppointmentEventPayload.encode(
              message.morfAppointment,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.json != null &&
            Object.hasOwnProperty.call(message, 'json')
          ) {
            google$2.protobuf.Struct.encode(
              message.json,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.acuityEventPayload != null &&
            Object.hasOwnProperty.call(message, 'acuityEventPayload')
          ) {
            $root.sources.v1.AcuityEventPayload.encode(
              message.acuityEventPayload,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.vitalEventPayload != null &&
            Object.hasOwnProperty.call(message, 'vitalEventPayload')
          ) {
            $root.sources.v1.VitalEventPayload.encode(
              message.vitalEventPayload,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.segmentEventPayload != null &&
            Object.hasOwnProperty.call(message, 'segmentEventPayload')
          ) {
            $root.sources.v1.SegmentEventPayload.encode(
              message.segmentEventPayload,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.activeCampaignEventPayload != null &&
            Object.hasOwnProperty.call(message, 'activeCampaignEventPayload')
          ) {
            $root.sources.v1.ActiveCampaignEventPayload.encode(
              message.activeCampaignEventPayload,
              writer.uint32(106).fork()
            ).ldelim();
          }
          if (
            message.intakeqEventPayload != null &&
            Object.hasOwnProperty.call(message, 'intakeqEventPayload')
          ) {
            $root.sources.v1.IntakeQEventPayload.encode(
              message.intakeqEventPayload,
              writer.uint32(114).fork()
            ).ldelim();
          }
          if (
            message.customerIoEventPayload != null &&
            Object.hasOwnProperty.call(message, 'customerIoEventPayload')
          ) {
            $root.sources.v1.CustomerIoEventPayload.encode(
              message.customerIoEventPayload,
              writer.uint32(122).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formsortFormResponse =
                  formsort$1.v1.FormsortFormResponse.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.formsortRevisionUpdate =
                  formsort$1.v1.FormsortFormRevisionUpdate.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.healthieEventPayload =
                  $root.sources.v1.HealthieEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.axleHealthEventPayload =
                  $root.sources.v1.AxleHealthEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.butterflyLabsEventPayload =
                  $root.sources.v1.ButterflyLabsEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.migration = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.morfApiCall =
                  $root.sources.v1.MorfApiCallEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.morfAppointment =
                  $root.sources.v1.MorfAppointmentEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                message.json = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 10: {
                message.acuityEventPayload =
                  $root.sources.v1.AcuityEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                message.vitalEventPayload =
                  $root.sources.v1.VitalEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 12: {
                message.segmentEventPayload =
                  $root.sources.v1.SegmentEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 13: {
                message.activeCampaignEventPayload =
                  $root.sources.v1.ActiveCampaignEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 14: {
                message.intakeqEventPayload =
                  $root.sources.v1.IntakeQEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 15: {
                message.customerIoEventPayload =
                  $root.sources.v1.CustomerIoEventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.EventPayload) {
            return object;
          }
          const message = new $root.sources.v1.EventPayload();
          if (object.formsortFormResponse != null) {
            if (typeof object.formsortFormResponse !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.formsortFormResponse: object expected, but got ' +
                  typeof object.formsortFormResponse
              );
            }
            message.formsortFormResponse =
              formsort$1.v1.FormsortFormResponse.fromObject(
                object.formsortFormResponse
              );
          }
          if (object.formsortRevisionUpdate != null) {
            if (typeof object.formsortRevisionUpdate !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.formsortRevisionUpdate: object expected, but got ' +
                  typeof object.formsortRevisionUpdate
              );
            }
            message.formsortRevisionUpdate =
              formsort$1.v1.FormsortFormRevisionUpdate.fromObject(
                object.formsortRevisionUpdate
              );
          }
          if (object.healthieEventPayload != null) {
            if (typeof object.healthieEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.healthieEventPayload: object expected, but got ' +
                  typeof object.healthieEventPayload
              );
            }
            message.healthieEventPayload =
              $root.sources.v1.HealthieEventPayload.fromObject(
                object.healthieEventPayload
              );
          }
          if (object.axleHealthEventPayload != null) {
            if (typeof object.axleHealthEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.axleHealthEventPayload: object expected, but got ' +
                  typeof object.axleHealthEventPayload
              );
            }
            message.axleHealthEventPayload =
              $root.sources.v1.AxleHealthEventPayload.fromObject(
                object.axleHealthEventPayload
              );
          }
          if (object.butterflyLabsEventPayload != null) {
            if (typeof object.butterflyLabsEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.butterflyLabsEventPayload: object expected, but got ' +
                  typeof object.butterflyLabsEventPayload
              );
            }
            message.butterflyLabsEventPayload =
              $root.sources.v1.ButterflyLabsEventPayload.fromObject(
                object.butterflyLabsEventPayload
              );
          }
          if (object.migration != null) {
            if (typeof object.migration !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.migration: object expected, but got ' +
                  typeof object.migration
              );
            }
            message.migration = google$1.protobuf.Empty.fromObject(
              object.migration
            );
          }
          if (object.morfApiCall != null) {
            if (typeof object.morfApiCall !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.morfApiCall: object expected, but got ' +
                  typeof object.morfApiCall
              );
            }
            message.morfApiCall =
              $root.sources.v1.MorfApiCallEventPayload.fromObject(
                object.morfApiCall
              );
          }
          if (object.morfAppointment != null) {
            if (typeof object.morfAppointment !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.morfAppointment: object expected, but got ' +
                  typeof object.morfAppointment
              );
            }
            message.morfAppointment =
              $root.sources.v1.MorfAppointmentEventPayload.fromObject(
                object.morfAppointment
              );
          }
          if (object.json != null) {
            if (typeof object.json !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.json: object expected, but got ' +
                  typeof object.json
              );
            }
            message.json = google$2.protobuf.Struct.fromObject(object.json);
          }
          if (object.acuityEventPayload != null) {
            if (typeof object.acuityEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.acuityEventPayload: object expected, but got ' +
                  typeof object.acuityEventPayload
              );
            }
            message.acuityEventPayload =
              $root.sources.v1.AcuityEventPayload.fromObject(
                object.acuityEventPayload
              );
          }
          if (object.vitalEventPayload != null) {
            if (typeof object.vitalEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.vitalEventPayload: object expected, but got ' +
                  typeof object.vitalEventPayload
              );
            }
            message.vitalEventPayload =
              $root.sources.v1.VitalEventPayload.fromObject(
                object.vitalEventPayload
              );
          }
          if (object.segmentEventPayload != null) {
            if (typeof object.segmentEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.segmentEventPayload: object expected, but got ' +
                  typeof object.segmentEventPayload
              );
            }
            message.segmentEventPayload =
              $root.sources.v1.SegmentEventPayload.fromObject(
                object.segmentEventPayload
              );
          }
          if (object.activeCampaignEventPayload != null) {
            if (typeof object.activeCampaignEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.activeCampaignEventPayload: object expected, but got ' +
                  typeof object.activeCampaignEventPayload
              );
            }
            message.activeCampaignEventPayload =
              $root.sources.v1.ActiveCampaignEventPayload.fromObject(
                object.activeCampaignEventPayload
              );
          }
          if (object.intakeqEventPayload != null) {
            if (typeof object.intakeqEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.intakeqEventPayload: object expected, but got ' +
                  typeof object.intakeqEventPayload
              );
            }
            message.intakeqEventPayload =
              $root.sources.v1.IntakeQEventPayload.fromObject(
                object.intakeqEventPayload
              );
          }
          if (object.customerIoEventPayload != null) {
            if (typeof object.customerIoEventPayload !== 'object') {
              throw new TypeError(
                '.sources.v1.EventPayload.customerIoEventPayload: object expected, but got ' +
                  typeof object.customerIoEventPayload
              );
            }
            message.customerIoEventPayload =
              $root.sources.v1.CustomerIoEventPayload.fromObject(
                object.customerIoEventPayload
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.formsortFormResponse != null &&
            message.hasOwnProperty('formsortFormResponse')
          ) {
            object.formsortFormResponse =
              formsort$1.v1.FormsortFormResponse.toObject(
                message.formsortFormResponse,
                options
              );
            if (options.oneofs) {
              object.payload = 'formsortFormResponse';
            }
          }
          if (
            message.formsortRevisionUpdate != null &&
            message.hasOwnProperty('formsortRevisionUpdate')
          ) {
            object.formsortRevisionUpdate =
              formsort$1.v1.FormsortFormRevisionUpdate.toObject(
                message.formsortRevisionUpdate,
                options
              );
            if (options.oneofs) {
              object.payload = 'formsortRevisionUpdate';
            }
          }
          if (
            message.healthieEventPayload != null &&
            message.hasOwnProperty('healthieEventPayload')
          ) {
            object.healthieEventPayload =
              $root.sources.v1.HealthieEventPayload.toObject(
                message.healthieEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'healthieEventPayload';
            }
          }
          if (
            message.axleHealthEventPayload != null &&
            message.hasOwnProperty('axleHealthEventPayload')
          ) {
            object.axleHealthEventPayload =
              $root.sources.v1.AxleHealthEventPayload.toObject(
                message.axleHealthEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'axleHealthEventPayload';
            }
          }
          if (
            message.butterflyLabsEventPayload != null &&
            message.hasOwnProperty('butterflyLabsEventPayload')
          ) {
            object.butterflyLabsEventPayload =
              $root.sources.v1.ButterflyLabsEventPayload.toObject(
                message.butterflyLabsEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'butterflyLabsEventPayload';
            }
          }
          if (
            message.migration != null &&
            message.hasOwnProperty('migration')
          ) {
            object.migration = google$1.protobuf.Empty.toObject(
              message.migration,
              options
            );
            if (options.oneofs) {
              object.payload = 'migration';
            }
          }
          if (
            message.morfApiCall != null &&
            message.hasOwnProperty('morfApiCall')
          ) {
            object.morfApiCall =
              $root.sources.v1.MorfApiCallEventPayload.toObject(
                message.morfApiCall,
                options
              );
            if (options.oneofs) {
              object.payload = 'morfApiCall';
            }
          }
          if (
            message.morfAppointment != null &&
            message.hasOwnProperty('morfAppointment')
          ) {
            object.morfAppointment =
              $root.sources.v1.MorfAppointmentEventPayload.toObject(
                message.morfAppointment,
                options
              );
            if (options.oneofs) {
              object.payload = 'morfAppointment';
            }
          }
          if (message.json != null && message.hasOwnProperty('json')) {
            object.json = google$2.protobuf.Struct.toObject(
              message.json,
              options
            );
            if (options.oneofs) {
              object.payload = 'json';
            }
          }
          if (
            message.acuityEventPayload != null &&
            message.hasOwnProperty('acuityEventPayload')
          ) {
            object.acuityEventPayload =
              $root.sources.v1.AcuityEventPayload.toObject(
                message.acuityEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'acuityEventPayload';
            }
          }
          if (
            message.vitalEventPayload != null &&
            message.hasOwnProperty('vitalEventPayload')
          ) {
            object.vitalEventPayload =
              $root.sources.v1.VitalEventPayload.toObject(
                message.vitalEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'vitalEventPayload';
            }
          }
          if (
            message.segmentEventPayload != null &&
            message.hasOwnProperty('segmentEventPayload')
          ) {
            object.segmentEventPayload =
              $root.sources.v1.SegmentEventPayload.toObject(
                message.segmentEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'segmentEventPayload';
            }
          }
          if (
            message.activeCampaignEventPayload != null &&
            message.hasOwnProperty('activeCampaignEventPayload')
          ) {
            object.activeCampaignEventPayload =
              $root.sources.v1.ActiveCampaignEventPayload.toObject(
                message.activeCampaignEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'activeCampaignEventPayload';
            }
          }
          if (
            message.intakeqEventPayload != null &&
            message.hasOwnProperty('intakeqEventPayload')
          ) {
            object.intakeqEventPayload =
              $root.sources.v1.IntakeQEventPayload.toObject(
                message.intakeqEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'intakeqEventPayload';
            }
          }
          if (
            message.customerIoEventPayload != null &&
            message.hasOwnProperty('customerIoEventPayload')
          ) {
            object.customerIoEventPayload =
              $root.sources.v1.CustomerIoEventPayload.toObject(
                message.customerIoEventPayload,
                options
              );
            if (options.oneofs) {
              object.payload = 'customerIoEventPayload';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.EventPayload';
        }
      }

      EventPayload.prototype.formsortFormResponse = null;
      EventPayload.prototype.formsortRevisionUpdate = null;
      EventPayload.prototype.healthieEventPayload = null;
      EventPayload.prototype.axleHealthEventPayload = null;
      EventPayload.prototype.butterflyLabsEventPayload = null;
      EventPayload.prototype.migration = null;
      EventPayload.prototype.morfApiCall = null;
      EventPayload.prototype.morfAppointment = null;
      EventPayload.prototype.json = null;
      EventPayload.prototype.acuityEventPayload = null;
      EventPayload.prototype.vitalEventPayload = null;
      EventPayload.prototype.segmentEventPayload = null;
      EventPayload.prototype.activeCampaignEventPayload = null;
      EventPayload.prototype.intakeqEventPayload = null;
      EventPayload.prototype.customerIoEventPayload = null;

      return EventPayload;
    })();

    v1.AcuityEventPayload = (() => {
      class AcuityEventPayload {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AcuityEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.acuityAppointment != null &&
            Object.hasOwnProperty.call(message, 'acuityAppointment')
          ) {
            acuity$1.v1.Appointment.encode(
              message.acuityAppointment,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AcuityEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.acuityAppointment = acuity$1.v1.Appointment.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.AcuityEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.AcuityEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_ACUITY_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'ACUITY_APPOINTMENT_SCHEDULED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'ACUITY_APPOINTMENT_RESCHEDULED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'ACUITY_APPOINTMENT_CANCELLED':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'ACUITY_APPOINTMENT_UPDATED':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'ACUITY_APPOINTMENT_NO_SHOW':
              case 5: {
                message.eventType = 5;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.acuityAppointment != null) {
            if (typeof object.acuityAppointment !== 'object') {
              throw new TypeError(
                '.sources.v1.AcuityEventPayload.acuityAppointment: object expected, but got ' +
                  typeof object.acuityAppointment
              );
            }
            message.acuityAppointment = acuity$1.v1.Appointment.fromObject(
              object.acuityAppointment
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_ACUITY_EVENT_TYPE'
                : 0;
            object.acuityAppointment = null;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? acuity$1.v1.AcuityEventType[message.eventType] === undefined
                  ? message.eventType
                  : acuity$1.v1.AcuityEventType[message.eventType]
                : message.eventType;
          }
          if (
            message.acuityAppointment != null &&
            message.hasOwnProperty('acuityAppointment')
          ) {
            object.acuityAppointment = acuity$1.v1.Appointment.toObject(
              message.acuityAppointment,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.AcuityEventPayload';
        }
      }

      AcuityEventPayload.prototype.eventType = 0;
      AcuityEventPayload.prototype.acuityAppointment = null;

      return AcuityEventPayload;
    })();

    v1.MorfApiCallEventPayload = (() => {
      class MorfApiCallEventPayload {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfApiCallEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.userId != null &&
            Object.hasOwnProperty.call(message, 'userId')
          ) {
            writer.uint32(10).string(message.userId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfApiCallEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.userId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.MorfApiCallEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.MorfApiCallEventPayload();
          if (object.userId != null) {
            message.userId = String(object.userId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.userId != null && message.hasOwnProperty('userId')) {
            object.userId = message.userId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.MorfApiCallEventPayload';
        }
      }

      MorfApiCallEventPayload.prototype.userId = null;

      return MorfApiCallEventPayload;
    })();

    v1.HealthieEventPayload = (() => {
      class HealthieEventPayload {
        get event() {
          for (const key of [
            'healthieFormAnswer',
            'healthieAppointment',
            'healthieUser',
            'healthieCms1500',
            'healthieInsuranceAuthorization',
            'healthieRequestedFormCompletion',
            'healthieBillingItem',
            'healthiePolicy',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of [
            'healthieFormAnswer',
            'healthieAppointment',
            'healthieUser',
            'healthieCms1500',
            'healthieInsuranceAuthorization',
            'healthieRequestedFormCompletion',
            'healthieBillingItem',
            'healthiePolicy',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.healthieFormAnswer != null &&
            Object.hasOwnProperty.call(message, 'healthieFormAnswer')
          ) {
            healthie$1.v1.FormAnswerGroup.encode(
              message.healthieFormAnswer,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.healthieAppointment != null &&
            Object.hasOwnProperty.call(message, 'healthieAppointment')
          ) {
            healthie$1.v1.Appointment.encode(
              message.healthieAppointment,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.healthieUser != null &&
            Object.hasOwnProperty.call(message, 'healthieUser')
          ) {
            healthie$1.v1.User.encode(
              message.healthieUser,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.healthieCms1500 != null &&
            Object.hasOwnProperty.call(message, 'healthieCms1500')
          ) {
            healthie$1.v1.CMS1500.encode(
              message.healthieCms1500,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.healthieInsuranceAuthorization != null &&
            Object.hasOwnProperty.call(
              message,
              'healthieInsuranceAuthorization'
            )
          ) {
            healthie$1.v1.InsuranceAuthorization.encode(
              message.healthieInsuranceAuthorization,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.healthieRequestedFormCompletion != null &&
            Object.hasOwnProperty.call(
              message,
              'healthieRequestedFormCompletion'
            )
          ) {
            healthie$1.v1.RequestedFormCompletion.encode(
              message.healthieRequestedFormCompletion,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.healthieBillingItem != null &&
            Object.hasOwnProperty.call(message, 'healthieBillingItem')
          ) {
            healthie$1.v1.BillingItem.encode(
              message.healthieBillingItem,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.healthiePolicy != null &&
            Object.hasOwnProperty.call(message, 'healthiePolicy')
          ) {
            healthie$1.v1.Policy.encode(
              message.healthiePolicy,
              writer.uint32(74).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.healthieFormAnswer =
                  healthie$1.v1.FormAnswerGroup.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.healthieAppointment = healthie$1.v1.Appointment.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.healthieUser = healthie$1.v1.User.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.healthieCms1500 = healthie$1.v1.CMS1500.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.healthieInsuranceAuthorization =
                  healthie$1.v1.InsuranceAuthorization.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.healthieRequestedFormCompletion =
                  healthie$1.v1.RequestedFormCompletion.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.healthieBillingItem = healthie$1.v1.BillingItem.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.healthiePolicy = healthie$1.v1.Policy.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.HealthieEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.HealthieEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_SCHEDULED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_DELETED':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'HEALTHIE_BILLING_ITEM_CREATED':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'HEALTHIE_BILLING_ITEM_UPDATED':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'HEALTHIE_CMS1500_CREATED':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'HEALTHIE_CMS1500_UPDATED':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'HEALTHIE_CMS1500_DELETED':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'HEALTHIE_COMMENT_CREATED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'HEALTHIE_COMMENT_UPDATED':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'HEALTHIE_COMMENT_DELETED':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'HEALTHIE_CONVERSATION_MEMBERSHIP_VIEWED':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'HEALTHIE_DOCUMENT_CREATED':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'HEALTHIE_DOCUMENT_UPDATED':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'HEALTHIE_DOCUMENT_DELETED':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'HEALTHIE_ENTRY_CREATED':
              case 16: {
                message.eventType = 16;
                break;
              }
              case 'HEALTHIE_ENTRY_UPDATED':
              case 17: {
                message.eventType = 17;
                break;
              }
              case 'HEALTHIE_ENTRY_DELETED':
              case 18: {
                message.eventType = 18;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_CREATED':
              case 19: {
                message.eventType = 19;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_DELETED':
              case 20: {
                message.eventType = 20;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_LOCKED':
              case 21: {
                message.eventType = 21;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_SIGNED':
              case 22: {
                message.eventType = 22;
                break;
              }
              case 'HEALTHIE_GOAL_CREATED':
              case 23: {
                message.eventType = 23;
                break;
              }
              case 'HEALTHIE_GOAL_UPDATED':
              case 24: {
                message.eventType = 24;
                break;
              }
              case 'HEALTHIE_GOAL_DELETED':
              case 25: {
                message.eventType = 25;
                break;
              }
              case 'HEALTHIE_GOAL_HISTORY_CREATED':
              case 26: {
                message.eventType = 26;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_CREATED':
              case 27: {
                message.eventType = 27;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_UPDATED':
              case 28: {
                message.eventType = 28;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_DELETED':
              case 29: {
                message.eventType = 29;
                break;
              }
              case 'HEALTHIE_MESSAGE_CREATED':
              case 30: {
                message.eventType = 30;
                break;
              }
              case 'HEALTHIE_MESSAGE_DELETED':
              case 31: {
                message.eventType = 31;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_CREATED':
              case 32: {
                message.eventType = 32;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_UPDATED':
              case 33: {
                message.eventType = 33;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_DELETED':
              case 34: {
                message.eventType = 34;
                break;
              }
              case 'HEALTHIE_PATIENT_CREATED':
              case 35: {
                message.eventType = 35;
                break;
              }
              case 'HEALTHIE_PATIENT_UPDATED':
              case 36: {
                message.eventType = 36;
                break;
              }
              case 'HEALTHIE_POLICY_CREATED':
              case 37: {
                message.eventType = 37;
                break;
              }
              case 'HEALTHIE_POLICY_UPDATED':
              case 38: {
                message.eventType = 38;
                break;
              }
              case 'HEALTHIE_POLICY_DELETED':
              case 39: {
                message.eventType = 39;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_CREATED':
              case 40: {
                message.eventType = 40;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_UPDATED':
              case 41: {
                message.eventType = 41;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_DELETED':
              case 42: {
                message.eventType = 42;
                break;
              }
              case 'HEALTHIE_TASK_CREATED':
              case 43: {
                message.eventType = 43;
                break;
              }
              case 'HEALTHIE_TASK_UPDATED':
              case 44: {
                message.eventType = 44;
                break;
              }
              case 'HEALTHIE_TASK_DELETED':
              case 45: {
                message.eventType = 45;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_MARKED_OCCURRED':
              case 46: {
                message.eventType = 46;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_NO_SHOW':
              case 47: {
                message.eventType = 47;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_RESCHEDULED':
              case 48: {
                message.eventType = 48;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_CANCELLED':
              case 49: {
                message.eventType = 49;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.healthieFormAnswer != null) {
            if (typeof object.healthieFormAnswer !== 'object') {
              throw new TypeError(
                '.sources.v1.HealthieEventPayload.healthieFormAnswer: object expected, but got ' +
                  typeof object.healthieFormAnswer
              );
            }
            message.healthieFormAnswer =
              healthie$1.v1.FormAnswerGroup.fromObject(
                object.healthieFormAnswer
              );
          }
          if (object.healthieAppointment != null) {
            if (typeof object.healthieAppointment !== 'object') {
              throw new TypeError(
                '.sources.v1.HealthieEventPayload.healthieAppointment: object expected, but got ' +
                  typeof object.healthieAppointment
              );
            }
            message.healthieAppointment = healthie$1.v1.Appointment.fromObject(
              object.healthieAppointment
            );
          }
          if (object.healthieUser != null) {
            if (typeof object.healthieUser !== 'object') {
              throw new TypeError(
                '.sources.v1.HealthieEventPayload.healthieUser: object expected, but got ' +
                  typeof object.healthieUser
              );
            }
            message.healthieUser = healthie$1.v1.User.fromObject(
              object.healthieUser
            );
          }
          if (object.healthieCms1500 != null) {
            if (typeof object.healthieCms1500 !== 'object') {
              throw new TypeError(
                '.sources.v1.HealthieEventPayload.healthieCms1500: object expected, but got ' +
                  typeof object.healthieCms1500
              );
            }
            message.healthieCms1500 = healthie$1.v1.CMS1500.fromObject(
              object.healthieCms1500
            );
          }
          if (object.healthieInsuranceAuthorization != null) {
            if (typeof object.healthieInsuranceAuthorization !== 'object') {
              throw new TypeError(
                '.sources.v1.HealthieEventPayload.healthieInsuranceAuthorization: object expected, but got ' +
                  typeof object.healthieInsuranceAuthorization
              );
            }
            message.healthieInsuranceAuthorization =
              healthie$1.v1.InsuranceAuthorization.fromObject(
                object.healthieInsuranceAuthorization
              );
          }
          if (object.healthieRequestedFormCompletion != null) {
            if (typeof object.healthieRequestedFormCompletion !== 'object') {
              throw new TypeError(
                '.sources.v1.HealthieEventPayload.healthieRequestedFormCompletion: object expected, but got ' +
                  typeof object.healthieRequestedFormCompletion
              );
            }
            message.healthieRequestedFormCompletion =
              healthie$1.v1.RequestedFormCompletion.fromObject(
                object.healthieRequestedFormCompletion
              );
          }
          if (object.healthieBillingItem != null) {
            if (typeof object.healthieBillingItem !== 'object') {
              throw new TypeError(
                '.sources.v1.HealthieEventPayload.healthieBillingItem: object expected, but got ' +
                  typeof object.healthieBillingItem
              );
            }
            message.healthieBillingItem = healthie$1.v1.BillingItem.fromObject(
              object.healthieBillingItem
            );
          }
          if (object.healthiePolicy != null) {
            if (typeof object.healthiePolicy !== 'object') {
              throw new TypeError(
                '.sources.v1.HealthieEventPayload.healthiePolicy: object expected, but got ' +
                  typeof object.healthiePolicy
              );
            }
            message.healthiePolicy = healthie$1.v1.Policy.fromObject(
              object.healthiePolicy
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE'
                : 0;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? healthie$1.v1.HealthieEventType[message.eventType] ===
                  undefined
                  ? message.eventType
                  : healthie$1.v1.HealthieEventType[message.eventType]
                : message.eventType;
          }
          if (
            message.healthieFormAnswer != null &&
            message.hasOwnProperty('healthieFormAnswer')
          ) {
            object.healthieFormAnswer = healthie$1.v1.FormAnswerGroup.toObject(
              message.healthieFormAnswer,
              options
            );
            if (options.oneofs) {
              object.event = 'healthieFormAnswer';
            }
          }
          if (
            message.healthieAppointment != null &&
            message.hasOwnProperty('healthieAppointment')
          ) {
            object.healthieAppointment = healthie$1.v1.Appointment.toObject(
              message.healthieAppointment,
              options
            );
            if (options.oneofs) {
              object.event = 'healthieAppointment';
            }
          }
          if (
            message.healthieUser != null &&
            message.hasOwnProperty('healthieUser')
          ) {
            object.healthieUser = healthie$1.v1.User.toObject(
              message.healthieUser,
              options
            );
            if (options.oneofs) {
              object.event = 'healthieUser';
            }
          }
          if (
            message.healthieCms1500 != null &&
            message.hasOwnProperty('healthieCms1500')
          ) {
            object.healthieCms1500 = healthie$1.v1.CMS1500.toObject(
              message.healthieCms1500,
              options
            );
            if (options.oneofs) {
              object.event = 'healthieCms1500';
            }
          }
          if (
            message.healthieInsuranceAuthorization != null &&
            message.hasOwnProperty('healthieInsuranceAuthorization')
          ) {
            object.healthieInsuranceAuthorization =
              healthie$1.v1.InsuranceAuthorization.toObject(
                message.healthieInsuranceAuthorization,
                options
              );
            if (options.oneofs) {
              object.event = 'healthieInsuranceAuthorization';
            }
          }
          if (
            message.healthieRequestedFormCompletion != null &&
            message.hasOwnProperty('healthieRequestedFormCompletion')
          ) {
            object.healthieRequestedFormCompletion =
              healthie$1.v1.RequestedFormCompletion.toObject(
                message.healthieRequestedFormCompletion,
                options
              );
            if (options.oneofs) {
              object.event = 'healthieRequestedFormCompletion';
            }
          }
          if (
            message.healthieBillingItem != null &&
            message.hasOwnProperty('healthieBillingItem')
          ) {
            object.healthieBillingItem = healthie$1.v1.BillingItem.toObject(
              message.healthieBillingItem,
              options
            );
            if (options.oneofs) {
              object.event = 'healthieBillingItem';
            }
          }
          if (
            message.healthiePolicy != null &&
            message.hasOwnProperty('healthiePolicy')
          ) {
            object.healthiePolicy = healthie$1.v1.Policy.toObject(
              message.healthiePolicy,
              options
            );
            if (options.oneofs) {
              object.event = 'healthiePolicy';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.HealthieEventPayload';
        }
      }

      HealthieEventPayload.prototype.eventType = 0;
      HealthieEventPayload.prototype.healthieFormAnswer = null;
      HealthieEventPayload.prototype.healthieAppointment = null;
      HealthieEventPayload.prototype.healthieUser = null;
      HealthieEventPayload.prototype.healthieCms1500 = null;
      HealthieEventPayload.prototype.healthieInsuranceAuthorization = null;
      HealthieEventPayload.prototype.healthieRequestedFormCompletion = null;
      HealthieEventPayload.prototype.healthieBillingItem = null;
      HealthieEventPayload.prototype.healthiePolicy = null;

      return HealthieEventPayload;
    })();

    v1.MorfAppointmentEventPayload = (() => {
      class MorfAppointmentEventPayload {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfAppointmentEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.daysUntilAppointment != null &&
            Object.hasOwnProperty.call(message, 'daysUntilAppointment')
          ) {
            writer.uint32(8).int32(message.daysUntilAppointment);
          }
          if (
            message.hoursUntilAppointment != null &&
            Object.hasOwnProperty.call(message, 'hoursUntilAppointment')
          ) {
            writer.uint32(16).int32(message.hoursUntilAppointment);
          }
          if (
            message.addToCalLink != null &&
            Object.hasOwnProperty.call(message, 'addToCalLink')
          ) {
            writer.uint32(26).string(message.addToCalLink);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.createdBy != null &&
            Object.hasOwnProperty.call(message, 'createdBy')
          ) {
            writer.uint32(42).string(message.createdBy);
          }
          if (
            message.datetime != null &&
            Object.hasOwnProperty.call(message, 'datetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.datetime,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(58).string(message.id);
          }
          if (
            message.lengthMinutes != null &&
            Object.hasOwnProperty.call(message, 'lengthMinutes')
          ) {
            writer.uint32(66).string(message.lengthMinutes);
          }
          if (
            message.locationLabel != null &&
            Object.hasOwnProperty.call(message, 'locationLabel')
          ) {
            writer.uint32(74).string(message.locationLabel);
          }
          if (
            message.locationType != null &&
            Object.hasOwnProperty.call(message, 'locationType')
          ) {
            writer.uint32(82).string(message.locationType);
          }
          if (
            message.locationDetail != null &&
            Object.hasOwnProperty.call(message, 'locationDetail')
          ) {
            writer.uint32(90).string(message.locationDetail);
          }
          if (
            message.providerFullName != null &&
            Object.hasOwnProperty.call(message, 'providerFullName')
          ) {
            writer.uint32(98).string(message.providerFullName);
          }
          if (
            message.providerId != null &&
            Object.hasOwnProperty.call(message, 'providerId')
          ) {
            writer.uint32(106).string(message.providerId);
          }
          if (
            message.patientTimezoneIdentifier != null &&
            Object.hasOwnProperty.call(message, 'patientTimezoneIdentifier')
          ) {
            writer.uint32(114).string(message.patientTimezoneIdentifier);
          }
          if (
            message.timezoneOffset != null &&
            Object.hasOwnProperty.call(message, 'timezoneOffset')
          ) {
            writer.uint32(122).string(message.timezoneOffset);
          }
          if (
            message.timezoneShortName != null &&
            Object.hasOwnProperty.call(message, 'timezoneShortName')
          ) {
            writer.uint32(130).string(message.timezoneShortName);
          }
          if (
            message.typeId != null &&
            Object.hasOwnProperty.call(message, 'typeId')
          ) {
            writer.uint32(138).string(message.typeId);
          }
          if (
            message.typeTitle != null &&
            Object.hasOwnProperty.call(message, 'typeTitle')
          ) {
            writer.uint32(146).string(message.typeTitle);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(154).fork()
            ).ldelim();
          }
          if (
            message.morfAppointmentEventType != null &&
            Object.hasOwnProperty.call(message, 'morfAppointmentEventType')
          ) {
            writer.uint32(160).int32(message.morfAppointmentEventType);
          }
          if (
            message.minutesUntilAppointment != null &&
            Object.hasOwnProperty.call(message, 'minutesUntilAppointment')
          ) {
            writer.uint32(168).int32(message.minutesUntilAppointment);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfAppointmentEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.daysUntilAppointment = reader.int32();
                break;
              }
              case 2: {
                message.hoursUntilAppointment = reader.int32();
                break;
              }
              case 3: {
                message.addToCalLink = reader.string();
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.createdBy = reader.string();
                break;
              }
              case 6: {
                message.datetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.id = reader.string();
                break;
              }
              case 8: {
                message.lengthMinutes = reader.string();
                break;
              }
              case 9: {
                message.locationLabel = reader.string();
                break;
              }
              case 10: {
                message.locationType = reader.string();
                break;
              }
              case 11: {
                message.locationDetail = reader.string();
                break;
              }
              case 12: {
                message.providerFullName = reader.string();
                break;
              }
              case 13: {
                message.providerId = reader.string();
                break;
              }
              case 14: {
                message.patientTimezoneIdentifier = reader.string();
                break;
              }
              case 15: {
                message.timezoneOffset = reader.string();
                break;
              }
              case 16: {
                message.timezoneShortName = reader.string();
                break;
              }
              case 17: {
                message.typeId = reader.string();
                break;
              }
              case 18: {
                message.typeTitle = reader.string();
                break;
              }
              case 19: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 20: {
                message.morfAppointmentEventType = reader.int32();
                break;
              }
              case 21: {
                message.minutesUntilAppointment = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.MorfAppointmentEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.MorfAppointmentEventPayload();
          if (object.daysUntilAppointment != null) {
            message.daysUntilAppointment = object.daysUntilAppointment | 0;
          }
          if (object.hoursUntilAppointment != null) {
            message.hoursUntilAppointment = object.hoursUntilAppointment | 0;
          }
          if (object.addToCalLink != null) {
            message.addToCalLink = String(object.addToCalLink);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.sources.v1.MorfAppointmentEventPayload.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.createdBy != null) {
            message.createdBy = String(object.createdBy);
          }
          if (object.datetime != null) {
            if (typeof object.datetime !== 'object') {
              throw new TypeError(
                '.sources.v1.MorfAppointmentEventPayload.datetime: object expected, but got ' +
                  typeof object.datetime
              );
            }
            message.datetime = timing$1.v1.Timestamp.fromObject(
              object.datetime
            );
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.lengthMinutes != null) {
            message.lengthMinutes = String(object.lengthMinutes);
          }
          if (object.locationLabel != null) {
            message.locationLabel = String(object.locationLabel);
          }
          if (object.locationType != null) {
            message.locationType = String(object.locationType);
          }
          if (object.locationDetail != null) {
            message.locationDetail = String(object.locationDetail);
          }
          if (object.providerFullName != null) {
            message.providerFullName = String(object.providerFullName);
          }
          if (object.providerId != null) {
            message.providerId = String(object.providerId);
          }
          if (object.patientTimezoneIdentifier != null) {
            message.patientTimezoneIdentifier = String(
              object.patientTimezoneIdentifier
            );
          }
          if (object.timezoneOffset != null) {
            message.timezoneOffset = String(object.timezoneOffset);
          }
          if (object.timezoneShortName != null) {
            message.timezoneShortName = String(object.timezoneShortName);
          }
          if (object.typeId != null) {
            message.typeId = String(object.typeId);
          }
          if (object.typeTitle != null) {
            message.typeTitle = String(object.typeTitle);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.sources.v1.MorfAppointmentEventPayload.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.morfAppointmentEventType != null) {
            switch (object.morfAppointmentEventType) {
              case 'UNSPECIFIED_MORF_APPOINTMENT_EVENT_TYPE':
              case 0: {
                message.morfAppointmentEventType = 0;
                break;
              }
              case 'MORF_APPOINTMENT_EVENT_UPCOMING':
              case 1: {
                message.morfAppointmentEventType = 1;
                break;
              }
              case 'MORF_APPOINTMENT_EVENT_ENDED':
              case 2: {
                message.morfAppointmentEventType = 2;
                break;
              }
              default: {
                if (typeof object.morfAppointmentEventType == 'number') {
                  message.morfAppointmentEventType =
                    object.morfAppointmentEventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.minutesUntilAppointment != null) {
            message.minutesUntilAppointment =
              object.minutesUntilAppointment | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.daysUntilAppointment = 0;
            object.hoursUntilAppointment = 0;
            object.addToCalLink = '';
            object.createdAt = null;
            object.createdBy = '';
            object.datetime = null;
            object.id = '';
            object.lengthMinutes = '';
            object.locationLabel = '';
            object.locationType = '';
            object.locationDetail = '';
            object.providerFullName = '';
            object.providerId = '';
            object.patientTimezoneIdentifier = '';
            object.timezoneOffset = '';
            object.timezoneShortName = '';
            object.typeId = '';
            object.typeTitle = '';
            object.updatedAt = null;
            object.morfAppointmentEventType =
              options.enums === String
                ? 'UNSPECIFIED_MORF_APPOINTMENT_EVENT_TYPE'
                : 0;
            object.minutesUntilAppointment = 0;
          }
          let keys;
          if (
            message.daysUntilAppointment != null &&
            message.hasOwnProperty('daysUntilAppointment')
          ) {
            object.daysUntilAppointment = message.daysUntilAppointment;
          }
          if (
            message.hoursUntilAppointment != null &&
            message.hasOwnProperty('hoursUntilAppointment')
          ) {
            object.hoursUntilAppointment = message.hoursUntilAppointment;
          }
          if (
            message.addToCalLink != null &&
            message.hasOwnProperty('addToCalLink')
          ) {
            object.addToCalLink = message.addToCalLink;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.createdBy != null &&
            message.hasOwnProperty('createdBy')
          ) {
            object.createdBy = message.createdBy;
          }
          if (message.datetime != null && message.hasOwnProperty('datetime')) {
            object.datetime = timing$1.v1.Timestamp.toObject(
              message.datetime,
              options
            );
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.lengthMinutes != null &&
            message.hasOwnProperty('lengthMinutes')
          ) {
            object.lengthMinutes = message.lengthMinutes;
          }
          if (
            message.locationLabel != null &&
            message.hasOwnProperty('locationLabel')
          ) {
            object.locationLabel = message.locationLabel;
          }
          if (
            message.locationType != null &&
            message.hasOwnProperty('locationType')
          ) {
            object.locationType = message.locationType;
          }
          if (
            message.locationDetail != null &&
            message.hasOwnProperty('locationDetail')
          ) {
            object.locationDetail = message.locationDetail;
          }
          if (
            message.providerFullName != null &&
            message.hasOwnProperty('providerFullName')
          ) {
            object.providerFullName = message.providerFullName;
          }
          if (
            message.providerId != null &&
            message.hasOwnProperty('providerId')
          ) {
            object.providerId = message.providerId;
          }
          if (
            message.patientTimezoneIdentifier != null &&
            message.hasOwnProperty('patientTimezoneIdentifier')
          ) {
            object.patientTimezoneIdentifier =
              message.patientTimezoneIdentifier;
          }
          if (
            message.timezoneOffset != null &&
            message.hasOwnProperty('timezoneOffset')
          ) {
            object.timezoneOffset = message.timezoneOffset;
          }
          if (
            message.timezoneShortName != null &&
            message.hasOwnProperty('timezoneShortName')
          ) {
            object.timezoneShortName = message.timezoneShortName;
          }
          if (message.typeId != null && message.hasOwnProperty('typeId')) {
            object.typeId = message.typeId;
          }
          if (
            message.typeTitle != null &&
            message.hasOwnProperty('typeTitle')
          ) {
            object.typeTitle = message.typeTitle;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.morfAppointmentEventType != null &&
            message.hasOwnProperty('morfAppointmentEventType')
          ) {
            object.morfAppointmentEventType =
              options.enums === String
                ? $root.sources.v1.MorfAppointmentEventType[
                    message.morfAppointmentEventType
                  ] === undefined
                  ? message.morfAppointmentEventType
                  : $root.sources.v1.MorfAppointmentEventType[
                      message.morfAppointmentEventType
                    ]
                : message.morfAppointmentEventType;
          }
          if (
            message.minutesUntilAppointment != null &&
            message.hasOwnProperty('minutesUntilAppointment')
          ) {
            object.minutesUntilAppointment = message.minutesUntilAppointment;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.MorfAppointmentEventPayload';
        }
      }

      MorfAppointmentEventPayload.prototype.daysUntilAppointment = 0;
      MorfAppointmentEventPayload.prototype.hoursUntilAppointment = 0;
      MorfAppointmentEventPayload.prototype.addToCalLink = '';
      MorfAppointmentEventPayload.prototype.createdAt = null;
      MorfAppointmentEventPayload.prototype.createdBy = '';
      MorfAppointmentEventPayload.prototype.datetime = null;
      MorfAppointmentEventPayload.prototype.id = '';
      MorfAppointmentEventPayload.prototype.lengthMinutes = '';
      MorfAppointmentEventPayload.prototype.locationLabel = '';
      MorfAppointmentEventPayload.prototype.locationType = '';
      MorfAppointmentEventPayload.prototype.locationDetail = '';
      MorfAppointmentEventPayload.prototype.providerFullName = '';
      MorfAppointmentEventPayload.prototype.providerId = '';
      MorfAppointmentEventPayload.prototype.patientTimezoneIdentifier = '';
      MorfAppointmentEventPayload.prototype.timezoneOffset = '';
      MorfAppointmentEventPayload.prototype.timezoneShortName = '';
      MorfAppointmentEventPayload.prototype.typeId = '';
      MorfAppointmentEventPayload.prototype.typeTitle = '';
      MorfAppointmentEventPayload.prototype.updatedAt = null;
      MorfAppointmentEventPayload.prototype.morfAppointmentEventType = 0;
      MorfAppointmentEventPayload.prototype.minutesUntilAppointment = 0;

      return MorfAppointmentEventPayload;
    })();

    v1.AxleHealthEventPayload = (() => {
      class AxleHealthEventPayload {
        get event() {
          for (const key of ['labOrderStatusUpdate', 'labOrderResults']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['labOrderStatusUpdate', 'labOrderResults']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AxleHealthEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.labOrderStatusUpdate != null &&
            Object.hasOwnProperty.call(message, 'labOrderStatusUpdate')
          ) {
            axle_health$1.v1.Visit.encode(
              message.labOrderStatusUpdate,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.labOrderResults != null &&
            Object.hasOwnProperty.call(message, 'labOrderResults')
          ) {
            axle_health$1.v1.LabResults.encode(
              message.labOrderResults,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AxleHealthEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.labOrderStatusUpdate = axle_health$1.v1.Visit.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.labOrderResults = axle_health$1.v1.LabResults.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.AxleHealthEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.AxleHealthEventPayload();
          if (object.labOrderStatusUpdate != null) {
            if (typeof object.labOrderStatusUpdate !== 'object') {
              throw new TypeError(
                '.sources.v1.AxleHealthEventPayload.labOrderStatusUpdate: object expected, but got ' +
                  typeof object.labOrderStatusUpdate
              );
            }
            message.labOrderStatusUpdate = axle_health$1.v1.Visit.fromObject(
              object.labOrderStatusUpdate
            );
          }
          if (object.labOrderResults != null) {
            if (typeof object.labOrderResults !== 'object') {
              throw new TypeError(
                '.sources.v1.AxleHealthEventPayload.labOrderResults: object expected, but got ' +
                  typeof object.labOrderResults
              );
            }
            message.labOrderResults = axle_health$1.v1.LabResults.fromObject(
              object.labOrderResults
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.labOrderStatusUpdate != null &&
            message.hasOwnProperty('labOrderStatusUpdate')
          ) {
            object.labOrderStatusUpdate = axle_health$1.v1.Visit.toObject(
              message.labOrderStatusUpdate,
              options
            );
            if (options.oneofs) {
              object.event = 'labOrderStatusUpdate';
            }
          }
          if (
            message.labOrderResults != null &&
            message.hasOwnProperty('labOrderResults')
          ) {
            object.labOrderResults = axle_health$1.v1.LabResults.toObject(
              message.labOrderResults,
              options
            );
            if (options.oneofs) {
              object.event = 'labOrderResults';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.AxleHealthEventPayload';
        }
      }

      AxleHealthEventPayload.prototype.labOrderStatusUpdate = null;
      AxleHealthEventPayload.prototype.labOrderResults = null;

      return AxleHealthEventPayload;
    })();

    v1.ButterflyLabsEventPayload = (() => {
      class ButterflyLabsEventPayload {
        get event() {
          for (const key of ['labOrderStatusUpdate', 'labOrderResults']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['labOrderStatusUpdate', 'labOrderResults']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ButterflyLabsEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.labOrderStatusUpdate != null &&
            Object.hasOwnProperty.call(message, 'labOrderStatusUpdate')
          ) {
            butterfly_labs$1.v1.Order.encode(
              message.labOrderStatusUpdate,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.labOrderResults != null &&
            Object.hasOwnProperty.call(message, 'labOrderResults')
          ) {
            butterfly_labs$1.v1.LabResults.encode(
              message.labOrderResults,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ButterflyLabsEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.labOrderStatusUpdate = butterfly_labs$1.v1.Order.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.labOrderResults = butterfly_labs$1.v1.LabResults.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.ButterflyLabsEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.ButterflyLabsEventPayload();
          if (object.labOrderStatusUpdate != null) {
            if (typeof object.labOrderStatusUpdate !== 'object') {
              throw new TypeError(
                '.sources.v1.ButterflyLabsEventPayload.labOrderStatusUpdate: object expected, but got ' +
                  typeof object.labOrderStatusUpdate
              );
            }
            message.labOrderStatusUpdate = butterfly_labs$1.v1.Order.fromObject(
              object.labOrderStatusUpdate
            );
          }
          if (object.labOrderResults != null) {
            if (typeof object.labOrderResults !== 'object') {
              throw new TypeError(
                '.sources.v1.ButterflyLabsEventPayload.labOrderResults: object expected, but got ' +
                  typeof object.labOrderResults
              );
            }
            message.labOrderResults = butterfly_labs$1.v1.LabResults.fromObject(
              object.labOrderResults
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.labOrderStatusUpdate != null &&
            message.hasOwnProperty('labOrderStatusUpdate')
          ) {
            object.labOrderStatusUpdate = butterfly_labs$1.v1.Order.toObject(
              message.labOrderStatusUpdate,
              options
            );
            if (options.oneofs) {
              object.event = 'labOrderStatusUpdate';
            }
          }
          if (
            message.labOrderResults != null &&
            message.hasOwnProperty('labOrderResults')
          ) {
            object.labOrderResults = butterfly_labs$1.v1.LabResults.toObject(
              message.labOrderResults,
              options
            );
            if (options.oneofs) {
              object.event = 'labOrderResults';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.ButterflyLabsEventPayload';
        }
      }

      ButterflyLabsEventPayload.prototype.labOrderStatusUpdate = null;
      ButterflyLabsEventPayload.prototype.labOrderResults = null;

      return ButterflyLabsEventPayload;
    })();

    v1.VitalEventPayload = (() => {
      class VitalEventPayload {
        get event() {
          for (const key of ['labOrderStatusUpdate', 'labOrderResults']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['labOrderStatusUpdate', 'labOrderResults']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new VitalEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.labOrderStatusUpdate != null &&
            Object.hasOwnProperty.call(message, 'labOrderStatusUpdate')
          ) {
            vital$1.v1.Order.encode(
              message.labOrderStatusUpdate,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.labOrderResults != null &&
            Object.hasOwnProperty.call(message, 'labOrderResults')
          ) {
            vital$1.v1.LabResults.encode(
              message.labOrderResults,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new VitalEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.labOrderStatusUpdate = vital$1.v1.Order.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.labOrderResults = vital$1.v1.LabResults.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.VitalEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.VitalEventPayload();
          if (object.labOrderStatusUpdate != null) {
            if (typeof object.labOrderStatusUpdate !== 'object') {
              throw new TypeError(
                '.sources.v1.VitalEventPayload.labOrderStatusUpdate: object expected, but got ' +
                  typeof object.labOrderStatusUpdate
              );
            }
            message.labOrderStatusUpdate = vital$1.v1.Order.fromObject(
              object.labOrderStatusUpdate
            );
          }
          if (object.labOrderResults != null) {
            if (typeof object.labOrderResults !== 'object') {
              throw new TypeError(
                '.sources.v1.VitalEventPayload.labOrderResults: object expected, but got ' +
                  typeof object.labOrderResults
              );
            }
            message.labOrderResults = vital$1.v1.LabResults.fromObject(
              object.labOrderResults
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.labOrderStatusUpdate != null &&
            message.hasOwnProperty('labOrderStatusUpdate')
          ) {
            object.labOrderStatusUpdate = vital$1.v1.Order.toObject(
              message.labOrderStatusUpdate,
              options
            );
            if (options.oneofs) {
              object.event = 'labOrderStatusUpdate';
            }
          }
          if (
            message.labOrderResults != null &&
            message.hasOwnProperty('labOrderResults')
          ) {
            object.labOrderResults = vital$1.v1.LabResults.toObject(
              message.labOrderResults,
              options
            );
            if (options.oneofs) {
              object.event = 'labOrderResults';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.VitalEventPayload';
        }
      }

      VitalEventPayload.prototype.labOrderStatusUpdate = null;
      VitalEventPayload.prototype.labOrderResults = null;

      return VitalEventPayload;
    })();

    v1.SegmentEventPayload = (() => {
      class SegmentEventPayload {
        get event() {
          for (const key of ['identify', 'track', 'page']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['identify', 'track', 'page']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SegmentEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.identify != null &&
            Object.hasOwnProperty.call(message, 'identify')
          ) {
            segment$1.v1.Identify.encode(
              message.identify,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.track != null &&
            Object.hasOwnProperty.call(message, 'track')
          ) {
            segment$1.v1.Track.encode(
              message.track,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            segment$1.v1.Page.encode(
              message.page,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SegmentEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.identify = segment$1.v1.Identify.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.track = segment$1.v1.Track.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.page = segment$1.v1.Page.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.SegmentEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.SegmentEventPayload();
          if (object.identify != null) {
            if (typeof object.identify !== 'object') {
              throw new TypeError(
                '.sources.v1.SegmentEventPayload.identify: object expected, but got ' +
                  typeof object.identify
              );
            }
            message.identify = segment$1.v1.Identify.fromObject(
              object.identify
            );
          }
          if (object.track != null) {
            if (typeof object.track !== 'object') {
              throw new TypeError(
                '.sources.v1.SegmentEventPayload.track: object expected, but got ' +
                  typeof object.track
              );
            }
            message.track = segment$1.v1.Track.fromObject(object.track);
          }
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.sources.v1.SegmentEventPayload.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = segment$1.v1.Page.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.identify != null && message.hasOwnProperty('identify')) {
            object.identify = segment$1.v1.Identify.toObject(
              message.identify,
              options
            );
            if (options.oneofs) {
              object.event = 'identify';
            }
          }
          if (message.track != null && message.hasOwnProperty('track')) {
            object.track = segment$1.v1.Track.toObject(message.track, options);
            if (options.oneofs) {
              object.event = 'track';
            }
          }
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = segment$1.v1.Page.toObject(message.page, options);
            if (options.oneofs) {
              object.event = 'page';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.SegmentEventPayload';
        }
      }

      SegmentEventPayload.prototype.identify = null;
      SegmentEventPayload.prototype.track = null;
      SegmentEventPayload.prototype.page = null;

      return SegmentEventPayload;
    })();

    v1.CustomerIoEventPayload = (() => {
      class CustomerIoEventPayload {
        get event() {
          for (const key of ['identify', 'track', 'page']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['identify', 'track', 'page']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomerIoEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.identify != null &&
            Object.hasOwnProperty.call(message, 'identify')
          ) {
            customerio$1.v1.Identify.encode(
              message.identify,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.track != null &&
            Object.hasOwnProperty.call(message, 'track')
          ) {
            customerio$1.v1.Track.encode(
              message.track,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            customerio$1.v1.Page.encode(
              message.page,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomerIoEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.identify = customerio$1.v1.Identify.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.track = customerio$1.v1.Track.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.page = customerio$1.v1.Page.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.CustomerIoEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.CustomerIoEventPayload();
          if (object.identify != null) {
            if (typeof object.identify !== 'object') {
              throw new TypeError(
                '.sources.v1.CustomerIoEventPayload.identify: object expected, but got ' +
                  typeof object.identify
              );
            }
            message.identify = customerio$1.v1.Identify.fromObject(
              object.identify
            );
          }
          if (object.track != null) {
            if (typeof object.track !== 'object') {
              throw new TypeError(
                '.sources.v1.CustomerIoEventPayload.track: object expected, but got ' +
                  typeof object.track
              );
            }
            message.track = customerio$1.v1.Track.fromObject(object.track);
          }
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.sources.v1.CustomerIoEventPayload.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = customerio$1.v1.Page.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.identify != null && message.hasOwnProperty('identify')) {
            object.identify = customerio$1.v1.Identify.toObject(
              message.identify,
              options
            );
            if (options.oneofs) {
              object.event = 'identify';
            }
          }
          if (message.track != null && message.hasOwnProperty('track')) {
            object.track = customerio$1.v1.Track.toObject(
              message.track,
              options
            );
            if (options.oneofs) {
              object.event = 'track';
            }
          }
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = customerio$1.v1.Page.toObject(message.page, options);
            if (options.oneofs) {
              object.event = 'page';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.CustomerIoEventPayload';
        }
      }

      CustomerIoEventPayload.prototype.identify = null;
      CustomerIoEventPayload.prototype.track = null;
      CustomerIoEventPayload.prototype.page = null;

      return CustomerIoEventPayload;
    })();

    v1.ActiveCampaignEventPayload = (() => {
      class ActiveCampaignEventPayload {
        get event() {
          for (const key of ['dealUpdate']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of ['dealUpdate']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ActiveCampaignEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.dealUpdate != null &&
            Object.hasOwnProperty.call(message, 'dealUpdate')
          ) {
            active_campaign$1.v1.Deal.encode(
              message.dealUpdate,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ActiveCampaignEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.dealUpdate = active_campaign$1.v1.Deal.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.ActiveCampaignEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.ActiveCampaignEventPayload();
          if (object.dealUpdate != null) {
            if (typeof object.dealUpdate !== 'object') {
              throw new TypeError(
                '.sources.v1.ActiveCampaignEventPayload.dealUpdate: object expected, but got ' +
                  typeof object.dealUpdate
              );
            }
            message.dealUpdate = active_campaign$1.v1.Deal.fromObject(
              object.dealUpdate
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.dealUpdate != null &&
            message.hasOwnProperty('dealUpdate')
          ) {
            object.dealUpdate = active_campaign$1.v1.Deal.toObject(
              message.dealUpdate,
              options
            );
            if (options.oneofs) {
              object.event = 'dealUpdate';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.ActiveCampaignEventPayload';
        }
      }

      ActiveCampaignEventPayload.prototype.dealUpdate = null;

      return ActiveCampaignEventPayload;
    })();

    v1.IntakeQEventPayload = (() => {
      class IntakeQEventPayload {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new IntakeQEventPayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(8).int32(message.eventType);
          }
          if (
            message.appointment != null &&
            Object.hasOwnProperty.call(message, 'appointment')
          ) {
            intakeq$1.v1.Appointment.encode(
              message.appointment,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new IntakeQEventPayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventType = reader.int32();
                break;
              }
              case 2: {
                message.appointment = intakeq$1.v1.Appointment.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.IntakeQEventPayload) {
            return object;
          }
          const message = new $root.sources.v1.IntakeQEventPayload();
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_ILLEGAL_INTAKEQ_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'INTAKEQ_APPOINTMENT_SCHEDULED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'INTAKEQ_APPOINTMENT_CANCELLED':
              case 2: {
                message.eventType = 2;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.appointment != null) {
            if (typeof object.appointment !== 'object') {
              throw new TypeError(
                '.sources.v1.IntakeQEventPayload.appointment: object expected, but got ' +
                  typeof object.appointment
              );
            }
            message.appointment = intakeq$1.v1.Appointment.fromObject(
              object.appointment
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventType =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_INTAKEQ_EVENT_TYPE'
                : 0;
            object.appointment = null;
          }
          let keys;
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? intakeq$1.v1.IntakeQEventType[message.eventType] === undefined
                  ? message.eventType
                  : intakeq$1.v1.IntakeQEventType[message.eventType]
                : message.eventType;
          }
          if (
            message.appointment != null &&
            message.hasOwnProperty('appointment')
          ) {
            object.appointment = intakeq$1.v1.Appointment.toObject(
              message.appointment,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.IntakeQEventPayload';
        }
      }

      IntakeQEventPayload.prototype.eventType = 0;
      IntakeQEventPayload.prototype.appointment = null;

      return IntakeQEventPayload;
    })();

    v1.FormsortUrls = (() => {
      class FormsortUrls {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortUrls(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.formsUrl != null &&
            Object.hasOwnProperty.call(message, 'formsUrl')
          ) {
            writer.uint32(10).string(message.formsUrl);
          }
          if (
            message.revisionUpdatesUrl != null &&
            Object.hasOwnProperty.call(message, 'revisionUpdatesUrl')
          ) {
            writer.uint32(18).string(message.revisionUpdatesUrl);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortUrls();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formsUrl = reader.string();
                break;
              }
              case 2: {
                message.revisionUpdatesUrl = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.FormsortUrls) {
            return object;
          }
          const message = new $root.sources.v1.FormsortUrls();
          if (object.formsUrl != null) {
            message.formsUrl = String(object.formsUrl);
          }
          if (object.revisionUpdatesUrl != null) {
            message.revisionUpdatesUrl = String(object.revisionUpdatesUrl);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.formsUrl = '';
            object.revisionUpdatesUrl = '';
          }
          let keys;
          if (message.formsUrl != null && message.hasOwnProperty('formsUrl')) {
            object.formsUrl = message.formsUrl;
          }
          if (
            message.revisionUpdatesUrl != null &&
            message.hasOwnProperty('revisionUpdatesUrl')
          ) {
            object.revisionUpdatesUrl = message.revisionUpdatesUrl;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.FormsortUrls';
        }
      }

      FormsortUrls.prototype.formsUrl = '';
      FormsortUrls.prototype.revisionUpdatesUrl = '';

      return FormsortUrls;
    })();

    v1.MorfUrls = (() => {
      class MorfUrls {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfUrls(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.appointmentUpcoming != null &&
            Object.hasOwnProperty.call(message, 'appointmentUpcoming')
          ) {
            writer.uint32(10).string(message.appointmentUpcoming);
          }
          if (
            message.appointmentEnded != null &&
            Object.hasOwnProperty.call(message, 'appointmentEnded')
          ) {
            writer.uint32(18).string(message.appointmentEnded);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfUrls();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointmentUpcoming = reader.string();
                break;
              }
              case 2: {
                message.appointmentEnded = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.MorfUrls) {
            return object;
          }
          const message = new $root.sources.v1.MorfUrls();
          if (object.appointmentUpcoming != null) {
            message.appointmentUpcoming = String(object.appointmentUpcoming);
          }
          if (object.appointmentEnded != null) {
            message.appointmentEnded = String(object.appointmentEnded);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointmentUpcoming = '';
            object.appointmentEnded = '';
          }
          let keys;
          if (
            message.appointmentUpcoming != null &&
            message.hasOwnProperty('appointmentUpcoming')
          ) {
            object.appointmentUpcoming = message.appointmentUpcoming;
          }
          if (
            message.appointmentEnded != null &&
            message.hasOwnProperty('appointmentEnded')
          ) {
            object.appointmentEnded = message.appointmentEnded;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.MorfUrls';
        }
      }

      MorfUrls.prototype.appointmentUpcoming = '';
      MorfUrls.prototype.appointmentEnded = '';

      return MorfUrls;
    })();

    v1.Source = (() => {
      class Source {
        get type() {
          for (const key of ['webhook']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ['webhook']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Source(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(8).int32(message.sourceApplication);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(26).string(message.label);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(34).string(message.description);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timestamp.encode(
              message.timing,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.webhook != null &&
            Object.hasOwnProperty.call(message, 'webhook')
          ) {
            $root.sources.v1.WebhookSource.encode(
              message.webhook,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Source();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 3: {
                message.label = reader.string();
                break;
              }
              case 4: {
                message.description = reader.string();
                break;
              }
              case 5: {
                message.timing = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.webhook = $root.sources.v1.WebhookSource.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.Source) {
            return object;
          }
          const message = new $root.sources.v1.Source();
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE_SANDBOX':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'HEALTHIE_PRODUCTION':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'AXLE_HEALTH_DEVELOPMENT':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'AXLE_HEALTH_PRODUCTION':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'MORF':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'PUSH_JSON':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'ACUITY':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'VITAL_SANDBOX':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'VITAL_PRODUCTION':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'SEGMENT':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              case 'INTAKEQ':
              case 14: {
                message.sourceApplication = 14;
                break;
              }
              case 'CUSTOMER_IO':
              case 15: {
                message.sourceApplication = 15;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.sources.v1.Source.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timestamp.fromObject(object.timing);
          }
          if (object.webhook != null) {
            if (typeof object.webhook !== 'object') {
              throw new TypeError(
                '.sources.v1.Source.webhook: object expected, but got ' +
                  typeof object.webhook
              );
            }
            message.webhook = $root.sources.v1.WebhookSource.fromObject(
              object.webhook
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.sourceApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.label = '';
            object.timing = null;
          }
          let keys;
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? $root.sources.v1.SourceApplication[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : $root.sources.v1.SourceApplication[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timestamp.toObject(
              message.timing,
              options
            );
          }
          if (message.webhook != null && message.hasOwnProperty('webhook')) {
            object.webhook = $root.sources.v1.WebhookSource.toObject(
              message.webhook,
              options
            );
            if (options.oneofs) {
              object.type = 'webhook';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.Source';
        }
      }

      Source.prototype.sourceApplication = 0;
      Source.prototype.label = '';
      Source.prototype.description = null;
      Source.prototype.timing = null;
      Source.prototype.webhook = null;

      return Source;
    })();

    v1.SourceResponse = (() => {
      class SourceResponse {
        get type() {
          for (const key of ['webhook']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ['webhook']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SourceResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(10).string(message.sourceId);
          }
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(16).int32(message.sourceApplication);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(34).string(message.label);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(42).string(message.description);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timestamp.encode(
              message.timing,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.webhook != null &&
            Object.hasOwnProperty.call(message, 'webhook')
          ) {
            $root.sources.v1.WebhookSourceResponse.encode(
              message.webhook,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(66).string(message.organizationId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SourceResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceId = reader.string();
                break;
              }
              case 2: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 4: {
                message.label = reader.string();
                break;
              }
              case 5: {
                message.description = reader.string();
                break;
              }
              case 6: {
                message.timing = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.webhook = $root.sources.v1.WebhookSourceResponse.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.organizationId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.SourceResponse) {
            return object;
          }
          const message = new $root.sources.v1.SourceResponse();
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE_SANDBOX':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'HEALTHIE_PRODUCTION':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'AXLE_HEALTH_DEVELOPMENT':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'AXLE_HEALTH_PRODUCTION':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'MORF':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'PUSH_JSON':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'ACUITY':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'VITAL_SANDBOX':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'VITAL_PRODUCTION':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'SEGMENT':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              case 'INTAKEQ':
              case 14: {
                message.sourceApplication = 14;
                break;
              }
              case 'CUSTOMER_IO':
              case 15: {
                message.sourceApplication = 15;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.sources.v1.SourceResponse.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timestamp.fromObject(object.timing);
          }
          if (object.webhook != null) {
            if (typeof object.webhook !== 'object') {
              throw new TypeError(
                '.sources.v1.SourceResponse.webhook: object expected, but got ' +
                  typeof object.webhook
              );
            }
            message.webhook = $root.sources.v1.WebhookSourceResponse.fromObject(
              object.webhook
            );
          }
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.sourceId = '';
            object.sourceApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.label = '';
            object.timing = null;
            object.organizationId = '';
          }
          let keys;
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? $root.sources.v1.SourceApplication[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : $root.sources.v1.SourceApplication[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timestamp.toObject(
              message.timing,
              options
            );
          }
          if (message.webhook != null && message.hasOwnProperty('webhook')) {
            object.webhook = $root.sources.v1.WebhookSourceResponse.toObject(
              message.webhook,
              options
            );
            if (options.oneofs) {
              object.type = 'webhook';
            }
          }
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.SourceResponse';
        }
      }

      SourceResponse.prototype.sourceId = '';
      SourceResponse.prototype.sourceApplication = 0;
      SourceResponse.prototype.label = '';
      SourceResponse.prototype.description = null;
      SourceResponse.prototype.timing = null;
      SourceResponse.prototype.webhook = null;
      SourceResponse.prototype.organizationId = '';

      return SourceResponse;
    })();

    v1.SourceDataReceivedNotification = (() => {
      class SourceDataReceivedNotification {
        get sourceNotificationKind() {
          for (const key of ['webhookRequest']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set sourceNotificationKind(name) {
          for (const key of ['webhookRequest']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SourceDataReceivedNotification(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhookRequest != null &&
            Object.hasOwnProperty.call(message, 'webhookRequest')
          ) {
            $root.sources.v1.WebhookRequestReceived.encode(
              message.webhookRequest,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SourceDataReceivedNotification();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.webhookRequest =
                  $root.sources.v1.WebhookRequestReceived.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.sources.v1.SourceDataReceivedNotification
          ) {
            return object;
          }
          const message = new $root.sources.v1.SourceDataReceivedNotification();
          if (object.webhookRequest != null) {
            if (typeof object.webhookRequest !== 'object') {
              throw new TypeError(
                '.sources.v1.SourceDataReceivedNotification.webhookRequest: object expected, but got ' +
                  typeof object.webhookRequest
              );
            }
            message.webhookRequest =
              $root.sources.v1.WebhookRequestReceived.fromObject(
                object.webhookRequest
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.webhookRequest != null &&
            message.hasOwnProperty('webhookRequest')
          ) {
            object.webhookRequest =
              $root.sources.v1.WebhookRequestReceived.toObject(
                message.webhookRequest,
                options
              );
            if (options.oneofs) {
              object.sourceNotificationKind = 'webhookRequest';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.SourceDataReceivedNotification';
        }
      }

      SourceDataReceivedNotification.prototype.webhookRequest = null;

      return SourceDataReceivedNotification;
    })();

    v1.WebhookRequest = (() => {
      class WebhookRequest {
        get body() {
          for (const key of ['bytes', 'json']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set body(name) {
          for (const key of ['bytes', 'json']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          this.headers = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WebhookRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhookRequestId != null &&
            Object.hasOwnProperty.call(message, 'webhookRequestId')
          ) {
            writer.uint32(10).string(message.webhookRequestId);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(18).string(message.sourceId);
          }
          if (
            message.requestTime != null &&
            Object.hasOwnProperty.call(message, 'requestTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.requestTime,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.method != null &&
            Object.hasOwnProperty.call(message, 'method')
          ) {
            writer.uint32(34).string(message.method);
          }
          if (
            message.host != null &&
            Object.hasOwnProperty.call(message, 'host')
          ) {
            writer.uint32(42).string(message.host);
          }
          if (
            message.rawQueryParams != null &&
            Object.hasOwnProperty.call(message, 'rawQueryParams')
          ) {
            writer.uint32(58).string(message.rawQueryParams);
          }
          if (
            message.headers != null &&
            Object.hasOwnProperty.call(message, 'headers')
          ) {
            for (const element of message.headers) {
              $root.sources.v1.WebhookRequestHeader.encode(
                element,
                writer.uint32(66).fork()
              ).ldelim();
            }
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(72).int32(message.eventType);
          }
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(82).string(message.organizationId);
          }
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(88).int32(message.application);
          }
          if (
            message.bytes != null &&
            Object.hasOwnProperty.call(message, 'bytes')
          ) {
            writer.uint32(98).bytes(message.bytes);
          }
          if (
            message.json != null &&
            Object.hasOwnProperty.call(message, 'json')
          ) {
            google$2.protobuf.Struct.encode(
              message.json,
              writer.uint32(106).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WebhookRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.webhookRequestId = reader.string();
                break;
              }
              case 2: {
                message.sourceId = reader.string();
                break;
              }
              case 3: {
                message.requestTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.method = reader.string();
                break;
              }
              case 5: {
                message.host = reader.string();
                break;
              }
              case 7: {
                message.rawQueryParams = reader.string();
                break;
              }
              case 8: {
                if (!message.headers || !message.headers.length) {
                  message.headers = [];
                }
                message.headers.push(
                  $root.sources.v1.WebhookRequestHeader.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 9: {
                message.eventType = reader.int32();
                break;
              }
              case 10: {
                message.organizationId = reader.string();
                break;
              }
              case 11: {
                message.application = reader.int32();
                break;
              }
              case 12: {
                message.bytes = reader.bytes();
                break;
              }
              case 13: {
                message.json = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.WebhookRequest) {
            return object;
          }
          const message = new $root.sources.v1.WebhookRequest();
          if (object.webhookRequestId != null) {
            message.webhookRequestId = String(object.webhookRequestId);
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.requestTime != null) {
            if (typeof object.requestTime !== 'object') {
              throw new TypeError(
                '.sources.v1.WebhookRequest.requestTime: object expected, but got ' +
                  typeof object.requestTime
              );
            }
            message.requestTime = timing$1.v1.Timestamp.fromObject(
              object.requestTime
            );
          }
          if (object.method != null) {
            message.method = String(object.method);
          }
          if (object.host != null) {
            message.host = String(object.host);
          }
          if (object.rawQueryParams != null) {
            message.rawQueryParams = String(object.rawQueryParams);
          }
          if (object.headers) {
            if (!Array.isArray(object.headers)) {
              throw new TypeError(
                '.sources.v1.WebhookRequest.headers: array type expected, but got ' +
                  typeof object.headers
              );
            }
            message.headers = new Array(object.headers.length);
            for (let i = 0; i < object.headers.length; ++i) {
              if (typeof object.headers[i] !== 'object') {
                throw new TypeError(
                  '.sources.v1.WebhookRequest.headers: object expected, but got ' +
                    typeof object.headers[i]
                );
              }
              message.headers[i] =
                $root.sources.v1.WebhookRequestHeader.fromObject(
                  object.headers[i]
                );
            }
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_FORM_RESPONSE_UPDATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'FORMSORT_FORM_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'MIGRATION':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'MORF_API_CALL':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'CUSTOM_PAYLOAD':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'VITAL_LAB_ORDER':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'SEGMENT_EVENT':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'ACTIVE_CAMPAIGN_WEBHOOK':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'INTAKEQ_APPOINTMENT':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'CUSTOMER_IO_EVENT':
              case 16: {
                message.eventType = 16;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE_SANDBOX':
              case 2: {
                message.application = 2;
                break;
              }
              case 'HEALTHIE_PRODUCTION':
              case 3: {
                message.application = 3;
                break;
              }
              case 'AXLE_HEALTH_DEVELOPMENT':
              case 4: {
                message.application = 4;
                break;
              }
              case 'AXLE_HEALTH_PRODUCTION':
              case 5: {
                message.application = 5;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 6: {
                message.application = 6;
                break;
              }
              case 'MORF':
              case 7: {
                message.application = 7;
                break;
              }
              case 'PUSH_JSON':
              case 8: {
                message.application = 8;
                break;
              }
              case 'ACUITY':
              case 9: {
                message.application = 9;
                break;
              }
              case 'VITAL_SANDBOX':
              case 10: {
                message.application = 10;
                break;
              }
              case 'VITAL_PRODUCTION':
              case 11: {
                message.application = 11;
                break;
              }
              case 'SEGMENT':
              case 12: {
                message.application = 12;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 13: {
                message.application = 13;
                break;
              }
              case 'INTAKEQ':
              case 14: {
                message.application = 14;
                break;
              }
              case 'CUSTOMER_IO':
              case 15: {
                message.application = 15;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.bytes != null) {
            if (typeof object.bytes === 'string') {
              $util.base64.decode(
                object.bytes,
                (message.bytes = $util.newBuffer(
                  $util.base64.length(object.bytes)
                )),
                0
              );
            } else if (object.bytes.length >= 0) {
              message.bytes = object.bytes;
            }
          }
          if (object.json != null) {
            if (typeof object.json !== 'object') {
              throw new TypeError(
                '.sources.v1.WebhookRequest.json: object expected, but got ' +
                  typeof object.json
              );
            }
            message.json = google$2.protobuf.Struct.fromObject(object.json);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.headers = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.webhookRequestId = '';
            object.sourceId = '';
            object.requestTime = null;
            object.method = '';
            object.host = '';
            object.rawQueryParams = '';
            object.eventType =
              options.enums === String ? 'UNSPECIFIED_EVENT_TYPE' : 0;
            object.organizationId = '';
            object.application =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
          }
          let keys;
          if (
            message.webhookRequestId != null &&
            message.hasOwnProperty('webhookRequestId')
          ) {
            object.webhookRequestId = message.webhookRequestId;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.requestTime != null &&
            message.hasOwnProperty('requestTime')
          ) {
            object.requestTime = timing$1.v1.Timestamp.toObject(
              message.requestTime,
              options
            );
          }
          if (message.method != null && message.hasOwnProperty('method')) {
            object.method = message.method;
          }
          if (message.host != null && message.hasOwnProperty('host')) {
            object.host = message.host;
          }
          if (
            message.rawQueryParams != null &&
            message.hasOwnProperty('rawQueryParams')
          ) {
            object.rawQueryParams = message.rawQueryParams;
          }
          if (message.headers && message.headers.length) {
            object.headers = new Array(message.headers.length);
            for (let i = 0; i < message.headers.length; ++i) {
              object.headers[i] =
                $root.sources.v1.WebhookRequestHeader.toObject(
                  message.headers[i],
                  options
                );
            }
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? $root.sources.v1.EventType[message.eventType] === undefined
                  ? message.eventType
                  : $root.sources.v1.EventType[message.eventType]
                : message.eventType;
          }
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? $root.sources.v1.SourceApplication[message.application] ===
                  undefined
                  ? message.application
                  : $root.sources.v1.SourceApplication[message.application]
                : message.application;
          }
          if (message.bytes != null && message.hasOwnProperty('bytes')) {
            object.bytes =
              options.bytes === String
                ? $util.base64.encode(message.bytes, 0, message.bytes.length)
                : options.bytes === Array
                ? Array.prototype.slice.call(message.bytes)
                : message.bytes;
            if (options.oneofs) {
              object.body = 'bytes';
            }
          }
          if (message.json != null && message.hasOwnProperty('json')) {
            object.json = google$2.protobuf.Struct.toObject(
              message.json,
              options
            );
            if (options.oneofs) {
              object.body = 'json';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.WebhookRequest';
        }
      }

      WebhookRequest.prototype.webhookRequestId = '';
      WebhookRequest.prototype.sourceId = '';
      WebhookRequest.prototype.requestTime = null;
      WebhookRequest.prototype.method = '';
      WebhookRequest.prototype.host = '';
      WebhookRequest.prototype.rawQueryParams = '';
      WebhookRequest.prototype.headers = $util.emptyArray;
      WebhookRequest.prototype.eventType = 0;
      WebhookRequest.prototype.organizationId = '';
      WebhookRequest.prototype.application = 0;
      WebhookRequest.prototype.bytes = new Uint8Array();
      WebhookRequest.prototype.json = null;

      return WebhookRequest;
    })();

    v1.WebhookRequestResponse = (() => {
      class WebhookRequestResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WebhookRequestResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhookRequestId != null &&
            Object.hasOwnProperty.call(message, 'webhookRequestId')
          ) {
            writer.uint32(10).string(message.webhookRequestId);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(18).string(message.sourceId);
          }
          if (
            message.requestTime != null &&
            Object.hasOwnProperty.call(message, 'requestTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.requestTime,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(32).int32(message.eventType);
          }
          if (
            message.body != null &&
            Object.hasOwnProperty.call(message, 'body')
          ) {
            google$2.protobuf.Struct.encode(
              message.body,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WebhookRequestResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.webhookRequestId = reader.string();
                break;
              }
              case 2: {
                message.sourceId = reader.string();
                break;
              }
              case 3: {
                message.requestTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.eventType = reader.int32();
                break;
              }
              case 5: {
                message.body = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.WebhookRequestResponse) {
            return object;
          }
          const message = new $root.sources.v1.WebhookRequestResponse();
          if (object.webhookRequestId != null) {
            message.webhookRequestId = String(object.webhookRequestId);
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.requestTime != null) {
            if (typeof object.requestTime !== 'object') {
              throw new TypeError(
                '.sources.v1.WebhookRequestResponse.requestTime: object expected, but got ' +
                  typeof object.requestTime
              );
            }
            message.requestTime = timing$1.v1.Timestamp.fromObject(
              object.requestTime
            );
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_FORM_RESPONSE_UPDATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'FORMSORT_FORM_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'MIGRATION':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'MORF_API_CALL':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'CUSTOM_PAYLOAD':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'VITAL_LAB_ORDER':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'SEGMENT_EVENT':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'ACTIVE_CAMPAIGN_WEBHOOK':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'INTAKEQ_APPOINTMENT':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'CUSTOMER_IO_EVENT':
              case 16: {
                message.eventType = 16;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.body != null) {
            if (typeof object.body !== 'object') {
              throw new TypeError(
                '.sources.v1.WebhookRequestResponse.body: object expected, but got ' +
                  typeof object.body
              );
            }
            message.body = google$2.protobuf.Struct.fromObject(object.body);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.webhookRequestId = '';
            object.sourceId = '';
            object.requestTime = null;
            object.eventType =
              options.enums === String ? 'UNSPECIFIED_EVENT_TYPE' : 0;
            object.body = null;
          }
          let keys;
          if (
            message.webhookRequestId != null &&
            message.hasOwnProperty('webhookRequestId')
          ) {
            object.webhookRequestId = message.webhookRequestId;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.requestTime != null &&
            message.hasOwnProperty('requestTime')
          ) {
            object.requestTime = timing$1.v1.Timestamp.toObject(
              message.requestTime,
              options
            );
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? $root.sources.v1.EventType[message.eventType] === undefined
                  ? message.eventType
                  : $root.sources.v1.EventType[message.eventType]
                : message.eventType;
          }
          if (message.body != null && message.hasOwnProperty('body')) {
            object.body = google$2.protobuf.Struct.toObject(
              message.body,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.WebhookRequestResponse';
        }
      }

      WebhookRequestResponse.prototype.webhookRequestId = '';
      WebhookRequestResponse.prototype.sourceId = '';
      WebhookRequestResponse.prototype.requestTime = null;
      WebhookRequestResponse.prototype.eventType = 0;
      WebhookRequestResponse.prototype.body = null;

      return WebhookRequestResponse;
    })();

    v1.WebhookRequestHeader = (() => {
      class WebhookRequestHeader {
        constructor(properties) {
          this.value = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WebhookRequestHeader(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.key != null &&
            Object.hasOwnProperty.call(message, 'key')
          ) {
            writer.uint32(10).string(message.key);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            for (const element of message.value) {
              writer.uint32(18).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WebhookRequestHeader();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.key = reader.string();
                break;
              }
              case 2: {
                if (!message.value || !message.value.length) {
                  message.value = [];
                }
                message.value.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.WebhookRequestHeader) {
            return object;
          }
          const message = new $root.sources.v1.WebhookRequestHeader();
          if (object.key != null) {
            message.key = String(object.key);
          }
          if (object.value) {
            if (!Array.isArray(object.value)) {
              throw new TypeError(
                '.sources.v1.WebhookRequestHeader.value: array type expected, but got ' +
                  typeof object.value
              );
            }
            message.value = new Array(object.value.length);
            for (let i = 0; i < object.value.length; ++i) {
              message.value[i] = String(object.value[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.value = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.key = '';
          }
          let keys;
          if (message.key != null && message.hasOwnProperty('key')) {
            object.key = message.key;
          }
          if (message.value && message.value.length) {
            object.value = new Array(message.value.length);
            for (let i = 0; i < message.value.length; ++i) {
              object.value[i] = message.value[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.WebhookRequestHeader';
        }
      }

      WebhookRequestHeader.prototype.key = '';
      WebhookRequestHeader.prototype.value = $util.emptyArray;

      return WebhookRequestHeader;
    })();

    v1.WebhookRequestReceived = (() => {
      class WebhookRequestReceived {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WebhookRequestReceived(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhookRequestId != null &&
            Object.hasOwnProperty.call(message, 'webhookRequestId')
          ) {
            writer.uint32(10).string(message.webhookRequestId);
          }
          if (
            message.webhookRequest != null &&
            Object.hasOwnProperty.call(message, 'webhookRequest')
          ) {
            $root.sources.v1.WebhookRequest.encode(
              message.webhookRequest,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WebhookRequestReceived();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.webhookRequestId = reader.string();
                break;
              }
              case 2: {
                message.webhookRequest = $root.sources.v1.WebhookRequest.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.WebhookRequestReceived) {
            return object;
          }
          const message = new $root.sources.v1.WebhookRequestReceived();
          if (object.webhookRequestId != null) {
            message.webhookRequestId = String(object.webhookRequestId);
          }
          if (object.webhookRequest != null) {
            if (typeof object.webhookRequest !== 'object') {
              throw new TypeError(
                '.sources.v1.WebhookRequestReceived.webhookRequest: object expected, but got ' +
                  typeof object.webhookRequest
              );
            }
            message.webhookRequest = $root.sources.v1.WebhookRequest.fromObject(
              object.webhookRequest
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.webhookRequestId = '';
            object.webhookRequest = null;
          }
          let keys;
          if (
            message.webhookRequestId != null &&
            message.hasOwnProperty('webhookRequestId')
          ) {
            object.webhookRequestId = message.webhookRequestId;
          }
          if (
            message.webhookRequest != null &&
            message.hasOwnProperty('webhookRequest')
          ) {
            object.webhookRequest = $root.sources.v1.WebhookRequest.toObject(
              message.webhookRequest,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.WebhookRequestReceived';
        }
      }

      WebhookRequestReceived.prototype.webhookRequestId = '';
      WebhookRequestReceived.prototype.webhookRequest = null;

      return WebhookRequestReceived;
    })();

    v1.WebhookSource = (() => {
      class WebhookSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WebhookSource(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WebhookSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.WebhookSource) {
            return object;
          }
          const message = new $root.sources.v1.WebhookSource();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.WebhookSource';
        }
      }

      return WebhookSource;
    })();

    v1.WebhookSourceResponse = (() => {
      class WebhookSourceResponse {
        get url() {
          for (const key of ['fullUrl', 'formsortUrls', 'morfUrls']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set url(name) {
          for (const key of ['fullUrl', 'formsortUrls', 'morfUrls']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WebhookSourceResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fullUrl != null &&
            Object.hasOwnProperty.call(message, 'fullUrl')
          ) {
            writer.uint32(10).string(message.fullUrl);
          }
          if (
            message.formsortUrls != null &&
            Object.hasOwnProperty.call(message, 'formsortUrls')
          ) {
            $root.sources.v1.FormsortUrls.encode(
              message.formsortUrls,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.morfUrls != null &&
            Object.hasOwnProperty.call(message, 'morfUrls')
          ) {
            $root.sources.v1.MorfUrls.encode(
              message.morfUrls,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WebhookSourceResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fullUrl = reader.string();
                break;
              }
              case 2: {
                message.formsortUrls = $root.sources.v1.FormsortUrls.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.morfUrls = $root.sources.v1.MorfUrls.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.WebhookSourceResponse) {
            return object;
          }
          const message = new $root.sources.v1.WebhookSourceResponse();
          if (object.fullUrl != null) {
            message.fullUrl = String(object.fullUrl);
          }
          if (object.formsortUrls != null) {
            if (typeof object.formsortUrls !== 'object') {
              throw new TypeError(
                '.sources.v1.WebhookSourceResponse.formsortUrls: object expected, but got ' +
                  typeof object.formsortUrls
              );
            }
            message.formsortUrls = $root.sources.v1.FormsortUrls.fromObject(
              object.formsortUrls
            );
          }
          if (object.morfUrls != null) {
            if (typeof object.morfUrls !== 'object') {
              throw new TypeError(
                '.sources.v1.WebhookSourceResponse.morfUrls: object expected, but got ' +
                  typeof object.morfUrls
              );
            }
            message.morfUrls = $root.sources.v1.MorfUrls.fromObject(
              object.morfUrls
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.fullUrl != null && message.hasOwnProperty('fullUrl')) {
            object.fullUrl = message.fullUrl;
            if (options.oneofs) {
              object.url = 'fullUrl';
            }
          }
          if (
            message.formsortUrls != null &&
            message.hasOwnProperty('formsortUrls')
          ) {
            object.formsortUrls = $root.sources.v1.FormsortUrls.toObject(
              message.formsortUrls,
              options
            );
            if (options.oneofs) {
              object.url = 'formsortUrls';
            }
          }
          if (message.morfUrls != null && message.hasOwnProperty('morfUrls')) {
            object.morfUrls = $root.sources.v1.MorfUrls.toObject(
              message.morfUrls,
              options
            );
            if (options.oneofs) {
              object.url = 'morfUrls';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.WebhookSourceResponse';
        }
      }

      WebhookSourceResponse.prototype.fullUrl = '';
      WebhookSourceResponse.prototype.formsortUrls = null;
      WebhookSourceResponse.prototype.morfUrls = null;

      return WebhookSourceResponse;
    })();

    v1.CreateSourceRequest = (() => {
      class CreateSourceRequest {
        get type() {
          for (const key of ['webhook']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of ['webhook']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateSourceRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(8).int32(message.sourceApplication);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(18).string(message.label);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.webhook != null &&
            Object.hasOwnProperty.call(message, 'webhook')
          ) {
            $root.sources.v1.CreateWebhookSourceRequest.encode(
              message.webhook,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateSourceRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 2: {
                message.label = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 5: {
                message.webhook =
                  $root.sources.v1.CreateWebhookSourceRequest.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.CreateSourceRequest) {
            return object;
          }
          const message = new $root.sources.v1.CreateSourceRequest();
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE_SANDBOX':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'HEALTHIE_PRODUCTION':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'AXLE_HEALTH_DEVELOPMENT':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'AXLE_HEALTH_PRODUCTION':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'MORF':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'PUSH_JSON':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'ACUITY':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'VITAL_SANDBOX':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'VITAL_PRODUCTION':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'SEGMENT':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              case 'INTAKEQ':
              case 14: {
                message.sourceApplication = 14;
                break;
              }
              case 'CUSTOMER_IO':
              case 15: {
                message.sourceApplication = 15;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.webhook != null) {
            if (typeof object.webhook !== 'object') {
              throw new TypeError(
                '.sources.v1.CreateSourceRequest.webhook: object expected, but got ' +
                  typeof object.webhook
              );
            }
            message.webhook =
              $root.sources.v1.CreateWebhookSourceRequest.fromObject(
                object.webhook
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.sourceApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.label = '';
          }
          let keys;
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? $root.sources.v1.SourceApplication[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : $root.sources.v1.SourceApplication[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.webhook != null && message.hasOwnProperty('webhook')) {
            object.webhook =
              $root.sources.v1.CreateWebhookSourceRequest.toObject(
                message.webhook,
                options
              );
            if (options.oneofs) {
              object.type = 'webhook';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.CreateSourceRequest';
        }
      }

      CreateSourceRequest.prototype.sourceApplication = 0;
      CreateSourceRequest.prototype.label = '';
      CreateSourceRequest.prototype.description = null;
      CreateSourceRequest.prototype.webhook = null;

      return CreateSourceRequest;
    })();

    v1.UpdateSourceRequest = (() => {
      class UpdateSourceRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateSourceRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(18).string(message.label);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateSourceRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.label = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.UpdateSourceRequest) {
            return object;
          }
          const message = new $root.sources.v1.UpdateSourceRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.UpdateSourceRequest';
        }
      }

      UpdateSourceRequest.prototype.id = '';
      UpdateSourceRequest.prototype.label = null;
      UpdateSourceRequest.prototype.description = null;

      return UpdateSourceRequest;
    })();

    v1.CreateSourceResponse = (() => {
      class CreateSourceResponse {
        get source() {
          for (const key of ['webhook']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set source(name) {
          for (const key of ['webhook']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateSourceResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timestamp.encode(
              message.timing,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.webhook != null &&
            Object.hasOwnProperty.call(message, 'webhook')
          ) {
            $root.sources.v1.WebhookSourceResponse.encode(
              message.webhook,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateSourceResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.timing = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.webhook = $root.sources.v1.WebhookSourceResponse.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.CreateSourceResponse) {
            return object;
          }
          const message = new $root.sources.v1.CreateSourceResponse();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.sources.v1.CreateSourceResponse.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timestamp.fromObject(object.timing);
          }
          if (object.webhook != null) {
            if (typeof object.webhook !== 'object') {
              throw new TypeError(
                '.sources.v1.CreateSourceResponse.webhook: object expected, but got ' +
                  typeof object.webhook
              );
            }
            message.webhook = $root.sources.v1.WebhookSourceResponse.fromObject(
              object.webhook
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.timing = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timestamp.toObject(
              message.timing,
              options
            );
          }
          if (message.webhook != null && message.hasOwnProperty('webhook')) {
            object.webhook = $root.sources.v1.WebhookSourceResponse.toObject(
              message.webhook,
              options
            );
            if (options.oneofs) {
              object.source = 'webhook';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.CreateSourceResponse';
        }
      }

      CreateSourceResponse.prototype.id = '';
      CreateSourceResponse.prototype.timing = null;
      CreateSourceResponse.prototype.webhook = null;

      return CreateSourceResponse;
    })();

    v1.UpdateSourceResponse = (() => {
      class UpdateSourceResponse {
        get source() {
          for (const key of ['webhook']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set source(name) {
          for (const key of ['webhook']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateSourceResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.webhook != null &&
            Object.hasOwnProperty.call(message, 'webhook')
          ) {
            $root.sources.v1.WebhookSourceResponse.encode(
              message.webhook,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateSourceResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.webhook = $root.sources.v1.WebhookSourceResponse.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.UpdateSourceResponse) {
            return object;
          }
          const message = new $root.sources.v1.UpdateSourceResponse();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.sources.v1.UpdateSourceResponse.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.webhook != null) {
            if (typeof object.webhook !== 'object') {
              throw new TypeError(
                '.sources.v1.UpdateSourceResponse.webhook: object expected, but got ' +
                  typeof object.webhook
              );
            }
            message.webhook = $root.sources.v1.WebhookSourceResponse.fromObject(
              object.webhook
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.timing = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.webhook != null && message.hasOwnProperty('webhook')) {
            object.webhook = $root.sources.v1.WebhookSourceResponse.toObject(
              message.webhook,
              options
            );
            if (options.oneofs) {
              object.source = 'webhook';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.UpdateSourceResponse';
        }
      }

      UpdateSourceResponse.prototype.id = '';
      UpdateSourceResponse.prototype.timing = null;
      UpdateSourceResponse.prototype.webhook = null;

      return UpdateSourceResponse;
    })();

    v1.DeleteSourceRequest = (() => {
      class DeleteSourceRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteSourceRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteSourceRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.DeleteSourceRequest) {
            return object;
          }
          const message = new $root.sources.v1.DeleteSourceRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.DeleteSourceRequest';
        }
      }

      DeleteSourceRequest.prototype.id = '';

      return DeleteSourceRequest;
    })();

    v1.DeleteSourceResponse = (() => {
      class DeleteSourceResponse {
        get source() {
          for (const key of ['webhook']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set source(name) {
          for (const key of ['webhook']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteSourceResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.webhook != null &&
            Object.hasOwnProperty.call(message, 'webhook')
          ) {
            $root.sources.v1.WebhookSourceResponse.encode(
              message.webhook,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteSourceResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.webhook = $root.sources.v1.WebhookSourceResponse.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.DeleteSourceResponse) {
            return object;
          }
          const message = new $root.sources.v1.DeleteSourceResponse();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.webhook != null) {
            if (typeof object.webhook !== 'object') {
              throw new TypeError(
                '.sources.v1.DeleteSourceResponse.webhook: object expected, but got ' +
                  typeof object.webhook
              );
            }
            message.webhook = $root.sources.v1.WebhookSourceResponse.fromObject(
              object.webhook
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.webhook != null && message.hasOwnProperty('webhook')) {
            object.webhook = $root.sources.v1.WebhookSourceResponse.toObject(
              message.webhook,
              options
            );
            if (options.oneofs) {
              object.source = 'webhook';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.DeleteSourceResponse';
        }
      }

      DeleteSourceResponse.prototype.id = '';
      DeleteSourceResponse.prototype.webhook = null;

      return DeleteSourceResponse;
    })();

    v1.CreateWebhookSourceRequest = (() => {
      class CreateWebhookSourceRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWebhookSourceRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.autoRegisterWebhooks != null &&
            Object.hasOwnProperty.call(message, 'autoRegisterWebhooks')
          ) {
            writer.uint32(8).bool(message.autoRegisterWebhooks);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWebhookSourceRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.autoRegisterWebhooks = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.CreateWebhookSourceRequest) {
            return object;
          }
          const message = new $root.sources.v1.CreateWebhookSourceRequest();
          if (object.autoRegisterWebhooks != null) {
            message.autoRegisterWebhooks = Boolean(object.autoRegisterWebhooks);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.autoRegisterWebhooks = false;
          }
          let keys;
          if (
            message.autoRegisterWebhooks != null &&
            message.hasOwnProperty('autoRegisterWebhooks')
          ) {
            object.autoRegisterWebhooks = message.autoRegisterWebhooks;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.CreateWebhookSourceRequest';
        }
      }

      CreateWebhookSourceRequest.prototype.autoRegisterWebhooks = false;

      return CreateWebhookSourceRequest;
    })();

    v1.GetWebhookRequestParams = (() => {
      class GetWebhookRequestParams {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWebhookRequestParams(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWebhookRequestParams();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.GetWebhookRequestParams) {
            return object;
          }
          const message = new $root.sources.v1.GetWebhookRequestParams();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.GetWebhookRequestParams';
        }
      }

      GetWebhookRequestParams.prototype.id = '';

      return GetWebhookRequestParams;
    })();

    v1.ListReceivedSourceDataRequest = (() => {
      class ListReceivedSourceDataRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListReceivedSourceDataRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(10).string(message.sourceId);
          }
          if (
            message.lastNEvents != null &&
            Object.hasOwnProperty.call(message, 'lastNEvents')
          ) {
            writer.uint32(16).int32(message.lastNEvents);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListReceivedSourceDataRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceId = reader.string();
                break;
              }
              case 2: {
                message.lastNEvents = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.sources.v1.ListReceivedSourceDataRequest
          ) {
            return object;
          }
          const message = new $root.sources.v1.ListReceivedSourceDataRequest();
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.lastNEvents != null) {
            message.lastNEvents = object.lastNEvents | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.sourceId = '';
            object.lastNEvents = 0;
          }
          let keys;
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.lastNEvents != null &&
            message.hasOwnProperty('lastNEvents')
          ) {
            object.lastNEvents = message.lastNEvents;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.ListReceivedSourceDataRequest';
        }
      }

      ListReceivedSourceDataRequest.prototype.sourceId = '';
      ListReceivedSourceDataRequest.prototype.lastNEvents = 0;

      return ListReceivedSourceDataRequest;
    })();

    v1.ListWebhooksRequest = (() => {
      class ListWebhooksRequest {
        constructor(properties) {
          this.bodyKeywordSearch = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWebhooksRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.pagination != null &&
            Object.hasOwnProperty.call(message, 'pagination')
          ) {
            pagination$1.v1.Pagination.encode(
              message.pagination,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(16).int32(message.eventType);
          }
          if (
            message.startDatetime != null &&
            Object.hasOwnProperty.call(message, 'startDatetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.startDatetime,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.endDatetime != null &&
            Object.hasOwnProperty.call(message, 'endDatetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.endDatetime,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.bodyKeywordSearch != null &&
            Object.hasOwnProperty.call(message, 'bodyKeywordSearch')
          ) {
            for (const element of message.bodyKeywordSearch) {
              writer.uint32(42).string(element);
            }
          }
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(48).int32(message.sourceApplication);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWebhooksRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.pagination = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.eventType = reader.int32();
                break;
              }
              case 3: {
                message.startDatetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.endDatetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                if (
                  !message.bodyKeywordSearch ||
                  !message.bodyKeywordSearch.length
                ) {
                  message.bodyKeywordSearch = [];
                }
                message.bodyKeywordSearch.push(reader.string());
                break;
              }
              case 6: {
                message.sourceApplication = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.ListWebhooksRequest) {
            return object;
          }
          const message = new $root.sources.v1.ListWebhooksRequest();
          if (object.pagination != null) {
            if (typeof object.pagination !== 'object') {
              throw new TypeError(
                '.sources.v1.ListWebhooksRequest.pagination: object expected, but got ' +
                  typeof object.pagination
              );
            }
            message.pagination = pagination$1.v1.Pagination.fromObject(
              object.pagination
            );
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_FORM_RESPONSE_UPDATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'FORMSORT_FORM_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'MIGRATION':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'MORF_API_CALL':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'CUSTOM_PAYLOAD':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'VITAL_LAB_ORDER':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'SEGMENT_EVENT':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'ACTIVE_CAMPAIGN_WEBHOOK':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'INTAKEQ_APPOINTMENT':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'CUSTOMER_IO_EVENT':
              case 16: {
                message.eventType = 16;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.startDatetime != null) {
            if (typeof object.startDatetime !== 'object') {
              throw new TypeError(
                '.sources.v1.ListWebhooksRequest.startDatetime: object expected, but got ' +
                  typeof object.startDatetime
              );
            }
            message.startDatetime = timing$1.v1.Timestamp.fromObject(
              object.startDatetime
            );
          }
          if (object.endDatetime != null) {
            if (typeof object.endDatetime !== 'object') {
              throw new TypeError(
                '.sources.v1.ListWebhooksRequest.endDatetime: object expected, but got ' +
                  typeof object.endDatetime
              );
            }
            message.endDatetime = timing$1.v1.Timestamp.fromObject(
              object.endDatetime
            );
          }
          if (object.bodyKeywordSearch) {
            if (!Array.isArray(object.bodyKeywordSearch)) {
              throw new TypeError(
                '.sources.v1.ListWebhooksRequest.bodyKeywordSearch: array type expected, but got ' +
                  typeof object.bodyKeywordSearch
              );
            }
            message.bodyKeywordSearch = new Array(
              object.bodyKeywordSearch.length
            );
            for (let i = 0; i < object.bodyKeywordSearch.length; ++i) {
              message.bodyKeywordSearch[i] = String(
                object.bodyKeywordSearch[i]
              );
            }
          }
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE_SANDBOX':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'HEALTHIE_PRODUCTION':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'AXLE_HEALTH_DEVELOPMENT':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'AXLE_HEALTH_PRODUCTION':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'MORF':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'PUSH_JSON':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'ACUITY':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'VITAL_SANDBOX':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'VITAL_PRODUCTION':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'SEGMENT':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              case 'INTAKEQ':
              case 14: {
                message.sourceApplication = 14;
                break;
              }
              case 'CUSTOMER_IO':
              case 15: {
                message.sourceApplication = 15;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.bodyKeywordSearch = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.pagination = null;
          }
          let keys;
          if (
            message.pagination != null &&
            message.hasOwnProperty('pagination')
          ) {
            object.pagination = pagination$1.v1.Pagination.toObject(
              message.pagination,
              options
            );
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? $root.sources.v1.EventType[message.eventType] === undefined
                  ? message.eventType
                  : $root.sources.v1.EventType[message.eventType]
                : message.eventType;
          }
          if (
            message.startDatetime != null &&
            message.hasOwnProperty('startDatetime')
          ) {
            object.startDatetime = timing$1.v1.Timestamp.toObject(
              message.startDatetime,
              options
            );
          }
          if (
            message.endDatetime != null &&
            message.hasOwnProperty('endDatetime')
          ) {
            object.endDatetime = timing$1.v1.Timestamp.toObject(
              message.endDatetime,
              options
            );
          }
          if (message.bodyKeywordSearch && message.bodyKeywordSearch.length) {
            object.bodyKeywordSearch = new Array(
              message.bodyKeywordSearch.length
            );
            for (let i = 0; i < message.bodyKeywordSearch.length; ++i) {
              object.bodyKeywordSearch[i] = message.bodyKeywordSearch[i];
            }
          }
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? $root.sources.v1.SourceApplication[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : $root.sources.v1.SourceApplication[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.ListWebhooksRequest';
        }
      }

      ListWebhooksRequest.prototype.pagination = null;
      ListWebhooksRequest.prototype.eventType = null;
      ListWebhooksRequest.prototype.startDatetime = null;
      ListWebhooksRequest.prototype.endDatetime = null;
      ListWebhooksRequest.prototype.bodyKeywordSearch = $util.emptyArray;
      ListWebhooksRequest.prototype.sourceApplication = null;

      return ListWebhooksRequest;
    })();

    v1.ListWebhooksResponse = (() => {
      class ListWebhooksResponse {
        constructor(properties) {
          this.webhooks = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWebhooksResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhooks != null &&
            Object.hasOwnProperty.call(message, 'webhooks')
          ) {
            for (const element of message.webhooks) {
              $root.sources.v1.WebhookRequestResponse.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWebhooksResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.webhooks || !message.webhooks.length) {
                  message.webhooks = [];
                }
                message.webhooks.push(
                  $root.sources.v1.WebhookRequestResponse.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.ListWebhooksResponse) {
            return object;
          }
          const message = new $root.sources.v1.ListWebhooksResponse();
          if (object.webhooks) {
            if (!Array.isArray(object.webhooks)) {
              throw new TypeError(
                '.sources.v1.ListWebhooksResponse.webhooks: array type expected, but got ' +
                  typeof object.webhooks
              );
            }
            message.webhooks = new Array(object.webhooks.length);
            for (let i = 0; i < object.webhooks.length; ++i) {
              if (typeof object.webhooks[i] !== 'object') {
                throw new TypeError(
                  '.sources.v1.ListWebhooksResponse.webhooks: object expected, but got ' +
                    typeof object.webhooks[i]
                );
              }
              message.webhooks[i] =
                $root.sources.v1.WebhookRequestResponse.fromObject(
                  object.webhooks[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.webhooks = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.webhooks && message.webhooks.length) {
            object.webhooks = new Array(message.webhooks.length);
            for (let i = 0; i < message.webhooks.length; ++i) {
              object.webhooks[i] =
                $root.sources.v1.WebhookRequestResponse.toObject(
                  message.webhooks[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.ListWebhooksResponse';
        }
      }

      ListWebhooksResponse.prototype.webhooks = $util.emptyArray;

      return ListWebhooksResponse;
    })();

    v1.ListReceivedSourceDataResponse = (() => {
      class ListReceivedSourceDataResponse {
        get sourceDataKind() {
          for (const key of ['webhookData']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set sourceDataKind(name) {
          for (const key of ['webhookData']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListReceivedSourceDataResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhookData != null &&
            Object.hasOwnProperty.call(message, 'webhookData')
          ) {
            $root.sources.v1.SourceWebhookData.encode(
              message.webhookData,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListReceivedSourceDataResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.webhookData = $root.sources.v1.SourceWebhookData.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.sources.v1.ListReceivedSourceDataResponse
          ) {
            return object;
          }
          const message = new $root.sources.v1.ListReceivedSourceDataResponse();
          if (object.webhookData != null) {
            if (typeof object.webhookData !== 'object') {
              throw new TypeError(
                '.sources.v1.ListReceivedSourceDataResponse.webhookData: object expected, but got ' +
                  typeof object.webhookData
              );
            }
            message.webhookData = $root.sources.v1.SourceWebhookData.fromObject(
              object.webhookData
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.webhookData != null &&
            message.hasOwnProperty('webhookData')
          ) {
            object.webhookData = $root.sources.v1.SourceWebhookData.toObject(
              message.webhookData,
              options
            );
            if (options.oneofs) {
              object.sourceDataKind = 'webhookData';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.ListReceivedSourceDataResponse';
        }
      }

      ListReceivedSourceDataResponse.prototype.webhookData = null;

      return ListReceivedSourceDataResponse;
    })();

    v1.ListSourceRequest = (() => {
      class ListSourceRequest {
        constructor(properties) {
          this.orderBys = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListSourceRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.orderBys != null &&
            Object.hasOwnProperty.call(message, 'orderBys')
          ) {
            for (const element of message.orderBys) {
              $root.sources.v1.OrderBySource.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListSourceRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.orderBys || !message.orderBys.length) {
                  message.orderBys = [];
                }
                message.orderBys.push(
                  $root.sources.v1.OrderBySource.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.ListSourceRequest) {
            return object;
          }
          const message = new $root.sources.v1.ListSourceRequest();
          if (object.orderBys) {
            if (!Array.isArray(object.orderBys)) {
              throw new TypeError(
                '.sources.v1.ListSourceRequest.orderBys: array type expected, but got ' +
                  typeof object.orderBys
              );
            }
            message.orderBys = new Array(object.orderBys.length);
            for (let i = 0; i < object.orderBys.length; ++i) {
              if (typeof object.orderBys[i] !== 'object') {
                throw new TypeError(
                  '.sources.v1.ListSourceRequest.orderBys: object expected, but got ' +
                    typeof object.orderBys[i]
                );
              }
              message.orderBys[i] = $root.sources.v1.OrderBySource.fromObject(
                object.orderBys[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.orderBys = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.orderBys && message.orderBys.length) {
            object.orderBys = new Array(message.orderBys.length);
            for (let i = 0; i < message.orderBys.length; ++i) {
              object.orderBys[i] = $root.sources.v1.OrderBySource.toObject(
                message.orderBys[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.ListSourceRequest';
        }
      }

      ListSourceRequest.prototype.orderBys = $util.emptyArray;

      return ListSourceRequest;
    })();

    v1.ListSourceResponse = (() => {
      class ListSourceResponse {
        constructor(properties) {
          this.sources = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListSourceResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sources != null &&
            Object.hasOwnProperty.call(message, 'sources')
          ) {
            for (const element of message.sources) {
              $root.sources.v1.SourceResponse.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListSourceResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.sources || !message.sources.length) {
                  message.sources = [];
                }
                message.sources.push(
                  $root.sources.v1.SourceResponse.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.ListSourceResponse) {
            return object;
          }
          const message = new $root.sources.v1.ListSourceResponse();
          if (object.sources) {
            if (!Array.isArray(object.sources)) {
              throw new TypeError(
                '.sources.v1.ListSourceResponse.sources: array type expected, but got ' +
                  typeof object.sources
              );
            }
            message.sources = new Array(object.sources.length);
            for (let i = 0; i < object.sources.length; ++i) {
              if (typeof object.sources[i] !== 'object') {
                throw new TypeError(
                  '.sources.v1.ListSourceResponse.sources: object expected, but got ' +
                    typeof object.sources[i]
                );
              }
              message.sources[i] = $root.sources.v1.SourceResponse.fromObject(
                object.sources[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sources = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.sources && message.sources.length) {
            object.sources = new Array(message.sources.length);
            for (let i = 0; i < message.sources.length; ++i) {
              object.sources[i] = $root.sources.v1.SourceResponse.toObject(
                message.sources[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.ListSourceResponse';
        }
      }

      ListSourceResponse.prototype.sources = $util.emptyArray;

      return ListSourceResponse;
    })();

    v1.GetSourceRequest = (() => {
      class GetSourceRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetSourceRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetSourceRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.GetSourceRequest) {
            return object;
          }
          const message = new $root.sources.v1.GetSourceRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.GetSourceRequest';
        }
      }

      GetSourceRequest.prototype.id = '';

      return GetSourceRequest;
    })();

    v1.GetSourceResponse = (() => {
      class GetSourceResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetSourceResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.source != null &&
            Object.hasOwnProperty.call(message, 'source')
          ) {
            $root.sources.v1.SourceResponse.encode(
              message.source,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetSourceResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.source = $root.sources.v1.SourceResponse.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.GetSourceResponse) {
            return object;
          }
          const message = new $root.sources.v1.GetSourceResponse();
          if (object.source != null) {
            if (typeof object.source !== 'object') {
              throw new TypeError(
                '.sources.v1.GetSourceResponse.source: object expected, but got ' +
                  typeof object.source
              );
            }
            message.source = $root.sources.v1.SourceResponse.fromObject(
              object.source
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.source = null;
          }
          let keys;
          if (message.source != null && message.hasOwnProperty('source')) {
            object.source = $root.sources.v1.SourceResponse.toObject(
              message.source,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.GetSourceResponse';
        }
      }

      GetSourceResponse.prototype.source = null;

      return GetSourceResponse;
    })();

    v1.OrderBySource = (() => {
      class OrderBySource {
        constructor(properties) {
          this.columns = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrderBySource(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.columns != null &&
            Object.hasOwnProperty.call(message, 'columns')
          ) {
            writer.uint32(10).fork();
            for (const element of message.columns) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          if (
            message.direction != null &&
            Object.hasOwnProperty.call(message, 'direction')
          ) {
            writer.uint32(16).int32(message.direction);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrderBySource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.columns || !message.columns.length) {
                  message.columns = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.columns.push(reader.int32());
                } else {
                  message.columns.push(reader.int32());
                }
                break;
              }
              case 2: {
                message.direction = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.OrderBySource) {
            return object;
          }
          const message = new $root.sources.v1.OrderBySource();
          if (object.columns) {
            if (!Array.isArray(object.columns)) {
              throw new TypeError(
                '.sources.v1.OrderBySource.columns: array type expected, but got ' +
                  typeof object.columns
              );
            }
            message.columns = new Array(object.columns.length);
            for (let i = 0; i < object.columns.length; ++i) {
              switch (object.columns[i]) {
                case 'UNSPECIFIED_ORDER_BY_SOURCE_COLUMN':
                case 0: {
                  message.columns[i] = 0;
                  break;
                }
                case 'LABEL':
                case 1: {
                  message.columns[i] = 1;
                  break;
                }
                case 'TIMING':
                case 2: {
                  message.columns[i] = 2;
                  break;
                }
                default: {
                  if (typeof object.columns[i] == 'number') {
                    message.columns[i] = object.columns[i];
                    break;
                  }
                  message.columns[i] = 0;
                  break;
                }
              }
            }
          }
          if (object.direction != null) {
            switch (object.direction) {
              case 'UNSPECIFIED_ORDER_BY_DIRECTION':
              case 0: {
                message.direction = 0;
                break;
              }
              case 'ASC':
              case 1: {
                message.direction = 1;
                break;
              }
              case 'DESC':
              case 2: {
                message.direction = 2;
                break;
              }
              default: {
                if (typeof object.direction == 'number') {
                  message.direction = object.direction;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.columns = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.direction =
              options.enums === String ? 'UNSPECIFIED_ORDER_BY_DIRECTION' : 0;
          }
          let keys;
          if (message.columns && message.columns.length) {
            object.columns = new Array(message.columns.length);
            for (let i = 0; i < message.columns.length; ++i) {
              object.columns[i] =
                options.enums === String
                  ? $root.sources.v1.OrderBySourceColumn[message.columns[i]] ===
                    undefined
                    ? message.columns[i]
                    : $root.sources.v1.OrderBySourceColumn[message.columns[i]]
                  : message.columns[i];
            }
          }
          if (
            message.direction != null &&
            message.hasOwnProperty('direction')
          ) {
            object.direction =
              options.enums === String
                ? order_by$1.v1.OrderByDirection[message.direction] ===
                  undefined
                  ? message.direction
                  : order_by$1.v1.OrderByDirection[message.direction]
                : message.direction;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.OrderBySource';
        }
      }

      OrderBySource.prototype.columns = $util.emptyArray;
      OrderBySource.prototype.direction = 0;

      return OrderBySource;
    })();

    v1.SaveWebhookRequestReply = (() => {
      class SaveWebhookRequestReply {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SaveWebhookRequestReply(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SaveWebhookRequestReply();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.SaveWebhookRequestReply) {
            return object;
          }
          const message = new $root.sources.v1.SaveWebhookRequestReply();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.SaveWebhookRequestReply';
        }
      }

      return SaveWebhookRequestReply;
    })();

    v1.SourceWebhookData = (() => {
      class SourceWebhookData {
        constructor(properties) {
          this.webhookRequests = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SourceWebhookData(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhookRequests != null &&
            Object.hasOwnProperty.call(message, 'webhookRequests')
          ) {
            for (const element of message.webhookRequests) {
              $root.sources.v1.WebhookRequest.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SourceWebhookData();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.webhookRequests ||
                  !message.webhookRequests.length
                ) {
                  message.webhookRequests = [];
                }
                message.webhookRequests.push(
                  $root.sources.v1.WebhookRequest.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.SourceWebhookData) {
            return object;
          }
          const message = new $root.sources.v1.SourceWebhookData();
          if (object.webhookRequests) {
            if (!Array.isArray(object.webhookRequests)) {
              throw new TypeError(
                '.sources.v1.SourceWebhookData.webhookRequests: array type expected, but got ' +
                  typeof object.webhookRequests
              );
            }
            message.webhookRequests = new Array(object.webhookRequests.length);
            for (let i = 0; i < object.webhookRequests.length; ++i) {
              if (typeof object.webhookRequests[i] !== 'object') {
                throw new TypeError(
                  '.sources.v1.SourceWebhookData.webhookRequests: object expected, but got ' +
                    typeof object.webhookRequests[i]
                );
              }
              message.webhookRequests[i] =
                $root.sources.v1.WebhookRequest.fromObject(
                  object.webhookRequests[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.webhookRequests = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.webhookRequests && message.webhookRequests.length) {
            object.webhookRequests = new Array(message.webhookRequests.length);
            for (let i = 0; i < message.webhookRequests.length; ++i) {
              object.webhookRequests[i] =
                $root.sources.v1.WebhookRequest.toObject(
                  message.webhookRequests[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.SourceWebhookData';
        }
      }

      SourceWebhookData.prototype.webhookRequests = $util.emptyArray;

      return SourceWebhookData;
    })();

    v1.UpdateWebhookRequestRequest = (() => {
      class UpdateWebhookRequestRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWebhookRequestRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.reprocess != null &&
            Object.hasOwnProperty.call(message, 'reprocess')
          ) {
            writer.uint32(16).bool(message.reprocess);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWebhookRequestRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.reprocess = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.UpdateWebhookRequestRequest) {
            return object;
          }
          const message = new $root.sources.v1.UpdateWebhookRequestRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.reprocess != null) {
            message.reprocess = Boolean(object.reprocess);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.reprocess != null &&
            message.hasOwnProperty('reprocess')
          ) {
            object.reprocess = message.reprocess;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.UpdateWebhookRequestRequest';
        }
      }

      UpdateWebhookRequestRequest.prototype.id = '';
      UpdateWebhookRequestRequest.prototype.reprocess = null;

      return UpdateWebhookRequestRequest;
    })();

    v1.UpdateWebhookRequestResponse = (() => {
      class UpdateWebhookRequestResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWebhookRequestResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWebhookRequestResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.sources.v1.UpdateWebhookRequestResponse) {
            return object;
          }
          const message = new $root.sources.v1.UpdateWebhookRequestResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'sources.v1.UpdateWebhookRequestResponse';
        }
      }

      return UpdateWebhookRequestResponse;
    })();

    v1.SourceApplication = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_SOURCE_APPLICATION')] = 0;
      values[(valuesById[1] = 'FORMSORT')] = 1;
      values[(valuesById[2] = 'HEALTHIE_SANDBOX')] = 2;
      values[(valuesById[3] = 'HEALTHIE_PRODUCTION')] = 3;
      values[(valuesById[4] = 'AXLE_HEALTH_DEVELOPMENT')] = 4;
      values[(valuesById[5] = 'AXLE_HEALTH_PRODUCTION')] = 5;
      values[(valuesById[6] = 'BUTTERFLY_LABS')] = 6;
      values[(valuesById[7] = 'MORF')] = 7;
      values[(valuesById[8] = 'PUSH_JSON')] = 8;
      values[(valuesById[9] = 'ACUITY')] = 9;
      values[(valuesById[10] = 'VITAL_SANDBOX')] = 10;
      values[(valuesById[11] = 'VITAL_PRODUCTION')] = 11;
      values[(valuesById[12] = 'SEGMENT')] = 12;
      values[(valuesById[13] = 'ACTIVE_CAMPAIGN')] = 13;
      values[(valuesById[14] = 'INTAKEQ')] = 14;
      values[(valuesById[15] = 'CUSTOMER_IO')] = 15;
      return values;
    })();

    v1.EventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_EVENT_TYPE')] = 0;
      values[(valuesById[1] = 'FORMSORT_FORM_RESPONSE_UPDATED')] = 1;
      values[(valuesById[2] = 'FORMSORT_FORM_UPDATED')] = 2;
      values[(valuesById[3] = 'HEALTHIE')] = 3;
      values[(valuesById[4] = 'AXLE_HEALTH_LAB_ORDER')] = 4;
      values[(valuesById[5] = 'BUTTERFLY_LABS_LAB_ORDER')] = 5;
      values[(valuesById[6] = 'MIGRATION')] = 6;
      values[(valuesById[7] = 'MORF_API_CALL')] = 7;
      values[(valuesById[8] = 'MORF_APPOINTMENT_UPCOMING')] = 8;
      values[(valuesById[9] = 'MORF_APPOINTMENT_ENDED')] = 9;
      values[(valuesById[10] = 'CUSTOM_PAYLOAD')] = 10;
      values[(valuesById[11] = 'ACUITY_APPOINTMENT')] = 11;
      values[(valuesById[12] = 'VITAL_LAB_ORDER')] = 12;
      values[(valuesById[13] = 'SEGMENT_EVENT')] = 13;
      values[(valuesById[14] = 'ACTIVE_CAMPAIGN_WEBHOOK')] = 14;
      values[(valuesById[15] = 'INTAKEQ_APPOINTMENT')] = 15;
      values[(valuesById[16] = 'CUSTOMER_IO_EVENT')] = 16;
      return values;
    })();

    v1.ObjectCategory = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_OBJECT_CATEGORY')] = 0;
      values[(valuesById[1] = 'FORM')] = 1;
      values[(valuesById[2] = 'APPOINTMENT')] = 2;
      values[(valuesById[3] = 'PATIENT')] = 3;
      values[(valuesById[4] = 'LAB_ORDER')] = 4;
      return values;
    })();

    v1.MorfAppointmentEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_MORF_APPOINTMENT_EVENT_TYPE')] = 0;
      values[(valuesById[1] = 'MORF_APPOINTMENT_EVENT_UPCOMING')] = 1;
      values[(valuesById[2] = 'MORF_APPOINTMENT_EVENT_ENDED')] = 2;
      return values;
    })();

    v1.OrderBySourceColumn = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ORDER_BY_SOURCE_COLUMN')] = 0;
      values[(valuesById[1] = 'LABEL')] = 1;
      values[(valuesById[2] = 'TIMING')] = 2;
      return values;
    })();

    return v1;
  })();

  return sources;
})();

export const sources = $root.sources;
