import { DestinationActionConfigure } from '../Workflow/CustomNode/DestinationActionNode/DestinationActionConfigure';
import { DestinationActionSettings } from '../Workflow/CustomNode/DestinationActionNode/DestinationActionSettings';
import { FetchActionConfigure } from '../Workflow/CustomNode/FetchActionNode/FetchActionConfigure';
import { FetchActionSettings } from '../Workflow/CustomNode/FetchActionNode/FetchActionSettings';
import { FilterConfigure } from '../Workflow/CustomNode/FilterNode/FilterConfigure';
import { Node } from 'reactflow';
import { NodePanelProps } from './NodePanel/types';
import { NodeType } from '../Workflow/types';
import { PauseConfigure } from '../Workflow/CustomNode/PauseNode/PauseConfigure/PauseConfigure.stories';
import { ProfileConvertConfigure } from '../Workflow/CustomNode/ProfileConvertNode/ProfileConvertConfigure';
import { ProfileConvertSettings } from '../Workflow/CustomNode/ProfileConvertNode/ProfileConvertSettings';
import { ProfileLookupConfigure } from '../Workflow/CustomNode/ProfileLookupNode/ProfileLookupConfigure';
import { ProfileUpdateIdentifiers } from '../Workflow/CustomNode/ProfileUpdateNode/ProfileUpdateIdentifiers';
import { ProfileUpdateProperties } from '../Workflow/CustomNode/ProfileUpdateNode/ProfileUpdateProperties';
import { ProfileUpdateSettings } from '../Workflow/CustomNode/ProfileUpdateNode/ProfileUpdateSettings';
import { RestartConfigure } from '../Workflow/CustomNode/RestartNode/RestartConfigure';
import { TriggerConfigure } from '../Workflow/CustomNode/TriggerNode/TriggerConfigure';

export const renderPanel = (selectedNode: Node): NodePanelProps['tabs'] => {
  switch (selectedNode.type) {
    case NodeType.TriggerNode:
      return [
        {
          name: 'Configure',
          children: <TriggerConfigure {...selectedNode} />,
        },
      ];
    case NodeType.ProfileLookupNode:
      return [
        {
          name: 'Configure',
          children: <ProfileLookupConfigure {...selectedNode} />,
        },
      ];
    case NodeType.ProfileUpdateNode:
      return [
        {
          name: 'Profile IDs',
          children: <ProfileUpdateIdentifiers {...selectedNode} />,
        },
        {
          name: 'Profile Properties',
          children: <ProfileUpdateProperties {...selectedNode} />,
        },
        {
          name: 'Settings',
          children: <ProfileUpdateSettings {...selectedNode} />,
        },
      ];
    case NodeType.ProfileConvertNode:
      return [
        {
          name: 'Configure',
          children: <ProfileConvertConfigure {...selectedNode} />,
        },
        {
          name: 'Settings',
          children: <ProfileConvertSettings {...selectedNode} />,
        },
      ];
    case NodeType.FilterNode:
      return [
        {
          name: 'Configure',
          children: <FilterConfigure {...selectedNode} />,
        },
      ];
    case NodeType.PauseNode:
      return [
        {
          name: 'Configure',
          children: <PauseConfigure {...selectedNode} />,
        },
      ];
    case NodeType.RestartNode:
      return [
        {
          name: 'Configure',
          children: <RestartConfigure {...selectedNode} />,
        },
      ];
    case NodeType.FetchActionNode:
      return [
        {
          name: 'Configure',
          children: <FetchActionConfigure {...selectedNode} />,
        },
        {
          name: 'Settings',
          children: <FetchActionSettings {...selectedNode} />,
        },
      ];
    case NodeType.DestinationActionNode:
      return [
        {
          name: 'Configure',
          children: <DestinationActionConfigure {...selectedNode} />,
        },
        {
          name: 'Settings',
          children: <DestinationActionSettings {...selectedNode} />,
        },
      ];
  }

  return [];
};
