import { values } from '@morf/proto/values_v1_ts_proto';

export const urlToString = (
  urlObject: values.v1.IURL | null | undefined
): string => {
  if (!urlObject) {
    return 'n/a';
  }

  const { scheme, host, path, params } = urlObject;
  const queryParams = !!params?.length
    ? `?${params.map((param) => `${param.key}=${param.values}`).join('&')}`
    : '';
  return `${scheme || 'http'}://${host || ''}${path || ''}${queryParams}`;
};
