import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import rpcService from '@morf/server/browser-server';

export const getValidTriggerEvents = async (
  request: workflows.v1.GetValidTriggerEventsRequest
) => {
  try {
    return await rpcService.workflowsV1Service.getValidTriggerEvents(request);
  } catch (error) {
    throw new Error('getValidTriggerEvents: ' + error);
  }
};
