import { FC, memo, useRef, useState } from 'react';
import { Tooltip, Menu, Container } from '@morf/ui';
import { UserMenuProps } from './types';
import { useClickOutside } from '@morf/ui/Hooks/useClickOutside';
import { StyledUserImage } from './UserMenu.css';
import { useTheme } from '@morf/theming';

const UserMenu: FC<UserMenuProps> = ({
  onCopyStytchSessionJWT,
  onLogout,
  userImage,
}) => {
  const userMenuRef = useRef<HTMLDivElement>(null);
  const [userMenu, setUserMenu] = useState(false);
  const theme = useTheme();

  useClickOutside(userMenuRef, () => {
    setUserMenu(false);
  });

  const items = [
    {
      label: 'Logout',
      icon: {
        name: 'logout',
        fill: 'none',
        stroke: theme.colors.ui.dark,
      },
      onClick: onLogout,
    },
  ];

  if (onCopyStytchSessionJWT) {
    items.splice(0, 0, {
      label: 'Copy JWT to Clipboard',
      icon: {
        name: 'copy',
        fill: theme.colors.ui.dark,
        stroke: 'none',
      },
      onClick: () => {
        onCopyStytchSessionJWT();
        setUserMenu(false);
      },
    });
  }

  return (
    <Tooltip
      tooltipText='Profile and Settings'
      tooltipDirection='right'
      isActive={!userMenu}
    >
      <Container ref={userMenuRef} data-testid='user-menu' cursor='pointer'>
        <StyledUserImage
          alt='user'
          src={userImage}
          onClick={() => setUserMenu(!userMenu)}
          isActive={userMenu}
        />
        {userMenu && (
          <Menu position='absolute' bottom='0' left='3.5rem' items={items} />
        )}
      </Container>
    </Tooltip>
  );
};

export const MemoizedUserMenu = memo(UserMenu);
