import { Container } from '../../Container';
import { FC, memo } from 'react';
import { Text } from '../../Typography';
import { TimestampOptionProps } from './types';
import { calculateTimeframe } from '../calculateTimeframe';
import { useTheme } from '@morf/theming';

const TimestampOption: FC<TimestampOptionProps> = ({
  isSelected,
  label,
  setPlaceholder,
  setShowTimeframes,
  setTimeframe,
  value,
}) => {
  const theme = useTheme();

  const handleOptionClick = (hours: number, placeholder: string) => {
    setTimeframe(calculateTimeframe(new Date(), hours));
    setPlaceholder(placeholder);
    setShowTimeframes(false);
  };

  return (
    <Container
      data-testid='timestamp-option'
      backgroundColor={isSelected ? theme.colors.ui.body : theme.colors.ui.card}
      borderRadius={theme.input.borderRadius}
      cursor='pointer'
      hoverBackgroundColor={theme.colors.ui.body}
      onClick={() => handleOptionClick(value, label)}
      px={0.5}
      py={0.25}
    >
      <Text tag='p2'>{label}</Text>
    </Container>
  );
};

export const MemoizedTimestampOption = memo(TimestampOption);
