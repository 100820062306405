import { ParameterConfigProps } from '../../../types';
import { formatSensitivities } from '../../../../Helpers/formatSensitivities';
import { hasField } from '../../../../Helpers/hasField';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { formatStaticDestinationActionParameterType } from './formatStaticDestinationActionParameterType';
import { formatDynamicDestinationActionParameterType } from './formatDynamicDestinationActionParameterType';

export const formatDestinationActionParameter = (
  param: workflow_parameters.v1.DestinationActionParameter
): ParameterConfigProps => {
  if (hasField(param, 'static')) {
    return {
      fieldName: param.static!.fieldName,
      isRequired: param.static!.isRequired,
      fieldType: param.static!.objectTypeIdContext
        ? 'objectTypeId'
        : formatStaticDestinationActionParameterType(param.static!.fieldType),
      destinationFieldName: param.static!.destinationFieldName || '',
      sensitivity: formatSensitivities(param.static!.sensitivity),
    };
  } else if (hasField(param, 'dynamic')) {
    return {
      fieldName: param.dynamic!.fieldName,
      isRequired: param.dynamic!.isRequired,
      fieldType: formatDynamicDestinationActionParameterType(
        param.dynamic!.elementFieldType
      ),
      destinationFieldName: param.dynamic!.destinationFieldName || '',
      sensitivity: formatSensitivities(param.dynamic!.sensitivity),
    };
  } else return {};
};
