import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { EventSnapshotProps } from './types';
import { Icon } from '../Icon';
import { useTheme } from '@morf/theming';
import { Text } from '../Typography';
import { Snapshot } from './Snapshot';

const EventSnapshot: FC<EventSnapshotProps> = ({ selectedEvent, ...props }) => {
  const theme = useTheme();
  return (
    <Flexbox
      data-testid='event-snapshot'
      justifyContent='flex-start'
      alignItems='center'
      gap={0}
    >
      {!selectedEvent ? (
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          gap={0.5}
        >
          <Icon name='folder-open' stroke={theme.colors.text.muted} />
          <Text tag='p2' color={theme.colors.text.muted}>
            Select an event
          </Text>
        </Flexbox>
      ) : (
        <Snapshot {...selectedEvent} {...props} />
      )}
    </Flexbox>
  );
};

export const MemoizedEventSnapshot = memo(EventSnapshot);
