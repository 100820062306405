import { ThemeContainerProps, css, styled } from '@morf/theming';
import { TooltipProps } from './types';

const Top = ({
  theme,
  color,
}: {
  theme: ThemeContainerProps;
  color: string;
}) => css`
  bottom: calc(100% + ${theme.spacing[0.75]});
  &::after {
    bottom: -${theme.spacing[0.75]};
    border-top-color: ${color};
  }
`;

const Bottom = ({
  theme,
  color,
}: {
  theme: ThemeContainerProps;
  color: string;
}) => css`
  top: calc(100% + ${theme.spacing[0.75]});
  &::after {
    top: -${theme.spacing[0.75]};
    border-bottom-color: ${color};
  }
`;

const Left = ({
  theme,
  color,
}: {
  theme: ThemeContainerProps;
  color: string;
}) => css`
  right: calc(100% + ${theme.spacing[0.75]});
  top: 50%;
  transform: translateY(-50%);
  &::after {
    right: -${theme.spacing[0.75]};
    top: 50%;
    transform: translateY(-50%);
    border-left-color: ${color};
  }
`;

const Right = ({
  theme,
  color,
}: {
  theme: ThemeContainerProps;
  color: string;
}) => css`
  left: calc(100% + ${theme.spacing[0.75]});
  top: 50%;
  transform: translateY(-50%);
  &::after {
    left: -${theme.spacing[0.75]};
    top: 50%;
    transform: translateY(-50%);
    border-right-color: ${color};
  }
`;

const LeftArrow = css(
  ({ theme }) => `
    left: 0;
    &::after {
        left: ${theme.spacing[0.75]};
      }
          `
);

const RightArrow = css(
  ({ theme }) => `
      right: 0;
      &::after {
          right: ${theme.spacing[0.75]};
        }
            `
);

const CenterArrow = css`
  left: 50%;
  transform: translateX(-50%);
  &::after {
    left: 50%;
  }
`;

const handleArrowDirection = (
  tooltipDirection: TooltipProps['tooltipDirection'],
  arrowDirection: TooltipProps['arrowDirection']
) => {
  if (tooltipDirection === 'top' || tooltipDirection === 'bottom') {
    switch (arrowDirection) {
      case 'right':
        return RightArrow;
      case 'center':
        return CenterArrow;
      default:
        return LeftArrow;
    }
  }
};

const handleDirection = (
  tooltipDirection: TooltipProps['tooltipDirection'],
  color: string,
  theme: ThemeContainerProps
) => {
  switch (tooltipDirection) {
    case 'bottom':
      return Bottom({ theme, color });
    case 'left':
      return Left({ theme, color });
    case 'right':
      return Right({ theme, color });
    default:
      return Top({ theme, color });
  }
};

export const StyledTooltip = styled.div<{
  tooltipDirection: TooltipProps['tooltipDirection'];
  arrowDirection: TooltipProps['arrowDirection'];
  backgroundColor: string;
  width?: string;
  height?: string;
}>`
  z-index: var(--tooltip-z-index);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: absolute;
  padding: ${(props) => props.theme.spacing[0.5]};
  border-radius: ${(props) =>
    props.theme.borderRadius[props.theme.tooltip.borderRadius]};
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-width: ${(props) => props.theme.borderWidth[0.0625]};
  border-style: solid;
  border-color: ${(props) => props.backgroundColor};

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-width: calc(${(props) => props.theme.spacing[0.75]} / 2);
    pointer-events: none;
    border-style: solid;
    border-color: transparent;
  }

  ${(props) =>
    handleDirection(
      props.tooltipDirection,
      props.backgroundColor,
      props.theme
    )};
  ${(props) =>
    handleArrowDirection(props.tooltipDirection, props.arrowDirection)};
`;
