import { SelectValuePairCategory } from './SelectValuePairModal/types';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export const getVisibleCategories = (
  selectedParam: workflow_parameters.v1.DestinationActionParameter | null,
  selectedSubRow: number | null
): SelectValuePairCategory[] => {
  if (selectedParam?.static) {
    if (selectedParam?.static?.objectTypeIdContext) {
      return [
        SelectValuePairCategory.ObjectTypeIds,
        SelectValuePairCategory.PayloadField,
        SelectValuePairCategory.CalculatedValue,
        SelectValuePairCategory.ConstantValue,
        SelectValuePairCategory.ElidedValue,
      ];
    }

    return [
      SelectValuePairCategory.Identifiers,
      SelectValuePairCategory.MorfProperties,
      SelectValuePairCategory.CustomProperties,
      SelectValuePairCategory.PayloadField,
      SelectValuePairCategory.CalculatedValue,
      SelectValuePairCategory.ConstantValue,
      SelectValuePairCategory.ElidedValue,
      SelectValuePairCategory.MorfEventType,
    ];
  }

  if (selectedParam?.dynamic?.elementFieldType?.listType) {
    if (selectedSubRow !== null) {
      return [
        SelectValuePairCategory.ObjectTypeIds,
        SelectValuePairCategory.Identifiers,
        SelectValuePairCategory.MorfProperties,
        SelectValuePairCategory.CustomProperties,
        SelectValuePairCategory.PayloadField,
        SelectValuePairCategory.CalculatedValue,
        SelectValuePairCategory.ConstantValue,
        SelectValuePairCategory.ElidedValue,
        SelectValuePairCategory.MorfEventType,
      ];
    }

    return [
      SelectValuePairCategory.CalculatedValue,
      SelectValuePairCategory.PayloadField,
      SelectValuePairCategory.BuildArray,
    ];
  }

  if (selectedParam?.dynamic?.elementFieldType?.mapType) {
    if (selectedSubRow !== null) {
      return [
        SelectValuePairCategory.ObjectTypeIds,
        SelectValuePairCategory.Identifiers,
        SelectValuePairCategory.MorfProperties,
        SelectValuePairCategory.CustomProperties,
        SelectValuePairCategory.PayloadField,
        SelectValuePairCategory.CalculatedValue,
        SelectValuePairCategory.ConstantValue,
        SelectValuePairCategory.ElidedValue,
        SelectValuePairCategory.MorfEventType,
      ];
    }

    return [
      SelectValuePairCategory.CalculatedValue,
      SelectValuePairCategory.PayloadField,
      SelectValuePairCategory.BuildObject,
    ];
  } else {
    return [];
  }
};
