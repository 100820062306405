'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { object_type as object_type$1 } from './object_type_v1_ts_proto';
import { sources as sources$1 } from './sources_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.event_snapshot = (() => {
  const event_snapshot = {};
  event_snapshot.v1 = (() => {
    const v1 = {};
    v1.EventSnapshot = (() => {
      class EventSnapshot {
        constructor(properties) {
          this.patientIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventSnapshot(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.payload != null &&
            Object.hasOwnProperty.call(message, 'payload')
          ) {
            sources$1.v1.EventPayload.encode(
              message.payload,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(24).int32(message.eventType);
          }
          if (
            message.patientIds != null &&
            Object.hasOwnProperty.call(message, 'patientIds')
          ) {
            for (const element of message.patientIds) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.webhookRequestId != null &&
            Object.hasOwnProperty.call(message, 'webhookRequestId')
          ) {
            writer.uint32(42).string(message.webhookRequestId);
          }
          if (
            message.objectType != null &&
            Object.hasOwnProperty.call(message, 'objectType')
          ) {
            object_type$1.v1.ObjectType.encode(
              message.objectType,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventSnapshot();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.payload = sources$1.v1.EventPayload.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.eventType = reader.int32();
                break;
              }
              case 4: {
                if (!message.patientIds || !message.patientIds.length) {
                  message.patientIds = [];
                }
                message.patientIds.push(reader.string());
                break;
              }
              case 5: {
                message.webhookRequestId = reader.string();
                break;
              }
              case 6: {
                message.objectType = object_type$1.v1.ObjectType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_snapshot.v1.EventSnapshot) {
            return object;
          }
          const message = new $root.event_snapshot.v1.EventSnapshot();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.payload != null) {
            if (typeof object.payload !== 'object') {
              throw new TypeError(
                '.event_snapshot.v1.EventSnapshot.payload: object expected, but got ' +
                  typeof object.payload
              );
            }
            message.payload = sources$1.v1.EventPayload.fromObject(
              object.payload
            );
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_FORM_RESPONSE_UPDATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'FORMSORT_FORM_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'MIGRATION':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'MORF_API_CALL':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'CUSTOM_PAYLOAD':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'VITAL_LAB_ORDER':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'SEGMENT_EVENT':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'ACTIVE_CAMPAIGN_WEBHOOK':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'INTAKEQ_APPOINTMENT':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'CUSTOMER_IO_EVENT':
              case 16: {
                message.eventType = 16;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.patientIds) {
            if (!Array.isArray(object.patientIds)) {
              throw new TypeError(
                '.event_snapshot.v1.EventSnapshot.patientIds: array type expected, but got ' +
                  typeof object.patientIds
              );
            }
            message.patientIds = new Array(object.patientIds.length);
            for (let i = 0; i < object.patientIds.length; ++i) {
              message.patientIds[i] = String(object.patientIds[i]);
            }
          }
          if (object.webhookRequestId != null) {
            message.webhookRequestId = String(object.webhookRequestId);
          }
          if (object.objectType != null) {
            if (typeof object.objectType !== 'object') {
              throw new TypeError(
                '.event_snapshot.v1.EventSnapshot.objectType: object expected, but got ' +
                  typeof object.objectType
              );
            }
            message.objectType = object_type$1.v1.ObjectType.fromObject(
              object.objectType
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.patientIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.eventType =
              options.enums === String ? 'UNSPECIFIED_EVENT_TYPE' : 0;
            object.objectType = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.payload != null && message.hasOwnProperty('payload')) {
            object.payload = sources$1.v1.EventPayload.toObject(
              message.payload,
              options
            );
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? sources$1.v1.EventType[message.eventType] === undefined
                  ? message.eventType
                  : sources$1.v1.EventType[message.eventType]
                : message.eventType;
          }
          if (message.patientIds && message.patientIds.length) {
            object.patientIds = new Array(message.patientIds.length);
            for (let i = 0; i < message.patientIds.length; ++i) {
              object.patientIds[i] = message.patientIds[i];
            }
          }
          if (
            message.webhookRequestId != null &&
            message.hasOwnProperty('webhookRequestId')
          ) {
            object.webhookRequestId = message.webhookRequestId;
          }
          if (
            message.objectType != null &&
            message.hasOwnProperty('objectType')
          ) {
            object.objectType = object_type$1.v1.ObjectType.toObject(
              message.objectType,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_snapshot.v1.EventSnapshot';
        }
      }

      EventSnapshot.prototype.id = '';
      EventSnapshot.prototype.payload = null;
      EventSnapshot.prototype.eventType = 0;
      EventSnapshot.prototype.patientIds = $util.emptyArray;
      EventSnapshot.prototype.webhookRequestId = null;
      EventSnapshot.prototype.objectType = null;

      return EventSnapshot;
    })();

    v1.UpdateEventSnapshotRequest = (() => {
      class UpdateEventSnapshotRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateEventSnapshotRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.sendToDestinations != null &&
            Object.hasOwnProperty.call(message, 'sendToDestinations')
          ) {
            writer.uint32(16).bool(message.sendToDestinations);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateEventSnapshotRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.sendToDestinations = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.event_snapshot.v1.UpdateEventSnapshotRequest
          ) {
            return object;
          }
          const message =
            new $root.event_snapshot.v1.UpdateEventSnapshotRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.sendToDestinations != null) {
            message.sendToDestinations = Boolean(object.sendToDestinations);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.sendToDestinations != null &&
            message.hasOwnProperty('sendToDestinations')
          ) {
            object.sendToDestinations = message.sendToDestinations;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_snapshot.v1.UpdateEventSnapshotRequest';
        }
      }

      UpdateEventSnapshotRequest.prototype.id = '';
      UpdateEventSnapshotRequest.prototype.sendToDestinations = null;

      return UpdateEventSnapshotRequest;
    })();

    v1.UpdateEventSnapshotResponse = (() => {
      class UpdateEventSnapshotResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateEventSnapshotResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventSnapshot != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshot')
          ) {
            $root.event_snapshot.v1.EventSnapshot.encode(
              message.eventSnapshot,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateEventSnapshotResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventSnapshot =
                  $root.event_snapshot.v1.EventSnapshot.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.event_snapshot.v1.UpdateEventSnapshotResponse
          ) {
            return object;
          }
          const message =
            new $root.event_snapshot.v1.UpdateEventSnapshotResponse();
          if (object.eventSnapshot != null) {
            if (typeof object.eventSnapshot !== 'object') {
              throw new TypeError(
                '.event_snapshot.v1.UpdateEventSnapshotResponse.eventSnapshot: object expected, but got ' +
                  typeof object.eventSnapshot
              );
            }
            message.eventSnapshot =
              $root.event_snapshot.v1.EventSnapshot.fromObject(
                object.eventSnapshot
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventSnapshot = null;
          }
          let keys;
          if (
            message.eventSnapshot != null &&
            message.hasOwnProperty('eventSnapshot')
          ) {
            object.eventSnapshot =
              $root.event_snapshot.v1.EventSnapshot.toObject(
                message.eventSnapshot,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'event_snapshot.v1.UpdateEventSnapshotResponse'
          );
        }
      }

      UpdateEventSnapshotResponse.prototype.eventSnapshot = null;

      return UpdateEventSnapshotResponse;
    })();

    v1.ListEventSnapshotsFilter = (() => {
      class ListEventSnapshotsFilter {
        get filter() {
          for (const key of ['sourceId']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set filter(name) {
          for (const key of ['sourceId']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListEventSnapshotsFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(10).string(message.sourceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListEventSnapshotsFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.event_snapshot.v1.ListEventSnapshotsFilter
          ) {
            return object;
          }
          const message =
            new $root.event_snapshot.v1.ListEventSnapshotsFilter();
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
            if (options.oneofs) {
              object.filter = 'sourceId';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_snapshot.v1.ListEventSnapshotsFilter';
        }
      }

      ListEventSnapshotsFilter.prototype.sourceId = '';

      return ListEventSnapshotsFilter;
    })();

    v1.ListEventSnapshotsRequest = (() => {
      class ListEventSnapshotsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListEventSnapshotsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.event_snapshot.v1.ListEventSnapshotsFilter.encode(
              message.filter,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListEventSnapshotsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.filter =
                  $root.event_snapshot.v1.ListEventSnapshotsFilter.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.event_snapshot.v1.ListEventSnapshotsRequest
          ) {
            return object;
          }
          const message =
            new $root.event_snapshot.v1.ListEventSnapshotsRequest();
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.event_snapshot.v1.ListEventSnapshotsRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter =
              $root.event_snapshot.v1.ListEventSnapshotsFilter.fromObject(
                object.filter
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter =
              $root.event_snapshot.v1.ListEventSnapshotsFilter.toObject(
                message.filter,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_snapshot.v1.ListEventSnapshotsRequest';
        }
      }

      ListEventSnapshotsRequest.prototype.filter = null;

      return ListEventSnapshotsRequest;
    })();

    v1.ListEventSnapshotsResponse = (() => {
      class ListEventSnapshotsResponse {
        constructor(properties) {
          this.snapshots = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListEventSnapshotsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.snapshots != null &&
            Object.hasOwnProperty.call(message, 'snapshots')
          ) {
            for (const element of message.snapshots) {
              $root.event_snapshot.v1.EventSnapshot.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListEventSnapshotsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.snapshots || !message.snapshots.length) {
                  message.snapshots = [];
                }
                message.snapshots.push(
                  $root.event_snapshot.v1.EventSnapshot.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.event_snapshot.v1.ListEventSnapshotsResponse
          ) {
            return object;
          }
          const message =
            new $root.event_snapshot.v1.ListEventSnapshotsResponse();
          if (object.snapshots) {
            if (!Array.isArray(object.snapshots)) {
              throw new TypeError(
                '.event_snapshot.v1.ListEventSnapshotsResponse.snapshots: array type expected, but got ' +
                  typeof object.snapshots
              );
            }
            message.snapshots = new Array(object.snapshots.length);
            for (let i = 0; i < object.snapshots.length; ++i) {
              if (typeof object.snapshots[i] !== 'object') {
                throw new TypeError(
                  '.event_snapshot.v1.ListEventSnapshotsResponse.snapshots: object expected, but got ' +
                    typeof object.snapshots[i]
                );
              }
              message.snapshots[i] =
                $root.event_snapshot.v1.EventSnapshot.fromObject(
                  object.snapshots[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.snapshots = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.snapshots && message.snapshots.length) {
            object.snapshots = new Array(message.snapshots.length);
            for (let i = 0; i < message.snapshots.length; ++i) {
              object.snapshots[i] =
                $root.event_snapshot.v1.EventSnapshot.toObject(
                  message.snapshots[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_snapshot.v1.ListEventSnapshotsResponse';
        }
      }

      ListEventSnapshotsResponse.prototype.snapshots = $util.emptyArray;

      return ListEventSnapshotsResponse;
    })();

    return v1;
  })();

  return event_snapshot;
})();

export const event_snapshot = $root.event_snapshot;
