import { css, styled } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { DotBadgeProps } from './types';

const typeStyles = {
  inactive: css(
    ({ theme }) => `
    background-color: ${theme.colors.text.muted};
  `
  ),
  alert: css(
    ({ theme }) => `
    background-color: ${theme.colors.support.red.darkest};
  `
  ),
  warning: css(
    ({ theme }) => `
    background-color: ${theme.colors.support.yellow.darkest};
  `
  ),
  success: css(
    ({ theme }) => `
    background-color: ${theme.colors.support.green.darkest};
  `
  ),
  live: css(
    ({ theme }) => `
    background-color: ${theme.colors.main.primary.darker};
  `
  ),
};

export const StyledDotBadge = styled(Flexbox)<{ type: DotBadgeProps['type'] }>`
  ${(props) => typeStyles[props.type]};
`;
