import { FC, memo, useRef, useState } from 'react';
import { useTheme } from '@morf/theming';
import { RetrySnapshotIconProps } from './types';
import { useClickOutside } from '../../../../../Hooks/useClickOutside';
import { Container } from '../../../../../Container';
import { SnapshotIcon } from '..';
import { Menu } from '../../../../../Menu';
import { MenuItem } from '../../../../../Menu/types';

const RetrySnapshotIcon: FC<RetrySnapshotIconProps> = ({
  onRetryBranch,
  onRetryDestinationAction,
  onRetryEventSnapshot,
  onRetryWorkflow,
}) => {
  const theme = useTheme();

  const retryMenuRef = useRef<HTMLDivElement>(null);
  const [retryMenu, setRetryMenu] = useState(false);

  useClickOutside(retryMenuRef, () => {
    setRetryMenu(false);
  });

  const items: MenuItem[] = [
    { label: 'Retry Workflow', onClick: onRetryWorkflow },
    { label: 'Retry Event Snapshot', onClick: onRetryEventSnapshot },
    { label: 'Retry Destination Action', onClick: onRetryDestinationAction },
    { label: 'Retry Branch', onClick: onRetryBranch },
  ].filter((item): item is MenuItem => item.onClick !== undefined);

  const menuItems = items.map((item) => ({
    ...item,
    onClick: () => {
      item.onClick();
      setRetryMenu(false);
    },
  }));

  return (
    <Container
      ref={retryMenuRef}
      position='relative'
      width='auto'
      height='auto'
    >
      <SnapshotIcon
        name={retryMenu ? 'refresh-chevron-up' : 'refresh-chevron-down'}
        {...(retryMenu && { stroke: theme.colors.main.primary.darkest })}
        viewBox='0 0 32 20'
        width='2rem'
        height='1.25rem'
        onClick={() => setRetryMenu(!retryMenu)}
        tooltipText='Retry'
        isActive={!retryMenu}
      />
      {retryMenu && (
        <Menu position='absolute' top='2rem' right='0' items={menuItems} />
      )}
    </Container>
  );
};

export const MemoizedRetrySnapshotIcon = memo(RetrySnapshotIcon);
