import { workflow_evaluation_results } from '@morf/proto/workflow_evaluation_results_v1_ts_proto';

export const formatWorkflowEvaluationResultsErrorClass = (
  data: workflow_evaluation_results.v1.ErrorClass | undefined
) => {
  switch (data) {
    case workflow_evaluation_results.v1.ErrorClass.ERROR:
      return 'error';
    case workflow_evaluation_results.v1.ErrorClass.WARNING:
      return 'warning';
    case workflow_evaluation_results.v1.ErrorClass.RETRY:
      return 'retry';
    default:
      return 'success';
  }
};
