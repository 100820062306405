import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonLoadableProps } from './types';
import { useTheme } from '@morf/theming';
import { convertRemToPixel } from '../Helpers/convertRemToPixel';

export const SkeletonLoadable: FC<SkeletonLoadableProps> = ({
  isLoading,
  width = '100%',
  height = '100%',
  children,
}) => {
  const theme = useTheme();
  const skeletonWidth = convertRemToPixel(width);
  const skeletonHeight = convertRemToPixel(height);

  return isLoading ? (
    <Skeleton
      width={skeletonWidth}
      height={skeletonHeight}
      baseColor={theme.colors.ui.body}
    />
  ) : (
    children
  );
};
