import { FC, memo, useEffect, useMemo, useState } from 'react';
import { SnapshotProps } from './types';
import { Tab, Tabs } from '../../Tabs';
import { Flexbox } from '../../Flexbox';
import { Container } from '../../Container';
import { SnapshotSection } from './SnapshotSection';
import { SnapshotActions } from './SnapshotActions';
import { Allotment } from 'allotment';

const Snapshot: FC<SnapshotProps> = ({
  eventPayload,
  eventTimestamp,
  inputPayload,
  inputTimestamp,
  outcomePayload,
  outcomeTimestamp,
  profileLookupPayload,
  profileLookupTimestamp,
  webhookPayload,
  webhookTimestamp,
  ...props
}) => {
  const payloads = [
    outcomePayload,
    eventPayload,
    profileLookupPayload,
    webhookPayload,
  ];

  const selectedTab = useMemo(() => {
    return payloads.findIndex((payload) => payload != null) || 0;
  }, [payloads]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTab);

  useEffect(() => {
    setSelectedTabIndex(selectedTab);
  }, [selectedTab]);

  return (
    <Container data-testid='snapshot' position='relative'>
      <Tabs
        initialValue={selectedTabIndex}
        onChange={(selectedIndex) => setSelectedTabIndex(selectedIndex)}
        height='3rem'
        px={0}
      >
        {outcomePayload ? (
          <Tab label='Log' key='log'>
            <Flexbox
              direction='column'
              justifyContent='flex-start'
              alignItems='center'
              gap={0}
            >
              <Allotment vertical>
                <SnapshotSection
                  name='Outcome'
                  timestamp={outcomeTimestamp}
                  payload={outcomePayload}
                />
                {inputPayload && (
                  <SnapshotSection
                    name='Input'
                    timestamp={inputTimestamp}
                    payload={inputPayload}
                  />
                )}
              </Allotment>
            </Flexbox>
          </Tab>
        ) : null}
        {eventPayload ? (
          <Tab label='Event Payload' key='event-payload'>
            <SnapshotSection
              timestamp={eventTimestamp}
              payload={eventPayload}
            />
          </Tab>
        ) : null}

        {profileLookupPayload ? (
          <Tab label='Profile Lookup' key='profile-lookup'>
            <SnapshotSection
              timestamp={profileLookupTimestamp}
              payload={profileLookupPayload}
            />
          </Tab>
        ) : null}

        {webhookPayload ? (
          <Tab label='Webhook' key='webhook'>
            <SnapshotSection
              timestamp={webhookTimestamp}
              payload={webhookPayload}
            />
          </Tab>
        ) : null}
      </Tabs>
      <Container
        position='absolute'
        data-testid='snapshot-actions'
        top='0'
        right='0'
        height='auto'
        width='auto'
      >
        <SnapshotActions {...props} />
      </Container>
    </Container>
  );
};

export const MemoizedSnapshot = memo(Snapshot);
