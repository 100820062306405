export const Bolt = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z'
    />
  );
};

export const BoltSlash = () => {
  return (
    <>
      <path
        d='M12.7316 9.65069L14.75 2.25L4.25 13.5H9'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 13.5L10.25 21.75L20.75 10.5H16'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 2L18.8363 3.66365L4 18.5L2.5 20'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const BoltArrow = () => {
  return (
    <>
      <path
        d='M9 13.5H4.25L14.75 2.25L13 10H14'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.75 10L10.25 21.75L12.5 13.5H6M20.75 10H17M20.75 10L21 13.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};

export const BoltDouble = () => {
  return (
    <>
      <path
        d='M2.5 14.2692L11.3846 4.75L9.48077 11.7308H16.4615L7.57692 21.25L9.48077 14.2692H2.5Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.0866 17.8898L12.5962 19.7692L21.75 9.51775H14.5577L16.5193 3L12.5962 7.39349'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  );
};
