import { values } from '@morf/proto/values_v1_ts_proto';

export const numericValueToString = (
  numericValue: values.v1.INumericValue | null | undefined
): string => {
  if (!numericValue) {
    return 'n/a';
  }

  switch (true) {
    case !!numericValue.floatValue:
      return `${numericValue.floatValue}`;
    case !!numericValue.intValue:
      return `${numericValue.intValue}`;
    case !!numericValue.unsignedIntValue:
      return `${numericValue.unsignedIntValue}`;
    case !!numericValue.bigIntegerValue:
      return `${numericValue.bigIntegerValue?.value}`;
    case !!numericValue.bigDecimalValue:
      return `${numericValue.bigDecimalValue?.value}`;
    default:
      return 'n/a';
  }
};
