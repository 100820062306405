import { useTheme } from '@morf/theming';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { LogTimelineRowProps } from './types';
import { Text, TooltipText } from '../Typography';
import { SkeletonLoadable } from '../Skeleton';
import { useHover } from '../Hooks/useHover';
import { IntegrationIcon } from '../IntegrationIcon';
import { Container } from '../Container';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

const LogTimelineRow: FC<LogTimelineRowProps> = ({
  description,
  descriptionDetails,
  integrationIconName,
  isLoading,
  isSelected,
  name,
  onClick,
  profile,
  status,
  timestamp,
  type,
  tooltipDirection,
}) => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const statusMap: Record<LogTimelineRowProps['status'], string> = {
    success: theme.colors.support.green.darkest,
    error: theme.colors.support.red.darkest,
    inactive: theme.colors.text.muted,
  };

  const iconMap: Record<LogTimelineRowProps['type'], string> = {
    webhookRequest: 'webhook',
    eventSnapshot: 'webhook',
    workflowTriggerEvaluation: 'bolt-slash',
    workflowExecution: 'bolt',
  };

  const backgroundColor =
    !isLoading && (isHovered || isSelected)
      ? theme.colors.ui.body
      : theme.colors.ui.card;

  return (
    <Flexbox
      data-testid='log-timeline-row'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor={backgroundColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      height='3.25rem'
      cursor={isLoading ? 'inherit' : 'pointer'}
      px={1}
      py={0.5}
      gap={0.5}
    >
      <Container width='auto' height='auto'>
        <SkeletonLoadable isLoading={isLoading} height='1rem' width='1rem'>
          <Icon name={iconMap[type]} stroke={statusMap[status]} size={1} />
        </SkeletonLoadable>
      </Container>

      <Container width='auto' height='auto'>
        <SkeletonLoadable isLoading={isLoading} height='1rem' width='1rem'>
          <IntegrationIcon name={integrationIconName} size={1} />
        </SkeletonLoadable>
      </Container>

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        gap={0}
        maxWidth='12.5rem'
      >
        <SkeletonLoadable isLoading={isLoading} height='1.25rem'>
          <TooltipText
            tag={isSelected ? 'h5' : 'p2'}
            tooltipDirection={tooltipDirection}
            whiteSpace='nowrap'
            tooltipWidth='12.5rem'
          >
            {name}
          </TooltipText>
        </SkeletonLoadable>

        <SkeletonLoadable isLoading={isLoading} height='1rem'>
          {descriptionDetails ? (
            <Tooltip
              tooltipText={descriptionDetails}
              tooltipDirection={tooltipDirection}
              tooltipWidth='23.5rem'
            >
              <Text
                tag={isSelected ? 'h6' : 'p3'}
                whiteSpace='nowrap'
                color={statusMap[status]}
              >
                {description}
              </Text>
            </Tooltip>
          ) : (
            <TooltipText
              tag={isSelected ? 'h6' : 'p3'}
              whiteSpace='nowrap'
              tooltipDirection={tooltipDirection}
              color={statusMap[status]}
              tooltipWidth='12.5rem'
            >
              {description}
            </TooltipText>
          )}
        </SkeletonLoadable>
      </Flexbox>

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-end'
        maxWidth='14.5rem'
        gap={0}
      >
        <SkeletonLoadable isLoading={isLoading} height='1rem'>
          <Text tag={isSelected ? 'h6' : 'p3'} whiteSpace='nowrap'>
            {timestamp}
          </Text>
        </SkeletonLoadable>
        {profile && (
          <SkeletonLoadable isLoading={isLoading} height='1rem'>
            <Text
              tag={isSelected ? 'h6' : 'p3'}
              whiteSpace='nowrap'
              color={theme.colors.text.muted}
            >
              {profile}
            </Text>
          </SkeletonLoadable>
        )}
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedLogTimelineRow = memo(LogTimelineRow);
