import { FC } from 'react';
import { IntegrationCardProps } from './types';
import { useTheme } from '@morf/theming';
import { FlexItem, Flexbox } from '../Flexbox';
import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { Text } from '../Typography';
import { Badge } from '../Badge';
import { useResize } from '../Hooks/useResize';
import { Container } from '../Container';
import { Tooltip } from '../Tooltip';

export const IntegrationCard: FC<IntegrationCardProps> = ({
  imageUrl,
  name,
  shortDescription,
  isConnected,
  onClick,
  buttonDisabled = false,
}) => {
  const theme = useTheme();

  const responsiveCard = useResize({
    direction: {
      xxl: 'row',
      xl: 'row',
      lg: 'row',
      md: 'row',
      sm: 'row',
      xs: 'column',
    },
    align: {
      xxl: 'center',
      xl: 'center',
      lg: 'center',
      md: 'center',
      sm: 'center',
      xs: 'flex-start',
    },
    width: {
      xxl: 'auto',
      xl: 'auto',
      lg: 'auto',
      md: 'auto',
      sm: 'auto',
      xs: '100%',
    },
  });

  return (
    <Flexbox
      key={name}
      data-testid='integration-card'
      alignItems={responsiveCard.align}
      direction={responsiveCard.direction}
      backgroundColor={theme.colors.ui.card}
      borderRadius={1}
      p={1.5}
      gap={1.5}
      height='auto'
    >
      <Container width='auto' height='auto'>
        <Avatar size='lg' src={imageUrl} alt={name} />
      </Container>

      <Flexbox direction='column' height='auto' gap={0}>
        <Flexbox
          justifyContent='flex-start'
          direction={responsiveCard.direction}
          alignItems='flex-start'
        >
          <FlexItem flexOrder={responsiveCard.direction === 'row' ? 1 : 2}>
            <Text tag='h3'>{name}</Text>
          </FlexItem>
          {isConnected && (
            <FlexItem flexOrder={responsiveCard.direction === 'row' ? 2 : 1}>
              <Badge type='success' label='Connected' />
            </FlexItem>
          )}
        </Flexbox>
        <Text tag='p2'>{shortDescription}</Text>
      </Flexbox>

      <Tooltip
        tooltipDirection='top'
        arrowDirection='right'
        tooltipWidth='4rem'
        containerWidth={responsiveCard.width}
        tooltipText='Self-service not supported!'
        isActive={buttonDisabled}
      >
        <Container>
          <Button
            variant={isConnected ? 'secondary' : 'primary'}
            onClick={onClick}
            isDisabled={buttonDisabled}
            width={responsiveCard.width}
          >
            <Text color='inherit'>{isConnected ? 'View' : 'Add'}</Text>
          </Button>
        </Container>
      </Tooltip>
    </Flexbox>
  );
};
