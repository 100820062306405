import moment from 'moment';
import { timing } from '@morf/proto/timing_v1_ts_proto';

export const convertTimestampToRFC3339 = (
  timestamp: timing.v1.Timestamp
): string => {
  const milliseconds =
    timestamp.seconds.toNumber() * 1000 + timestamp.nanoseconds / 1e6;
  return moment(milliseconds).format();
};
