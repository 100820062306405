import { DefaultTheme } from '../Theme';
import { ThemeContainerProps } from '../types';
import { defaultColors } from '../ColorPalette';
import {
  defaultH1,
  defaultH2,
  defaultH3,
  defaultH4,
  defaultH5,
  defaultH6,
  defaultLink,
  defaultLongform,
  defaultMaxNrOfSlots,
  defaultP1,
  defaultP2,
  defaultP3,
  defaultP4,
  defaultSubtitle,
} from '../ColorPalette/constants';

export const resolute: ThemeContainerProps = {
  ...DefaultTheme,
  colors: {
    ...defaultColors,
    text: {
      body: '#252829',
      ...defaultColors.text,
    },
    main: {
      primary: {
        darkest: '#074E82',
        darker: '#1A74B5',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      dark: {
        darkest: '#1A74B5',
        darker: '',
        dark: '',
        normal: '',
        light: '',
        lighter: '',
        lightest: '',
      },
      light: {
        darkest: '',
        darker: '',
        dark: '',
        normal: '#B6E6FD',
        light: '#FFFFFF',
        lighter: '',
        lightest: '',
      },
    },
  },
  container: {
    borderType: 'border',
    borderRadius: 0.25,
  },
  contentOrder: {
    bio: 2,
    specialties: 1,
  },
  datePicker: {
    borderRadius: 0.5,
    style: 'fill',
  },
  fontTypes: {
    h1: {
      ...defaultH1,
      family: 'abc-prophet-regular',
    },
    h2: {
      ...defaultH2,
      family: 'abc-prophet-regular',
    },
    h3: {
      ...defaultH3,
      family: 'inter-bold',
    },
    h4: {
      ...defaultH4,
      family: 'inter-bold',
    },
    h5: {
      ...defaultH5,
      family: 'inter-bold',
    },
    h6: {
      ...defaultH6,
      family: 'inter-bold',
    },
    p1: {
      ...defaultP1,
      family: 'inter-medium',
    },
    p2: {
      ...defaultP2,
      family: 'inter-medium',
    },
    p3: {
      ...defaultP3,
      family: 'inter-medium',
    },
    p4: {
      ...defaultP4,
      family: 'inter-medium',
    },
    subtitle: {
      ...defaultSubtitle,
      family: 'inter-medium',
    },
    longform: {
      ...defaultLongform,
      family: 'inter-medium',
    },
    link: {
      ...defaultLink,
      decoration: 'underline',
      family: 'inter-regular',
    },
  },
  input: { borderRadius: 0.25 },
  maxNrOfSlots: defaultMaxNrOfSlots,
  timePicker: {
    borderRadius: 0.5,
    style: 'darkFill',
  },
  tooltip: {
    borderRadius: 0.5,
    type: 'dark',
  },
};
