import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExtendedRowData, TableProps } from './types';
import {
  ColumnFiltersState,
  ColumnPinningState,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Container } from '../Container';
import { StyledTable } from './Table.css';
import { TableHead } from './TableHead/TableHead';
import { TableBody } from './TableBody/TableBody';
import { EmptyTableBody } from './TableBody/EmptyTableBody';
import { useTheme } from '@morf/theming';

const TableContainer: <RowData>(
  props: TableProps<ExtendedRowData<RowData>>
) => JSX.Element = ({
  cellBackgroundColor,
  cellHeight,
  cellHoveredBackgroundColor,
  cellHoveredBorderColor,
  cellSelectedBackgroundColor,
  cellSelectedBorderColor,
  columns,
  data,
  emptyText,
  enableRowSelection,
  enableSubRowSelection,
  fetchNextPage,
  headCellBackgroundColor,
  headCellColor,
  headCellHeight,
  isFetching,
  isLoading,
  onColumnClick,
  onRowAdd,
  onRowClick,
  onRowHover,
  onRowRemove,
  onSubRowAdd,
  onSubRowClick,
  onSubRowRemove,
  parentCellHeight,
  selectedRow,
  subCellHeight,
  subRowComponent,
  tableMaxHeight,
  totalCount,
  totalFetched,
}) => {
  const theme = useTheme();

  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: [columns[0].id as string],
  });

  let initialRowSelection: { [key: number]: boolean } = {};
  if (selectedRow !== undefined && selectedRow !== null) {
    initialRowSelection[selectedRow] = true;
  }

  const memoizedDataWithPlaceholder = useMemo(
    () => (isLoading ? Array(20).fill({}) : data),
    [isLoading, data]
  );

  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const { getHeaderGroups, getRowModel, resetRowSelection } = useReactTable({
    data: memoizedDataWithPlaceholder,
    columns,
    state: {
      columnFilters,
      columnPinning,
      rowSelection,
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    enableSubRowSelection: enableSubRowSelection,
    enableRowSelection: enableRowSelection,
    enableMultiRowSelection: false,
    enableExpanding: true,
    onRowSelectionChange: setRowSelection,
    onColumnPinningChange: setColumnPinning,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      size: 180,
      minSize: 70,
      maxSize: 700,
    },
  });
  const [isScrollable, setIsScrollable] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const containerElement = containerRef.current;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching &&
          totalFetched < totalCount
        ) {
          fetchNextPage && fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalCount]
  );

  useEffect(() => {
    if (selectedRow === null) {
      resetRowSelection();
    }
  }, [selectedRow]);

  useEffect(() => {
    fetchMoreOnBottomReached(containerElement);
  }, [fetchMoreOnBottomReached]);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setIsScrollable(scrollLeft > 0 && scrollWidth > clientWidth);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const headerGroups = getHeaderGroups();
  const rows = getRowModel().rows;

  return (
    <Container
      ref={containerRef}
      overflow='scroll'
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      backgroundColor={theme.colors.ui.card}
      maxHeight={tableMaxHeight}
    >
      <StyledTable data-testid='table' height={!rows.length ? '100%' : 'auto'}>
        <TableHead
          headCellBackgroundColor={headCellBackgroundColor}
          headCellColor={headCellColor}
          headCellHeight={headCellHeight}
          headerGroups={headerGroups}
          isScrollable={isScrollable}
          onRowAdd={onRowAdd}
        />
        {!rows.length ? (
          <EmptyTableBody numberOfColumns={columns.length} text={emptyText} />
        ) : (
          <TableBody
            cellBackgroundColor={cellBackgroundColor}
            cellHeight={cellHeight}
            cellHoveredBackgroundColor={cellHoveredBackgroundColor}
            cellHoveredBorderColor={cellHoveredBorderColor}
            cellSelectedBackgroundColor={cellSelectedBackgroundColor}
            cellSelectedBorderColor={cellSelectedBorderColor}
            isLoading={isLoading}
            isScrollable={isScrollable}
            onColumnClick={onColumnClick}
            onRowClick={onRowClick}
            onRowHover={onRowHover}
            onRowRemove={onRowRemove}
            onSubRowAdd={onSubRowAdd}
            onSubRowClick={onSubRowClick}
            onSubRowRemove={onSubRowRemove}
            parentCellHeight={parentCellHeight}
            rows={rows}
            subCellHeight={subCellHeight}
            subRowComponent={subRowComponent}
          />
        )}
      </StyledTable>
    </Container>
  );
};

export const Table = memo(TableContainer) as typeof TableContainer;
