import { ColumnType } from '../../../../Table';
import { Container } from '../../../../Container';
import { InsetTableSubRow } from '../../../../InsetTable';
import { ParamSourceConfigProps } from '../../../types';
import { ParameterConfig } from '../../../../ParameterConfig';
import { SourceConfig } from '../../../../SourceConfig';

export const columns: ColumnType<ParamSourceConfigProps> = [
  {
    id: 'param',
    accessorKey: 'param',
    header: 'Destination Parameter',
    enablePinning: false,
    cell: (row) => {
      const param = row.getValue<ParamSourceConfigProps['param']>();
      if (!!row.row.subRows.length) {
        return <ParameterConfig {...param} />;
      }
      return (
        <InsetTableSubRow row={row}>
          <ParameterConfig {...param} />
        </InsetTableSubRow>
      );
    },
    cellBorderType: 'borderTop',
    cellPb: 0.5,
    cellPl: 0.5,
    cellPr: 0.5,
    cellPt: 0.5,
    headCellBorderType: 'borderBottom',
    headCellPl: 0.5,
    headCellPr: 0.5,
    parentCellBorderType: 'borderTop',
    parentCellPb: 0.5,
    parentCellPl: 0.5,
    parentCellPr: 0,
    parentCellPt: 0.5,
    subCellBorderType: 'borderTop',
    subCellPb: 0.5,
    subCellPl: 0.5,
    subCellPr: 0.5,
    subCellPt: 0.5,
    size: 240,
  },
  {
    id: 'source',
    accessorKey: 'source',
    header: 'Value',
    cell: (row) => {
      const source = row.getValue<ParamSourceConfigProps['source']>();
      if (!!row.row.subRows.length) {
        return <Container height='3rem' />;
      }
      return <SourceConfig {...source} />;
    },
    cellBorderType: 'borderTopLeft',
    cellPb: 0.5,
    cellPl: 0.5,
    cellPr: 0.5,
    cellPt: 0.5,
    headCellBorderType: 'borderBottom',
    headCellPl: 0.5,
    headCellPr: 0.5,
    parentCellBorderType: 'borderTop',
    parentCellPb: 0.5,
    parentCellPl: 0.5,
    parentCellPr: 0,
    parentCellPt: 0.5,
    subCellBorderType: 'borderTopLeft',
    subCellPb: 0.5,
    subCellPl: 0.5,
    subCellPr: 0.5,
    subCellPt: 0.5,
    size: 300,
  },
];
