import { timing } from '@morf/proto/timing_v1_ts_proto';
import Long from 'long';

export const calculateTimeframe = (
  date: Date,
  hours: number
): {
  startTimestamp: timing.v1.Timestamp;
  endTimestamp: timing.v1.Timestamp;
} => {
  const nowInSeconds = Math.floor(date.getTime() / 1000);
  const nowInNanoseconds = date.getMilliseconds() * 1000000;

  return {
    startTimestamp: new timing.v1.Timestamp({
      seconds: Long.fromNumber(nowInSeconds - hours * 60 * 60),
      nanoseconds: nowInNanoseconds,
    }),
    endTimestamp: new timing.v1.Timestamp({
      seconds: Long.fromNumber(nowInSeconds),
      nanoseconds: nowInNanoseconds,
    }),
  };
};
