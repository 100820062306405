import { values } from '@morf/proto/values_v1_ts_proto';

export const redactedValueToString = (
  redactedValue: values.v1.IValue['redactedValue'] | null | undefined
): string => {
  if (redactedValue) {
    return '<Redacted>';
  }
  return 'n/a';
};
