export const Segment = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.6872 18.5024H18.8389C18.3839 18.5024 18.0047 18.1232 18.0047 17.6683V16.2464C18.0047 15.7915 18.3839 15.4123 18.8389 15.4123H30.763C31.218 15.4123 31.5972 15.7915 31.5972 16.2464V17.6683C31.5024 18.1232 31.1422 18.5024 30.6872 18.5024ZM21.6825 24.4929C22.1374 24.4929 22.5166 24.1137 22.5735 23.7346V22.3128C22.5735 21.8578 22.1943 21.4787 21.7393 21.4787H9.83412C9.37915 21.4787 9 21.8578 9 22.237V23.6588C9 24.1137 9.37915 24.4929 9.83412 24.4929H21.6825ZM13.5118 26.7488C12.6777 26.7488 12.0142 27.4123 12.0142 28.2464C12.0142 29.0806 12.6777 29.7441 13.5118 29.7441C14.346 29.7441 15.0095 29.0806 15.0095 28.2464C15.0095 27.4123 14.327 26.7488 13.5118 26.7488ZM27.0095 10.237C26.1754 10.237 25.5119 10.9005 25.5119 11.7346C25.5119 12.5687 26.1754 13.2322 27.0095 13.2322C27.8436 13.2322 28.5071 12.5687 28.5071 11.7346C28.5071 10.9194 27.8436 10.237 27.0095 10.237ZM20.2607 29.0047C19.4265 29.0047 18.6114 28.8531 17.8531 28.6256C17.4739 28.5498 17.0948 28.7014 16.9621 29.0806L16.4313 30.5024C16.2796 30.8815 16.5071 31.3365 16.9621 31.4692C18.0047 31.8483 19.1422 32 20.2607 32C25.1327 32 29.3412 29.0806 31.218 24.872C31.3697 24.4929 31.218 23.981 30.763 23.8294L29.3412 23.2986C28.9621 23.1469 28.5829 23.2986 28.4502 23.6777C27.0095 26.8246 23.9384 29.0047 20.2607 29.0047ZM20.2607 10.9953C21.0948 10.9953 21.91 11.1469 22.6682 11.3744C23.0474 11.4502 23.4265 11.2986 23.5592 10.9194L24.09 9.49763C24.2417 9.11849 24.0142 8.66351 23.5592 8.53081C22.5166 8.15166 21.3791 8 20.2607 8C15.3886 8 11.1801 10.9194 9.30332 15.128C9.15166 15.5071 9.30332 16.019 9.75829 16.1706L11.1801 16.7014C11.5592 16.8531 11.9384 16.7014 12.0711 16.3223C13.5118 13.1754 16.5829 10.9953 20.2607 10.9953Z'
        fill='#25C365'
      />
    </svg>
  );
};
