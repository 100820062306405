import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { WorkflowCard } from '../WorkflowCard';
import { WorkflowTableProps } from './types';
import { columnWidths } from '../WorkflowCard/WorkflowCard';
import { useTheme } from '@morf/theming';
import { Icon } from '../Icon';

export const headers = [
  'Workflow',
  'Source',
  'Destinations',
  'Last updated',
  '',
];

export const WorkflowTable: FC<WorkflowTableProps> = ({ workflows }) => {
  const theme = useTheme();
  return (
    <Flexbox direction='column' height='auto' gap={0.5}>
      <Flexbox px={1.25}>
        {headers.map((header, index) => {
          const lastColumn = index === headers.length - 2;
          return (
            <Flexbox
              direction='row'
              justifyContent={lastColumn ? 'flex-end' : 'flex-start'}
              alignItems='center'
              key={index}
              width={columnWidths[index]}
              gap={0.25}
            >
              {lastColumn && <Icon name='sorter-top' size={1} />}
              <Text tag='h5' color={theme.colors.text.muted}>
                {header}
              </Text>
            </Flexbox>
          );
        })}
      </Flexbox>
      <Flexbox direction='column' justifyContent='flex-start' gap={0.5}>
        {workflows.map((workflow, index) => {
          return <WorkflowCard key={index} {...workflow} />;
        })}
      </Flexbox>
    </Flexbox>
  );
};
