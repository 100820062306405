import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { FC } from 'react';
import { Text, TooltipText } from '../Typography';
import { Divider } from '../Divider';
import { PatientProfileProps } from './types';
import { InfoBlock } from '../InfoBlock/InfoBlock';
import { Container } from '../Container';
import { CopyToClipboard } from '../CopyToClipboard';
import { Button } from '../Button';
import { LinkedApp } from '../LinkedApp/LinkedApp';
import { ProfileImage } from '../ProfileImage';
import { StyledLink } from './PatientProfile.css';
import { Timestamp } from '../Timestamp';

export const PatientProfile: FC<PatientProfileProps> = ({
  imageUrl,
  id,
  name,
  age,
  dob,
  sex,
  gender,
  email,
  phone,
  address,
  linkedApps,
  createdAt,
  profileHref,
  isLoading,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      direction='column'
      p={2}
      gap={1}
      position='relative'
      height='auto'
      overflowY='scroll'
      overflowX='hidden'
    >
      <ProfileImage imageUrl={imageUrl} name={name} />
      <Flexbox direction='column' gap={0}>
        <Text tag='h3'>{name}</Text>

        <Flexbox justifyContent='flex-start' alignItems='center'>
          <Text tag='p2' color={theme.colors.text.muted}>
            {id}
          </Text>
          <CopyToClipboard
            text={id}
            tooltipDirection='left'
            tooltipWidth='7rem'
          />
        </Flexbox>
      </Flexbox>
      <Flexbox direction='row'>
        <Container width='50%'>
          <InfoBlock isLoading={isLoading} label='Age' direction='column'>
            <Text tag='p2'>{age}</Text>
          </InfoBlock>
        </Container>
        <Container width='50%'>
          <InfoBlock isLoading={isLoading} label='DOB' direction='column'>
            <Text tag='p2'>{dob}</Text>
          </InfoBlock>
        </Container>
      </Flexbox>
      <Flexbox direction='row'>
        <Container width='50%'>
          <InfoBlock isLoading={isLoading} label='Sex' direction='column'>
            <Text tag='p2'>{sex}</Text>
          </InfoBlock>
        </Container>
        <Container width='50%'>
          <InfoBlock
            isLoading={isLoading}
            label='Gender Identity'
            direction='column'
          >
            <Text tag='p2'>{gender}</Text>
          </InfoBlock>
        </Container>
      </Flexbox>
      <Flexbox direction='row' py={0.25}>
        <Button
          width='100%'
          variant='custom'
          borderColor={theme.colors.ui.divider}
          backgroundColor={theme.colors.ui.card}
        >
          <Text tag='h5' color={theme.colors.main.primary.darker}>
            Generate Link
          </Text>
        </Button>
        <Button
          width='100%'
          variant='custom'
          borderColor={theme.colors.ui.divider}
          backgroundColor={theme.colors.ui.card}
        >
          <StyledLink
            href={profileHref}
            data-testid='profile-link'
            target='_blank'
          >
            <Text tag='h5' color={theme.colors.main.primary.darker}>
              View Profile
            </Text>
          </StyledLink>
        </Button>
      </Flexbox>
      <Divider />
      <Container>
        <Text tag='h5'>Contact</Text>
      </Container>
      <InfoBlock
        isLoading={isLoading}
        label='Email'
        copyIcon={email !== 'n/a'}
        copyText={email}
      >
        <TooltipText tag='p2'>{email}</TooltipText>
      </InfoBlock>
      <InfoBlock isLoading={isLoading} label='Phone'>
        <TooltipText tag='p2'>{phone}</TooltipText>
      </InfoBlock>
      <InfoBlock isLoading={isLoading} label='Address'>
        <TooltipText tag='p2'>{address}</TooltipText>
      </InfoBlock>
      <Divider />
      <Container>
        <Text tag='h5'>Linked Accounts</Text>
      </Container>
      {linkedApps.map((linkedApp, index) => (
        <LinkedApp
          key={index}
          name={linkedApp.name}
          id={linkedApp.id}
          imageUrl={linkedApp.imageUrl}
          isLoading={isLoading}
        />
      ))}
      <Container position='static' left='0' bottom='0' pt={1.5}>
        <Timestamp
          tag='p2'
          color={theme.colors.text.muted}
          timestamp={createdAt}
        />
      </Container>
    </Flexbox>
  );
};
