'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.condition_expr = (() => {
  const condition_expr = {};
  condition_expr.v1 = (() => {
    const v1 = {};
    v1.ConditionExpr = (() => {
      class ConditionExpr {
        constructor(properties) {
          this.conditions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ConditionExpr(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.conditions != null &&
            Object.hasOwnProperty.call(message, 'conditions')
          ) {
            for (const element of message.conditions) {
              $root.condition_expr.v1.Condition.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ConditionExpr();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.conditions || !message.conditions.length) {
                  message.conditions = [];
                }
                message.conditions.push(
                  $root.condition_expr.v1.Condition.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.condition_expr.v1.ConditionExpr) {
            return object;
          }
          const message = new $root.condition_expr.v1.ConditionExpr();
          if (object.conditions) {
            if (!Array.isArray(object.conditions)) {
              throw new TypeError(
                '.condition_expr.v1.ConditionExpr.conditions: array type expected, but got ' +
                  typeof object.conditions
              );
            }
            message.conditions = new Array(object.conditions.length);
            for (let i = 0; i < object.conditions.length; ++i) {
              if (typeof object.conditions[i] !== 'object') {
                throw new TypeError(
                  '.condition_expr.v1.ConditionExpr.conditions: object expected, but got ' +
                    typeof object.conditions[i]
                );
              }
              message.conditions[i] =
                $root.condition_expr.v1.Condition.fromObject(
                  object.conditions[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.conditions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.conditions && message.conditions.length) {
            object.conditions = new Array(message.conditions.length);
            for (let i = 0; i < message.conditions.length; ++i) {
              object.conditions[i] = $root.condition_expr.v1.Condition.toObject(
                message.conditions[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'condition_expr.v1.ConditionExpr';
        }
      }

      ConditionExpr.prototype.conditions = $util.emptyArray;

      return ConditionExpr;
    })();

    v1.Condition = (() => {
      class Condition {
        get condition() {
          for (const key of ['orExpr', 'boolEq', 'stringIn', 'boolean']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set condition(name) {
          for (const key of ['orExpr', 'boolEq', 'stringIn', 'boolean']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Condition(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.orExpr != null &&
            Object.hasOwnProperty.call(message, 'orExpr')
          ) {
            $root.condition_expr.v1.OrExpr.encode(
              message.orExpr,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.boolEq != null &&
            Object.hasOwnProperty.call(message, 'boolEq')
          ) {
            $root.condition_expr.v1.BoolEq.encode(
              message.boolEq,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.stringIn != null &&
            Object.hasOwnProperty.call(message, 'stringIn')
          ) {
            $root.condition_expr.v1.StringIn.encode(
              message.stringIn,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.boolean != null &&
            Object.hasOwnProperty.call(message, 'boolean')
          ) {
            writer.uint32(32).bool(message.boolean);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Condition();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.orExpr = $root.condition_expr.v1.OrExpr.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.boolEq = $root.condition_expr.v1.BoolEq.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.stringIn = $root.condition_expr.v1.StringIn.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.boolean = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.condition_expr.v1.Condition) {
            return object;
          }
          const message = new $root.condition_expr.v1.Condition();
          if (object.orExpr != null) {
            if (typeof object.orExpr !== 'object') {
              throw new TypeError(
                '.condition_expr.v1.Condition.orExpr: object expected, but got ' +
                  typeof object.orExpr
              );
            }
            message.orExpr = $root.condition_expr.v1.OrExpr.fromObject(
              object.orExpr
            );
          }
          if (object.boolEq != null) {
            if (typeof object.boolEq !== 'object') {
              throw new TypeError(
                '.condition_expr.v1.Condition.boolEq: object expected, but got ' +
                  typeof object.boolEq
              );
            }
            message.boolEq = $root.condition_expr.v1.BoolEq.fromObject(
              object.boolEq
            );
          }
          if (object.stringIn != null) {
            if (typeof object.stringIn !== 'object') {
              throw new TypeError(
                '.condition_expr.v1.Condition.stringIn: object expected, but got ' +
                  typeof object.stringIn
              );
            }
            message.stringIn = $root.condition_expr.v1.StringIn.fromObject(
              object.stringIn
            );
          }
          if (object.boolean != null) {
            message.boolean = Boolean(object.boolean);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.orExpr != null && message.hasOwnProperty('orExpr')) {
            object.orExpr = $root.condition_expr.v1.OrExpr.toObject(
              message.orExpr,
              options
            );
            if (options.oneofs) {
              object.condition = 'orExpr';
            }
          }
          if (message.boolEq != null && message.hasOwnProperty('boolEq')) {
            object.boolEq = $root.condition_expr.v1.BoolEq.toObject(
              message.boolEq,
              options
            );
            if (options.oneofs) {
              object.condition = 'boolEq';
            }
          }
          if (message.stringIn != null && message.hasOwnProperty('stringIn')) {
            object.stringIn = $root.condition_expr.v1.StringIn.toObject(
              message.stringIn,
              options
            );
            if (options.oneofs) {
              object.condition = 'stringIn';
            }
          }
          if (message.boolean != null && message.hasOwnProperty('boolean')) {
            object.boolean = message.boolean;
            if (options.oneofs) {
              object.condition = 'boolean';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'condition_expr.v1.Condition';
        }
      }

      Condition.prototype.orExpr = null;
      Condition.prototype.boolEq = null;
      Condition.prototype.stringIn = null;
      Condition.prototype.boolean = false;

      return Condition;
    })();

    v1.BoolEq = (() => {
      class BoolEq {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new BoolEq(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.key != null &&
            Object.hasOwnProperty.call(message, 'key')
          ) {
            writer.uint32(10).string(message.key);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            writer.uint32(16).bool(message.value);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new BoolEq();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.key = reader.string();
                break;
              }
              case 2: {
                message.value = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.condition_expr.v1.BoolEq) {
            return object;
          }
          const message = new $root.condition_expr.v1.BoolEq();
          if (object.key != null) {
            message.key = String(object.key);
          }
          if (object.value != null) {
            message.value = Boolean(object.value);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.key = '';
            object.value = false;
          }
          let keys;
          if (message.key != null && message.hasOwnProperty('key')) {
            object.key = message.key;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = message.value;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'condition_expr.v1.BoolEq';
        }
      }

      BoolEq.prototype.key = '';
      BoolEq.prototype.value = false;

      return BoolEq;
    })();

    v1.StringIn = (() => {
      class StringIn {
        constructor(properties) {
          this.values = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new StringIn(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.key != null &&
            Object.hasOwnProperty.call(message, 'key')
          ) {
            writer.uint32(10).string(message.key);
          }
          if (
            message.values != null &&
            Object.hasOwnProperty.call(message, 'values')
          ) {
            for (const element of message.values) {
              writer.uint32(18).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new StringIn();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.key = reader.string();
                break;
              }
              case 2: {
                if (!message.values || !message.values.length) {
                  message.values = [];
                }
                message.values.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.condition_expr.v1.StringIn) {
            return object;
          }
          const message = new $root.condition_expr.v1.StringIn();
          if (object.key != null) {
            message.key = String(object.key);
          }
          if (object.values) {
            if (!Array.isArray(object.values)) {
              throw new TypeError(
                '.condition_expr.v1.StringIn.values: array type expected, but got ' +
                  typeof object.values
              );
            }
            message.values = new Array(object.values.length);
            for (let i = 0; i < object.values.length; ++i) {
              message.values[i] = String(object.values[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.values = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.key = '';
          }
          let keys;
          if (message.key != null && message.hasOwnProperty('key')) {
            object.key = message.key;
          }
          if (message.values && message.values.length) {
            object.values = new Array(message.values.length);
            for (let i = 0; i < message.values.length; ++i) {
              object.values[i] = message.values[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'condition_expr.v1.StringIn';
        }
      }

      StringIn.prototype.key = '';
      StringIn.prototype.values = $util.emptyArray;

      return StringIn;
    })();

    v1.OrExpr = (() => {
      class OrExpr {
        constructor(properties) {
          this.conditions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new OrExpr(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.conditions != null &&
            Object.hasOwnProperty.call(message, 'conditions')
          ) {
            for (const element of message.conditions) {
              $root.condition_expr.v1.Condition.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new OrExpr();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.conditions || !message.conditions.length) {
                  message.conditions = [];
                }
                message.conditions.push(
                  $root.condition_expr.v1.Condition.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.condition_expr.v1.OrExpr) {
            return object;
          }
          const message = new $root.condition_expr.v1.OrExpr();
          if (object.conditions) {
            if (!Array.isArray(object.conditions)) {
              throw new TypeError(
                '.condition_expr.v1.OrExpr.conditions: array type expected, but got ' +
                  typeof object.conditions
              );
            }
            message.conditions = new Array(object.conditions.length);
            for (let i = 0; i < object.conditions.length; ++i) {
              if (typeof object.conditions[i] !== 'object') {
                throw new TypeError(
                  '.condition_expr.v1.OrExpr.conditions: object expected, but got ' +
                    typeof object.conditions[i]
                );
              }
              message.conditions[i] =
                $root.condition_expr.v1.Condition.fromObject(
                  object.conditions[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.conditions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.conditions && message.conditions.length) {
            object.conditions = new Array(message.conditions.length);
            for (let i = 0; i < message.conditions.length; ++i) {
              object.conditions[i] = $root.condition_expr.v1.Condition.toObject(
                message.conditions[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'condition_expr.v1.OrExpr';
        }
      }

      OrExpr.prototype.conditions = $util.emptyArray;

      return OrExpr;
    })();

    return v1;
  })();

  return condition_expr;
})();

export const condition_expr = $root.condition_expr;
