import { values } from '@morf/proto/values_v1_ts_proto';
import { numericValueToString } from './numericValueToString';

export const ratioValueToString = (
  ratioValue: values.v1.IValue['ratioValue'] | null | undefined
): string => {
  if (ratioValue) {
    const numeratorValue = numericValueToString(ratioValue.numerator);
    const denominatorValue = numericValueToString(ratioValue.denominator);
    return `${numeratorValue} : ${denominatorValue}`;
  }
  return 'n/a';
};
