import { useTheme } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { FC } from 'react';
import { Text } from '../Typography';
import { Divider } from '../Divider';
import { ProviderProfileProps } from './types';
import { InfoBlock } from '../InfoBlock/InfoBlock';
import { Container } from '../Container';
import { ProfileImage } from '../ProfileImage';
import { Badge } from '../Badge';
import { RenderHTML } from '../RenderHTML';

export const ProviderProfile: FC<ProviderProfileProps> = ({
  id,
  imageUrl,
  name,
  qualifications,
  healthieId,
  order,
  shortBio,
  bio,
  states,
  insurances,
  specialties,
  internalTags,
  isLoading,
}) => {
  const theme = useTheme();
  return (
    <Container
      data-testid={id}
      height='100%'
      backgroundColor={theme.colors.ui.card}
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        position='relative'
        overflow='scroll'
        p={2}
        gap={1}
      >
        <Flexbox direction='column' gap={0.25} height='auto'>
          <ProfileImage imageUrl={imageUrl} name={name} />

          <Container height='auto'>
            <Text tag='h3'>{name}</Text>
          </Container>

          <Flexbox
            justifyContent='flex-start'
            direction='row'
            flexWrap='wrap'
            height='auto'
            gap={0.25}
          >
            {qualifications.map((qualification, index) => (
              <Badge key={index} label={qualification} type='live' />
            ))}
          </Flexbox>
        </Flexbox>

        <Flexbox direction='row' height='auto'>
          <InfoBlock isLoading={isLoading} label='Order' direction='column'>
            <Text tag='p2'>{order}</Text>
          </InfoBlock>
          <InfoBlock
            isLoading={isLoading}
            label='Third party application provider ID'
            direction='column'
          >
            <Text tag='p2'>{healthieId}</Text>
          </InfoBlock>
        </Flexbox>

        <InfoBlock isLoading={isLoading} label='Short bio' direction='column'>
          <Text tag='p2'>{shortBio}</Text>
        </InfoBlock>
        <Divider />
        <InfoBlock isLoading={isLoading} label='Bio' direction='column'>
          <Text tag='p2'>
            <RenderHTML htmlContent={bio} />
          </Text>
        </InfoBlock>
        <Divider />

        <InfoBlock isLoading={isLoading} label='States' direction='column'>
          <Flexbox
            justifyContent='flex-start'
            direction='row'
            flexWrap='wrap'
            gap={0.25}
          >
            {states.map((state, index) => (
              <Badge key={index} label={state} type='live' />
            ))}
          </Flexbox>
        </InfoBlock>

        <Divider />
        <InfoBlock isLoading={isLoading} label='Insurances' direction='column'>
          <Flexbox
            justifyContent='flex-start'
            direction='row'
            flexWrap='wrap'
            gap={0.25}
          >
            {insurances.map((insurance, index) => (
              <Badge key={index} label={insurance} type='live' />
            ))}
          </Flexbox>
        </InfoBlock>

        <Divider />
        <InfoBlock isLoading={isLoading} label='Specialties' direction='column'>
          <Flexbox
            justifyContent='flex-start'
            direction='row'
            flexWrap='wrap'
            gap={0.25}
          >
            {specialties.map((specialty, index) => (
              <Badge key={index} label={specialty.name} type='live' />
            ))}
          </Flexbox>
        </InfoBlock>

        <Divider />
        <InfoBlock
          isLoading={isLoading}
          label='Internal Tags'
          direction='column'
        >
          <Flexbox
            justifyContent='flex-start'
            direction='row'
            flexWrap='wrap'
            gap={0.25}
          >
            {internalTags.map((internalTag, index) => (
              <Badge key={index} label={internalTag} type='live' />
            ))}
          </Flexbox>
        </InfoBlock>
      </Flexbox>
    </Container>
  );
};
