import { StyledRow } from './TableRow.css';
import { TableRowProps } from './types';
import { TableCell } from './TableCell';
import { memo } from 'react';

const Row: <RowData>(props: TableRowProps<RowData>) => JSX.Element = ({
  cellBackgroundColor,
  cellHeight,
  cellHoveredBackgroundColor,
  cellHoveredBorderColor,
  cellSelectedBackgroundColor,
  cellSelectedBorderColor,
  firstRowId,
  isHovered,
  isLoading,
  isScrollable,
  onColumnClick,
  onMouseEnter,
  onMouseLeave,
  onRowClick,
  onRowRemove,
  onSubRowAdd,
  onSubRowClick,
  onSubRowRemove,
  parentCellHeight,
  row,
  subCellHeight,
}) => {
  const cells = row.getVisibleCells();
  const isRowClickable = row.getCanSelect() && !isLoading;
  const isRowHovered = isHovered && !isLoading;
  const isRowScrollable = isScrollable && !isLoading;
  const isSelected =
    row.getIsSelected() || row.getParentRow()?.getIsSelected() || false;

  const handleMouseEnter = () => onMouseEnter(row);

  const handleRowClick = () => {
    if (!isLoading) {
      row.toggleSelected();
      const [rowId, subRowId] = String(row.id).split('.').map(Number);
      onRowClick && onRowClick(isSelected ? null : rowId);
      onSubRowClick && onSubRowClick(subRowId !== undefined ? subRowId : null);
    }
  };

  const handleCellClick = (columnId: number) => {
    if (!isLoading) {
      onColumnClick && onColumnClick(columnId);
    }
  };

  return (
    <StyledRow
      key={row.id}
      data-testid='row'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleRowClick}
      isSelected={isSelected}
      isHovered={isRowHovered}
      isClickable={isRowClickable}
      cellBackgroundColor={cellBackgroundColor}
      cellHoveredBackgroundColor={cellHoveredBackgroundColor}
      cellHoveredBorderColor={cellHoveredBorderColor}
      cellSelectedBackgroundColor={cellSelectedBackgroundColor}
      cellSelectedBorderColor={cellSelectedBorderColor}
    >
      {cells.map((cell, cellIndex) => {
        const isExpanded = cell.row.getIsExpanded();
        const isFirstColumnCell = cellIndex === 0;
        const isLastColumnCell = cellIndex === cells.length - 1;
        const isFirstRow = cell.row.id === firstRowId;
        const onClick = () => handleCellClick(cellIndex);
        return (
          <TableCell
            key={cellIndex}
            cell={cell}
            cellHeight={cellHeight}
            isExpanded={isExpanded}
            isFirstColumnCell={isFirstColumnCell}
            isFirstRow={isFirstRow}
            isLastColumnCell={isLastColumnCell}
            isLoading={isLoading}
            isScrollable={isRowScrollable}
            onClick={onClick}
            onRowRemove={onRowRemove}
            onSubRowAdd={onSubRowAdd}
            onSubRowRemove={onSubRowRemove}
            parentCellHeight={parentCellHeight}
            subCellHeight={subCellHeight}
          />
        );
      })}
    </StyledRow>
  );
};
export const TableRow = memo(Row) as typeof Row;
