import { MORF_ORG_ID } from '@morf/constants';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

const getQueryParam = (param: string): string | undefined => {
  const router = useRouter();
  const value = router.query[param];

  if (!value || typeof value !== 'string') {
    return undefined;
  }

  return value;
};

export const useUrlParams = (): {
  from?: string;
  organizationId?: string;
  stytch_token_type?: string;
  thirdPartyId?: string;
  thirdPartyType?: string;
  token?: string;
  workflowId?: string;
} => {
  const from = getQueryParam('from');
  const organizationId = getQueryParam('organizationId');
  const stytch_token_type = getQueryParam('stytch_token_type');
  const thirdPartyId = getQueryParam('thirdPartyId');
  const thirdPartyType = getQueryParam('thirdPartyType');
  const token = getQueryParam('token');
  const workflowId = getQueryParam('workflowId');

  if (organizationId && organizationId !== Cookies.get(MORF_ORG_ID)) {
    Cookies.set(MORF_ORG_ID, organizationId);
  }

  return {
    from,
    organizationId,
    stytch_token_type,
    thirdPartyId,
    thirdPartyType,
    token, 
    workflowId,
  };
};
