'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { activity as activity$1 } from './activity_v1_ts_proto';
import { api_key as api_key$1 } from './api_key_v1_ts_proto';
import { google as google$2 } from './empty_ts_proto';
import { event_config as event_config$1 } from './event_config_v1_ts_proto';
import { event_snapshot as event_snapshot$1 } from './event_snapshot_v1_ts_proto';
import { object_type as object_type$1 } from './object_type_v1_ts_proto';
import { pagination as pagination$1 } from './pagination_v1_ts_proto';
import { patients as patients$1 } from './patients_v1_ts_proto';
import { profile_properties as profile_properties$1 } from './profile_properties_v1_ts_proto';
import { sensitivity as sensitivity$1 } from './sensitivity_v1_ts_proto';
import { sources as sources$1 } from './sources_v1_ts_proto';
import { google as google$1 } from './struct_ts_proto';
import { thirdparties as thirdparties$1 } from './thirdparties_v1_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { values as values$1 } from './values_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.organization = (() => {
  const organization = {};
  organization.v1 = (() => {
    const v1 = {};
    v1.CreateMorfPatientPropertyTypeResponse = (() => {
      class CreateMorfPatientPropertyTypeResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateMorfPatientPropertyTypeResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.propertyType != null &&
            Object.hasOwnProperty.call(message, 'propertyType')
          ) {
            patients$1.v1.MorfPatientPropertyType.encode(
              message.propertyType,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateMorfPatientPropertyTypeResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.propertyType =
                  patients$1.v1.MorfPatientPropertyType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.CreateMorfPatientPropertyTypeResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreateMorfPatientPropertyTypeResponse();
          if (object.propertyType != null) {
            if (typeof object.propertyType !== 'object') {
              throw new TypeError(
                '.organization.v1.CreateMorfPatientPropertyTypeResponse.propertyType: object expected, but got ' +
                  typeof object.propertyType
              );
            }
            message.propertyType =
              patients$1.v1.MorfPatientPropertyType.fromObject(
                object.propertyType
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.propertyType = null;
          }
          let keys;
          if (
            message.propertyType != null &&
            message.hasOwnProperty('propertyType')
          ) {
            object.propertyType =
              patients$1.v1.MorfPatientPropertyType.toObject(
                message.propertyType,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.CreateMorfPatientPropertyTypeResponse'
          );
        }
      }

      CreateMorfPatientPropertyTypeResponse.prototype.propertyType = null;

      return CreateMorfPatientPropertyTypeResponse;
    })();

    v1.CreateMorfPatientPropertyTypeRequest = (() => {
      class CreateMorfPatientPropertyTypeRequest {
        constructor(properties) {
          this.defaultSensitivities = [];
          this.fields = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateMorfPatientPropertyTypeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.fhirIndexingPath != null &&
            Object.hasOwnProperty.call(message, 'fhirIndexingPath')
          ) {
            writer.uint32(26).string(message.fhirIndexingPath);
          }
          if (
            message.defaultSensitivities != null &&
            Object.hasOwnProperty.call(message, 'defaultSensitivities')
          ) {
            for (const element of message.defaultSensitivities) {
              sensitivity$1.v1.Sensitivity.encode(
                element,
                writer.uint32(34).fork()
              ).ldelim();
            }
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            values$1.v1.ValueType.encode(
              message.type,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.fields != null &&
            Object.hasOwnProperty.call(message, 'fields')
          ) {
            for (const element of message.fields) {
              $root.organization.v1.CreateMorfPatientPropertyTypeRequest.encode(
                element,
                writer.uint32(50).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateMorfPatientPropertyTypeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.fhirIndexingPath = reader.string();
                break;
              }
              case 4: {
                if (
                  !message.defaultSensitivities ||
                  !message.defaultSensitivities.length
                ) {
                  message.defaultSensitivities = [];
                }
                message.defaultSensitivities.push(
                  sensitivity$1.v1.Sensitivity.decode(reader, reader.uint32())
                );
                break;
              }
              case 5: {
                message.type = values$1.v1.ValueType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                if (!message.fields || !message.fields.length) {
                  message.fields = [];
                }
                message.fields.push(
                  $root.organization.v1.CreateMorfPatientPropertyTypeRequest.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.CreateMorfPatientPropertyTypeRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreateMorfPatientPropertyTypeRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.fhirIndexingPath != null) {
            message.fhirIndexingPath = String(object.fhirIndexingPath);
          }
          if (object.defaultSensitivities) {
            if (!Array.isArray(object.defaultSensitivities)) {
              throw new TypeError(
                '.organization.v1.CreateMorfPatientPropertyTypeRequest.defaultSensitivities: array type expected, but got ' +
                  typeof object.defaultSensitivities
              );
            }
            message.defaultSensitivities = new Array(
              object.defaultSensitivities.length
            );
            for (let i = 0; i < object.defaultSensitivities.length; ++i) {
              if (typeof object.defaultSensitivities[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.CreateMorfPatientPropertyTypeRequest.defaultSensitivities: object expected, but got ' +
                    typeof object.defaultSensitivities[i]
                );
              }
              message.defaultSensitivities[i] =
                sensitivity$1.v1.Sensitivity.fromObject(
                  object.defaultSensitivities[i]
                );
            }
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.organization.v1.CreateMorfPatientPropertyTypeRequest.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type = values$1.v1.ValueType.fromObject(object.type);
          }
          if (object.fields) {
            if (!Array.isArray(object.fields)) {
              throw new TypeError(
                '.organization.v1.CreateMorfPatientPropertyTypeRequest.fields: array type expected, but got ' +
                  typeof object.fields
              );
            }
            message.fields = new Array(object.fields.length);
            for (let i = 0; i < object.fields.length; ++i) {
              if (typeof object.fields[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.CreateMorfPatientPropertyTypeRequest.fields: object expected, but got ' +
                    typeof object.fields[i]
                );
              }
              message.fields[i] =
                $root.organization.v1.CreateMorfPatientPropertyTypeRequest.fromObject(
                  object.fields[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.defaultSensitivities = [];
            object.fields = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.type = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.fhirIndexingPath != null &&
            message.hasOwnProperty('fhirIndexingPath')
          ) {
            object.fhirIndexingPath = message.fhirIndexingPath;
          }
          if (
            message.defaultSensitivities &&
            message.defaultSensitivities.length
          ) {
            object.defaultSensitivities = new Array(
              message.defaultSensitivities.length
            );
            for (let i = 0; i < message.defaultSensitivities.length; ++i) {
              object.defaultSensitivities[i] =
                sensitivity$1.v1.Sensitivity.toObject(
                  message.defaultSensitivities[i],
                  options
                );
            }
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = values$1.v1.ValueType.toObject(message.type, options);
          }
          if (message.fields && message.fields.length) {
            object.fields = new Array(message.fields.length);
            for (let i = 0; i < message.fields.length; ++i) {
              object.fields[i] =
                $root.organization.v1.CreateMorfPatientPropertyTypeRequest.toObject(
                  message.fields[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.CreateMorfPatientPropertyTypeRequest'
          );
        }
      }

      CreateMorfPatientPropertyTypeRequest.prototype.name = '';
      CreateMorfPatientPropertyTypeRequest.prototype.description = null;
      CreateMorfPatientPropertyTypeRequest.prototype.fhirIndexingPath = null;
      CreateMorfPatientPropertyTypeRequest.prototype.defaultSensitivities =
        $util.emptyArray;
      CreateMorfPatientPropertyTypeRequest.prototype.type = null;
      CreateMorfPatientPropertyTypeRequest.prototype.fields = $util.emptyArray;

      return CreateMorfPatientPropertyTypeRequest;
    })();

    v1.ListMorfPatientPropertyTypesRequest = (() => {
      class ListMorfPatientPropertyTypesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListMorfPatientPropertyTypesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListMorfPatientPropertyTypesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.ListMorfPatientPropertyTypesRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.ListMorfPatientPropertyTypesRequest();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.ListMorfPatientPropertyTypesRequest'
          );
        }
      }

      return ListMorfPatientPropertyTypesRequest;
    })();

    v1.ListMorfPatientPropertyTypesResponse = (() => {
      class ListMorfPatientPropertyTypesResponse {
        constructor(properties) {
          this.propertyTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListMorfPatientPropertyTypesResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.propertyTypes != null &&
            Object.hasOwnProperty.call(message, 'propertyTypes')
          ) {
            for (const element of message.propertyTypes) {
              patients$1.v1.MorfPatientPropertyType.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListMorfPatientPropertyTypesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.propertyTypes || !message.propertyTypes.length) {
                  message.propertyTypes = [];
                }
                message.propertyTypes.push(
                  patients$1.v1.MorfPatientPropertyType.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.ListMorfPatientPropertyTypesResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.ListMorfPatientPropertyTypesResponse();
          if (object.propertyTypes) {
            if (!Array.isArray(object.propertyTypes)) {
              throw new TypeError(
                '.organization.v1.ListMorfPatientPropertyTypesResponse.propertyTypes: array type expected, but got ' +
                  typeof object.propertyTypes
              );
            }
            message.propertyTypes = new Array(object.propertyTypes.length);
            for (let i = 0; i < object.propertyTypes.length; ++i) {
              if (typeof object.propertyTypes[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.ListMorfPatientPropertyTypesResponse.propertyTypes: object expected, but got ' +
                    typeof object.propertyTypes[i]
                );
              }
              message.propertyTypes[i] =
                patients$1.v1.MorfPatientPropertyType.fromObject(
                  object.propertyTypes[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.propertyTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.propertyTypes && message.propertyTypes.length) {
            object.propertyTypes = new Array(message.propertyTypes.length);
            for (let i = 0; i < message.propertyTypes.length; ++i) {
              object.propertyTypes[i] =
                patients$1.v1.MorfPatientPropertyType.toObject(
                  message.propertyTypes[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.ListMorfPatientPropertyTypesResponse'
          );
        }
      }

      ListMorfPatientPropertyTypesResponse.prototype.propertyTypes =
        $util.emptyArray;

      return ListMorfPatientPropertyTypesResponse;
    })();

    v1.CreatePatientPropertyCollectionResponse = (() => {
      class CreatePatientPropertyCollectionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertyCollectionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.collection != null &&
            Object.hasOwnProperty.call(message, 'collection')
          ) {
            $root.organization.v1.PatientPropertyCollection.encode(
              message.collection,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertyCollectionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.collection =
                  $root.organization.v1.PatientPropertyCollection.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.CreatePatientPropertyCollectionResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreatePatientPropertyCollectionResponse();
          if (object.collection != null) {
            if (typeof object.collection !== 'object') {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyCollectionResponse.collection: object expected, but got ' +
                  typeof object.collection
              );
            }
            message.collection =
              $root.organization.v1.PatientPropertyCollection.fromObject(
                object.collection
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.collection = null;
          }
          let keys;
          if (
            message.collection != null &&
            message.hasOwnProperty('collection')
          ) {
            object.collection =
              $root.organization.v1.PatientPropertyCollection.toObject(
                message.collection,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.CreatePatientPropertyCollectionResponse'
          );
        }
      }

      CreatePatientPropertyCollectionResponse.prototype.collection = null;

      return CreatePatientPropertyCollectionResponse;
    })();

    v1.CreatePatientPropertyRequestField = (() => {
      class CreatePatientPropertyRequestField {
        constructor(properties) {
          this.sensitivities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertyRequestField(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.sensitivities != null &&
            Object.hasOwnProperty.call(message, 'sensitivities')
          ) {
            for (const element of message.sensitivities) {
              sensitivity$1.v1.Sensitivity.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          if (
            message.metadata != null &&
            Object.hasOwnProperty.call(message, 'metadata')
          ) {
            google$1.protobuf.Struct.encode(
              message.metadata,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            values$1.v1.ValueType.encode(
              message.type,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertyRequestField();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                if (!message.sensitivities || !message.sensitivities.length) {
                  message.sensitivities = [];
                }
                message.sensitivities.push(
                  sensitivity$1.v1.Sensitivity.decode(reader, reader.uint32())
                );
                break;
              }
              case 4: {
                message.metadata = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.type = values$1.v1.ValueType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.CreatePatientPropertyRequestField
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreatePatientPropertyRequestField();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.sensitivities) {
            if (!Array.isArray(object.sensitivities)) {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyRequestField.sensitivities: array type expected, but got ' +
                  typeof object.sensitivities
              );
            }
            message.sensitivities = new Array(object.sensitivities.length);
            for (let i = 0; i < object.sensitivities.length; ++i) {
              if (typeof object.sensitivities[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.CreatePatientPropertyRequestField.sensitivities: object expected, but got ' +
                    typeof object.sensitivities[i]
                );
              }
              message.sensitivities[i] =
                sensitivity$1.v1.Sensitivity.fromObject(
                  object.sensitivities[i]
                );
            }
          }
          if (object.metadata != null) {
            if (typeof object.metadata !== 'object') {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyRequestField.metadata: object expected, but got ' +
                  typeof object.metadata
              );
            }
            message.metadata = google$1.protobuf.Struct.fromObject(
              object.metadata
            );
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyRequestField.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type = values$1.v1.ValueType.fromObject(object.type);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sensitivities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.type = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.sensitivities && message.sensitivities.length) {
            object.sensitivities = new Array(message.sensitivities.length);
            for (let i = 0; i < message.sensitivities.length; ++i) {
              object.sensitivities[i] = sensitivity$1.v1.Sensitivity.toObject(
                message.sensitivities[i],
                options
              );
            }
          }
          if (message.metadata != null && message.hasOwnProperty('metadata')) {
            object.metadata = google$1.protobuf.Struct.toObject(
              message.metadata,
              options
            );
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = values$1.v1.ValueType.toObject(message.type, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.CreatePatientPropertyRequestField'
          );
        }
      }

      CreatePatientPropertyRequestField.prototype.name = '';
      CreatePatientPropertyRequestField.prototype.description = null;
      CreatePatientPropertyRequestField.prototype.sensitivities =
        $util.emptyArray;
      CreatePatientPropertyRequestField.prototype.metadata = null;
      CreatePatientPropertyRequestField.prototype.type = null;

      return CreatePatientPropertyRequestField;
    })();

    v1.CreatePatientPropertyRequestSchema = (() => {
      class CreatePatientPropertyRequestSchema {
        constructor(properties) {
          this.fields = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertyRequestSchema(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            values$1.v1.ValueType.encode(
              message.type,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.fields != null &&
            Object.hasOwnProperty.call(message, 'fields')
          ) {
            for (const element of message.fields) {
              $root.organization.v1.CreatePatientPropertyRequestField.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertyRequestSchema();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = values$1.v1.ValueType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                if (!message.fields || !message.fields.length) {
                  message.fields = [];
                }
                message.fields.push(
                  $root.organization.v1.CreatePatientPropertyRequestField.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.CreatePatientPropertyRequestSchema
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreatePatientPropertyRequestSchema();
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyRequestSchema.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type = values$1.v1.ValueType.fromObject(object.type);
          }
          if (object.fields) {
            if (!Array.isArray(object.fields)) {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyRequestSchema.fields: array type expected, but got ' +
                  typeof object.fields
              );
            }
            message.fields = new Array(object.fields.length);
            for (let i = 0; i < object.fields.length; ++i) {
              if (typeof object.fields[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.CreatePatientPropertyRequestSchema.fields: object expected, but got ' +
                    typeof object.fields[i]
                );
              }
              message.fields[i] =
                $root.organization.v1.CreatePatientPropertyRequestField.fromObject(
                  object.fields[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.fields = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = null;
          }
          let keys;
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = values$1.v1.ValueType.toObject(message.type, options);
          }
          if (message.fields && message.fields.length) {
            object.fields = new Array(message.fields.length);
            for (let i = 0; i < message.fields.length; ++i) {
              object.fields[i] =
                $root.organization.v1.CreatePatientPropertyRequestField.toObject(
                  message.fields[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.CreatePatientPropertyRequestSchema'
          );
        }
      }

      CreatePatientPropertyRequestSchema.prototype.type = null;
      CreatePatientPropertyRequestSchema.prototype.fields = $util.emptyArray;

      return CreatePatientPropertyRequestSchema;
    })();

    v1.CreatePatientPropertyRequest = (() => {
      class CreatePatientPropertyRequest {
        get schemaSource() {
          for (const key of ['schema', 'morfPatientPropertyTypeName']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set schemaSource(name) {
          for (const key of ['schema', 'morfPatientPropertyTypeName']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          this.sensitivities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertyRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.collectionName != null &&
            Object.hasOwnProperty.call(message, 'collectionName')
          ) {
            writer.uint32(26).string(message.collectionName);
          }
          if (
            message.schema != null &&
            Object.hasOwnProperty.call(message, 'schema')
          ) {
            $root.organization.v1.CreatePatientPropertyRequestSchema.encode(
              message.schema,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.morfPatientPropertyTypeName != null &&
            Object.hasOwnProperty.call(message, 'morfPatientPropertyTypeName')
          ) {
            writer.uint32(42).string(message.morfPatientPropertyTypeName);
          }
          if (
            message.sensitivities != null &&
            Object.hasOwnProperty.call(message, 'sensitivities')
          ) {
            for (const element of message.sensitivities) {
              sensitivity$1.v1.Sensitivity.encode(
                element,
                writer.uint32(50).fork()
              ).ldelim();
            }
          }
          if (
            message.metadata != null &&
            Object.hasOwnProperty.call(message, 'metadata')
          ) {
            google$1.protobuf.Struct.encode(
              message.metadata,
              writer.uint32(58).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.collectionName = reader.string();
                break;
              }
              case 4: {
                message.schema =
                  $root.organization.v1.CreatePatientPropertyRequestSchema.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.morfPatientPropertyTypeName = reader.string();
                break;
              }
              case 6: {
                if (!message.sensitivities || !message.sensitivities.length) {
                  message.sensitivities = [];
                }
                message.sensitivities.push(
                  sensitivity$1.v1.Sensitivity.decode(reader, reader.uint32())
                );
                break;
              }
              case 7: {
                message.metadata = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.organization.v1.CreatePatientPropertyRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreatePatientPropertyRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.collectionName != null) {
            message.collectionName = String(object.collectionName);
          }
          if (object.schema != null) {
            if (typeof object.schema !== 'object') {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyRequest.schema: object expected, but got ' +
                  typeof object.schema
              );
            }
            message.schema =
              $root.organization.v1.CreatePatientPropertyRequestSchema.fromObject(
                object.schema
              );
          }
          if (object.morfPatientPropertyTypeName != null) {
            message.morfPatientPropertyTypeName = String(
              object.morfPatientPropertyTypeName
            );
          }
          if (object.sensitivities) {
            if (!Array.isArray(object.sensitivities)) {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyRequest.sensitivities: array type expected, but got ' +
                  typeof object.sensitivities
              );
            }
            message.sensitivities = new Array(object.sensitivities.length);
            for (let i = 0; i < object.sensitivities.length; ++i) {
              if (typeof object.sensitivities[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.CreatePatientPropertyRequest.sensitivities: object expected, but got ' +
                    typeof object.sensitivities[i]
                );
              }
              message.sensitivities[i] =
                sensitivity$1.v1.Sensitivity.fromObject(
                  object.sensitivities[i]
                );
            }
          }
          if (object.metadata != null) {
            if (typeof object.metadata !== 'object') {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyRequest.metadata: object expected, but got ' +
                  typeof object.metadata
              );
            }
            message.metadata = google$1.protobuf.Struct.fromObject(
              object.metadata
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sensitivities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.collectionName = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.collectionName != null &&
            message.hasOwnProperty('collectionName')
          ) {
            object.collectionName = message.collectionName;
          }
          if (message.schema != null && message.hasOwnProperty('schema')) {
            object.schema =
              $root.organization.v1.CreatePatientPropertyRequestSchema.toObject(
                message.schema,
                options
              );
            if (options.oneofs) {
              object.schemaSource = 'schema';
            }
          }
          if (
            message.morfPatientPropertyTypeName != null &&
            message.hasOwnProperty('morfPatientPropertyTypeName')
          ) {
            object.morfPatientPropertyTypeName =
              message.morfPatientPropertyTypeName;
            if (options.oneofs) {
              object.schemaSource = 'morfPatientPropertyTypeName';
            }
          }
          if (message.sensitivities && message.sensitivities.length) {
            object.sensitivities = new Array(message.sensitivities.length);
            for (let i = 0; i < message.sensitivities.length; ++i) {
              object.sensitivities[i] = sensitivity$1.v1.Sensitivity.toObject(
                message.sensitivities[i],
                options
              );
            }
          }
          if (message.metadata != null && message.hasOwnProperty('metadata')) {
            object.metadata = google$1.protobuf.Struct.toObject(
              message.metadata,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.CreatePatientPropertyRequest';
        }
      }

      CreatePatientPropertyRequest.prototype.name = '';
      CreatePatientPropertyRequest.prototype.description = null;
      CreatePatientPropertyRequest.prototype.collectionName = '';
      CreatePatientPropertyRequest.prototype.schema = null;
      CreatePatientPropertyRequest.prototype.morfPatientPropertyTypeName = '';
      CreatePatientPropertyRequest.prototype.sensitivities = $util.emptyArray;
      CreatePatientPropertyRequest.prototype.metadata = null;

      return CreatePatientPropertyRequest;
    })();

    v1.CreatePatientPropertyResponse = (() => {
      class CreatePatientPropertyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertyResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.property != null &&
            Object.hasOwnProperty.call(message, 'property')
          ) {
            patients$1.v1.PatientProperty.encode(
              message.property,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.property = patients$1.v1.PatientProperty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.CreatePatientPropertyResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreatePatientPropertyResponse();
          if (object.property != null) {
            if (typeof object.property !== 'object') {
              throw new TypeError(
                '.organization.v1.CreatePatientPropertyResponse.property: object expected, but got ' +
                  typeof object.property
              );
            }
            message.property = patients$1.v1.PatientProperty.fromObject(
              object.property
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.property = null;
          }
          let keys;
          if (message.property != null && message.hasOwnProperty('property')) {
            object.property = patients$1.v1.PatientProperty.toObject(
              message.property,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.CreatePatientPropertyResponse'
          );
        }
      }

      CreatePatientPropertyResponse.prototype.property = null;

      return CreatePatientPropertyResponse;
    })();

    v1.UpdatePatientPropertyRequest = (() => {
      class UpdatePatientPropertyRequest {
        constructor(properties) {
          this.updateSensitivities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientPropertyRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.lookup != null &&
            Object.hasOwnProperty.call(message, 'lookup')
          ) {
            patients$1.v1.PatientPropertyLookup.encode(
              message.lookup,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.updateName != null &&
            Object.hasOwnProperty.call(message, 'updateName')
          ) {
            writer.uint32(18).string(message.updateName);
          }
          if (
            message.updateDescription != null &&
            Object.hasOwnProperty.call(message, 'updateDescription')
          ) {
            writer.uint32(26).string(message.updateDescription);
          }
          if (
            message.updateSchemaSource != null &&
            Object.hasOwnProperty.call(message, 'updateSchemaSource')
          ) {
            $root.organization.v1.UpdatePatientPropertySchemaSource.encode(
              message.updateSchemaSource,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.updateCollectionName != null &&
            Object.hasOwnProperty.call(message, 'updateCollectionName')
          ) {
            writer.uint32(42).string(message.updateCollectionName);
          }
          if (
            message.updateSensitivities != null &&
            Object.hasOwnProperty.call(message, 'updateSensitivities')
          ) {
            for (const element of message.updateSensitivities) {
              sensitivity$1.v1.Sensitivity.encode(
                element,
                writer.uint32(50).fork()
              ).ldelim();
            }
          }
          if (
            message.metadata != null &&
            Object.hasOwnProperty.call(message, 'metadata')
          ) {
            google$1.protobuf.Struct.encode(
              message.metadata,
              writer.uint32(58).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientPropertyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.lookup = patients$1.v1.PatientPropertyLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.updateName = reader.string();
                break;
              }
              case 3: {
                message.updateDescription = reader.string();
                break;
              }
              case 4: {
                message.updateSchemaSource =
                  $root.organization.v1.UpdatePatientPropertySchemaSource.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.updateCollectionName = reader.string();
                break;
              }
              case 6: {
                if (
                  !message.updateSensitivities ||
                  !message.updateSensitivities.length
                ) {
                  message.updateSensitivities = [];
                }
                message.updateSensitivities.push(
                  sensitivity$1.v1.Sensitivity.decode(reader, reader.uint32())
                );
                break;
              }
              case 7: {
                message.metadata = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.organization.v1.UpdatePatientPropertyRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.UpdatePatientPropertyRequest();
          if (object.lookup != null) {
            if (typeof object.lookup !== 'object') {
              throw new TypeError(
                '.organization.v1.UpdatePatientPropertyRequest.lookup: object expected, but got ' +
                  typeof object.lookup
              );
            }
            message.lookup = patients$1.v1.PatientPropertyLookup.fromObject(
              object.lookup
            );
          }
          if (object.updateName != null) {
            message.updateName = String(object.updateName);
          }
          if (object.updateDescription != null) {
            message.updateDescription = String(object.updateDescription);
          }
          if (object.updateSchemaSource != null) {
            if (typeof object.updateSchemaSource !== 'object') {
              throw new TypeError(
                '.organization.v1.UpdatePatientPropertyRequest.updateSchemaSource: object expected, but got ' +
                  typeof object.updateSchemaSource
              );
            }
            message.updateSchemaSource =
              $root.organization.v1.UpdatePatientPropertySchemaSource.fromObject(
                object.updateSchemaSource
              );
          }
          if (object.updateCollectionName != null) {
            message.updateCollectionName = String(object.updateCollectionName);
          }
          if (object.updateSensitivities) {
            if (!Array.isArray(object.updateSensitivities)) {
              throw new TypeError(
                '.organization.v1.UpdatePatientPropertyRequest.updateSensitivities: array type expected, but got ' +
                  typeof object.updateSensitivities
              );
            }
            message.updateSensitivities = new Array(
              object.updateSensitivities.length
            );
            for (let i = 0; i < object.updateSensitivities.length; ++i) {
              if (typeof object.updateSensitivities[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.UpdatePatientPropertyRequest.updateSensitivities: object expected, but got ' +
                    typeof object.updateSensitivities[i]
                );
              }
              message.updateSensitivities[i] =
                sensitivity$1.v1.Sensitivity.fromObject(
                  object.updateSensitivities[i]
                );
            }
          }
          if (object.metadata != null) {
            if (typeof object.metadata !== 'object') {
              throw new TypeError(
                '.organization.v1.UpdatePatientPropertyRequest.metadata: object expected, but got ' +
                  typeof object.metadata
              );
            }
            message.metadata = google$1.protobuf.Struct.fromObject(
              object.metadata
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.updateSensitivities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.lookup = null;
          }
          let keys;
          if (message.lookup != null && message.hasOwnProperty('lookup')) {
            object.lookup = patients$1.v1.PatientPropertyLookup.toObject(
              message.lookup,
              options
            );
          }
          if (
            message.updateName != null &&
            message.hasOwnProperty('updateName')
          ) {
            object.updateName = message.updateName;
          }
          if (
            message.updateDescription != null &&
            message.hasOwnProperty('updateDescription')
          ) {
            object.updateDescription = message.updateDescription;
          }
          if (
            message.updateSchemaSource != null &&
            message.hasOwnProperty('updateSchemaSource')
          ) {
            object.updateSchemaSource =
              $root.organization.v1.UpdatePatientPropertySchemaSource.toObject(
                message.updateSchemaSource,
                options
              );
          }
          if (
            message.updateCollectionName != null &&
            message.hasOwnProperty('updateCollectionName')
          ) {
            object.updateCollectionName = message.updateCollectionName;
          }
          if (
            message.updateSensitivities &&
            message.updateSensitivities.length
          ) {
            object.updateSensitivities = new Array(
              message.updateSensitivities.length
            );
            for (let i = 0; i < message.updateSensitivities.length; ++i) {
              object.updateSensitivities[i] =
                sensitivity$1.v1.Sensitivity.toObject(
                  message.updateSensitivities[i],
                  options
                );
            }
          }
          if (message.metadata != null && message.hasOwnProperty('metadata')) {
            object.metadata = google$1.protobuf.Struct.toObject(
              message.metadata,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.UpdatePatientPropertyRequest';
        }
      }

      UpdatePatientPropertyRequest.prototype.lookup = null;
      UpdatePatientPropertyRequest.prototype.updateName = null;
      UpdatePatientPropertyRequest.prototype.updateDescription = null;
      UpdatePatientPropertyRequest.prototype.updateSchemaSource = null;
      UpdatePatientPropertyRequest.prototype.updateCollectionName = null;
      UpdatePatientPropertyRequest.prototype.updateSensitivities =
        $util.emptyArray;
      UpdatePatientPropertyRequest.prototype.metadata = null;

      return UpdatePatientPropertyRequest;
    })();

    v1.UpdatePatientPropertyResponse = (() => {
      class UpdatePatientPropertyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientPropertyResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.property != null &&
            Object.hasOwnProperty.call(message, 'property')
          ) {
            patients$1.v1.PatientProperty.encode(
              message.property,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientPropertyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.property = patients$1.v1.PatientProperty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.UpdatePatientPropertyResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.UpdatePatientPropertyResponse();
          if (object.property != null) {
            if (typeof object.property !== 'object') {
              throw new TypeError(
                '.organization.v1.UpdatePatientPropertyResponse.property: object expected, but got ' +
                  typeof object.property
              );
            }
            message.property = patients$1.v1.PatientProperty.fromObject(
              object.property
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.property = null;
          }
          let keys;
          if (message.property != null && message.hasOwnProperty('property')) {
            object.property = patients$1.v1.PatientProperty.toObject(
              message.property,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.UpdatePatientPropertyResponse'
          );
        }
      }

      UpdatePatientPropertyResponse.prototype.property = null;

      return UpdatePatientPropertyResponse;
    })();

    v1.UpdatePatientPropertySchemaSource = (() => {
      class UpdatePatientPropertySchemaSource {
        get updateSchemaSource() {
          for (const key of [
            'updateSchema',
            'updateMorfPatientPropertyTypeName',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set updateSchemaSource(name) {
          for (const key of [
            'updateSchema',
            'updateMorfPatientPropertyTypeName',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientPropertySchemaSource(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.updateSchema != null &&
            Object.hasOwnProperty.call(message, 'updateSchema')
          ) {
            $root.organization.v1.CreatePatientPropertyRequestSchema.encode(
              message.updateSchema,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.updateMorfPatientPropertyTypeName != null &&
            Object.hasOwnProperty.call(
              message,
              'updateMorfPatientPropertyTypeName'
            )
          ) {
            writer.uint32(18).string(message.updateMorfPatientPropertyTypeName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientPropertySchemaSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.updateSchema =
                  $root.organization.v1.CreatePatientPropertyRequestSchema.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.updateMorfPatientPropertyTypeName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.UpdatePatientPropertySchemaSource
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.UpdatePatientPropertySchemaSource();
          if (object.updateSchema != null) {
            if (typeof object.updateSchema !== 'object') {
              throw new TypeError(
                '.organization.v1.UpdatePatientPropertySchemaSource.updateSchema: object expected, but got ' +
                  typeof object.updateSchema
              );
            }
            message.updateSchema =
              $root.organization.v1.CreatePatientPropertyRequestSchema.fromObject(
                object.updateSchema
              );
          }
          if (object.updateMorfPatientPropertyTypeName != null) {
            message.updateMorfPatientPropertyTypeName = String(
              object.updateMorfPatientPropertyTypeName
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.updateSchema != null &&
            message.hasOwnProperty('updateSchema')
          ) {
            object.updateSchema =
              $root.organization.v1.CreatePatientPropertyRequestSchema.toObject(
                message.updateSchema,
                options
              );
            if (options.oneofs) {
              object.updateSchemaSource = 'updateSchema';
            }
          }
          if (
            message.updateMorfPatientPropertyTypeName != null &&
            message.hasOwnProperty('updateMorfPatientPropertyTypeName')
          ) {
            object.updateMorfPatientPropertyTypeName =
              message.updateMorfPatientPropertyTypeName;
            if (options.oneofs) {
              object.updateSchemaSource = 'updateMorfPatientPropertyTypeName';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.UpdatePatientPropertySchemaSource'
          );
        }
      }

      UpdatePatientPropertySchemaSource.prototype.updateSchema = null;
      UpdatePatientPropertySchemaSource.prototype.updateMorfPatientPropertyTypeName =
        '';

      return UpdatePatientPropertySchemaSource;
    })();

    v1.GetPatientPropertyRequest = (() => {
      class GetPatientPropertyRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientPropertyRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.lookup != null &&
            Object.hasOwnProperty.call(message, 'lookup')
          ) {
            patients$1.v1.PatientPropertyLookup.encode(
              message.lookup,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientPropertyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.lookup = patients$1.v1.PatientPropertyLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.organization.v1.GetPatientPropertyRequest
          ) {
            return object;
          }
          const message = new $root.organization.v1.GetPatientPropertyRequest();
          if (object.lookup != null) {
            if (typeof object.lookup !== 'object') {
              throw new TypeError(
                '.organization.v1.GetPatientPropertyRequest.lookup: object expected, but got ' +
                  typeof object.lookup
              );
            }
            message.lookup = patients$1.v1.PatientPropertyLookup.fromObject(
              object.lookup
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.lookup = null;
          }
          let keys;
          if (message.lookup != null && message.hasOwnProperty('lookup')) {
            object.lookup = patients$1.v1.PatientPropertyLookup.toObject(
              message.lookup,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.GetPatientPropertyRequest';
        }
      }

      GetPatientPropertyRequest.prototype.lookup = null;

      return GetPatientPropertyRequest;
    })();

    v1.DeletePatientPropertyRequest = (() => {
      class DeletePatientPropertyRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeletePatientPropertyRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeletePatientPropertyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.organization.v1.DeletePatientPropertyRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.DeletePatientPropertyRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.DeletePatientPropertyRequest';
        }
      }

      DeletePatientPropertyRequest.prototype.id = '';

      return DeletePatientPropertyRequest;
    })();

    v1.DeletePatientPropertyResponse = (() => {
      class DeletePatientPropertyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeletePatientPropertyResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.property != null &&
            Object.hasOwnProperty.call(message, 'property')
          ) {
            patients$1.v1.PatientProperty.encode(
              message.property,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeletePatientPropertyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.property = patients$1.v1.PatientProperty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.DeletePatientPropertyResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.DeletePatientPropertyResponse();
          if (object.property != null) {
            if (typeof object.property !== 'object') {
              throw new TypeError(
                '.organization.v1.DeletePatientPropertyResponse.property: object expected, but got ' +
                  typeof object.property
              );
            }
            message.property = patients$1.v1.PatientProperty.fromObject(
              object.property
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.property = null;
          }
          let keys;
          if (message.property != null && message.hasOwnProperty('property')) {
            object.property = patients$1.v1.PatientProperty.toObject(
              message.property,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.DeletePatientPropertyResponse'
          );
        }
      }

      DeletePatientPropertyResponse.prototype.property = null;

      return DeletePatientPropertyResponse;
    })();

    v1.GetPatientPropertyResponse = (() => {
      class GetPatientPropertyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientPropertyResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.property != null &&
            Object.hasOwnProperty.call(message, 'property')
          ) {
            patients$1.v1.PatientProperty.encode(
              message.property,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientPropertyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.property = patients$1.v1.PatientProperty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.organization.v1.GetPatientPropertyResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.GetPatientPropertyResponse();
          if (object.property != null) {
            if (typeof object.property !== 'object') {
              throw new TypeError(
                '.organization.v1.GetPatientPropertyResponse.property: object expected, but got ' +
                  typeof object.property
              );
            }
            message.property = patients$1.v1.PatientProperty.fromObject(
              object.property
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.property = null;
          }
          let keys;
          if (message.property != null && message.hasOwnProperty('property')) {
            object.property = patients$1.v1.PatientProperty.toObject(
              message.property,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.GetPatientPropertyResponse';
        }
      }

      GetPatientPropertyResponse.prototype.property = null;

      return GetPatientPropertyResponse;
    })();

    v1.ListPropertiesRequest = (() => {
      class ListPropertiesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPropertiesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.pagination != null &&
            Object.hasOwnProperty.call(message, 'pagination')
          ) {
            pagination$1.v1.Pagination.encode(
              message.pagination,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.organization.v1.ListPropertiesFilter.encode(
              message.filter,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPropertiesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.pagination = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.filter =
                  $root.organization.v1.ListPropertiesFilter.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.ListPropertiesRequest) {
            return object;
          }
          const message = new $root.organization.v1.ListPropertiesRequest();
          if (object.pagination != null) {
            if (typeof object.pagination !== 'object') {
              throw new TypeError(
                '.organization.v1.ListPropertiesRequest.pagination: object expected, but got ' +
                  typeof object.pagination
              );
            }
            message.pagination = pagination$1.v1.Pagination.fromObject(
              object.pagination
            );
          }
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.organization.v1.ListPropertiesRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter =
              $root.organization.v1.ListPropertiesFilter.fromObject(
                object.filter
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.pagination = null;
          }
          let keys;
          if (
            message.pagination != null &&
            message.hasOwnProperty('pagination')
          ) {
            object.pagination = pagination$1.v1.Pagination.toObject(
              message.pagination,
              options
            );
          }
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter = $root.organization.v1.ListPropertiesFilter.toObject(
              message.filter,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.ListPropertiesRequest';
        }
      }

      ListPropertiesRequest.prototype.pagination = null;
      ListPropertiesRequest.prototype.filter = null;

      return ListPropertiesRequest;
    })();

    v1.PatientPropertyLookups = (() => {
      class PatientPropertyLookups {
        constructor(properties) {
          this.lookups = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertyLookups(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.lookups != null &&
            Object.hasOwnProperty.call(message, 'lookups')
          ) {
            for (const element of message.lookups) {
              patients$1.v1.PatientPropertyLookup.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertyLookups();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.lookups || !message.lookups.length) {
                  message.lookups = [];
                }
                message.lookups.push(
                  patients$1.v1.PatientPropertyLookup.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.PatientPropertyLookups) {
            return object;
          }
          const message = new $root.organization.v1.PatientPropertyLookups();
          if (object.lookups) {
            if (!Array.isArray(object.lookups)) {
              throw new TypeError(
                '.organization.v1.PatientPropertyLookups.lookups: array type expected, but got ' +
                  typeof object.lookups
              );
            }
            message.lookups = new Array(object.lookups.length);
            for (let i = 0; i < object.lookups.length; ++i) {
              if (typeof object.lookups[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.PatientPropertyLookups.lookups: object expected, but got ' +
                    typeof object.lookups[i]
                );
              }
              message.lookups[i] =
                patients$1.v1.PatientPropertyLookup.fromObject(
                  object.lookups[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.lookups = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.lookups && message.lookups.length) {
            object.lookups = new Array(message.lookups.length);
            for (let i = 0; i < message.lookups.length; ++i) {
              object.lookups[i] = patients$1.v1.PatientPropertyLookup.toObject(
                message.lookups[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.PatientPropertyLookups';
        }
      }

      PatientPropertyLookups.prototype.lookups = $util.emptyArray;

      return PatientPropertyLookups;
    })();

    v1.ListPropertiesFilter = (() => {
      class ListPropertiesFilter {
        get nameFilter() {
          for (const key of ['none', 'selectNamesLike', 'lookup']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set nameFilter(name) {
          for (const key of ['none', 'selectNamesLike', 'lookup']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          this.sensitivityAnnotations = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPropertiesFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sensitivityAnnotations != null &&
            Object.hasOwnProperty.call(message, 'sensitivityAnnotations')
          ) {
            for (const element of message.sensitivityAnnotations) {
              sensitivity$1.v1.Sensitivity.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.none != null &&
            Object.hasOwnProperty.call(message, 'none')
          ) {
            google$2.protobuf.Empty.encode(
              message.none,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.selectNamesLike != null &&
            Object.hasOwnProperty.call(message, 'selectNamesLike')
          ) {
            writer.uint32(26).string(message.selectNamesLike);
          }
          if (
            message.lookup != null &&
            Object.hasOwnProperty.call(message, 'lookup')
          ) {
            $root.organization.v1.PatientPropertyLookups.encode(
              message.lookup,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPropertiesFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.sensitivityAnnotations ||
                  !message.sensitivityAnnotations.length
                ) {
                  message.sensitivityAnnotations = [];
                }
                message.sensitivityAnnotations.push(
                  sensitivity$1.v1.Sensitivity.decode(reader, reader.uint32())
                );
                break;
              }
              case 2: {
                message.none = google$2.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.selectNamesLike = reader.string();
                break;
              }
              case 4: {
                message.lookup =
                  $root.organization.v1.PatientPropertyLookups.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.ListPropertiesFilter) {
            return object;
          }
          const message = new $root.organization.v1.ListPropertiesFilter();
          if (object.sensitivityAnnotations) {
            if (!Array.isArray(object.sensitivityAnnotations)) {
              throw new TypeError(
                '.organization.v1.ListPropertiesFilter.sensitivityAnnotations: array type expected, but got ' +
                  typeof object.sensitivityAnnotations
              );
            }
            message.sensitivityAnnotations = new Array(
              object.sensitivityAnnotations.length
            );
            for (let i = 0; i < object.sensitivityAnnotations.length; ++i) {
              if (typeof object.sensitivityAnnotations[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.ListPropertiesFilter.sensitivityAnnotations: object expected, but got ' +
                    typeof object.sensitivityAnnotations[i]
                );
              }
              message.sensitivityAnnotations[i] =
                sensitivity$1.v1.Sensitivity.fromObject(
                  object.sensitivityAnnotations[i]
                );
            }
          }
          if (object.none != null) {
            if (typeof object.none !== 'object') {
              throw new TypeError(
                '.organization.v1.ListPropertiesFilter.none: object expected, but got ' +
                  typeof object.none
              );
            }
            message.none = google$2.protobuf.Empty.fromObject(object.none);
          }
          if (object.selectNamesLike != null) {
            message.selectNamesLike = String(object.selectNamesLike);
          }
          if (object.lookup != null) {
            if (typeof object.lookup !== 'object') {
              throw new TypeError(
                '.organization.v1.ListPropertiesFilter.lookup: object expected, but got ' +
                  typeof object.lookup
              );
            }
            message.lookup =
              $root.organization.v1.PatientPropertyLookups.fromObject(
                object.lookup
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sensitivityAnnotations = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.sensitivityAnnotations &&
            message.sensitivityAnnotations.length
          ) {
            object.sensitivityAnnotations = new Array(
              message.sensitivityAnnotations.length
            );
            for (let i = 0; i < message.sensitivityAnnotations.length; ++i) {
              object.sensitivityAnnotations[i] =
                sensitivity$1.v1.Sensitivity.toObject(
                  message.sensitivityAnnotations[i],
                  options
                );
            }
          }
          if (message.none != null && message.hasOwnProperty('none')) {
            object.none = google$2.protobuf.Empty.toObject(
              message.none,
              options
            );
            if (options.oneofs) {
              object.nameFilter = 'none';
            }
          }
          if (
            message.selectNamesLike != null &&
            message.hasOwnProperty('selectNamesLike')
          ) {
            object.selectNamesLike = message.selectNamesLike;
            if (options.oneofs) {
              object.nameFilter = 'selectNamesLike';
            }
          }
          if (message.lookup != null && message.hasOwnProperty('lookup')) {
            object.lookup =
              $root.organization.v1.PatientPropertyLookups.toObject(
                message.lookup,
                options
              );
            if (options.oneofs) {
              object.nameFilter = 'lookup';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.ListPropertiesFilter';
        }
      }

      ListPropertiesFilter.prototype.sensitivityAnnotations = $util.emptyArray;
      ListPropertiesFilter.prototype.none = null;
      ListPropertiesFilter.prototype.selectNamesLike = '';
      ListPropertiesFilter.prototype.lookup = null;

      return ListPropertiesFilter;
    })();

    v1.ListPropertiesResponse = (() => {
      class ListPropertiesResponse {
        constructor(properties) {
          this.properties = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPropertiesResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const element of message.properties) {
              patients$1.v1.PatientProperty.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(16).uint64(message.total);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPropertiesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.properties || !message.properties.length) {
                  message.properties = [];
                }
                message.properties.push(
                  patients$1.v1.PatientProperty.decode(reader, reader.uint32())
                );
                break;
              }
              case 2: {
                message.total = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.organization.v1.ListPropertiesResponse) {
            return object;
          }
          const message = new $root.organization.v1.ListPropertiesResponse();
          if (object.properties) {
            if (!Array.isArray(object.properties)) {
              throw new TypeError(
                '.organization.v1.ListPropertiesResponse.properties: array type expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = new Array(object.properties.length);
            for (let i = 0; i < object.properties.length; ++i) {
              if (typeof object.properties[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.ListPropertiesResponse.properties: object expected, but got ' +
                    typeof object.properties[i]
                );
              }
              message.properties[i] = patients$1.v1.PatientProperty.fromObject(
                object.properties[i]
              );
            }
          }
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.properties = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.properties && message.properties.length) {
            object.properties = new Array(message.properties.length);
            for (let i = 0; i < message.properties.length; ++i) {
              object.properties[i] = patients$1.v1.PatientProperty.toObject(
                message.properties[i],
                options
              );
            }
          }
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.ListPropertiesResponse';
        }
      }

      ListPropertiesResponse.prototype.properties = $util.emptyArray;
      ListPropertiesResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      return ListPropertiesResponse;
    })();

    v1.CreatePatientPropertyCollectionRequest = (() => {
      class CreatePatientPropertyCollectionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertyCollectionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertyCollectionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.CreatePatientPropertyCollectionRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreatePatientPropertyCollectionRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.CreatePatientPropertyCollectionRequest'
          );
        }
      }

      CreatePatientPropertyCollectionRequest.prototype.name = '';
      CreatePatientPropertyCollectionRequest.prototype.description = null;

      return CreatePatientPropertyCollectionRequest;
    })();

    v1.UpdatePatientPropertyCollectionResponse = (() => {
      class UpdatePatientPropertyCollectionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientPropertyCollectionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.collection != null &&
            Object.hasOwnProperty.call(message, 'collection')
          ) {
            $root.organization.v1.PatientPropertyCollection.encode(
              message.collection,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientPropertyCollectionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.collection =
                  $root.organization.v1.PatientPropertyCollection.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.UpdatePatientPropertyCollectionResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.UpdatePatientPropertyCollectionResponse();
          if (object.collection != null) {
            if (typeof object.collection !== 'object') {
              throw new TypeError(
                '.organization.v1.UpdatePatientPropertyCollectionResponse.collection: object expected, but got ' +
                  typeof object.collection
              );
            }
            message.collection =
              $root.organization.v1.PatientPropertyCollection.fromObject(
                object.collection
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.collection = null;
          }
          let keys;
          if (
            message.collection != null &&
            message.hasOwnProperty('collection')
          ) {
            object.collection =
              $root.organization.v1.PatientPropertyCollection.toObject(
                message.collection,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.UpdatePatientPropertyCollectionResponse'
          );
        }
      }

      UpdatePatientPropertyCollectionResponse.prototype.collection = null;

      return UpdatePatientPropertyCollectionResponse;
    })();

    v1.UpdatePatientPropertyCollectionRequest = (() => {
      class UpdatePatientPropertyCollectionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientPropertyCollectionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.updateName != null &&
            Object.hasOwnProperty.call(message, 'updateName')
          ) {
            writer.uint32(18).string(message.updateName);
          }
          if (
            message.updateDescription != null &&
            Object.hasOwnProperty.call(message, 'updateDescription')
          ) {
            writer.uint32(26).string(message.updateDescription);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientPropertyCollectionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.updateName = reader.string();
                break;
              }
              case 3: {
                message.updateDescription = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.UpdatePatientPropertyCollectionRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.UpdatePatientPropertyCollectionRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.updateName != null) {
            message.updateName = String(object.updateName);
          }
          if (object.updateDescription != null) {
            message.updateDescription = String(object.updateDescription);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.updateName != null &&
            message.hasOwnProperty('updateName')
          ) {
            object.updateName = message.updateName;
          }
          if (
            message.updateDescription != null &&
            message.hasOwnProperty('updateDescription')
          ) {
            object.updateDescription = message.updateDescription;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.UpdatePatientPropertyCollectionRequest'
          );
        }
      }

      UpdatePatientPropertyCollectionRequest.prototype.name = '';
      UpdatePatientPropertyCollectionRequest.prototype.updateName = null;
      UpdatePatientPropertyCollectionRequest.prototype.updateDescription = null;

      return UpdatePatientPropertyCollectionRequest;
    })();

    v1.GetPatientPropertyCollectionRequest = (() => {
      class GetPatientPropertyCollectionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientPropertyCollectionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientPropertyCollectionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.GetPatientPropertyCollectionRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.GetPatientPropertyCollectionRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.GetPatientPropertyCollectionRequest'
          );
        }
      }

      GetPatientPropertyCollectionRequest.prototype.name = '';

      return GetPatientPropertyCollectionRequest;
    })();

    v1.GetPatientPropertyCollectionResponse = (() => {
      class GetPatientPropertyCollectionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientPropertyCollectionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.collection != null &&
            Object.hasOwnProperty.call(message, 'collection')
          ) {
            $root.organization.v1.PatientPropertyCollection.encode(
              message.collection,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientPropertyCollectionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.collection =
                  $root.organization.v1.PatientPropertyCollection.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.GetPatientPropertyCollectionResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.GetPatientPropertyCollectionResponse();
          if (object.collection != null) {
            if (typeof object.collection !== 'object') {
              throw new TypeError(
                '.organization.v1.GetPatientPropertyCollectionResponse.collection: object expected, but got ' +
                  typeof object.collection
              );
            }
            message.collection =
              $root.organization.v1.PatientPropertyCollection.fromObject(
                object.collection
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.collection = null;
          }
          let keys;
          if (
            message.collection != null &&
            message.hasOwnProperty('collection')
          ) {
            object.collection =
              $root.organization.v1.PatientPropertyCollection.toObject(
                message.collection,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.GetPatientPropertyCollectionResponse'
          );
        }
      }

      GetPatientPropertyCollectionResponse.prototype.collection = null;

      return GetPatientPropertyCollectionResponse;
    })();

    v1.ListPatientPropertyCollectionsRequest = (() => {
      class ListPatientPropertyCollectionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientPropertyCollectionsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.organization.v1.ListPatientPropertyCollectionsFilter.encode(
              message.filter,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientPropertyCollectionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.filter =
                  $root.organization.v1.ListPatientPropertyCollectionsFilter.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.ListPatientPropertyCollectionsRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.ListPatientPropertyCollectionsRequest();
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.organization.v1.ListPatientPropertyCollectionsRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter =
              $root.organization.v1.ListPatientPropertyCollectionsFilter.fromObject(
                object.filter
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter =
              $root.organization.v1.ListPatientPropertyCollectionsFilter.toObject(
                message.filter,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.ListPatientPropertyCollectionsRequest'
          );
        }
      }

      ListPatientPropertyCollectionsRequest.prototype.filter = null;

      return ListPatientPropertyCollectionsRequest;
    })();

    v1.ListPatientPropertyCollectionsFilter = (() => {
      class ListPatientPropertyCollectionsFilter {
        get nameFilter() {
          for (const key of ['names', 'namesLike']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set nameFilter(name) {
          for (const key of ['names', 'namesLike']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientPropertyCollectionsFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.names != null &&
            Object.hasOwnProperty.call(message, 'names')
          ) {
            $root.organization.v1.ListPatientPropertyNamesSelect.encode(
              message.names,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.namesLike != null &&
            Object.hasOwnProperty.call(message, 'namesLike')
          ) {
            writer.uint32(18).string(message.namesLike);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientPropertyCollectionsFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.names =
                  $root.organization.v1.ListPatientPropertyNamesSelect.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.namesLike = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.ListPatientPropertyCollectionsFilter
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.ListPatientPropertyCollectionsFilter();
          if (object.names != null) {
            if (typeof object.names !== 'object') {
              throw new TypeError(
                '.organization.v1.ListPatientPropertyCollectionsFilter.names: object expected, but got ' +
                  typeof object.names
              );
            }
            message.names =
              $root.organization.v1.ListPatientPropertyNamesSelect.fromObject(
                object.names
              );
          }
          if (object.namesLike != null) {
            message.namesLike = String(object.namesLike);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.names != null && message.hasOwnProperty('names')) {
            object.names =
              $root.organization.v1.ListPatientPropertyNamesSelect.toObject(
                message.names,
                options
              );
            if (options.oneofs) {
              object.nameFilter = 'names';
            }
          }
          if (
            message.namesLike != null &&
            message.hasOwnProperty('namesLike')
          ) {
            object.namesLike = message.namesLike;
            if (options.oneofs) {
              object.nameFilter = 'namesLike';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.ListPatientPropertyCollectionsFilter'
          );
        }
      }

      ListPatientPropertyCollectionsFilter.prototype.names = null;
      ListPatientPropertyCollectionsFilter.prototype.namesLike = '';

      return ListPatientPropertyCollectionsFilter;
    })();

    v1.ListPatientPropertyNamesSelect = (() => {
      class ListPatientPropertyNamesSelect {
        constructor(properties) {
          this.names = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientPropertyNamesSelect(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.names != null &&
            Object.hasOwnProperty.call(message, 'names')
          ) {
            for (const element of message.names) {
              writer.uint32(10).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientPropertyNamesSelect();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.names || !message.names.length) {
                  message.names = [];
                }
                message.names.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.ListPatientPropertyNamesSelect
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.ListPatientPropertyNamesSelect();
          if (object.names) {
            if (!Array.isArray(object.names)) {
              throw new TypeError(
                '.organization.v1.ListPatientPropertyNamesSelect.names: array type expected, but got ' +
                  typeof object.names
              );
            }
            message.names = new Array(object.names.length);
            for (let i = 0; i < object.names.length; ++i) {
              message.names[i] = String(object.names[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.names = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.names && message.names.length) {
            object.names = new Array(message.names.length);
            for (let i = 0; i < message.names.length; ++i) {
              object.names[i] = message.names[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.ListPatientPropertyNamesSelect'
          );
        }
      }

      ListPatientPropertyNamesSelect.prototype.names = $util.emptyArray;

      return ListPatientPropertyNamesSelect;
    })();

    v1.ListPatientPropertyCollectionsResponse = (() => {
      class ListPatientPropertyCollectionsResponse {
        constructor(properties) {
          this.propertyCollections = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientPropertyCollectionsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.propertyCollections != null &&
            Object.hasOwnProperty.call(message, 'propertyCollections')
          ) {
            for (const element of message.propertyCollections) {
              $root.organization.v1.PatientPropertyCollection.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientPropertyCollectionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.propertyCollections ||
                  !message.propertyCollections.length
                ) {
                  message.propertyCollections = [];
                }
                message.propertyCollections.push(
                  $root.organization.v1.PatientPropertyCollection.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.ListPatientPropertyCollectionsResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.ListPatientPropertyCollectionsResponse();
          if (object.propertyCollections) {
            if (!Array.isArray(object.propertyCollections)) {
              throw new TypeError(
                '.organization.v1.ListPatientPropertyCollectionsResponse.propertyCollections: array type expected, but got ' +
                  typeof object.propertyCollections
              );
            }
            message.propertyCollections = new Array(
              object.propertyCollections.length
            );
            for (let i = 0; i < object.propertyCollections.length; ++i) {
              if (typeof object.propertyCollections[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.ListPatientPropertyCollectionsResponse.propertyCollections: object expected, but got ' +
                    typeof object.propertyCollections[i]
                );
              }
              message.propertyCollections[i] =
                $root.organization.v1.PatientPropertyCollection.fromObject(
                  object.propertyCollections[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.propertyCollections = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.propertyCollections &&
            message.propertyCollections.length
          ) {
            object.propertyCollections = new Array(
              message.propertyCollections.length
            );
            for (let i = 0; i < message.propertyCollections.length; ++i) {
              object.propertyCollections[i] =
                $root.organization.v1.PatientPropertyCollection.toObject(
                  message.propertyCollections[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'organization.v1.ListPatientPropertyCollectionsResponse'
          );
        }
      }

      ListPatientPropertyCollectionsResponse.prototype.propertyCollections =
        $util.emptyArray;

      return ListPatientPropertyCollectionsResponse;
    })();

    v1.PatientPropertyCollection = (() => {
      class PatientPropertyCollection {
        constructor(properties) {
          this.properties = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertyCollection(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const element of message.properties) {
              patients$1.v1.PatientProperty.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertyCollection();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                if (!message.properties || !message.properties.length) {
                  message.properties = [];
                }
                message.properties.push(
                  patients$1.v1.PatientProperty.decode(reader, reader.uint32())
                );
                break;
              }
              case 4: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.organization.v1.PatientPropertyCollection
          ) {
            return object;
          }
          const message = new $root.organization.v1.PatientPropertyCollection();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.properties) {
            if (!Array.isArray(object.properties)) {
              throw new TypeError(
                '.organization.v1.PatientPropertyCollection.properties: array type expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = new Array(object.properties.length);
            for (let i = 0; i < object.properties.length; ++i) {
              if (typeof object.properties[i] !== 'object') {
                throw new TypeError(
                  '.organization.v1.PatientPropertyCollection.properties: object expected, but got ' +
                    typeof object.properties[i]
                );
              }
              message.properties[i] = patients$1.v1.PatientProperty.fromObject(
                object.properties[i]
              );
            }
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.organization.v1.PatientPropertyCollection.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.properties = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.timing = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.properties && message.properties.length) {
            object.properties = new Array(message.properties.length);
            for (let i = 0; i < message.properties.length; ++i) {
              object.properties[i] = patients$1.v1.PatientProperty.toObject(
                message.properties[i],
                options
              );
            }
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.PatientPropertyCollection';
        }
      }

      PatientPropertyCollection.prototype.name = '';
      PatientPropertyCollection.prototype.description = null;
      PatientPropertyCollection.prototype.properties = $util.emptyArray;
      PatientPropertyCollection.prototype.timing = null;

      return PatientPropertyCollection;
    })();

    v1.CreateProfilePropertyRequest = (() => {
      class CreateProfilePropertyRequest {
        constructor(properties) {
          this.additionalCustomSensitivities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateProfilePropertyRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.propertyTypeStub != null &&
            Object.hasOwnProperty.call(message, 'propertyTypeStub')
          ) {
            profile_properties$1.v1.PropertyType.encode(
              message.propertyTypeStub,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.additionalCustomSensitivities != null &&
            Object.hasOwnProperty.call(message, 'additionalCustomSensitivities')
          ) {
            writer.uint32(34).fork();
            for (const element of message.additionalCustomSensitivities) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          if (
            message.metadata != null &&
            Object.hasOwnProperty.call(message, 'metadata')
          ) {
            google$1.protobuf.Struct.encode(
              message.metadata,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateProfilePropertyRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.propertyTypeStub =
                  profile_properties$1.v1.PropertyType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                if (
                  !message.additionalCustomSensitivities ||
                  !message.additionalCustomSensitivities.length
                ) {
                  message.additionalCustomSensitivities = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.additionalCustomSensitivities.push(reader.int32());
                } else {
                  message.additionalCustomSensitivities.push(reader.int32());
                }
                break;
              }
              case 5: {
                message.metadata = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.organization.v1.CreateProfilePropertyRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreateProfilePropertyRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.propertyTypeStub != null) {
            if (typeof object.propertyTypeStub !== 'object') {
              throw new TypeError(
                '.organization.v1.CreateProfilePropertyRequest.propertyTypeStub: object expected, but got ' +
                  typeof object.propertyTypeStub
              );
            }
            message.propertyTypeStub =
              profile_properties$1.v1.PropertyType.fromObject(
                object.propertyTypeStub
              );
          }
          if (object.additionalCustomSensitivities) {
            if (!Array.isArray(object.additionalCustomSensitivities)) {
              throw new TypeError(
                '.organization.v1.CreateProfilePropertyRequest.additionalCustomSensitivities: array type expected, but got ' +
                  typeof object.additionalCustomSensitivities
              );
            }
            message.additionalCustomSensitivities = new Array(
              object.additionalCustomSensitivities.length
            );
            for (
              let i = 0;
              i < object.additionalCustomSensitivities.length;
              ++i
            ) {
              switch (object.additionalCustomSensitivities[i]) {
                case 'UNSPECIFIED':
                case 0: {
                  message.additionalCustomSensitivities[i] = 0;
                  break;
                }
                case 'PII':
                case 1: {
                  message.additionalCustomSensitivities[i] = 1;
                  break;
                }
                case 'HI':
                case 2: {
                  message.additionalCustomSensitivities[i] = 2;
                  break;
                }
                case 'ID':
                case 3: {
                  message.additionalCustomSensitivities[i] = 3;
                  break;
                }
                default: {
                  if (
                    typeof object.additionalCustomSensitivities[i] == 'number'
                  ) {
                    message.additionalCustomSensitivities[i] =
                      object.additionalCustomSensitivities[i];
                    break;
                  }
                  message.additionalCustomSensitivities[i] = 0;
                  break;
                }
              }
            }
          }
          if (object.metadata != null) {
            if (typeof object.metadata !== 'object') {
              throw new TypeError(
                '.organization.v1.CreateProfilePropertyRequest.metadata: object expected, but got ' +
                  typeof object.metadata
              );
            }
            message.metadata = google$1.protobuf.Struct.fromObject(
              object.metadata
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.additionalCustomSensitivities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.propertyTypeStub = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.propertyTypeStub != null &&
            message.hasOwnProperty('propertyTypeStub')
          ) {
            object.propertyTypeStub =
              profile_properties$1.v1.PropertyType.toObject(
                message.propertyTypeStub,
                options
              );
          }
          if (
            message.additionalCustomSensitivities &&
            message.additionalCustomSensitivities.length
          ) {
            object.additionalCustomSensitivities = new Array(
              message.additionalCustomSensitivities.length
            );
            for (
              let i = 0;
              i < message.additionalCustomSensitivities.length;
              ++i
            ) {
              object.additionalCustomSensitivities[i] =
                options.enums === String
                  ? sensitivity$1.v1.Sensitivity.Value[
                      message.additionalCustomSensitivities[i]
                    ] === undefined
                    ? message.additionalCustomSensitivities[i]
                    : sensitivity$1.v1.Sensitivity.Value[
                        message.additionalCustomSensitivities[i]
                      ]
                  : message.additionalCustomSensitivities[i];
            }
          }
          if (message.metadata != null && message.hasOwnProperty('metadata')) {
            object.metadata = google$1.protobuf.Struct.toObject(
              message.metadata,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.CreateProfilePropertyRequest';
        }
      }

      CreateProfilePropertyRequest.prototype.name = '';
      CreateProfilePropertyRequest.prototype.description = null;
      CreateProfilePropertyRequest.prototype.propertyTypeStub = null;
      CreateProfilePropertyRequest.prototype.additionalCustomSensitivities =
        $util.emptyArray;
      CreateProfilePropertyRequest.prototype.metadata = null;

      return CreateProfilePropertyRequest;
    })();

    v1.CreateProfilePropertyResponse = (() => {
      class CreateProfilePropertyResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateProfilePropertyResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.property != null &&
            Object.hasOwnProperty.call(message, 'property')
          ) {
            profile_properties$1.v1.ProfileProperty.encode(
              message.property,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateProfilePropertyResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.property =
                  profile_properties$1.v1.ProfileProperty.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.CreateProfilePropertyResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.CreateProfilePropertyResponse();
          if (object.property != null) {
            if (typeof object.property !== 'object') {
              throw new TypeError(
                '.organization.v1.CreateProfilePropertyResponse.property: object expected, but got ' +
                  typeof object.property
              );
            }
            message.property =
              profile_properties$1.v1.ProfileProperty.fromObject(
                object.property
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.property = null;
          }
          let keys;
          if (message.property != null && message.hasOwnProperty('property')) {
            object.property = profile_properties$1.v1.ProfileProperty.toObject(
              message.property,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.CreateProfilePropertyResponse'
          );
        }
      }

      CreateProfilePropertyResponse.prototype.property = null;

      return CreateProfilePropertyResponse;
    })();

    v1.ListProfilePropertiesRequest = (() => {
      class ListProfilePropertiesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfilePropertiesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.includeDefaultProperties != null &&
            Object.hasOwnProperty.call(message, 'includeDefaultProperties')
          ) {
            writer.uint32(8).bool(message.includeDefaultProperties);
          }
          if (
            message.includeCustomProperties != null &&
            Object.hasOwnProperty.call(message, 'includeCustomProperties')
          ) {
            writer.uint32(16).bool(message.includeCustomProperties);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfilePropertiesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.includeDefaultProperties = reader.bool();
                break;
              }
              case 2: {
                message.includeCustomProperties = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.organization.v1.ListProfilePropertiesRequest
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.ListProfilePropertiesRequest();
          if (object.includeDefaultProperties != null) {
            message.includeDefaultProperties = Boolean(
              object.includeDefaultProperties
            );
          }
          if (object.includeCustomProperties != null) {
            message.includeCustomProperties = Boolean(
              object.includeCustomProperties
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.includeDefaultProperties = false;
            object.includeCustomProperties = false;
          }
          let keys;
          if (
            message.includeDefaultProperties != null &&
            message.hasOwnProperty('includeDefaultProperties')
          ) {
            object.includeDefaultProperties = message.includeDefaultProperties;
          }
          if (
            message.includeCustomProperties != null &&
            message.hasOwnProperty('includeCustomProperties')
          ) {
            object.includeCustomProperties = message.includeCustomProperties;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'organization.v1.ListProfilePropertiesRequest';
        }
      }

      ListProfilePropertiesRequest.prototype.includeDefaultProperties = false;
      ListProfilePropertiesRequest.prototype.includeCustomProperties = false;

      return ListProfilePropertiesRequest;
    })();

    v1.ListProfilePropertiesResponse = (() => {
      class ListProfilePropertiesResponse {
        constructor(properties) {
          this.properties = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfilePropertiesResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const key of Object.keys(message.properties)) {
              writer.uint32(10).fork().uint32(10).string(key);
              profile_properties$1.v1.ProfileProperty.encode(
                message.properties[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfilePropertiesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (message.properties === $util.emptyObject)
                  message.properties = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = profile_properties$1.v1.ProfileProperty.decode(
                        reader,
                        reader.uint32()
                      );
                      break;
                    }
                  }
                  message.properties[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.organization.v1.ListProfilePropertiesResponse
          ) {
            return object;
          }
          const message =
            new $root.organization.v1.ListProfilePropertiesResponse();
          if (object.properties) {
            if (typeof object.properties !== 'object') {
              throw new TypeError(
                '.organization.v1.ListProfilePropertiesResponse.properties: object expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = {};
            for (
              let keys = Object.keys(object.properties), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.properties[keys[i]] !== 'object') {
                throw new TypeError(
                  '.organization.v1.ListProfilePropertiesResponse.value: object expected, but got ' +
                    typeof object.properties[keys[i]]
                );
              }
              message.properties[keys[i]] =
                profile_properties$1.v1.ProfileProperty.fromObject(
                  object.properties[keys[i]]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.properties = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.properties &&
            (keys = Object.keys(message.properties)).length
          ) {
            object.properties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.properties[keys[i]] =
                profile_properties$1.v1.ProfileProperty.toObject(
                  message.properties[keys[i]],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'organization.v1.ListProfilePropertiesResponse'
          );
        }
      }

      ListProfilePropertiesResponse.prototype.properties = $util.emptyObject;

      return ListProfilePropertiesResponse;
    })();

    v1.OrganizationService = (() => {
      class OrganizationService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new OrganizationService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (OrganizationService.prototype.createMorfPatientPropertyType =
          function createMorfPatientPropertyType(request, callback) {
            return this.rpcCall(
              createMorfPatientPropertyType,
              $root.organization.v1.CreateMorfPatientPropertyTypeRequest,
              $root.organization.v1.CreateMorfPatientPropertyTypeResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreateMorfPatientPropertyType' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listMorfPatientPropertyTypes =
          function listMorfPatientPropertyTypes(request, callback) {
            return this.rpcCall(
              listMorfPatientPropertyTypes,
              $root.organization.v1.ListMorfPatientPropertyTypesRequest,
              $root.organization.v1.ListMorfPatientPropertyTypesResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListMorfPatientPropertyTypes' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.createPatientPropertyCollection =
          function createPatientPropertyCollection(request, callback) {
            return this.rpcCall(
              createPatientPropertyCollection,
              $root.organization.v1.CreatePatientPropertyCollectionRequest,
              $root.organization.v1.CreatePatientPropertyCollectionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreatePatientPropertyCollection' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.updatePatientPropertyCollection =
          function updatePatientPropertyCollection(request, callback) {
            return this.rpcCall(
              updatePatientPropertyCollection,
              $root.organization.v1.UpdatePatientPropertyCollectionRequest,
              $root.organization.v1.UpdatePatientPropertyCollectionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdatePatientPropertyCollection' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.getPatientPropertyCollection =
          function getPatientPropertyCollection(request, callback) {
            return this.rpcCall(
              getPatientPropertyCollection,
              $root.organization.v1.GetPatientPropertyCollectionRequest,
              $root.organization.v1.GetPatientPropertyCollectionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetPatientPropertyCollection' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listPatientPropertyCollections =
          function listPatientPropertyCollections(request, callback) {
            return this.rpcCall(
              listPatientPropertyCollections,
              $root.organization.v1.ListPatientPropertyCollectionsRequest,
              $root.organization.v1.ListPatientPropertyCollectionsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListPatientPropertyCollections' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.createPatientProperty =
          function createPatientProperty(request, callback) {
            return this.rpcCall(
              createPatientProperty,
              $root.organization.v1.CreatePatientPropertyRequest,
              $root.organization.v1.CreatePatientPropertyResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreatePatientProperty' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.updatePatientProperty =
          function updatePatientProperty(request, callback) {
            return this.rpcCall(
              updatePatientProperty,
              $root.organization.v1.UpdatePatientPropertyRequest,
              $root.organization.v1.UpdatePatientPropertyResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdatePatientProperty' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.getPatientProperty =
          function getPatientProperty(request, callback) {
            return this.rpcCall(
              getPatientProperty,
              $root.organization.v1.GetPatientPropertyRequest,
              $root.organization.v1.GetPatientPropertyResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetPatientProperty' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.deletePatientProperty =
          function deletePatientProperty(request, callback) {
            return this.rpcCall(
              deletePatientProperty,
              $root.organization.v1.DeletePatientPropertyRequest,
              $root.organization.v1.DeletePatientPropertyResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'DeletePatientProperty' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listProperties = function listProperties(
          request,
          callback
        ) {
          return this.rpcCall(
            listProperties,
            $root.organization.v1.ListPropertiesRequest,
            $root.organization.v1.ListPropertiesResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListProperties' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.createProfileProperty =
          function createProfileProperty(request, callback) {
            return this.rpcCall(
              createProfileProperty,
              $root.organization.v1.CreateProfilePropertyRequest,
              $root.organization.v1.CreateProfilePropertyResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreateProfileProperty' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listProfileProperties =
          function listProfileProperties(request, callback) {
            return this.rpcCall(
              listProfileProperties,
              $root.organization.v1.ListProfilePropertiesRequest,
              $root.organization.v1.ListProfilePropertiesResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListProfileProperties' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.createSource = function createSource(
          request,
          callback
        ) {
          return this.rpcCall(
            createSource,
            sources$1.v1.CreateSourceRequest,
            sources$1.v1.CreateSourceResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'CreateSource' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.updateSource = function updateSource(
          request,
          callback
        ) {
          return this.rpcCall(
            updateSource,
            sources$1.v1.UpdateSourceRequest,
            sources$1.v1.UpdateSourceResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'UpdateSource' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.deleteSource = function deleteSource(
          request,
          callback
        ) {
          return this.rpcCall(
            deleteSource,
            sources$1.v1.DeleteSourceRequest,
            sources$1.v1.DeleteSourceResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'DeleteSource' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listSources = function listSources(
          request,
          callback
        ) {
          return this.rpcCall(
            listSources,
            sources$1.v1.ListSourceRequest,
            sources$1.v1.ListSourceResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListSources' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.getSource = function getSource(
          request,
          callback
        ) {
          return this.rpcCall(
            getSource,
            sources$1.v1.GetSourceRequest,
            sources$1.v1.GetSourceResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetSource' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listReceivedSourceData =
          function listReceivedSourceData(request, callback) {
            return this.rpcCall(
              listReceivedSourceData,
              sources$1.v1.ListReceivedSourceDataRequest,
              sources$1.v1.ListReceivedSourceDataResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListReceivedSourceData' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listEventSnapshots =
          function listEventSnapshots(request, callback) {
            return this.rpcCall(
              listEventSnapshots,
              event_snapshot$1.v1.ListEventSnapshotsRequest,
              event_snapshot$1.v1.ListEventSnapshotsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListEventSnapshots' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.createThirdPartySecret =
          function createThirdPartySecret(request, callback) {
            return this.rpcCall(
              createThirdPartySecret,
              thirdparties$1.v1.CreateThirdPartySecretRequest,
              thirdparties$1.v1.CreateThirdPartySecretResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreateThirdPartySecret' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listActivities = function listActivities(
          request,
          callback
        ) {
          return this.rpcCall(
            listActivities,
            activity$1.v1.ListActivitiesRequest,
            activity$1.v1.ListActivitiesResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListActivities' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.getSourceObjectType =
          function getSourceObjectType(request, callback) {
            return this.rpcCall(
              getSourceObjectType,
              object_type$1.v1.GetSourceObjectTypeRequest,
              object_type$1.v1.GetSourceObjectTypeResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetSourceObjectType' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.updateObjectType =
          function updateObjectType(request, callback) {
            return this.rpcCall(
              updateObjectType,
              object_type$1.v1.UpdateObjectTypeRequest,
              object_type$1.v1.UpdateObjectTypeResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateObjectType' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listObjectTypes =
          function listObjectTypes(request, callback) {
            return this.rpcCall(
              listObjectTypes,
              object_type$1.v1.ListObjectTypesRequest,
              object_type$1.v1.ListObjectTypesResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListObjectTypes' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.updateObjectGroup =
          function updateObjectGroup(request, callback) {
            return this.rpcCall(
              updateObjectGroup,
              object_type$1.v1.UpdateObjectGroupRequest,
              object_type$1.v1.UpdateObjectGroupResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateObjectGroup' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listObjectGroups =
          function listObjectGroups(request, callback) {
            return this.rpcCall(
              listObjectGroups,
              object_type$1.v1.ListObjectGroupsRequest,
              object_type$1.v1.ListObjectGroupsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListObjectGroups' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.getObjectGroup = function getObjectGroup(
          request,
          callback
        ) {
          return this.rpcCall(
            getObjectGroup,
            object_type$1.v1.GetObjectGroupRequest,
            object_type$1.v1.GetObjectGroupResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetObjectGroup' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.deleteObjectType =
          function deleteObjectType(request, callback) {
            return this.rpcCall(
              deleteObjectType,
              object_type$1.v1.DeleteObjectTypeRequest,
              object_type$1.v1.DeleteObjectTypeResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'DeleteObjectType' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.createObjectInstanceRelationships =
          function createObjectInstanceRelationships(request, callback) {
            return this.rpcCall(
              createObjectInstanceRelationships,
              object_type$1.v1.CreateObjectInstanceRelationshipsRequest,
              object_type$1.v1.CreateObjectInstanceRelationshipsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreateObjectInstanceRelationships' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.createPatientPropertyMapping =
          function createPatientPropertyMapping(request, callback) {
            return this.rpcCall(
              createPatientPropertyMapping,
              event_config$1.v1.CreatePatientPropertyMappingRequest,
              event_config$1.v1.CreatePatientPropertyMappingResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreatePatientPropertyMapping' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.updatePatientPropertyMapping =
          function updatePatientPropertyMapping(request, callback) {
            return this.rpcCall(
              updatePatientPropertyMapping,
              event_config$1.v1.UpdatePatientPropertyMappingRequest,
              event_config$1.v1.UpdatePatientPropertyMappingResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdatePatientPropertyMapping' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.deletePatientPropertyMapping =
          function deletePatientPropertyMapping(request, callback) {
            return this.rpcCall(
              deletePatientPropertyMapping,
              event_config$1.v1.DeletePatientPropertyMappingRequest,
              event_config$1.v1.DeletePatientPropertyMappingResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'DeletePatientPropertyMapping' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listObjectInstanceRelationships =
          function listObjectInstanceRelationships(request, callback) {
            return this.rpcCall(
              listObjectInstanceRelationships,
              object_type$1.v1.ListObjectInstanceRelationshipsRequest,
              object_type$1.v1.ListObjectInstanceRelationshipsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListObjectInstanceRelationships' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.updateEventSnapshot =
          function updateEventSnapshot(request, callback) {
            return this.rpcCall(
              updateEventSnapshot,
              event_snapshot$1.v1.UpdateEventSnapshotRequest,
              event_snapshot$1.v1.UpdateEventSnapshotResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateEventSnapshot' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.createAPIKey = function createAPIKey(
          request,
          callback
        ) {
          return this.rpcCall(
            createAPIKey,
            api_key$1.v1.CreateAPIKeyRequest,
            api_key$1.v1.CreateAPIKeyResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'CreateAPIKey' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listAPIKeys = function listAPIKeys(
          request,
          callback
        ) {
          return this.rpcCall(
            listAPIKeys,
            api_key$1.v1.ListAPIKeysRequest,
            api_key$1.v1.ListAPIKeysResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListAPIKeys' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.deleteAPIKey = function deleteAPIKey(
          request,
          callback
        ) {
          return this.rpcCall(
            deleteAPIKey,
            api_key$1.v1.DeleteAPIKeyRequest,
            api_key$1.v1.DeleteAPIKeyResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'DeleteAPIKey' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.listWebhookRequests =
          function listWebhookRequests(request, callback) {
            return this.rpcCall(
              listWebhookRequests,
              sources$1.v1.ListWebhooksRequest,
              sources$1.v1.ListWebhooksResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListWebhookRequests' }
      );

      Object.defineProperty(
        (OrganizationService.prototype.updateWebhookRequest =
          function updateWebhookRequest(request, callback) {
            return this.rpcCall(
              updateWebhookRequest,
              sources$1.v1.UpdateWebhookRequestRequest,
              sources$1.v1.UpdateWebhookRequestResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateWebhookRequest' }
      );

      return OrganizationService;
    })();

    return v1;
  })();

  return organization;
})();

export const organization = $root.organization;
