import { SourceConfigProps } from '../Workflow/types';
import { convertToKebabCase } from './convertToKebabCase';
import { convertToTitleCase } from './convertToTitleCase';
import { formatConstantValue } from '../Workflow/CustomNode/DestinationActionNode/DestinationActionConfigure/SelectValuePairModal/ConstantValue/formatConstantValue';
import { formatSensitivities } from './formatSensitivities';
import { formatType } from './formatType';
import { hasField } from './hasField';
import { thirdparties } from '@morf/proto/thirdparties_v1_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export const formatDestinationActionParameterSource = (
  source:
    | workflow_parameters.v1.DestinationActionParameterSource
    | null
    | undefined,
  isRequired?: boolean
): SourceConfigProps => {
  const isRequiredProp = isRequired ? { isRequired: true } : {};

  if (source) {
    if (hasField(source, 'eventPayloadFieldLookup')) {
      return {
        fieldName: source?.eventPayloadFieldLookup?.celExpressionStr,
        fieldType: formatType(source?.eventPayloadFieldLookup?.runtimeType),
        tagType: 'payload',
        ...isRequiredProp,
      };
    }

    if (hasField(source, 'profileIdentifier')) {
      const profileIdentifier = source?.profileIdentifier;
      return {
        fieldName:
          convertToTitleCase(
            thirdparties.v1.ThirdPartyIdType[profileIdentifier]
          ) + ' ID',
        fieldType: 'string',
        integrationIconName: convertToKebabCase(
          thirdparties.v1.ThirdPartyIdType[profileIdentifier]
        ),
        sensitivity: 'ID',
        tagType: 'identifier',
        ...isRequiredProp,
      };
    }

    if (hasField(source, 'profilePropertyLookup')) {
      return {
        fieldName:
          source.profilePropertyLookup?.propertyName +
          (source.profilePropertyLookup?.celExpressionStr
            ? `.${source.profilePropertyLookup.celExpressionStr}`
            : ''),
        tagType: source.profilePropertyLookup?.isMorfDefault
          ? 'morf'
          : 'custom',
        fieldType: source.profilePropertyLookup?.valueType?.type || 'n/a',
        sensitivity: formatSensitivities(
          source.profilePropertyLookup?.sensitivities
        ),
        ...isRequiredProp,
      };
    }

    if (hasField(source, 'calculatedValue')) {
      return {
        fieldName: source?.calculatedValue?.celExpressionStr,
        fieldType: formatType(source?.calculatedValue?.runtimeType),
        tagType: 'calculated',
        ...isRequiredProp,
      };
    }

    if (hasField(source, 'constantValue')) {
      return {
        fieldName: formatConstantValue(source),
        fieldType: Object.keys(source.constantValue!).find(
          (key) =>
            source.constantValue![key as keyof typeof source.constantValue] !==
            undefined
        ),
        tagType: 'constant',
        ...isRequiredProp,
      };
    }

    if (hasField(source, 'elidedValue')) {
      return {
        fieldName: 'no value',
        fieldType: 'elided',
        tagType: 'disabled',
        ...isRequiredProp,
      };
    }

    if (hasField(source, 'morfEventType')) {
      return {
        fieldName: 'Event Type',
        fieldType: 'morfEventType',
        integrationIconName: 'morf',
        tagType: 'identifier',
        ...isRequiredProp,
      };
    }

    if (hasField(source, 'objectTypeId')) {
      return {
        fieldName: source?.objectTypeId?.label || source?.objectTypeId?.id,
        fieldType: 'objectTypeId',
        tagType: 'objectTypeId',
        ...isRequiredProp,
      };
    }
  }

  return {};
};
