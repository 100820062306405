import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Text } from '../../../Typography';
import { useTheme } from '@morf/theming';
import { SnapshotSectionProps } from './types';
import { Payload } from '../../../Payload';
import { Timestamp } from '../../../Timestamp';

const SnapshotSection: FC<SnapshotSectionProps> = ({
  name,
  payload,
  timestamp,
}) => {
  const theme = useTheme();
  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      data-testid='snapshot-section'
      gap={0}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='2rem'
        borderType='borderBottom'
        px={1}
        py={0.5}
        gap={name ? 1.25 : 0}
      >
        {name && <Text tag='p2'>{name}</Text>}
        {timestamp && (
          <Timestamp
            tag='p2'
            color={theme.colors.text.muted}
            timestamp={timestamp}
          />
        )}
      </Flexbox>
      <Payload payload={payload} />
    </Flexbox>
  );
};

export const MemoizedSnapshotSection = memo(SnapshotSection);
