'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { acuity as acuity$1 } from './acuity_v1_ts_proto';
import { event_config as event_config$1 } from './event_config_v1_ts_proto';
import { field_lookup as field_lookup$1 } from './field_lookup_v1_ts_proto';
import { pagination as pagination$1 } from './pagination_v1_ts_proto';
import { sources as sources$1 } from './sources_v1_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { values as values$1 } from './values_v1_ts_proto';
import { vital as vital$1 } from './vital_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.object_type = (() => {
  const object_type = {};
  object_type.v1 = (() => {
    const v1 = {};
    v1.GetSourceObjectTypeRequest = (() => {
      class GetSourceObjectTypeRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetSourceObjectTypeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(10).string(message.sourceId);
          }
          if (
            message.objectTypeId != null &&
            Object.hasOwnProperty.call(message, 'objectTypeId')
          ) {
            writer.uint32(18).string(message.objectTypeId);
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(24).int32(message.eventType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetSourceObjectTypeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceId = reader.string();
                break;
              }
              case 2: {
                message.objectTypeId = reader.string();
                break;
              }
              case 3: {
                message.eventType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.object_type.v1.GetSourceObjectTypeRequest
          ) {
            return object;
          }
          const message = new $root.object_type.v1.GetSourceObjectTypeRequest();
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.objectTypeId != null) {
            message.objectTypeId = String(object.objectTypeId);
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_FORM_RESPONSE_UPDATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'FORMSORT_FORM_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'MIGRATION':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'MORF_API_CALL':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'CUSTOM_PAYLOAD':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'VITAL_LAB_ORDER':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'SEGMENT_EVENT':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'ACTIVE_CAMPAIGN_WEBHOOK':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'INTAKEQ_APPOINTMENT':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'CUSTOMER_IO_EVENT':
              case 16: {
                message.eventType = 16;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.sourceId = '';
            object.objectTypeId = '';
            object.eventType =
              options.enums === String ? 'UNSPECIFIED_EVENT_TYPE' : 0;
          }
          let keys;
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.objectTypeId != null &&
            message.hasOwnProperty('objectTypeId')
          ) {
            object.objectTypeId = message.objectTypeId;
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? sources$1.v1.EventType[message.eventType] === undefined
                  ? message.eventType
                  : sources$1.v1.EventType[message.eventType]
                : message.eventType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.GetSourceObjectTypeRequest';
        }
      }

      GetSourceObjectTypeRequest.prototype.sourceId = '';
      GetSourceObjectTypeRequest.prototype.objectTypeId = '';
      GetSourceObjectTypeRequest.prototype.eventType = 0;

      return GetSourceObjectTypeRequest;
    })();

    v1.GetSourceObjectTypeResponse = (() => {
      class GetSourceObjectTypeResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetSourceObjectTypeResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectType != null &&
            Object.hasOwnProperty.call(message, 'objectType')
          ) {
            $root.object_type.v1.ObjectType.encode(
              message.objectType,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetSourceObjectTypeResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.objectType = $root.object_type.v1.ObjectType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.object_type.v1.GetSourceObjectTypeResponse
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.GetSourceObjectTypeResponse();
          if (object.objectType != null) {
            if (typeof object.objectType !== 'object') {
              throw new TypeError(
                '.object_type.v1.GetSourceObjectTypeResponse.objectType: object expected, but got ' +
                  typeof object.objectType
              );
            }
            message.objectType = $root.object_type.v1.ObjectType.fromObject(
              object.objectType
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.objectType = null;
          }
          let keys;
          if (
            message.objectType != null &&
            message.hasOwnProperty('objectType')
          ) {
            object.objectType = $root.object_type.v1.ObjectType.toObject(
              message.objectType,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.GetSourceObjectTypeResponse';
        }
      }

      GetSourceObjectTypeResponse.prototype.objectType = null;

      return GetSourceObjectTypeResponse;
    })();

    v1.UpdateObjectTypeRequest = (() => {
      class UpdateObjectTypeRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateObjectTypeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(18).string(message.label);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateObjectTypeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.label = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.UpdateObjectTypeRequest) {
            return object;
          }
          const message = new $root.object_type.v1.UpdateObjectTypeRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.UpdateObjectTypeRequest';
        }
      }

      UpdateObjectTypeRequest.prototype.id = '';
      UpdateObjectTypeRequest.prototype.label = null;
      UpdateObjectTypeRequest.prototype.description = null;

      return UpdateObjectTypeRequest;
    })();

    v1.UpdateObjectGroupRequest = (() => {
      class UpdateObjectGroupRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateObjectGroupRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(18).string(message.label);
          }
          if (
            message.eventConfig != null &&
            Object.hasOwnProperty.call(message, 'eventConfig')
          ) {
            event_config$1.v1.UpdateEventConfig.encode(
              message.eventConfig,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateObjectGroupRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.label = reader.string();
                break;
              }
              case 3: {
                message.eventConfig =
                  event_config$1.v1.UpdateEventConfig.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.UpdateObjectGroupRequest) {
            return object;
          }
          const message = new $root.object_type.v1.UpdateObjectGroupRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.eventConfig != null) {
            if (typeof object.eventConfig !== 'object') {
              throw new TypeError(
                '.object_type.v1.UpdateObjectGroupRequest.eventConfig: object expected, but got ' +
                  typeof object.eventConfig
              );
            }
            message.eventConfig =
              event_config$1.v1.UpdateEventConfig.fromObject(
                object.eventConfig
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.eventConfig != null &&
            message.hasOwnProperty('eventConfig')
          ) {
            object.eventConfig = event_config$1.v1.UpdateEventConfig.toObject(
              message.eventConfig,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.UpdateObjectGroupRequest';
        }
      }

      UpdateObjectGroupRequest.prototype.id = '';
      UpdateObjectGroupRequest.prototype.label = null;
      UpdateObjectGroupRequest.prototype.eventConfig = null;

      return UpdateObjectGroupRequest;
    })();

    v1.UpdateObjectGroupResponse = (() => {
      class UpdateObjectGroupResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateObjectGroupResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.group != null &&
            Object.hasOwnProperty.call(message, 'group')
          ) {
            $root.object_type.v1.ObjectGroup.encode(
              message.group,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateObjectGroupResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.group = $root.object_type.v1.ObjectGroup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.object_type.v1.UpdateObjectGroupResponse
          ) {
            return object;
          }
          const message = new $root.object_type.v1.UpdateObjectGroupResponse();
          if (object.group != null) {
            if (typeof object.group !== 'object') {
              throw new TypeError(
                '.object_type.v1.UpdateObjectGroupResponse.group: object expected, but got ' +
                  typeof object.group
              );
            }
            message.group = $root.object_type.v1.ObjectGroup.fromObject(
              object.group
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.group = null;
          }
          let keys;
          if (message.group != null && message.hasOwnProperty('group')) {
            object.group = $root.object_type.v1.ObjectGroup.toObject(
              message.group,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.UpdateObjectGroupResponse';
        }
      }

      UpdateObjectGroupResponse.prototype.group = null;

      return UpdateObjectGroupResponse;
    })();

    v1.DeleteObjectTypeRequest = (() => {
      class DeleteObjectTypeRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteObjectTypeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteObjectTypeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.DeleteObjectTypeRequest) {
            return object;
          }
          const message = new $root.object_type.v1.DeleteObjectTypeRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.DeleteObjectTypeRequest';
        }
      }

      DeleteObjectTypeRequest.prototype.id = '';

      return DeleteObjectTypeRequest;
    })();

    v1.DeleteObjectTypeResponse = (() => {
      class DeleteObjectTypeResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeleteObjectTypeResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeleteObjectTypeResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.DeleteObjectTypeResponse) {
            return object;
          }
          const message = new $root.object_type.v1.DeleteObjectTypeResponse();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.DeleteObjectTypeResponse';
        }
      }

      DeleteObjectTypeResponse.prototype.id = '';

      return DeleteObjectTypeResponse;
    })();

    v1.UpdateObjectTypeResponse = (() => {
      class UpdateObjectTypeResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateObjectTypeResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectType != null &&
            Object.hasOwnProperty.call(message, 'objectType')
          ) {
            $root.object_type.v1.ObjectType.encode(
              message.objectType,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateObjectTypeResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.objectType = $root.object_type.v1.ObjectType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.UpdateObjectTypeResponse) {
            return object;
          }
          const message = new $root.object_type.v1.UpdateObjectTypeResponse();
          if (object.objectType != null) {
            if (typeof object.objectType !== 'object') {
              throw new TypeError(
                '.object_type.v1.UpdateObjectTypeResponse.objectType: object expected, but got ' +
                  typeof object.objectType
              );
            }
            message.objectType = $root.object_type.v1.ObjectType.fromObject(
              object.objectType
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.objectType = null;
          }
          let keys;
          if (
            message.objectType != null &&
            message.hasOwnProperty('objectType')
          ) {
            object.objectType = $root.object_type.v1.ObjectType.toObject(
              message.objectType,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.UpdateObjectTypeResponse';
        }
      }

      UpdateObjectTypeResponse.prototype.objectType = null;

      return UpdateObjectTypeResponse;
    })();

    v1.ListObjectTypesRequest = (() => {
      class ListObjectTypesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectTypesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectTypesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ListObjectTypesRequest) {
            return object;
          }
          const message = new $root.object_type.v1.ListObjectTypesRequest();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ListObjectTypesRequest';
        }
      }

      return ListObjectTypesRequest;
    })();

    v1.ListObjectTypesResponse = (() => {
      class ListObjectTypesResponse {
        constructor(properties) {
          this.objectTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectTypesResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectTypes != null &&
            Object.hasOwnProperty.call(message, 'objectTypes')
          ) {
            for (const element of message.objectTypes) {
              $root.object_type.v1.ObjectType.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectTypesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.objectTypes || !message.objectTypes.length) {
                  message.objectTypes = [];
                }
                message.objectTypes.push(
                  $root.object_type.v1.ObjectType.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ListObjectTypesResponse) {
            return object;
          }
          const message = new $root.object_type.v1.ListObjectTypesResponse();
          if (object.objectTypes) {
            if (!Array.isArray(object.objectTypes)) {
              throw new TypeError(
                '.object_type.v1.ListObjectTypesResponse.objectTypes: array type expected, but got ' +
                  typeof object.objectTypes
              );
            }
            message.objectTypes = new Array(object.objectTypes.length);
            for (let i = 0; i < object.objectTypes.length; ++i) {
              if (typeof object.objectTypes[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.ListObjectTypesResponse.objectTypes: object expected, but got ' +
                    typeof object.objectTypes[i]
                );
              }
              message.objectTypes[i] =
                $root.object_type.v1.ObjectType.fromObject(
                  object.objectTypes[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.objectTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.objectTypes && message.objectTypes.length) {
            object.objectTypes = new Array(message.objectTypes.length);
            for (let i = 0; i < message.objectTypes.length; ++i) {
              object.objectTypes[i] = $root.object_type.v1.ObjectType.toObject(
                message.objectTypes[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ListObjectTypesResponse';
        }
      }

      ListObjectTypesResponse.prototype.objectTypes = $util.emptyArray;

      return ListObjectTypesResponse;
    })();

    v1.ObjectTypePayload = (() => {
      class ObjectTypePayload {
        get objectType() {
          for (const key of [
            'formsortForm',
            'healthieCustomModuleForm',
            'healthieAppointmentType',
            'healthieUser',
            'healthieCms1500',
            'healthieInsuranceAuthorization',
            'healthieRequestedFormCompletion',
            'healthieBillingItem',
            'axleHealthLabOrder',
            'butterflyLabsLabOrder',
            'morfAppointment',
            'customPayloads',
            'acuityAppointmentType',
            'healthiePolicy',
            'vitalLabTest',
            'segmentEvent',
            'activeCampaignDealGroup',
            'intakeqAppointmentType',
            'customerIoEvent',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set objectType(name) {
          for (const key of [
            'formsortForm',
            'healthieCustomModuleForm',
            'healthieAppointmentType',
            'healthieUser',
            'healthieCms1500',
            'healthieInsuranceAuthorization',
            'healthieRequestedFormCompletion',
            'healthieBillingItem',
            'axleHealthLabOrder',
            'butterflyLabsLabOrder',
            'morfAppointment',
            'customPayloads',
            'acuityAppointmentType',
            'healthiePolicy',
            'vitalLabTest',
            'segmentEvent',
            'activeCampaignDealGroup',
            'intakeqAppointmentType',
            'customerIoEvent',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ObjectTypePayload(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.formsortForm != null &&
            Object.hasOwnProperty.call(message, 'formsortForm')
          ) {
            $root.object_type.v1.FormsortFormObjectType.encode(
              message.formsortForm,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.healthieCustomModuleForm != null &&
            Object.hasOwnProperty.call(message, 'healthieCustomModuleForm')
          ) {
            $root.object_type.v1.HealthieCustomModuleFormObjectType.encode(
              message.healthieCustomModuleForm,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.healthieAppointmentType != null &&
            Object.hasOwnProperty.call(message, 'healthieAppointmentType')
          ) {
            $root.object_type.v1.HealthieAppointmentTypeObjectType.encode(
              message.healthieAppointmentType,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.healthieUser != null &&
            Object.hasOwnProperty.call(message, 'healthieUser')
          ) {
            $root.object_type.v1.HealthieUserObjectType.encode(
              message.healthieUser,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.healthieCms1500 != null &&
            Object.hasOwnProperty.call(message, 'healthieCms1500')
          ) {
            $root.object_type.v1.HealthieCMS1500ObjectType.encode(
              message.healthieCms1500,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.healthieInsuranceAuthorization != null &&
            Object.hasOwnProperty.call(
              message,
              'healthieInsuranceAuthorization'
            )
          ) {
            $root.object_type.v1.HealthieInsuranceAuthorizationObjectType.encode(
              message.healthieInsuranceAuthorization,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.healthieRequestedFormCompletion != null &&
            Object.hasOwnProperty.call(
              message,
              'healthieRequestedFormCompletion'
            )
          ) {
            $root.object_type.v1.HealthieRequestedFormCompletionObjectType.encode(
              message.healthieRequestedFormCompletion,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.healthieBillingItem != null &&
            Object.hasOwnProperty.call(message, 'healthieBillingItem')
          ) {
            $root.object_type.v1.HealthieBillingItemObjectType.encode(
              message.healthieBillingItem,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.axleHealthLabOrder != null &&
            Object.hasOwnProperty.call(message, 'axleHealthLabOrder')
          ) {
            $root.object_type.v1.AxleHealthLabOrderObjectType.encode(
              message.axleHealthLabOrder,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.butterflyLabsLabOrder != null &&
            Object.hasOwnProperty.call(message, 'butterflyLabsLabOrder')
          ) {
            $root.object_type.v1.ButterflyLabsLabOrderObjectType.encode(
              message.butterflyLabsLabOrder,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.morfAppointment != null &&
            Object.hasOwnProperty.call(message, 'morfAppointment')
          ) {
            $root.object_type.v1.MorfAppointmentObjectType.encode(
              message.morfAppointment,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.customPayloads != null &&
            Object.hasOwnProperty.call(message, 'customPayloads')
          ) {
            $root.object_type.v1.CustomPayloadsObjectType.encode(
              message.customPayloads,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.acuityAppointmentType != null &&
            Object.hasOwnProperty.call(message, 'acuityAppointmentType')
          ) {
            $root.object_type.v1.AcuityAppointmentTypeObjectType.encode(
              message.acuityAppointmentType,
              writer.uint32(106).fork()
            ).ldelim();
          }
          if (
            message.healthiePolicy != null &&
            Object.hasOwnProperty.call(message, 'healthiePolicy')
          ) {
            $root.object_type.v1.HealthiePolicyObjectType.encode(
              message.healthiePolicy,
              writer.uint32(114).fork()
            ).ldelim();
          }
          if (
            message.vitalLabTest != null &&
            Object.hasOwnProperty.call(message, 'vitalLabTest')
          ) {
            $root.object_type.v1.VitalLabTestObjectType.encode(
              message.vitalLabTest,
              writer.uint32(122).fork()
            ).ldelim();
          }
          if (
            message.segmentEvent != null &&
            Object.hasOwnProperty.call(message, 'segmentEvent')
          ) {
            $root.object_type.v1.SegmentEventObjectType.encode(
              message.segmentEvent,
              writer.uint32(130).fork()
            ).ldelim();
          }
          if (
            message.activeCampaignDealGroup != null &&
            Object.hasOwnProperty.call(message, 'activeCampaignDealGroup')
          ) {
            $root.object_type.v1.ActiveCampaignDealGroupObjectType.encode(
              message.activeCampaignDealGroup,
              writer.uint32(138).fork()
            ).ldelim();
          }
          if (
            message.intakeqAppointmentType != null &&
            Object.hasOwnProperty.call(message, 'intakeqAppointmentType')
          ) {
            $root.object_type.v1.IntakeQAppointmentTypeObjectType.encode(
              message.intakeqAppointmentType,
              writer.uint32(146).fork()
            ).ldelim();
          }
          if (
            message.customerIoEvent != null &&
            Object.hasOwnProperty.call(message, 'customerIoEvent')
          ) {
            $root.object_type.v1.CustomerIoEventObjectType.encode(
              message.customerIoEvent,
              writer.uint32(154).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ObjectTypePayload();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.formsortForm =
                  $root.object_type.v1.FormsortFormObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.healthieCustomModuleForm =
                  $root.object_type.v1.HealthieCustomModuleFormObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.healthieAppointmentType =
                  $root.object_type.v1.HealthieAppointmentTypeObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.healthieUser =
                  $root.object_type.v1.HealthieUserObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.healthieCms1500 =
                  $root.object_type.v1.HealthieCMS1500ObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.healthieInsuranceAuthorization =
                  $root.object_type.v1.HealthieInsuranceAuthorizationObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.healthieRequestedFormCompletion =
                  $root.object_type.v1.HealthieRequestedFormCompletionObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.healthieBillingItem =
                  $root.object_type.v1.HealthieBillingItemObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                message.axleHealthLabOrder =
                  $root.object_type.v1.AxleHealthLabOrderObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 10: {
                message.butterflyLabsLabOrder =
                  $root.object_type.v1.ButterflyLabsLabOrderObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                message.morfAppointment =
                  $root.object_type.v1.MorfAppointmentObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 12: {
                message.customPayloads =
                  $root.object_type.v1.CustomPayloadsObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 13: {
                message.acuityAppointmentType =
                  $root.object_type.v1.AcuityAppointmentTypeObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 14: {
                message.healthiePolicy =
                  $root.object_type.v1.HealthiePolicyObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 15: {
                message.vitalLabTest =
                  $root.object_type.v1.VitalLabTestObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 16: {
                message.segmentEvent =
                  $root.object_type.v1.SegmentEventObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 17: {
                message.activeCampaignDealGroup =
                  $root.object_type.v1.ActiveCampaignDealGroupObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 18: {
                message.intakeqAppointmentType =
                  $root.object_type.v1.IntakeQAppointmentTypeObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 19: {
                message.customerIoEvent =
                  $root.object_type.v1.CustomerIoEventObjectType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ObjectTypePayload) {
            return object;
          }
          const message = new $root.object_type.v1.ObjectTypePayload();
          if (object.formsortForm != null) {
            if (typeof object.formsortForm !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.formsortForm: object expected, but got ' +
                  typeof object.formsortForm
              );
            }
            message.formsortForm =
              $root.object_type.v1.FormsortFormObjectType.fromObject(
                object.formsortForm
              );
          }
          if (object.healthieCustomModuleForm != null) {
            if (typeof object.healthieCustomModuleForm !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.healthieCustomModuleForm: object expected, but got ' +
                  typeof object.healthieCustomModuleForm
              );
            }
            message.healthieCustomModuleForm =
              $root.object_type.v1.HealthieCustomModuleFormObjectType.fromObject(
                object.healthieCustomModuleForm
              );
          }
          if (object.healthieAppointmentType != null) {
            if (typeof object.healthieAppointmentType !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.healthieAppointmentType: object expected, but got ' +
                  typeof object.healthieAppointmentType
              );
            }
            message.healthieAppointmentType =
              $root.object_type.v1.HealthieAppointmentTypeObjectType.fromObject(
                object.healthieAppointmentType
              );
          }
          if (object.healthieUser != null) {
            if (typeof object.healthieUser !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.healthieUser: object expected, but got ' +
                  typeof object.healthieUser
              );
            }
            message.healthieUser =
              $root.object_type.v1.HealthieUserObjectType.fromObject(
                object.healthieUser
              );
          }
          if (object.healthieCms1500 != null) {
            if (typeof object.healthieCms1500 !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.healthieCms1500: object expected, but got ' +
                  typeof object.healthieCms1500
              );
            }
            message.healthieCms1500 =
              $root.object_type.v1.HealthieCMS1500ObjectType.fromObject(
                object.healthieCms1500
              );
          }
          if (object.healthieInsuranceAuthorization != null) {
            if (typeof object.healthieInsuranceAuthorization !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.healthieInsuranceAuthorization: object expected, but got ' +
                  typeof object.healthieInsuranceAuthorization
              );
            }
            message.healthieInsuranceAuthorization =
              $root.object_type.v1.HealthieInsuranceAuthorizationObjectType.fromObject(
                object.healthieInsuranceAuthorization
              );
          }
          if (object.healthieRequestedFormCompletion != null) {
            if (typeof object.healthieRequestedFormCompletion !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.healthieRequestedFormCompletion: object expected, but got ' +
                  typeof object.healthieRequestedFormCompletion
              );
            }
            message.healthieRequestedFormCompletion =
              $root.object_type.v1.HealthieRequestedFormCompletionObjectType.fromObject(
                object.healthieRequestedFormCompletion
              );
          }
          if (object.healthieBillingItem != null) {
            if (typeof object.healthieBillingItem !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.healthieBillingItem: object expected, but got ' +
                  typeof object.healthieBillingItem
              );
            }
            message.healthieBillingItem =
              $root.object_type.v1.HealthieBillingItemObjectType.fromObject(
                object.healthieBillingItem
              );
          }
          if (object.axleHealthLabOrder != null) {
            if (typeof object.axleHealthLabOrder !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.axleHealthLabOrder: object expected, but got ' +
                  typeof object.axleHealthLabOrder
              );
            }
            message.axleHealthLabOrder =
              $root.object_type.v1.AxleHealthLabOrderObjectType.fromObject(
                object.axleHealthLabOrder
              );
          }
          if (object.butterflyLabsLabOrder != null) {
            if (typeof object.butterflyLabsLabOrder !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.butterflyLabsLabOrder: object expected, but got ' +
                  typeof object.butterflyLabsLabOrder
              );
            }
            message.butterflyLabsLabOrder =
              $root.object_type.v1.ButterflyLabsLabOrderObjectType.fromObject(
                object.butterflyLabsLabOrder
              );
          }
          if (object.morfAppointment != null) {
            if (typeof object.morfAppointment !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.morfAppointment: object expected, but got ' +
                  typeof object.morfAppointment
              );
            }
            message.morfAppointment =
              $root.object_type.v1.MorfAppointmentObjectType.fromObject(
                object.morfAppointment
              );
          }
          if (object.customPayloads != null) {
            if (typeof object.customPayloads !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.customPayloads: object expected, but got ' +
                  typeof object.customPayloads
              );
            }
            message.customPayloads =
              $root.object_type.v1.CustomPayloadsObjectType.fromObject(
                object.customPayloads
              );
          }
          if (object.acuityAppointmentType != null) {
            if (typeof object.acuityAppointmentType !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.acuityAppointmentType: object expected, but got ' +
                  typeof object.acuityAppointmentType
              );
            }
            message.acuityAppointmentType =
              $root.object_type.v1.AcuityAppointmentTypeObjectType.fromObject(
                object.acuityAppointmentType
              );
          }
          if (object.healthiePolicy != null) {
            if (typeof object.healthiePolicy !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.healthiePolicy: object expected, but got ' +
                  typeof object.healthiePolicy
              );
            }
            message.healthiePolicy =
              $root.object_type.v1.HealthiePolicyObjectType.fromObject(
                object.healthiePolicy
              );
          }
          if (object.vitalLabTest != null) {
            if (typeof object.vitalLabTest !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.vitalLabTest: object expected, but got ' +
                  typeof object.vitalLabTest
              );
            }
            message.vitalLabTest =
              $root.object_type.v1.VitalLabTestObjectType.fromObject(
                object.vitalLabTest
              );
          }
          if (object.segmentEvent != null) {
            if (typeof object.segmentEvent !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.segmentEvent: object expected, but got ' +
                  typeof object.segmentEvent
              );
            }
            message.segmentEvent =
              $root.object_type.v1.SegmentEventObjectType.fromObject(
                object.segmentEvent
              );
          }
          if (object.activeCampaignDealGroup != null) {
            if (typeof object.activeCampaignDealGroup !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.activeCampaignDealGroup: object expected, but got ' +
                  typeof object.activeCampaignDealGroup
              );
            }
            message.activeCampaignDealGroup =
              $root.object_type.v1.ActiveCampaignDealGroupObjectType.fromObject(
                object.activeCampaignDealGroup
              );
          }
          if (object.intakeqAppointmentType != null) {
            if (typeof object.intakeqAppointmentType !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.intakeqAppointmentType: object expected, but got ' +
                  typeof object.intakeqAppointmentType
              );
            }
            message.intakeqAppointmentType =
              $root.object_type.v1.IntakeQAppointmentTypeObjectType.fromObject(
                object.intakeqAppointmentType
              );
          }
          if (object.customerIoEvent != null) {
            if (typeof object.customerIoEvent !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypePayload.customerIoEvent: object expected, but got ' +
                  typeof object.customerIoEvent
              );
            }
            message.customerIoEvent =
              $root.object_type.v1.CustomerIoEventObjectType.fromObject(
                object.customerIoEvent
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.formsortForm != null &&
            message.hasOwnProperty('formsortForm')
          ) {
            object.formsortForm =
              $root.object_type.v1.FormsortFormObjectType.toObject(
                message.formsortForm,
                options
              );
            if (options.oneofs) {
              object.objectType = 'formsortForm';
            }
          }
          if (
            message.healthieCustomModuleForm != null &&
            message.hasOwnProperty('healthieCustomModuleForm')
          ) {
            object.healthieCustomModuleForm =
              $root.object_type.v1.HealthieCustomModuleFormObjectType.toObject(
                message.healthieCustomModuleForm,
                options
              );
            if (options.oneofs) {
              object.objectType = 'healthieCustomModuleForm';
            }
          }
          if (
            message.healthieAppointmentType != null &&
            message.hasOwnProperty('healthieAppointmentType')
          ) {
            object.healthieAppointmentType =
              $root.object_type.v1.HealthieAppointmentTypeObjectType.toObject(
                message.healthieAppointmentType,
                options
              );
            if (options.oneofs) {
              object.objectType = 'healthieAppointmentType';
            }
          }
          if (
            message.healthieUser != null &&
            message.hasOwnProperty('healthieUser')
          ) {
            object.healthieUser =
              $root.object_type.v1.HealthieUserObjectType.toObject(
                message.healthieUser,
                options
              );
            if (options.oneofs) {
              object.objectType = 'healthieUser';
            }
          }
          if (
            message.healthieCms1500 != null &&
            message.hasOwnProperty('healthieCms1500')
          ) {
            object.healthieCms1500 =
              $root.object_type.v1.HealthieCMS1500ObjectType.toObject(
                message.healthieCms1500,
                options
              );
            if (options.oneofs) {
              object.objectType = 'healthieCms1500';
            }
          }
          if (
            message.healthieInsuranceAuthorization != null &&
            message.hasOwnProperty('healthieInsuranceAuthorization')
          ) {
            object.healthieInsuranceAuthorization =
              $root.object_type.v1.HealthieInsuranceAuthorizationObjectType.toObject(
                message.healthieInsuranceAuthorization,
                options
              );
            if (options.oneofs) {
              object.objectType = 'healthieInsuranceAuthorization';
            }
          }
          if (
            message.healthieRequestedFormCompletion != null &&
            message.hasOwnProperty('healthieRequestedFormCompletion')
          ) {
            object.healthieRequestedFormCompletion =
              $root.object_type.v1.HealthieRequestedFormCompletionObjectType.toObject(
                message.healthieRequestedFormCompletion,
                options
              );
            if (options.oneofs) {
              object.objectType = 'healthieRequestedFormCompletion';
            }
          }
          if (
            message.healthieBillingItem != null &&
            message.hasOwnProperty('healthieBillingItem')
          ) {
            object.healthieBillingItem =
              $root.object_type.v1.HealthieBillingItemObjectType.toObject(
                message.healthieBillingItem,
                options
              );
            if (options.oneofs) {
              object.objectType = 'healthieBillingItem';
            }
          }
          if (
            message.axleHealthLabOrder != null &&
            message.hasOwnProperty('axleHealthLabOrder')
          ) {
            object.axleHealthLabOrder =
              $root.object_type.v1.AxleHealthLabOrderObjectType.toObject(
                message.axleHealthLabOrder,
                options
              );
            if (options.oneofs) {
              object.objectType = 'axleHealthLabOrder';
            }
          }
          if (
            message.butterflyLabsLabOrder != null &&
            message.hasOwnProperty('butterflyLabsLabOrder')
          ) {
            object.butterflyLabsLabOrder =
              $root.object_type.v1.ButterflyLabsLabOrderObjectType.toObject(
                message.butterflyLabsLabOrder,
                options
              );
            if (options.oneofs) {
              object.objectType = 'butterflyLabsLabOrder';
            }
          }
          if (
            message.morfAppointment != null &&
            message.hasOwnProperty('morfAppointment')
          ) {
            object.morfAppointment =
              $root.object_type.v1.MorfAppointmentObjectType.toObject(
                message.morfAppointment,
                options
              );
            if (options.oneofs) {
              object.objectType = 'morfAppointment';
            }
          }
          if (
            message.customPayloads != null &&
            message.hasOwnProperty('customPayloads')
          ) {
            object.customPayloads =
              $root.object_type.v1.CustomPayloadsObjectType.toObject(
                message.customPayloads,
                options
              );
            if (options.oneofs) {
              object.objectType = 'customPayloads';
            }
          }
          if (
            message.acuityAppointmentType != null &&
            message.hasOwnProperty('acuityAppointmentType')
          ) {
            object.acuityAppointmentType =
              $root.object_type.v1.AcuityAppointmentTypeObjectType.toObject(
                message.acuityAppointmentType,
                options
              );
            if (options.oneofs) {
              object.objectType = 'acuityAppointmentType';
            }
          }
          if (
            message.healthiePolicy != null &&
            message.hasOwnProperty('healthiePolicy')
          ) {
            object.healthiePolicy =
              $root.object_type.v1.HealthiePolicyObjectType.toObject(
                message.healthiePolicy,
                options
              );
            if (options.oneofs) {
              object.objectType = 'healthiePolicy';
            }
          }
          if (
            message.vitalLabTest != null &&
            message.hasOwnProperty('vitalLabTest')
          ) {
            object.vitalLabTest =
              $root.object_type.v1.VitalLabTestObjectType.toObject(
                message.vitalLabTest,
                options
              );
            if (options.oneofs) {
              object.objectType = 'vitalLabTest';
            }
          }
          if (
            message.segmentEvent != null &&
            message.hasOwnProperty('segmentEvent')
          ) {
            object.segmentEvent =
              $root.object_type.v1.SegmentEventObjectType.toObject(
                message.segmentEvent,
                options
              );
            if (options.oneofs) {
              object.objectType = 'segmentEvent';
            }
          }
          if (
            message.activeCampaignDealGroup != null &&
            message.hasOwnProperty('activeCampaignDealGroup')
          ) {
            object.activeCampaignDealGroup =
              $root.object_type.v1.ActiveCampaignDealGroupObjectType.toObject(
                message.activeCampaignDealGroup,
                options
              );
            if (options.oneofs) {
              object.objectType = 'activeCampaignDealGroup';
            }
          }
          if (
            message.intakeqAppointmentType != null &&
            message.hasOwnProperty('intakeqAppointmentType')
          ) {
            object.intakeqAppointmentType =
              $root.object_type.v1.IntakeQAppointmentTypeObjectType.toObject(
                message.intakeqAppointmentType,
                options
              );
            if (options.oneofs) {
              object.objectType = 'intakeqAppointmentType';
            }
          }
          if (
            message.customerIoEvent != null &&
            message.hasOwnProperty('customerIoEvent')
          ) {
            object.customerIoEvent =
              $root.object_type.v1.CustomerIoEventObjectType.toObject(
                message.customerIoEvent,
                options
              );
            if (options.oneofs) {
              object.objectType = 'customerIoEvent';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ObjectTypePayload';
        }
      }

      ObjectTypePayload.prototype.formsortForm = null;
      ObjectTypePayload.prototype.healthieCustomModuleForm = null;
      ObjectTypePayload.prototype.healthieAppointmentType = null;
      ObjectTypePayload.prototype.healthieUser = null;
      ObjectTypePayload.prototype.healthieCms1500 = null;
      ObjectTypePayload.prototype.healthieInsuranceAuthorization = null;
      ObjectTypePayload.prototype.healthieRequestedFormCompletion = null;
      ObjectTypePayload.prototype.healthieBillingItem = null;
      ObjectTypePayload.prototype.axleHealthLabOrder = null;
      ObjectTypePayload.prototype.butterflyLabsLabOrder = null;
      ObjectTypePayload.prototype.morfAppointment = null;
      ObjectTypePayload.prototype.customPayloads = null;
      ObjectTypePayload.prototype.acuityAppointmentType = null;
      ObjectTypePayload.prototype.healthiePolicy = null;
      ObjectTypePayload.prototype.vitalLabTest = null;
      ObjectTypePayload.prototype.segmentEvent = null;
      ObjectTypePayload.prototype.activeCampaignDealGroup = null;
      ObjectTypePayload.prototype.intakeqAppointmentType = null;
      ObjectTypePayload.prototype.customerIoEvent = null;

      return ObjectTypePayload;
    })();

    v1.CustomPayloadsObjectType = (() => {
      class CustomPayloadsObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomPayloadsObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomPayloadsObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.CustomPayloadsObjectType) {
            return object;
          }
          const message = new $root.object_type.v1.CustomPayloadsObjectType();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.CustomPayloadsObjectType';
        }
      }

      return CustomPayloadsObjectType;
    })();

    v1.MorfAppointmentObjectType = (() => {
      class MorfAppointmentObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfAppointmentObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.addToCalLink != null &&
            Object.hasOwnProperty.call(message, 'addToCalLink')
          ) {
            writer.uint32(18).string(message.addToCalLink);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.createdBy != null &&
            Object.hasOwnProperty.call(message, 'createdBy')
          ) {
            writer.uint32(34).string(message.createdBy);
          }
          if (
            message.datetime != null &&
            Object.hasOwnProperty.call(message, 'datetime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.datetime,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.lengthMinutes != null &&
            Object.hasOwnProperty.call(message, 'lengthMinutes')
          ) {
            writer.uint32(50).string(message.lengthMinutes);
          }
          if (
            message.locationLabel != null &&
            Object.hasOwnProperty.call(message, 'locationLabel')
          ) {
            writer.uint32(58).string(message.locationLabel);
          }
          if (
            message.locationType != null &&
            Object.hasOwnProperty.call(message, 'locationType')
          ) {
            writer.uint32(66).string(message.locationType);
          }
          if (
            message.locationDetail != null &&
            Object.hasOwnProperty.call(message, 'locationDetail')
          ) {
            writer.uint32(74).string(message.locationDetail);
          }
          if (
            message.providerId != null &&
            Object.hasOwnProperty.call(message, 'providerId')
          ) {
            writer.uint32(82).string(message.providerId);
          }
          if (
            message.timezoneOffset != null &&
            Object.hasOwnProperty.call(message, 'timezoneOffset')
          ) {
            writer.uint32(90).string(message.timezoneOffset);
          }
          if (
            message.typeId != null &&
            Object.hasOwnProperty.call(message, 'typeId')
          ) {
            writer.uint32(98).string(message.typeId);
          }
          if (
            message.typeTitle != null &&
            Object.hasOwnProperty.call(message, 'typeTitle')
          ) {
            writer.uint32(106).string(message.typeTitle);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(114).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfAppointmentObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.addToCalLink = reader.string();
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.createdBy = reader.string();
                break;
              }
              case 5: {
                message.datetime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.lengthMinutes = reader.string();
                break;
              }
              case 7: {
                message.locationLabel = reader.string();
                break;
              }
              case 8: {
                message.locationType = reader.string();
                break;
              }
              case 9: {
                message.locationDetail = reader.string();
                break;
              }
              case 10: {
                message.providerId = reader.string();
                break;
              }
              case 11: {
                message.timezoneOffset = reader.string();
                break;
              }
              case 12: {
                message.typeId = reader.string();
                break;
              }
              case 13: {
                message.typeTitle = reader.string();
                break;
              }
              case 14: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.object_type.v1.MorfAppointmentObjectType
          ) {
            return object;
          }
          const message = new $root.object_type.v1.MorfAppointmentObjectType();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.addToCalLink != null) {
            message.addToCalLink = String(object.addToCalLink);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.object_type.v1.MorfAppointmentObjectType.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.createdBy != null) {
            message.createdBy = String(object.createdBy);
          }
          if (object.datetime != null) {
            if (typeof object.datetime !== 'object') {
              throw new TypeError(
                '.object_type.v1.MorfAppointmentObjectType.datetime: object expected, but got ' +
                  typeof object.datetime
              );
            }
            message.datetime = timing$1.v1.Timestamp.fromObject(
              object.datetime
            );
          }
          if (object.lengthMinutes != null) {
            message.lengthMinutes = String(object.lengthMinutes);
          }
          if (object.locationLabel != null) {
            message.locationLabel = String(object.locationLabel);
          }
          if (object.locationType != null) {
            message.locationType = String(object.locationType);
          }
          if (object.locationDetail != null) {
            message.locationDetail = String(object.locationDetail);
          }
          if (object.providerId != null) {
            message.providerId = String(object.providerId);
          }
          if (object.timezoneOffset != null) {
            message.timezoneOffset = String(object.timezoneOffset);
          }
          if (object.typeId != null) {
            message.typeId = String(object.typeId);
          }
          if (object.typeTitle != null) {
            message.typeTitle = String(object.typeTitle);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.object_type.v1.MorfAppointmentObjectType.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.addToCalLink = '';
            object.createdAt = null;
            object.createdBy = '';
            object.datetime = null;
            object.lengthMinutes = '';
            object.locationLabel = '';
            object.locationType = '';
            object.locationDetail = '';
            object.providerId = '';
            object.timezoneOffset = '';
            object.typeId = '';
            object.typeTitle = '';
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.addToCalLink != null &&
            message.hasOwnProperty('addToCalLink')
          ) {
            object.addToCalLink = message.addToCalLink;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.createdBy != null &&
            message.hasOwnProperty('createdBy')
          ) {
            object.createdBy = message.createdBy;
          }
          if (message.datetime != null && message.hasOwnProperty('datetime')) {
            object.datetime = timing$1.v1.Timestamp.toObject(
              message.datetime,
              options
            );
          }
          if (
            message.lengthMinutes != null &&
            message.hasOwnProperty('lengthMinutes')
          ) {
            object.lengthMinutes = message.lengthMinutes;
          }
          if (
            message.locationLabel != null &&
            message.hasOwnProperty('locationLabel')
          ) {
            object.locationLabel = message.locationLabel;
          }
          if (
            message.locationType != null &&
            message.hasOwnProperty('locationType')
          ) {
            object.locationType = message.locationType;
          }
          if (
            message.locationDetail != null &&
            message.hasOwnProperty('locationDetail')
          ) {
            object.locationDetail = message.locationDetail;
          }
          if (
            message.providerId != null &&
            message.hasOwnProperty('providerId')
          ) {
            object.providerId = message.providerId;
          }
          if (
            message.timezoneOffset != null &&
            message.hasOwnProperty('timezoneOffset')
          ) {
            object.timezoneOffset = message.timezoneOffset;
          }
          if (message.typeId != null && message.hasOwnProperty('typeId')) {
            object.typeId = message.typeId;
          }
          if (
            message.typeTitle != null &&
            message.hasOwnProperty('typeTitle')
          ) {
            object.typeTitle = message.typeTitle;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.MorfAppointmentObjectType';
        }
      }

      MorfAppointmentObjectType.prototype.id = '';
      MorfAppointmentObjectType.prototype.addToCalLink = '';
      MorfAppointmentObjectType.prototype.createdAt = null;
      MorfAppointmentObjectType.prototype.createdBy = '';
      MorfAppointmentObjectType.prototype.datetime = null;
      MorfAppointmentObjectType.prototype.lengthMinutes = '';
      MorfAppointmentObjectType.prototype.locationLabel = '';
      MorfAppointmentObjectType.prototype.locationType = '';
      MorfAppointmentObjectType.prototype.locationDetail = '';
      MorfAppointmentObjectType.prototype.providerId = '';
      MorfAppointmentObjectType.prototype.timezoneOffset = '';
      MorfAppointmentObjectType.prototype.typeId = '';
      MorfAppointmentObjectType.prototype.typeTitle = '';
      MorfAppointmentObjectType.prototype.updatedAt = null;

      return MorfAppointmentObjectType;
    })();

    v1.ObjectInstance = (() => {
      class ObjectInstance {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ObjectInstance(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(16).int32(message.type);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ObjectInstance();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.type = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ObjectInstance) {
            return object;
          }
          const message = new $root.object_type.v1.ObjectInstance();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.type != null) {
            switch (object.type) {
              case 'UNSPECIFIED_OBJECT_INSTANCE_TYPE':
              case 0: {
                message.type = 0;
                break;
              }
              case 'ACTIVE_CAMPAIGN_DEAL':
              case 1: {
                message.type = 1;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 2: {
                message.type = 2;
                break;
              }
              default: {
                if (typeof object.type == 'number') {
                  message.type = object.type;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.type =
              options.enums === String ? 'UNSPECIFIED_OBJECT_INSTANCE_TYPE' : 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type =
              options.enums === String
                ? $root.object_type.v1.ObjectInstanceType[message.type] ===
                  undefined
                  ? message.type
                  : $root.object_type.v1.ObjectInstanceType[message.type]
                : message.type;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ObjectInstance';
        }
      }

      ObjectInstance.prototype.id = '';
      ObjectInstance.prototype.type = 0;

      return ObjectInstance;
    })();

    v1.CreateObjectInstanceRelationshipsRequest = (() => {
      class CreateObjectInstanceRelationshipsRequest {
        constructor(properties) {
          this.relatedObjectInstances = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateObjectInstanceRelationshipsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectInstance != null &&
            Object.hasOwnProperty.call(message, 'objectInstance')
          ) {
            $root.object_type.v1.ObjectInstance.encode(
              message.objectInstance,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.relatedObjectInstances != null &&
            Object.hasOwnProperty.call(message, 'relatedObjectInstances')
          ) {
            for (const element of message.relatedObjectInstances) {
              $root.object_type.v1.ObjectInstance.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateObjectInstanceRelationshipsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.objectInstance =
                  $root.object_type.v1.ObjectInstance.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                if (
                  !message.relatedObjectInstances ||
                  !message.relatedObjectInstances.length
                ) {
                  message.relatedObjectInstances = [];
                }
                message.relatedObjectInstances.push(
                  $root.object_type.v1.ObjectInstance.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.CreateObjectInstanceRelationshipsRequest
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.CreateObjectInstanceRelationshipsRequest();
          if (object.objectInstance != null) {
            if (typeof object.objectInstance !== 'object') {
              throw new TypeError(
                '.object_type.v1.CreateObjectInstanceRelationshipsRequest.objectInstance: object expected, but got ' +
                  typeof object.objectInstance
              );
            }
            message.objectInstance =
              $root.object_type.v1.ObjectInstance.fromObject(
                object.objectInstance
              );
          }
          if (object.relatedObjectInstances) {
            if (!Array.isArray(object.relatedObjectInstances)) {
              throw new TypeError(
                '.object_type.v1.CreateObjectInstanceRelationshipsRequest.relatedObjectInstances: array type expected, but got ' +
                  typeof object.relatedObjectInstances
              );
            }
            message.relatedObjectInstances = new Array(
              object.relatedObjectInstances.length
            );
            for (let i = 0; i < object.relatedObjectInstances.length; ++i) {
              if (typeof object.relatedObjectInstances[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.CreateObjectInstanceRelationshipsRequest.relatedObjectInstances: object expected, but got ' +
                    typeof object.relatedObjectInstances[i]
                );
              }
              message.relatedObjectInstances[i] =
                $root.object_type.v1.ObjectInstance.fromObject(
                  object.relatedObjectInstances[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.relatedObjectInstances = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.objectInstance = null;
          }
          let keys;
          if (
            message.objectInstance != null &&
            message.hasOwnProperty('objectInstance')
          ) {
            object.objectInstance =
              $root.object_type.v1.ObjectInstance.toObject(
                message.objectInstance,
                options
              );
          }
          if (
            message.relatedObjectInstances &&
            message.relatedObjectInstances.length
          ) {
            object.relatedObjectInstances = new Array(
              message.relatedObjectInstances.length
            );
            for (let i = 0; i < message.relatedObjectInstances.length; ++i) {
              object.relatedObjectInstances[i] =
                $root.object_type.v1.ObjectInstance.toObject(
                  message.relatedObjectInstances[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'object_type.v1.CreateObjectInstanceRelationshipsRequest'
          );
        }
      }

      CreateObjectInstanceRelationshipsRequest.prototype.objectInstance = null;
      CreateObjectInstanceRelationshipsRequest.prototype.relatedObjectInstances =
        $util.emptyArray;

      return CreateObjectInstanceRelationshipsRequest;
    })();

    v1.CreateObjectInstanceRelationshipsResponse = (() => {
      class CreateObjectInstanceRelationshipsResponse {
        constructor(properties) {
          this.relatedObjectInstances = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateObjectInstanceRelationshipsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectInstance != null &&
            Object.hasOwnProperty.call(message, 'objectInstance')
          ) {
            $root.object_type.v1.ObjectInstance.encode(
              message.objectInstance,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.relatedObjectInstances != null &&
            Object.hasOwnProperty.call(message, 'relatedObjectInstances')
          ) {
            for (const element of message.relatedObjectInstances) {
              $root.object_type.v1.ObjectInstance.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateObjectInstanceRelationshipsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.objectInstance =
                  $root.object_type.v1.ObjectInstance.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                if (
                  !message.relatedObjectInstances ||
                  !message.relatedObjectInstances.length
                ) {
                  message.relatedObjectInstances = [];
                }
                message.relatedObjectInstances.push(
                  $root.object_type.v1.ObjectInstance.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.CreateObjectInstanceRelationshipsResponse
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.CreateObjectInstanceRelationshipsResponse();
          if (object.objectInstance != null) {
            if (typeof object.objectInstance !== 'object') {
              throw new TypeError(
                '.object_type.v1.CreateObjectInstanceRelationshipsResponse.objectInstance: object expected, but got ' +
                  typeof object.objectInstance
              );
            }
            message.objectInstance =
              $root.object_type.v1.ObjectInstance.fromObject(
                object.objectInstance
              );
          }
          if (object.relatedObjectInstances) {
            if (!Array.isArray(object.relatedObjectInstances)) {
              throw new TypeError(
                '.object_type.v1.CreateObjectInstanceRelationshipsResponse.relatedObjectInstances: array type expected, but got ' +
                  typeof object.relatedObjectInstances
              );
            }
            message.relatedObjectInstances = new Array(
              object.relatedObjectInstances.length
            );
            for (let i = 0; i < object.relatedObjectInstances.length; ++i) {
              if (typeof object.relatedObjectInstances[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.CreateObjectInstanceRelationshipsResponse.relatedObjectInstances: object expected, but got ' +
                    typeof object.relatedObjectInstances[i]
                );
              }
              message.relatedObjectInstances[i] =
                $root.object_type.v1.ObjectInstance.fromObject(
                  object.relatedObjectInstances[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.relatedObjectInstances = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.objectInstance = null;
          }
          let keys;
          if (
            message.objectInstance != null &&
            message.hasOwnProperty('objectInstance')
          ) {
            object.objectInstance =
              $root.object_type.v1.ObjectInstance.toObject(
                message.objectInstance,
                options
              );
          }
          if (
            message.relatedObjectInstances &&
            message.relatedObjectInstances.length
          ) {
            object.relatedObjectInstances = new Array(
              message.relatedObjectInstances.length
            );
            for (let i = 0; i < message.relatedObjectInstances.length; ++i) {
              object.relatedObjectInstances[i] =
                $root.object_type.v1.ObjectInstance.toObject(
                  message.relatedObjectInstances[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'object_type.v1.CreateObjectInstanceRelationshipsResponse'
          );
        }
      }

      CreateObjectInstanceRelationshipsResponse.prototype.objectInstance = null;
      CreateObjectInstanceRelationshipsResponse.prototype.relatedObjectInstances =
        $util.emptyArray;

      return CreateObjectInstanceRelationshipsResponse;
    })();

    v1.ListObjectInstanceRelationshipsFilter = (() => {
      class ListObjectInstanceRelationshipsFilter {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectInstanceRelationshipsFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(8).int32(message.type);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectInstanceRelationshipsFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.ListObjectInstanceRelationshipsFilter
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.ListObjectInstanceRelationshipsFilter();
          if (object.type != null) {
            switch (object.type) {
              case 'UNSPECIFIED_OBJECT_INSTANCE_TYPE':
              case 0: {
                message.type = 0;
                break;
              }
              case 'ACTIVE_CAMPAIGN_DEAL':
              case 1: {
                message.type = 1;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 2: {
                message.type = 2;
                break;
              }
              default: {
                if (typeof object.type == 'number') {
                  message.type = object.type;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type =
              options.enums === String
                ? $root.object_type.v1.ObjectInstanceType[message.type] ===
                  undefined
                  ? message.type
                  : $root.object_type.v1.ObjectInstanceType[message.type]
                : message.type;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'object_type.v1.ListObjectInstanceRelationshipsFilter'
          );
        }
      }

      ListObjectInstanceRelationshipsFilter.prototype.type = null;

      return ListObjectInstanceRelationshipsFilter;
    })();

    v1.ListObjectInstanceRelationshipsRequest = (() => {
      class ListObjectInstanceRelationshipsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectInstanceRelationshipsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectInstance != null &&
            Object.hasOwnProperty.call(message, 'objectInstance')
          ) {
            $root.object_type.v1.ObjectInstance.encode(
              message.objectInstance,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.object_type.v1.ListObjectInstanceRelationshipsFilter.encode(
              message.filter,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectInstanceRelationshipsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.objectInstance =
                  $root.object_type.v1.ObjectInstance.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.filter =
                  $root.object_type.v1.ListObjectInstanceRelationshipsFilter.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.ListObjectInstanceRelationshipsRequest
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.ListObjectInstanceRelationshipsRequest();
          if (object.objectInstance != null) {
            if (typeof object.objectInstance !== 'object') {
              throw new TypeError(
                '.object_type.v1.ListObjectInstanceRelationshipsRequest.objectInstance: object expected, but got ' +
                  typeof object.objectInstance
              );
            }
            message.objectInstance =
              $root.object_type.v1.ObjectInstance.fromObject(
                object.objectInstance
              );
          }
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.object_type.v1.ListObjectInstanceRelationshipsRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter =
              $root.object_type.v1.ListObjectInstanceRelationshipsFilter.fromObject(
                object.filter
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.objectInstance = null;
          }
          let keys;
          if (
            message.objectInstance != null &&
            message.hasOwnProperty('objectInstance')
          ) {
            object.objectInstance =
              $root.object_type.v1.ObjectInstance.toObject(
                message.objectInstance,
                options
              );
          }
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter =
              $root.object_type.v1.ListObjectInstanceRelationshipsFilter.toObject(
                message.filter,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'object_type.v1.ListObjectInstanceRelationshipsRequest'
          );
        }
      }

      ListObjectInstanceRelationshipsRequest.prototype.objectInstance = null;
      ListObjectInstanceRelationshipsRequest.prototype.filter = null;

      return ListObjectInstanceRelationshipsRequest;
    })();

    v1.ListObjectInstanceRelationshipsResponse = (() => {
      class ListObjectInstanceRelationshipsResponse {
        constructor(properties) {
          this.relatedObjectInstances = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectInstanceRelationshipsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectInstance != null &&
            Object.hasOwnProperty.call(message, 'objectInstance')
          ) {
            $root.object_type.v1.ObjectInstance.encode(
              message.objectInstance,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.relatedObjectInstances != null &&
            Object.hasOwnProperty.call(message, 'relatedObjectInstances')
          ) {
            for (const element of message.relatedObjectInstances) {
              $root.object_type.v1.ObjectInstance.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectInstanceRelationshipsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.objectInstance =
                  $root.object_type.v1.ObjectInstance.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                if (
                  !message.relatedObjectInstances ||
                  !message.relatedObjectInstances.length
                ) {
                  message.relatedObjectInstances = [];
                }
                message.relatedObjectInstances.push(
                  $root.object_type.v1.ObjectInstance.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.ListObjectInstanceRelationshipsResponse
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.ListObjectInstanceRelationshipsResponse();
          if (object.objectInstance != null) {
            if (typeof object.objectInstance !== 'object') {
              throw new TypeError(
                '.object_type.v1.ListObjectInstanceRelationshipsResponse.objectInstance: object expected, but got ' +
                  typeof object.objectInstance
              );
            }
            message.objectInstance =
              $root.object_type.v1.ObjectInstance.fromObject(
                object.objectInstance
              );
          }
          if (object.relatedObjectInstances) {
            if (!Array.isArray(object.relatedObjectInstances)) {
              throw new TypeError(
                '.object_type.v1.ListObjectInstanceRelationshipsResponse.relatedObjectInstances: array type expected, but got ' +
                  typeof object.relatedObjectInstances
              );
            }
            message.relatedObjectInstances = new Array(
              object.relatedObjectInstances.length
            );
            for (let i = 0; i < object.relatedObjectInstances.length; ++i) {
              if (typeof object.relatedObjectInstances[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.ListObjectInstanceRelationshipsResponse.relatedObjectInstances: object expected, but got ' +
                    typeof object.relatedObjectInstances[i]
                );
              }
              message.relatedObjectInstances[i] =
                $root.object_type.v1.ObjectInstance.fromObject(
                  object.relatedObjectInstances[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.relatedObjectInstances = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.objectInstance = null;
          }
          let keys;
          if (
            message.objectInstance != null &&
            message.hasOwnProperty('objectInstance')
          ) {
            object.objectInstance =
              $root.object_type.v1.ObjectInstance.toObject(
                message.objectInstance,
                options
              );
          }
          if (
            message.relatedObjectInstances &&
            message.relatedObjectInstances.length
          ) {
            object.relatedObjectInstances = new Array(
              message.relatedObjectInstances.length
            );
            for (let i = 0; i < message.relatedObjectInstances.length; ++i) {
              object.relatedObjectInstances[i] =
                $root.object_type.v1.ObjectInstance.toObject(
                  message.relatedObjectInstances[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'object_type.v1.ListObjectInstanceRelationshipsResponse'
          );
        }
      }

      ListObjectInstanceRelationshipsResponse.prototype.objectInstance = null;
      ListObjectInstanceRelationshipsResponse.prototype.relatedObjectInstances =
        $util.emptyArray;

      return ListObjectInstanceRelationshipsResponse;
    })();

    v1.ObjectType = (() => {
      class ObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(18).string(message.label);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.objectCategory != null &&
            Object.hasOwnProperty.call(message, 'objectCategory')
          ) {
            writer.uint32(32).int32(message.objectCategory);
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(48).int32(message.eventType);
          }
          if (
            message.payload != null &&
            Object.hasOwnProperty.call(message, 'payload')
          ) {
            $root.object_type.v1.ObjectTypePayload.encode(
              message.payload,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.label = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.objectCategory = reader.int32();
                break;
              }
              case 6: {
                message.eventType = reader.int32();
                break;
              }
              case 8: {
                message.payload = $root.object_type.v1.ObjectTypePayload.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ObjectType) {
            return object;
          }
          const message = new $root.object_type.v1.ObjectType();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.objectCategory != null) {
            switch (object.objectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.objectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.objectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.objectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.objectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.objectCategory = 4;
                break;
              }
              default: {
                if (typeof object.objectCategory == 'number') {
                  message.objectCategory = object.objectCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_FORM_RESPONSE_UPDATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'FORMSORT_FORM_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'MIGRATION':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'MORF_API_CALL':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'CUSTOM_PAYLOAD':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'VITAL_LAB_ORDER':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'SEGMENT_EVENT':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'ACTIVE_CAMPAIGN_WEBHOOK':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'INTAKEQ_APPOINTMENT':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'CUSTOMER_IO_EVENT':
              case 16: {
                message.eventType = 16;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.payload != null) {
            if (typeof object.payload !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectType.payload: object expected, but got ' +
                  typeof object.payload
              );
            }
            message.payload = $root.object_type.v1.ObjectTypePayload.fromObject(
              object.payload
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.label = '';
            object.objectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            object.eventType =
              options.enums === String ? 'UNSPECIFIED_EVENT_TYPE' : 0;
            object.payload = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.objectCategory != null &&
            message.hasOwnProperty('objectCategory')
          ) {
            object.objectCategory =
              options.enums === String
                ? sources$1.v1.ObjectCategory[message.objectCategory] ===
                  undefined
                  ? message.objectCategory
                  : sources$1.v1.ObjectCategory[message.objectCategory]
                : message.objectCategory;
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? sources$1.v1.EventType[message.eventType] === undefined
                  ? message.eventType
                  : sources$1.v1.EventType[message.eventType]
                : message.eventType;
          }
          if (message.payload != null && message.hasOwnProperty('payload')) {
            object.payload = $root.object_type.v1.ObjectTypePayload.toObject(
              message.payload,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ObjectType';
        }
      }

      ObjectType.prototype.id = '';
      ObjectType.prototype.label = '';
      ObjectType.prototype.description = null;
      ObjectType.prototype.objectCategory = 0;
      ObjectType.prototype.eventType = 0;
      ObjectType.prototype.payload = null;

      return ObjectType;
    })();

    v1.ObjectTypeSummary = (() => {
      class ObjectTypeSummary {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ObjectTypeSummary(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(18).string(message.label);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.objectCategory != null &&
            Object.hasOwnProperty.call(message, 'objectCategory')
          ) {
            writer.uint32(32).int32(message.objectCategory);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timestamp.encode(
              message.timing,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(48).int32(message.eventType);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(58).string(message.sourceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ObjectTypeSummary();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.label = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.objectCategory = reader.int32();
                break;
              }
              case 5: {
                message.timing = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.eventType = reader.int32();
                break;
              }
              case 7: {
                message.sourceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ObjectTypeSummary) {
            return object;
          }
          const message = new $root.object_type.v1.ObjectTypeSummary();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.objectCategory != null) {
            switch (object.objectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.objectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.objectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.objectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.objectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.objectCategory = 4;
                break;
              }
              default: {
                if (typeof object.objectCategory == 'number') {
                  message.objectCategory = object.objectCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectTypeSummary.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timestamp.fromObject(object.timing);
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_FORM_RESPONSE_UPDATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'FORMSORT_FORM_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'MIGRATION':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'MORF_API_CALL':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'CUSTOM_PAYLOAD':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'VITAL_LAB_ORDER':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'SEGMENT_EVENT':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'ACTIVE_CAMPAIGN_WEBHOOK':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'INTAKEQ_APPOINTMENT':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'CUSTOMER_IO_EVENT':
              case 16: {
                message.eventType = 16;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.label = '';
            object.objectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            object.timing = null;
            object.eventType =
              options.enums === String ? 'UNSPECIFIED_EVENT_TYPE' : 0;
            object.sourceId = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.objectCategory != null &&
            message.hasOwnProperty('objectCategory')
          ) {
            object.objectCategory =
              options.enums === String
                ? sources$1.v1.ObjectCategory[message.objectCategory] ===
                  undefined
                  ? message.objectCategory
                  : sources$1.v1.ObjectCategory[message.objectCategory]
                : message.objectCategory;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timestamp.toObject(
              message.timing,
              options
            );
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? sources$1.v1.EventType[message.eventType] === undefined
                  ? message.eventType
                  : sources$1.v1.EventType[message.eventType]
                : message.eventType;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ObjectTypeSummary';
        }
      }

      ObjectTypeSummary.prototype.id = '';
      ObjectTypeSummary.prototype.label = '';
      ObjectTypeSummary.prototype.description = null;
      ObjectTypeSummary.prototype.objectCategory = 0;
      ObjectTypeSummary.prototype.timing = null;
      ObjectTypeSummary.prototype.eventType = 0;
      ObjectTypeSummary.prototype.sourceId = '';

      return ObjectTypeSummary;
    })();

    v1.FormsortFormObjectType = (() => {
      class FormsortFormObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormsortFormObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.schema != null &&
            Object.hasOwnProperty.call(message, 'schema')
          ) {
            $root.object_type.v1.FormSchema.encode(
              message.schema,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.variantRevisionUuid != null &&
            Object.hasOwnProperty.call(message, 'variantRevisionUuid')
          ) {
            writer.uint32(18).string(message.variantRevisionUuid);
          }
          if (
            message.flowLabel != null &&
            Object.hasOwnProperty.call(message, 'flowLabel')
          ) {
            writer.uint32(26).string(message.flowLabel);
          }
          if (
            message.environmentLabel != null &&
            Object.hasOwnProperty.call(message, 'environmentLabel')
          ) {
            writer.uint32(34).string(message.environmentLabel);
          }
          if (
            message.variantLabel != null &&
            Object.hasOwnProperty.call(message, 'variantLabel')
          ) {
            writer.uint32(42).string(message.variantLabel);
          }
          if (
            message.publishedByEmail != null &&
            Object.hasOwnProperty.call(message, 'publishedByEmail')
          ) {
            writer.uint32(50).string(message.publishedByEmail);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormsortFormObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.schema = $root.object_type.v1.FormSchema.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.variantRevisionUuid = reader.string();
                break;
              }
              case 3: {
                message.flowLabel = reader.string();
                break;
              }
              case 4: {
                message.environmentLabel = reader.string();
                break;
              }
              case 5: {
                message.variantLabel = reader.string();
                break;
              }
              case 6: {
                message.publishedByEmail = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.FormsortFormObjectType) {
            return object;
          }
          const message = new $root.object_type.v1.FormsortFormObjectType();
          if (object.schema != null) {
            if (typeof object.schema !== 'object') {
              throw new TypeError(
                '.object_type.v1.FormsortFormObjectType.schema: object expected, but got ' +
                  typeof object.schema
              );
            }
            message.schema = $root.object_type.v1.FormSchema.fromObject(
              object.schema
            );
          }
          if (object.variantRevisionUuid != null) {
            message.variantRevisionUuid = String(object.variantRevisionUuid);
          }
          if (object.flowLabel != null) {
            message.flowLabel = String(object.flowLabel);
          }
          if (object.environmentLabel != null) {
            message.environmentLabel = String(object.environmentLabel);
          }
          if (object.variantLabel != null) {
            message.variantLabel = String(object.variantLabel);
          }
          if (object.publishedByEmail != null) {
            message.publishedByEmail = String(object.publishedByEmail);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.schema = null;
            object.variantRevisionUuid = '';
            object.flowLabel = '';
            object.environmentLabel = '';
            object.variantLabel = '';
            object.publishedByEmail = '';
          }
          let keys;
          if (message.schema != null && message.hasOwnProperty('schema')) {
            object.schema = $root.object_type.v1.FormSchema.toObject(
              message.schema,
              options
            );
          }
          if (
            message.variantRevisionUuid != null &&
            message.hasOwnProperty('variantRevisionUuid')
          ) {
            object.variantRevisionUuid = message.variantRevisionUuid;
          }
          if (
            message.flowLabel != null &&
            message.hasOwnProperty('flowLabel')
          ) {
            object.flowLabel = message.flowLabel;
          }
          if (
            message.environmentLabel != null &&
            message.hasOwnProperty('environmentLabel')
          ) {
            object.environmentLabel = message.environmentLabel;
          }
          if (
            message.variantLabel != null &&
            message.hasOwnProperty('variantLabel')
          ) {
            object.variantLabel = message.variantLabel;
          }
          if (
            message.publishedByEmail != null &&
            message.hasOwnProperty('publishedByEmail')
          ) {
            object.publishedByEmail = message.publishedByEmail;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.FormsortFormObjectType';
        }
      }

      FormsortFormObjectType.prototype.schema = null;
      FormsortFormObjectType.prototype.variantRevisionUuid = '';
      FormsortFormObjectType.prototype.flowLabel = '';
      FormsortFormObjectType.prototype.environmentLabel = '';
      FormsortFormObjectType.prototype.variantLabel = '';
      FormsortFormObjectType.prototype.publishedByEmail = '';

      return FormsortFormObjectType;
    })();

    v1.HealthieCustomModuleFormObjectType = (() => {
      class HealthieCustomModuleFormObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieCustomModuleFormObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.schema != null &&
            Object.hasOwnProperty.call(message, 'schema')
          ) {
            $root.object_type.v1.FormSchema.encode(
              message.schema,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.customModuleFormId != null &&
            Object.hasOwnProperty.call(message, 'customModuleFormId')
          ) {
            writer.uint32(18).string(message.customModuleFormId);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(26).string(message.name);
          }
          if (
            message.lastUpdatedByEmail != null &&
            Object.hasOwnProperty.call(message, 'lastUpdatedByEmail')
          ) {
            writer.uint32(34).string(message.lastUpdatedByEmail);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieCustomModuleFormObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.schema = $root.object_type.v1.FormSchema.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.customModuleFormId = reader.string();
                break;
              }
              case 3: {
                message.name = reader.string();
                break;
              }
              case 4: {
                message.lastUpdatedByEmail = reader.string();
                break;
              }
              case 5: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.HealthieCustomModuleFormObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.HealthieCustomModuleFormObjectType();
          if (object.schema != null) {
            if (typeof object.schema !== 'object') {
              throw new TypeError(
                '.object_type.v1.HealthieCustomModuleFormObjectType.schema: object expected, but got ' +
                  typeof object.schema
              );
            }
            message.schema = $root.object_type.v1.FormSchema.fromObject(
              object.schema
            );
          }
          if (object.customModuleFormId != null) {
            message.customModuleFormId = String(object.customModuleFormId);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.lastUpdatedByEmail != null) {
            message.lastUpdatedByEmail = String(object.lastUpdatedByEmail);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.object_type.v1.HealthieCustomModuleFormObjectType.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.schema = null;
            object.customModuleFormId = '';
            object.updatedAt = null;
          }
          let keys;
          if (message.schema != null && message.hasOwnProperty('schema')) {
            object.schema = $root.object_type.v1.FormSchema.toObject(
              message.schema,
              options
            );
          }
          if (
            message.customModuleFormId != null &&
            message.hasOwnProperty('customModuleFormId')
          ) {
            object.customModuleFormId = message.customModuleFormId;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.lastUpdatedByEmail != null &&
            message.hasOwnProperty('lastUpdatedByEmail')
          ) {
            object.lastUpdatedByEmail = message.lastUpdatedByEmail;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'object_type.v1.HealthieCustomModuleFormObjectType'
          );
        }
      }

      HealthieCustomModuleFormObjectType.prototype.schema = null;
      HealthieCustomModuleFormObjectType.prototype.customModuleFormId = '';
      HealthieCustomModuleFormObjectType.prototype.name = null;
      HealthieCustomModuleFormObjectType.prototype.lastUpdatedByEmail = null;
      HealthieCustomModuleFormObjectType.prototype.updatedAt = null;

      return HealthieCustomModuleFormObjectType;
    })();

    v1.AcuityAppointmentTypeObjectType = (() => {
      class AcuityAppointmentTypeObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AcuityAppointmentTypeObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.appointment != null &&
            Object.hasOwnProperty.call(message, 'appointment')
          ) {
            $root.object_type.v1.AppointmentSchema.encode(
              message.appointment,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.appointmentTypeId != null &&
            Object.hasOwnProperty.call(message, 'appointmentTypeId')
          ) {
            writer.uint32(18).string(message.appointmentTypeId);
          }
          if (
            message.appointmentType != null &&
            Object.hasOwnProperty.call(message, 'appointmentType')
          ) {
            acuity$1.v1.AppointmentType.encode(
              message.appointmentType,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AcuityAppointmentTypeObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointment =
                  $root.object_type.v1.AppointmentSchema.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.appointmentTypeId = reader.string();
                break;
              }
              case 3: {
                message.appointmentType = acuity$1.v1.AppointmentType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.AcuityAppointmentTypeObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.AcuityAppointmentTypeObjectType();
          if (object.appointment != null) {
            if (typeof object.appointment !== 'object') {
              throw new TypeError(
                '.object_type.v1.AcuityAppointmentTypeObjectType.appointment: object expected, but got ' +
                  typeof object.appointment
              );
            }
            message.appointment =
              $root.object_type.v1.AppointmentSchema.fromObject(
                object.appointment
              );
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          if (object.appointmentType != null) {
            if (typeof object.appointmentType !== 'object') {
              throw new TypeError(
                '.object_type.v1.AcuityAppointmentTypeObjectType.appointmentType: object expected, but got ' +
                  typeof object.appointmentType
              );
            }
            message.appointmentType = acuity$1.v1.AppointmentType.fromObject(
              object.appointmentType
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointment = null;
            object.appointmentTypeId = '';
            object.appointmentType = null;
          }
          let keys;
          if (
            message.appointment != null &&
            message.hasOwnProperty('appointment')
          ) {
            object.appointment =
              $root.object_type.v1.AppointmentSchema.toObject(
                message.appointment,
                options
              );
          }
          if (
            message.appointmentTypeId != null &&
            message.hasOwnProperty('appointmentTypeId')
          ) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          if (
            message.appointmentType != null &&
            message.hasOwnProperty('appointmentType')
          ) {
            object.appointmentType = acuity$1.v1.AppointmentType.toObject(
              message.appointmentType,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'object_type.v1.AcuityAppointmentTypeObjectType'
          );
        }
      }

      AcuityAppointmentTypeObjectType.prototype.appointment = null;
      AcuityAppointmentTypeObjectType.prototype.appointmentTypeId = '';
      AcuityAppointmentTypeObjectType.prototype.appointmentType = null;

      return AcuityAppointmentTypeObjectType;
    })();

    v1.IntakeQAppointmentTypeObjectType = (() => {
      class IntakeQAppointmentTypeObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new IntakeQAppointmentTypeObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.appointment != null &&
            Object.hasOwnProperty.call(message, 'appointment')
          ) {
            $root.object_type.v1.AppointmentSchema.encode(
              message.appointment,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.appointmentTypeId != null &&
            Object.hasOwnProperty.call(message, 'appointmentTypeId')
          ) {
            writer.uint32(18).string(message.appointmentTypeId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new IntakeQAppointmentTypeObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointment =
                  $root.object_type.v1.AppointmentSchema.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.appointmentTypeId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.IntakeQAppointmentTypeObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.IntakeQAppointmentTypeObjectType();
          if (object.appointment != null) {
            if (typeof object.appointment !== 'object') {
              throw new TypeError(
                '.object_type.v1.IntakeQAppointmentTypeObjectType.appointment: object expected, but got ' +
                  typeof object.appointment
              );
            }
            message.appointment =
              $root.object_type.v1.AppointmentSchema.fromObject(
                object.appointment
              );
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointment = null;
            object.appointmentTypeId = '';
          }
          let keys;
          if (
            message.appointment != null &&
            message.hasOwnProperty('appointment')
          ) {
            object.appointment =
              $root.object_type.v1.AppointmentSchema.toObject(
                message.appointment,
                options
              );
          }
          if (
            message.appointmentTypeId != null &&
            message.hasOwnProperty('appointmentTypeId')
          ) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'object_type.v1.IntakeQAppointmentTypeObjectType'
          );
        }
      }

      IntakeQAppointmentTypeObjectType.prototype.appointment = null;
      IntakeQAppointmentTypeObjectType.prototype.appointmentTypeId = '';

      return IntakeQAppointmentTypeObjectType;
    })();

    v1.HealthieAppointmentTypeObjectType = (() => {
      class HealthieAppointmentTypeObjectType {
        constructor(properties) {
          this.associatedProviderNames = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieAppointmentTypeObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.appointment != null &&
            Object.hasOwnProperty.call(message, 'appointment')
          ) {
            $root.object_type.v1.AppointmentSchema.encode(
              message.appointment,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.appointmentTypeId != null &&
            Object.hasOwnProperty.call(message, 'appointmentTypeId')
          ) {
            writer.uint32(18).string(message.appointmentTypeId);
          }
          if (
            message.userGroupId != null &&
            Object.hasOwnProperty.call(message, 'userGroupId')
          ) {
            writer.uint32(26).string(message.userGroupId);
          }
          if (
            message.associatedProviderNames != null &&
            Object.hasOwnProperty.call(message, 'associatedProviderNames')
          ) {
            for (const element of message.associatedProviderNames) {
              writer.uint32(34).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieAppointmentTypeObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.appointment =
                  $root.object_type.v1.AppointmentSchema.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.appointmentTypeId = reader.string();
                break;
              }
              case 3: {
                message.userGroupId = reader.string();
                break;
              }
              case 4: {
                if (
                  !message.associatedProviderNames ||
                  !message.associatedProviderNames.length
                ) {
                  message.associatedProviderNames = [];
                }
                message.associatedProviderNames.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.HealthieAppointmentTypeObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.HealthieAppointmentTypeObjectType();
          if (object.appointment != null) {
            if (typeof object.appointment !== 'object') {
              throw new TypeError(
                '.object_type.v1.HealthieAppointmentTypeObjectType.appointment: object expected, but got ' +
                  typeof object.appointment
              );
            }
            message.appointment =
              $root.object_type.v1.AppointmentSchema.fromObject(
                object.appointment
              );
          }
          if (object.appointmentTypeId != null) {
            message.appointmentTypeId = String(object.appointmentTypeId);
          }
          if (object.userGroupId != null) {
            message.userGroupId = String(object.userGroupId);
          }
          if (object.associatedProviderNames) {
            if (!Array.isArray(object.associatedProviderNames)) {
              throw new TypeError(
                '.object_type.v1.HealthieAppointmentTypeObjectType.associatedProviderNames: array type expected, but got ' +
                  typeof object.associatedProviderNames
              );
            }
            message.associatedProviderNames = new Array(
              object.associatedProviderNames.length
            );
            for (let i = 0; i < object.associatedProviderNames.length; ++i) {
              message.associatedProviderNames[i] = String(
                object.associatedProviderNames[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.associatedProviderNames = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.appointment = null;
            object.appointmentTypeId = '';
          }
          let keys;
          if (
            message.appointment != null &&
            message.hasOwnProperty('appointment')
          ) {
            object.appointment =
              $root.object_type.v1.AppointmentSchema.toObject(
                message.appointment,
                options
              );
          }
          if (
            message.appointmentTypeId != null &&
            message.hasOwnProperty('appointmentTypeId')
          ) {
            object.appointmentTypeId = message.appointmentTypeId;
          }
          if (
            message.userGroupId != null &&
            message.hasOwnProperty('userGroupId')
          ) {
            object.userGroupId = message.userGroupId;
          }
          if (
            message.associatedProviderNames &&
            message.associatedProviderNames.length
          ) {
            object.associatedProviderNames = new Array(
              message.associatedProviderNames.length
            );
            for (let i = 0; i < message.associatedProviderNames.length; ++i) {
              object.associatedProviderNames[i] =
                message.associatedProviderNames[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'object_type.v1.HealthieAppointmentTypeObjectType'
          );
        }
      }

      HealthieAppointmentTypeObjectType.prototype.appointment = null;
      HealthieAppointmentTypeObjectType.prototype.appointmentTypeId = '';
      HealthieAppointmentTypeObjectType.prototype.userGroupId = null;
      HealthieAppointmentTypeObjectType.prototype.associatedProviderNames =
        $util.emptyArray;

      return HealthieAppointmentTypeObjectType;
    })();

    v1.HealthieUserObjectType = (() => {
      class HealthieUserObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieUserObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieUserObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.HealthieUserObjectType) {
            return object;
          }
          const message = new $root.object_type.v1.HealthieUserObjectType();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.HealthieUserObjectType';
        }
      }

      return HealthieUserObjectType;
    })();

    v1.SegmentEventObjectType = (() => {
      class SegmentEventObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SegmentEventObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SegmentEventObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.SegmentEventObjectType) {
            return object;
          }
          const message = new $root.object_type.v1.SegmentEventObjectType();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.SegmentEventObjectType';
        }
      }

      return SegmentEventObjectType;
    })();

    v1.CustomerIoEventObjectType = (() => {
      class CustomerIoEventObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CustomerIoEventObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CustomerIoEventObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.object_type.v1.CustomerIoEventObjectType
          ) {
            return object;
          }
          const message = new $root.object_type.v1.CustomerIoEventObjectType();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.CustomerIoEventObjectType';
        }
      }

      return CustomerIoEventObjectType;
    })();

    v1.HealthieCMS1500ObjectType = (() => {
      class HealthieCMS1500ObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieCMS1500ObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.cms1500Id != null &&
            Object.hasOwnProperty.call(message, 'cms1500Id')
          ) {
            writer.uint32(10).string(message.cms1500Id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieCMS1500ObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.cms1500Id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.object_type.v1.HealthieCMS1500ObjectType
          ) {
            return object;
          }
          const message = new $root.object_type.v1.HealthieCMS1500ObjectType();
          if (object.cms1500Id != null) {
            message.cms1500Id = String(object.cms1500Id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.cms1500Id = '';
          }
          let keys;
          if (
            message.cms1500Id != null &&
            message.hasOwnProperty('cms1500Id')
          ) {
            object.cms1500Id = message.cms1500Id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.HealthieCMS1500ObjectType';
        }
      }

      HealthieCMS1500ObjectType.prototype.cms1500Id = '';

      return HealthieCMS1500ObjectType;
    })();

    v1.HealthieInsuranceAuthorizationObjectType = (() => {
      class HealthieInsuranceAuthorizationObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieInsuranceAuthorizationObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.insuranceAuthorizationId != null &&
            Object.hasOwnProperty.call(message, 'insuranceAuthorizationId')
          ) {
            writer.uint32(10).string(message.insuranceAuthorizationId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieInsuranceAuthorizationObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.insuranceAuthorizationId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.HealthieInsuranceAuthorizationObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.HealthieInsuranceAuthorizationObjectType();
          if (object.insuranceAuthorizationId != null) {
            message.insuranceAuthorizationId = String(
              object.insuranceAuthorizationId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.insuranceAuthorizationId = '';
          }
          let keys;
          if (
            message.insuranceAuthorizationId != null &&
            message.hasOwnProperty('insuranceAuthorizationId')
          ) {
            object.insuranceAuthorizationId = message.insuranceAuthorizationId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'object_type.v1.HealthieInsuranceAuthorizationObjectType'
          );
        }
      }

      HealthieInsuranceAuthorizationObjectType.prototype.insuranceAuthorizationId =
        '';

      return HealthieInsuranceAuthorizationObjectType;
    })();

    v1.HealthieRequestedFormCompletionObjectType = (() => {
      class HealthieRequestedFormCompletionObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieRequestedFormCompletionObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.requestedFormCompletionId != null &&
            Object.hasOwnProperty.call(message, 'requestedFormCompletionId')
          ) {
            writer.uint32(10).string(message.requestedFormCompletionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieRequestedFormCompletionObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.requestedFormCompletionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.HealthieRequestedFormCompletionObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.HealthieRequestedFormCompletionObjectType();
          if (object.requestedFormCompletionId != null) {
            message.requestedFormCompletionId = String(
              object.requestedFormCompletionId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.requestedFormCompletionId = '';
          }
          let keys;
          if (
            message.requestedFormCompletionId != null &&
            message.hasOwnProperty('requestedFormCompletionId')
          ) {
            object.requestedFormCompletionId =
              message.requestedFormCompletionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'object_type.v1.HealthieRequestedFormCompletionObjectType'
          );
        }
      }

      HealthieRequestedFormCompletionObjectType.prototype.requestedFormCompletionId =
        '';

      return HealthieRequestedFormCompletionObjectType;
    })();

    v1.HealthieBillingItemObjectType = (() => {
      class HealthieBillingItemObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthieBillingItemObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.billingItemId != null &&
            Object.hasOwnProperty.call(message, 'billingItemId')
          ) {
            writer.uint32(10).string(message.billingItemId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthieBillingItemObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.billingItemId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.object_type.v1.HealthieBillingItemObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.HealthieBillingItemObjectType();
          if (object.billingItemId != null) {
            message.billingItemId = String(object.billingItemId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.billingItemId = '';
          }
          let keys;
          if (
            message.billingItemId != null &&
            message.hasOwnProperty('billingItemId')
          ) {
            object.billingItemId = message.billingItemId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.HealthieBillingItemObjectType';
        }
      }

      HealthieBillingItemObjectType.prototype.billingItemId = '';

      return HealthieBillingItemObjectType;
    })();

    v1.HealthiePolicyObjectType = (() => {
      class HealthiePolicyObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new HealthiePolicyObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.policyId != null &&
            Object.hasOwnProperty.call(message, 'policyId')
          ) {
            writer.uint32(10).string(message.policyId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new HealthiePolicyObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.policyId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.HealthiePolicyObjectType) {
            return object;
          }
          const message = new $root.object_type.v1.HealthiePolicyObjectType();
          if (object.policyId != null) {
            message.policyId = String(object.policyId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.policyId = '';
          }
          let keys;
          if (message.policyId != null && message.hasOwnProperty('policyId')) {
            object.policyId = message.policyId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.HealthiePolicyObjectType';
        }
      }

      HealthiePolicyObjectType.prototype.policyId = '';

      return HealthiePolicyObjectType;
    })();

    v1.AxleHealthLabOrderObjectType = (() => {
      class AxleHealthLabOrderObjectType {
        constructor(properties) {
          this.testNames = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AxleHealthLabOrderObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.serviceId != null &&
            Object.hasOwnProperty.call(message, 'serviceId')
          ) {
            writer.uint32(10).string(message.serviceId);
          }
          if (
            message.testNames != null &&
            Object.hasOwnProperty.call(message, 'testNames')
          ) {
            for (const element of message.testNames) {
              writer.uint32(18).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AxleHealthLabOrderObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.serviceId = reader.string();
                break;
              }
              case 2: {
                if (!message.testNames || !message.testNames.length) {
                  message.testNames = [];
                }
                message.testNames.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.object_type.v1.AxleHealthLabOrderObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.AxleHealthLabOrderObjectType();
          if (object.serviceId != null) {
            message.serviceId = String(object.serviceId);
          }
          if (object.testNames) {
            if (!Array.isArray(object.testNames)) {
              throw new TypeError(
                '.object_type.v1.AxleHealthLabOrderObjectType.testNames: array type expected, but got ' +
                  typeof object.testNames
              );
            }
            message.testNames = new Array(object.testNames.length);
            for (let i = 0; i < object.testNames.length; ++i) {
              message.testNames[i] = String(object.testNames[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.testNames = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.serviceId = '';
          }
          let keys;
          if (
            message.serviceId != null &&
            message.hasOwnProperty('serviceId')
          ) {
            object.serviceId = message.serviceId;
          }
          if (message.testNames && message.testNames.length) {
            object.testNames = new Array(message.testNames.length);
            for (let i = 0; i < message.testNames.length; ++i) {
              object.testNames[i] = message.testNames[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.AxleHealthLabOrderObjectType';
        }
      }

      AxleHealthLabOrderObjectType.prototype.serviceId = '';
      AxleHealthLabOrderObjectType.prototype.testNames = $util.emptyArray;

      return AxleHealthLabOrderObjectType;
    })();

    v1.ButterflyLabsLabOrderObjectType = (() => {
      class ButterflyLabsLabOrderObjectType {
        constructor(properties) {
          this.testNames = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ButterflyLabsLabOrderObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.orderDefinitionCode != null &&
            Object.hasOwnProperty.call(message, 'orderDefinitionCode')
          ) {
            writer.uint32(10).string(message.orderDefinitionCode);
          }
          if (
            message.testNames != null &&
            Object.hasOwnProperty.call(message, 'testNames')
          ) {
            for (const element of message.testNames) {
              writer.uint32(18).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ButterflyLabsLabOrderObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.orderDefinitionCode = reader.string();
                break;
              }
              case 2: {
                if (!message.testNames || !message.testNames.length) {
                  message.testNames = [];
                }
                message.testNames.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.ButterflyLabsLabOrderObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.ButterflyLabsLabOrderObjectType();
          if (object.orderDefinitionCode != null) {
            message.orderDefinitionCode = String(object.orderDefinitionCode);
          }
          if (object.testNames) {
            if (!Array.isArray(object.testNames)) {
              throw new TypeError(
                '.object_type.v1.ButterflyLabsLabOrderObjectType.testNames: array type expected, but got ' +
                  typeof object.testNames
              );
            }
            message.testNames = new Array(object.testNames.length);
            for (let i = 0; i < object.testNames.length; ++i) {
              message.testNames[i] = String(object.testNames[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.testNames = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.orderDefinitionCode = '';
          }
          let keys;
          if (
            message.orderDefinitionCode != null &&
            message.hasOwnProperty('orderDefinitionCode')
          ) {
            object.orderDefinitionCode = message.orderDefinitionCode;
          }
          if (message.testNames && message.testNames.length) {
            object.testNames = new Array(message.testNames.length);
            for (let i = 0; i < message.testNames.length; ++i) {
              object.testNames[i] = message.testNames[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'object_type.v1.ButterflyLabsLabOrderObjectType'
          );
        }
      }

      ButterflyLabsLabOrderObjectType.prototype.orderDefinitionCode = '';
      ButterflyLabsLabOrderObjectType.prototype.testNames = $util.emptyArray;

      return ButterflyLabsLabOrderObjectType;
    })();

    v1.VitalLabTestObjectType = (() => {
      class VitalLabTestObjectType {
        constructor(properties) {
          this.testNames = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new VitalLabTestObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.labTest != null &&
            Object.hasOwnProperty.call(message, 'labTest')
          ) {
            vital$1.v1.OrderLabTest.encode(
              message.labTest,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.testNames != null &&
            Object.hasOwnProperty.call(message, 'testNames')
          ) {
            for (const element of message.testNames) {
              writer.uint32(18).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new VitalLabTestObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.labTest = vital$1.v1.OrderLabTest.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                if (!message.testNames || !message.testNames.length) {
                  message.testNames = [];
                }
                message.testNames.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.VitalLabTestObjectType) {
            return object;
          }
          const message = new $root.object_type.v1.VitalLabTestObjectType();
          if (object.labTest != null) {
            if (typeof object.labTest !== 'object') {
              throw new TypeError(
                '.object_type.v1.VitalLabTestObjectType.labTest: object expected, but got ' +
                  typeof object.labTest
              );
            }
            message.labTest = vital$1.v1.OrderLabTest.fromObject(
              object.labTest
            );
          }
          if (object.testNames) {
            if (!Array.isArray(object.testNames)) {
              throw new TypeError(
                '.object_type.v1.VitalLabTestObjectType.testNames: array type expected, but got ' +
                  typeof object.testNames
              );
            }
            message.testNames = new Array(object.testNames.length);
            for (let i = 0; i < object.testNames.length; ++i) {
              message.testNames[i] = String(object.testNames[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.testNames = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.labTest = null;
          }
          let keys;
          if (message.labTest != null && message.hasOwnProperty('labTest')) {
            object.labTest = vital$1.v1.OrderLabTest.toObject(
              message.labTest,
              options
            );
          }
          if (message.testNames && message.testNames.length) {
            object.testNames = new Array(message.testNames.length);
            for (let i = 0; i < message.testNames.length; ++i) {
              object.testNames[i] = message.testNames[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.VitalLabTestObjectType';
        }
      }

      VitalLabTestObjectType.prototype.labTest = null;
      VitalLabTestObjectType.prototype.testNames = $util.emptyArray;

      return VitalLabTestObjectType;
    })();

    v1.ActiveCampaignDealGroupObjectType = (() => {
      class ActiveCampaignDealGroupObjectType {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ActiveCampaignDealGroupObjectType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.group != null &&
            Object.hasOwnProperty.call(message, 'group')
          ) {
            writer.uint32(10).string(message.group);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ActiveCampaignDealGroupObjectType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.group = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.object_type.v1.ActiveCampaignDealGroupObjectType
          ) {
            return object;
          }
          const message =
            new $root.object_type.v1.ActiveCampaignDealGroupObjectType();
          if (object.group != null) {
            message.group = String(object.group);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.group = '';
          }
          let keys;
          if (message.group != null && message.hasOwnProperty('group')) {
            object.group = message.group;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'object_type.v1.ActiveCampaignDealGroupObjectType'
          );
        }
      }

      ActiveCampaignDealGroupObjectType.prototype.group = '';

      return ActiveCampaignDealGroupObjectType;
    })();

    v1.FormSchema = (() => {
      class FormSchema {
        constructor(properties) {
          this.groups = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormSchema(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.groups != null &&
            Object.hasOwnProperty.call(message, 'groups')
          ) {
            for (const element of message.groups) {
              $root.object_type.v1.FormInputGroup.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.variables != null &&
            Object.hasOwnProperty.call(message, 'variables')
          ) {
            $root.object_type.v1.FormVariables.encode(
              message.variables,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormSchema();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.groups || !message.groups.length) {
                  message.groups = [];
                }
                message.groups.push(
                  $root.object_type.v1.FormInputGroup.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.variables = $root.object_type.v1.FormVariables.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.FormSchema) {
            return object;
          }
          const message = new $root.object_type.v1.FormSchema();
          if (object.groups) {
            if (!Array.isArray(object.groups)) {
              throw new TypeError(
                '.object_type.v1.FormSchema.groups: array type expected, but got ' +
                  typeof object.groups
              );
            }
            message.groups = new Array(object.groups.length);
            for (let i = 0; i < object.groups.length; ++i) {
              if (typeof object.groups[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.FormSchema.groups: object expected, but got ' +
                    typeof object.groups[i]
                );
              }
              message.groups[i] =
                $root.object_type.v1.FormInputGroup.fromObject(
                  object.groups[i]
                );
            }
          }
          if (object.variables != null) {
            if (typeof object.variables !== 'object') {
              throw new TypeError(
                '.object_type.v1.FormSchema.variables: object expected, but got ' +
                  typeof object.variables
              );
            }
            message.variables = $root.object_type.v1.FormVariables.fromObject(
              object.variables
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.groups = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.variables = null;
          }
          let keys;
          if (message.groups && message.groups.length) {
            object.groups = new Array(message.groups.length);
            for (let i = 0; i < message.groups.length; ++i) {
              object.groups[i] = $root.object_type.v1.FormInputGroup.toObject(
                message.groups[i],
                options
              );
            }
          }
          if (
            message.variables != null &&
            message.hasOwnProperty('variables')
          ) {
            object.variables = $root.object_type.v1.FormVariables.toObject(
              message.variables,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.FormSchema';
        }
      }

      FormSchema.prototype.groups = $util.emptyArray;
      FormSchema.prototype.variables = null;

      return FormSchema;
    })();

    v1.FormInputGroup = (() => {
      class FormInputGroup {
        constructor(properties) {
          this.input = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormInputGroup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.input != null &&
            Object.hasOwnProperty.call(message, 'input')
          ) {
            for (const element of message.input) {
              $root.object_type.v1.FormInput.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormInputGroup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                if (!message.input || !message.input.length) {
                  message.input = [];
                }
                message.input.push(
                  $root.object_type.v1.FormInput.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.FormInputGroup) {
            return object;
          }
          const message = new $root.object_type.v1.FormInputGroup();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.input) {
            if (!Array.isArray(object.input)) {
              throw new TypeError(
                '.object_type.v1.FormInputGroup.input: array type expected, but got ' +
                  typeof object.input
              );
            }
            message.input = new Array(object.input.length);
            for (let i = 0; i < object.input.length; ++i) {
              if (typeof object.input[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.FormInputGroup.input: object expected, but got ' +
                    typeof object.input[i]
                );
              }
              message.input[i] = $root.object_type.v1.FormInput.fromObject(
                object.input[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.input = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.input && message.input.length) {
            object.input = new Array(message.input.length);
            for (let i = 0; i < message.input.length; ++i) {
              object.input[i] = $root.object_type.v1.FormInput.toObject(
                message.input[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.FormInputGroup';
        }
      }

      FormInputGroup.prototype.label = '';
      FormInputGroup.prototype.input = $util.emptyArray;

      return FormInputGroup;
    })();

    v1.FormInput = (() => {
      class FormInput {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormInput(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.question != null &&
            Object.hasOwnProperty.call(message, 'question')
          ) {
            $root.object_type.v1.FormInputQuestion.encode(
              message.question,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormInput();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.question =
                  $root.object_type.v1.FormInputQuestion.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.FormInput) {
            return object;
          }
          const message = new $root.object_type.v1.FormInput();
          if (object.question != null) {
            if (typeof object.question !== 'object') {
              throw new TypeError(
                '.object_type.v1.FormInput.question: object expected, but got ' +
                  typeof object.question
              );
            }
            message.question =
              $root.object_type.v1.FormInputQuestion.fromObject(
                object.question
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.question = null;
          }
          let keys;
          if (message.question != null && message.hasOwnProperty('question')) {
            object.question = $root.object_type.v1.FormInputQuestion.toObject(
              message.question,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.FormInput';
        }
      }

      FormInput.prototype.question = null;

      return FormInput;
    })();

    v1.FormInputQuestion = (() => {
      class FormInputQuestion {
        constructor(properties) {
          this.subquestions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormInputQuestion(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stableId != null &&
            Object.hasOwnProperty.call(message, 'stableId')
          ) {
            writer.uint32(10).string(message.stableId);
          }
          if (
            message.question != null &&
            Object.hasOwnProperty.call(message, 'question')
          ) {
            writer.uint32(18).string(message.question);
          }
          if (
            message.valueType != null &&
            Object.hasOwnProperty.call(message, 'valueType')
          ) {
            values$1.v1.ValueType.encode(
              message.valueType,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.fieldLookup != null &&
            Object.hasOwnProperty.call(message, 'fieldLookup')
          ) {
            field_lookup$1.v1.FieldLookup.encode(
              message.fieldLookup,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.subquestions != null &&
            Object.hasOwnProperty.call(message, 'subquestions')
          ) {
            for (const element of message.subquestions) {
              $root.object_type.v1.FormInputQuestion.encode(
                element,
                writer.uint32(42).fork()
              ).ldelim();
            }
          }
          if (
            message.disablePropertySourcingByDefault != null &&
            Object.hasOwnProperty.call(
              message,
              'disablePropertySourcingByDefault'
            )
          ) {
            writer.uint32(48).bool(message.disablePropertySourcingByDefault);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormInputQuestion();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stableId = reader.string();
                break;
              }
              case 2: {
                message.question = reader.string();
                break;
              }
              case 3: {
                message.valueType = values$1.v1.ValueType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.fieldLookup = field_lookup$1.v1.FieldLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                if (!message.subquestions || !message.subquestions.length) {
                  message.subquestions = [];
                }
                message.subquestions.push(
                  $root.object_type.v1.FormInputQuestion.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 6: {
                message.disablePropertySourcingByDefault = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.FormInputQuestion) {
            return object;
          }
          const message = new $root.object_type.v1.FormInputQuestion();
          if (object.stableId != null) {
            message.stableId = String(object.stableId);
          }
          if (object.question != null) {
            message.question = String(object.question);
          }
          if (object.valueType != null) {
            if (typeof object.valueType !== 'object') {
              throw new TypeError(
                '.object_type.v1.FormInputQuestion.valueType: object expected, but got ' +
                  typeof object.valueType
              );
            }
            message.valueType = values$1.v1.ValueType.fromObject(
              object.valueType
            );
          }
          if (object.fieldLookup != null) {
            if (typeof object.fieldLookup !== 'object') {
              throw new TypeError(
                '.object_type.v1.FormInputQuestion.fieldLookup: object expected, but got ' +
                  typeof object.fieldLookup
              );
            }
            message.fieldLookup = field_lookup$1.v1.FieldLookup.fromObject(
              object.fieldLookup
            );
          }
          if (object.subquestions) {
            if (!Array.isArray(object.subquestions)) {
              throw new TypeError(
                '.object_type.v1.FormInputQuestion.subquestions: array type expected, but got ' +
                  typeof object.subquestions
              );
            }
            message.subquestions = new Array(object.subquestions.length);
            for (let i = 0; i < object.subquestions.length; ++i) {
              if (typeof object.subquestions[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.FormInputQuestion.subquestions: object expected, but got ' +
                    typeof object.subquestions[i]
                );
              }
              message.subquestions[i] =
                $root.object_type.v1.FormInputQuestion.fromObject(
                  object.subquestions[i]
                );
            }
          }
          if (object.disablePropertySourcingByDefault != null) {
            message.disablePropertySourcingByDefault = Boolean(
              object.disablePropertySourcingByDefault
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.subquestions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stableId = '';
            object.question = '';
            object.valueType = null;
            object.fieldLookup = null;
            object.disablePropertySourcingByDefault = false;
          }
          let keys;
          if (message.stableId != null && message.hasOwnProperty('stableId')) {
            object.stableId = message.stableId;
          }
          if (message.question != null && message.hasOwnProperty('question')) {
            object.question = message.question;
          }
          if (
            message.valueType != null &&
            message.hasOwnProperty('valueType')
          ) {
            object.valueType = values$1.v1.ValueType.toObject(
              message.valueType,
              options
            );
          }
          if (
            message.fieldLookup != null &&
            message.hasOwnProperty('fieldLookup')
          ) {
            object.fieldLookup = field_lookup$1.v1.FieldLookup.toObject(
              message.fieldLookup,
              options
            );
          }
          if (message.subquestions && message.subquestions.length) {
            object.subquestions = new Array(message.subquestions.length);
            for (let i = 0; i < message.subquestions.length; ++i) {
              object.subquestions[i] =
                $root.object_type.v1.FormInputQuestion.toObject(
                  message.subquestions[i],
                  options
                );
            }
          }
          if (
            message.disablePropertySourcingByDefault != null &&
            message.hasOwnProperty('disablePropertySourcingByDefault')
          ) {
            object.disablePropertySourcingByDefault =
              message.disablePropertySourcingByDefault;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.FormInputQuestion';
        }
      }

      FormInputQuestion.prototype.stableId = '';
      FormInputQuestion.prototype.question = '';
      FormInputQuestion.prototype.valueType = null;
      FormInputQuestion.prototype.fieldLookup = null;
      FormInputQuestion.prototype.subquestions = $util.emptyArray;
      FormInputQuestion.prototype.disablePropertySourcingByDefault = false;

      return FormInputQuestion;
    })();

    v1.FormInputQuestionGroup = (() => {
      class FormInputQuestionGroup {
        constructor(properties) {
          this.questions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormInputQuestionGroup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.questions != null &&
            Object.hasOwnProperty.call(message, 'questions')
          ) {
            for (const element of message.questions) {
              $root.object_type.v1.FormInputQuestion.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormInputQuestionGroup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                if (!message.questions || !message.questions.length) {
                  message.questions = [];
                }
                message.questions.push(
                  $root.object_type.v1.FormInputQuestion.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.FormInputQuestionGroup) {
            return object;
          }
          const message = new $root.object_type.v1.FormInputQuestionGroup();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.questions) {
            if (!Array.isArray(object.questions)) {
              throw new TypeError(
                '.object_type.v1.FormInputQuestionGroup.questions: array type expected, but got ' +
                  typeof object.questions
              );
            }
            message.questions = new Array(object.questions.length);
            for (let i = 0; i < object.questions.length; ++i) {
              if (typeof object.questions[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.FormInputQuestionGroup.questions: object expected, but got ' +
                    typeof object.questions[i]
                );
              }
              message.questions[i] =
                $root.object_type.v1.FormInputQuestion.fromObject(
                  object.questions[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.questions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.questions && message.questions.length) {
            object.questions = new Array(message.questions.length);
            for (let i = 0; i < message.questions.length; ++i) {
              object.questions[i] =
                $root.object_type.v1.FormInputQuestion.toObject(
                  message.questions[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.FormInputQuestionGroup';
        }
      }

      FormInputQuestionGroup.prototype.label = '';
      FormInputQuestionGroup.prototype.questions = $util.emptyArray;

      return FormInputQuestionGroup;
    })();

    v1.FormVariables = (() => {
      class FormVariables {
        constructor(properties) {
          this.calculated = [];
          this.external = [];
          this.api = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormVariables(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.calculated != null &&
            Object.hasOwnProperty.call(message, 'calculated')
          ) {
            for (const element of message.calculated) {
              $root.object_type.v1.FormVariable.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.external != null &&
            Object.hasOwnProperty.call(message, 'external')
          ) {
            for (const element of message.external) {
              $root.object_type.v1.FormVariable.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          if (
            message.api != null &&
            Object.hasOwnProperty.call(message, 'api')
          ) {
            for (const element of message.api) {
              $root.object_type.v1.FormVariable.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormVariables();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.calculated || !message.calculated.length) {
                  message.calculated = [];
                }
                message.calculated.push(
                  $root.object_type.v1.FormVariable.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                if (!message.external || !message.external.length) {
                  message.external = [];
                }
                message.external.push(
                  $root.object_type.v1.FormVariable.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 3: {
                if (!message.api || !message.api.length) {
                  message.api = [];
                }
                message.api.push(
                  $root.object_type.v1.FormVariable.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.FormVariables) {
            return object;
          }
          const message = new $root.object_type.v1.FormVariables();
          if (object.calculated) {
            if (!Array.isArray(object.calculated)) {
              throw new TypeError(
                '.object_type.v1.FormVariables.calculated: array type expected, but got ' +
                  typeof object.calculated
              );
            }
            message.calculated = new Array(object.calculated.length);
            for (let i = 0; i < object.calculated.length; ++i) {
              if (typeof object.calculated[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.FormVariables.calculated: object expected, but got ' +
                    typeof object.calculated[i]
                );
              }
              message.calculated[i] =
                $root.object_type.v1.FormVariable.fromObject(
                  object.calculated[i]
                );
            }
          }
          if (object.external) {
            if (!Array.isArray(object.external)) {
              throw new TypeError(
                '.object_type.v1.FormVariables.external: array type expected, but got ' +
                  typeof object.external
              );
            }
            message.external = new Array(object.external.length);
            for (let i = 0; i < object.external.length; ++i) {
              if (typeof object.external[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.FormVariables.external: object expected, but got ' +
                    typeof object.external[i]
                );
              }
              message.external[i] =
                $root.object_type.v1.FormVariable.fromObject(
                  object.external[i]
                );
            }
          }
          if (object.api) {
            if (!Array.isArray(object.api)) {
              throw new TypeError(
                '.object_type.v1.FormVariables.api: array type expected, but got ' +
                  typeof object.api
              );
            }
            message.api = new Array(object.api.length);
            for (let i = 0; i < object.api.length; ++i) {
              if (typeof object.api[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.FormVariables.api: object expected, but got ' +
                    typeof object.api[i]
                );
              }
              message.api[i] = $root.object_type.v1.FormVariable.fromObject(
                object.api[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.calculated = [];
            object.external = [];
            object.api = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.calculated && message.calculated.length) {
            object.calculated = new Array(message.calculated.length);
            for (let i = 0; i < message.calculated.length; ++i) {
              object.calculated[i] = $root.object_type.v1.FormVariable.toObject(
                message.calculated[i],
                options
              );
            }
          }
          if (message.external && message.external.length) {
            object.external = new Array(message.external.length);
            for (let i = 0; i < message.external.length; ++i) {
              object.external[i] = $root.object_type.v1.FormVariable.toObject(
                message.external[i],
                options
              );
            }
          }
          if (message.api && message.api.length) {
            object.api = new Array(message.api.length);
            for (let i = 0; i < message.api.length; ++i) {
              object.api[i] = $root.object_type.v1.FormVariable.toObject(
                message.api[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.FormVariables';
        }
      }

      FormVariables.prototype.calculated = $util.emptyArray;
      FormVariables.prototype.external = $util.emptyArray;
      FormVariables.prototype.api = $util.emptyArray;

      return FormVariables;
    })();

    v1.FormVariable = (() => {
      class FormVariable {
        constructor(properties) {
          this.fields = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormVariable(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            values$1.v1.ValueType.encode(
              message.type,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.fieldLookup != null &&
            Object.hasOwnProperty.call(message, 'fieldLookup')
          ) {
            field_lookup$1.v1.FieldLookup.encode(
              message.fieldLookup,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.fields != null &&
            Object.hasOwnProperty.call(message, 'fields')
          ) {
            for (const element of message.fields) {
              $root.object_type.v1.FormVariable.encode(
                element,
                writer.uint32(34).fork()
              ).ldelim();
            }
          }
          if (
            message.disablePropertySourcingByDefault != null &&
            Object.hasOwnProperty.call(
              message,
              'disablePropertySourcingByDefault'
            )
          ) {
            writer.uint32(40).bool(message.disablePropertySourcingByDefault);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormVariable();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                message.type = values$1.v1.ValueType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.fieldLookup = field_lookup$1.v1.FieldLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                if (!message.fields || !message.fields.length) {
                  message.fields = [];
                }
                message.fields.push(
                  $root.object_type.v1.FormVariable.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 5: {
                message.disablePropertySourcingByDefault = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.FormVariable) {
            return object;
          }
          const message = new $root.object_type.v1.FormVariable();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.object_type.v1.FormVariable.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type = values$1.v1.ValueType.fromObject(object.type);
          }
          if (object.fieldLookup != null) {
            if (typeof object.fieldLookup !== 'object') {
              throw new TypeError(
                '.object_type.v1.FormVariable.fieldLookup: object expected, but got ' +
                  typeof object.fieldLookup
              );
            }
            message.fieldLookup = field_lookup$1.v1.FieldLookup.fromObject(
              object.fieldLookup
            );
          }
          if (object.fields) {
            if (!Array.isArray(object.fields)) {
              throw new TypeError(
                '.object_type.v1.FormVariable.fields: array type expected, but got ' +
                  typeof object.fields
              );
            }
            message.fields = new Array(object.fields.length);
            for (let i = 0; i < object.fields.length; ++i) {
              if (typeof object.fields[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.FormVariable.fields: object expected, but got ' +
                    typeof object.fields[i]
                );
              }
              message.fields[i] = $root.object_type.v1.FormVariable.fromObject(
                object.fields[i]
              );
            }
          }
          if (object.disablePropertySourcingByDefault != null) {
            message.disablePropertySourcingByDefault = Boolean(
              object.disablePropertySourcingByDefault
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.fields = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
            object.type = null;
            object.fieldLookup = null;
            object.disablePropertySourcingByDefault = false;
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = values$1.v1.ValueType.toObject(message.type, options);
          }
          if (
            message.fieldLookup != null &&
            message.hasOwnProperty('fieldLookup')
          ) {
            object.fieldLookup = field_lookup$1.v1.FieldLookup.toObject(
              message.fieldLookup,
              options
            );
          }
          if (message.fields && message.fields.length) {
            object.fields = new Array(message.fields.length);
            for (let i = 0; i < message.fields.length; ++i) {
              object.fields[i] = $root.object_type.v1.FormVariable.toObject(
                message.fields[i],
                options
              );
            }
          }
          if (
            message.disablePropertySourcingByDefault != null &&
            message.hasOwnProperty('disablePropertySourcingByDefault')
          ) {
            object.disablePropertySourcingByDefault =
              message.disablePropertySourcingByDefault;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.FormVariable';
        }
      }

      FormVariable.prototype.label = '';
      FormVariable.prototype.type = null;
      FormVariable.prototype.fieldLookup = null;
      FormVariable.prototype.fields = $util.emptyArray;
      FormVariable.prototype.disablePropertySourcingByDefault = false;

      return FormVariable;
    })();

    v1.AppointmentSchema = (() => {
      class AppointmentSchema {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new AppointmentSchema(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.lengthMinutes != null &&
            Object.hasOwnProperty.call(message, 'lengthMinutes')
          ) {
            writer.uint32(16).uint32(message.lengthMinutes);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new AppointmentSchema();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.lengthMinutes = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.AppointmentSchema) {
            return object;
          }
          const message = new $root.object_type.v1.AppointmentSchema();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.lengthMinutes != null) {
            message.lengthMinutes = object.lengthMinutes >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.lengthMinutes = 0;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.lengthMinutes != null &&
            message.hasOwnProperty('lengthMinutes')
          ) {
            object.lengthMinutes = message.lengthMinutes;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.AppointmentSchema';
        }
      }

      AppointmentSchema.prototype.name = '';
      AppointmentSchema.prototype.lengthMinutes = 0;

      return AppointmentSchema;
    })();

    v1.ObjectGroup = (() => {
      class ObjectGroup {
        constructor(properties) {
          this.members = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ObjectGroup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.label != null &&
            Object.hasOwnProperty.call(message, 'label')
          ) {
            writer.uint32(10).string(message.label);
          }
          if (
            message.membersCount != null &&
            Object.hasOwnProperty.call(message, 'membersCount')
          ) {
            writer.uint32(16).int32(message.membersCount);
          }
          if (
            message.configuration != null &&
            Object.hasOwnProperty.call(message, 'configuration')
          ) {
            event_config$1.v1.EventConfig.encode(
              message.configuration,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.membersCategory != null &&
            Object.hasOwnProperty.call(message, 'membersCategory')
          ) {
            writer.uint32(32).int32(message.membersCategory);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(42).string(message.sourceId);
          }
          if (
            message.members != null &&
            Object.hasOwnProperty.call(message, 'members')
          ) {
            for (const element of message.members) {
              $root.object_type.v1.ObjectTypeSummary.encode(
                element,
                writer.uint32(50).fork()
              ).ldelim();
            }
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(74).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ObjectGroup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.label = reader.string();
                break;
              }
              case 2: {
                message.membersCount = reader.int32();
                break;
              }
              case 3: {
                message.configuration = event_config$1.v1.EventConfig.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.membersCategory = reader.int32();
                break;
              }
              case 5: {
                message.sourceId = reader.string();
                break;
              }
              case 6: {
                if (!message.members || !message.members.length) {
                  message.members = [];
                }
                message.members.push(
                  $root.object_type.v1.ObjectTypeSummary.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 7: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ObjectGroup) {
            return object;
          }
          const message = new $root.object_type.v1.ObjectGroup();
          if (object.label != null) {
            message.label = String(object.label);
          }
          if (object.membersCount != null) {
            message.membersCount = object.membersCount | 0;
          }
          if (object.configuration != null) {
            if (typeof object.configuration !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectGroup.configuration: object expected, but got ' +
                  typeof object.configuration
              );
            }
            message.configuration = event_config$1.v1.EventConfig.fromObject(
              object.configuration
            );
          }
          if (object.membersCategory != null) {
            switch (object.membersCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.membersCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.membersCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.membersCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.membersCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.membersCategory = 4;
                break;
              }
              default: {
                if (typeof object.membersCategory == 'number') {
                  message.membersCategory = object.membersCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.members) {
            if (!Array.isArray(object.members)) {
              throw new TypeError(
                '.object_type.v1.ObjectGroup.members: array type expected, but got ' +
                  typeof object.members
              );
            }
            message.members = new Array(object.members.length);
            for (let i = 0; i < object.members.length; ++i) {
              if (typeof object.members[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.ObjectGroup.members: object expected, but got ' +
                    typeof object.members[i]
                );
              }
              message.members[i] =
                $root.object_type.v1.ObjectTypeSummary.fromObject(
                  object.members[i]
                );
            }
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectGroup.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.object_type.v1.ObjectGroup.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.members = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.label = '';
            object.membersCount = 0;
            object.configuration = null;
            object.membersCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            object.sourceId = '';
            object.createdAt = null;
            object.updatedAt = null;
            object.id = '';
          }
          let keys;
          if (message.label != null && message.hasOwnProperty('label')) {
            object.label = message.label;
          }
          if (
            message.membersCount != null &&
            message.hasOwnProperty('membersCount')
          ) {
            object.membersCount = message.membersCount;
          }
          if (
            message.configuration != null &&
            message.hasOwnProperty('configuration')
          ) {
            object.configuration = event_config$1.v1.EventConfig.toObject(
              message.configuration,
              options
            );
          }
          if (
            message.membersCategory != null &&
            message.hasOwnProperty('membersCategory')
          ) {
            object.membersCategory =
              options.enums === String
                ? sources$1.v1.ObjectCategory[message.membersCategory] ===
                  undefined
                  ? message.membersCategory
                  : sources$1.v1.ObjectCategory[message.membersCategory]
                : message.membersCategory;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (message.members && message.members.length) {
            object.members = new Array(message.members.length);
            for (let i = 0; i < message.members.length; ++i) {
              object.members[i] =
                $root.object_type.v1.ObjectTypeSummary.toObject(
                  message.members[i],
                  options
                );
            }
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ObjectGroup';
        }
      }

      ObjectGroup.prototype.label = '';
      ObjectGroup.prototype.membersCount = 0;
      ObjectGroup.prototype.configuration = null;
      ObjectGroup.prototype.membersCategory = 0;
      ObjectGroup.prototype.sourceId = '';
      ObjectGroup.prototype.members = $util.emptyArray;
      ObjectGroup.prototype.createdAt = null;
      ObjectGroup.prototype.updatedAt = null;
      ObjectGroup.prototype.id = '';

      return ObjectGroup;
    })();

    v1.ListObjectGroupsFilter = (() => {
      class ListObjectGroupsFilter {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectGroupsFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(10).string(message.sourceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectGroupsFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ListObjectGroupsFilter) {
            return object;
          }
          const message = new $root.object_type.v1.ListObjectGroupsFilter();
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ListObjectGroupsFilter';
        }
      }

      ListObjectGroupsFilter.prototype.sourceId = null;

      return ListObjectGroupsFilter;
    })();

    v1.ListObjectGroupsRequest = (() => {
      class ListObjectGroupsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectGroupsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.object_type.v1.ListObjectGroupsFilter.encode(
              message.filter,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.page != null &&
            Object.hasOwnProperty.call(message, 'page')
          ) {
            pagination$1.v1.Pagination.encode(
              message.page,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectGroupsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.filter =
                  $root.object_type.v1.ListObjectGroupsFilter.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.page = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ListObjectGroupsRequest) {
            return object;
          }
          const message = new $root.object_type.v1.ListObjectGroupsRequest();
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.object_type.v1.ListObjectGroupsRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter =
              $root.object_type.v1.ListObjectGroupsFilter.fromObject(
                object.filter
              );
          }
          if (object.page != null) {
            if (typeof object.page !== 'object') {
              throw new TypeError(
                '.object_type.v1.ListObjectGroupsRequest.page: object expected, but got ' +
                  typeof object.page
              );
            }
            message.page = pagination$1.v1.Pagination.fromObject(object.page);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.page = null;
          }
          let keys;
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter =
              $root.object_type.v1.ListObjectGroupsFilter.toObject(
                message.filter,
                options
              );
          }
          if (message.page != null && message.hasOwnProperty('page')) {
            object.page = pagination$1.v1.Pagination.toObject(
              message.page,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ListObjectGroupsRequest';
        }
      }

      ListObjectGroupsRequest.prototype.filter = null;
      ListObjectGroupsRequest.prototype.page = null;

      return ListObjectGroupsRequest;
    })();

    v1.ListObjectGroupsResponse = (() => {
      class ListObjectGroupsResponse {
        constructor(properties) {
          this.groups = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectGroupsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).uint64(message.total);
          }
          if (
            message.groups != null &&
            Object.hasOwnProperty.call(message, 'groups')
          ) {
            for (const element of message.groups) {
              $root.object_type.v1.ObjectGroup.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectGroupsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.uint64();
                break;
              }
              case 2: {
                if (!message.groups || !message.groups.length) {
                  message.groups = [];
                }
                message.groups.push(
                  $root.object_type.v1.ObjectGroup.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.ListObjectGroupsResponse) {
            return object;
          }
          const message = new $root.object_type.v1.ListObjectGroupsResponse();
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.groups) {
            if (!Array.isArray(object.groups)) {
              throw new TypeError(
                '.object_type.v1.ListObjectGroupsResponse.groups: array type expected, but got ' +
                  typeof object.groups
              );
            }
            message.groups = new Array(object.groups.length);
            for (let i = 0; i < object.groups.length; ++i) {
              if (typeof object.groups[i] !== 'object') {
                throw new TypeError(
                  '.object_type.v1.ListObjectGroupsResponse.groups: object expected, but got ' +
                    typeof object.groups[i]
                );
              }
              message.groups[i] = $root.object_type.v1.ObjectGroup.fromObject(
                object.groups[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.groups = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          if (message.groups && message.groups.length) {
            object.groups = new Array(message.groups.length);
            for (let i = 0; i < message.groups.length; ++i) {
              object.groups[i] = $root.object_type.v1.ObjectGroup.toObject(
                message.groups[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.ListObjectGroupsResponse';
        }
      }

      ListObjectGroupsResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListObjectGroupsResponse.prototype.groups = $util.emptyArray;

      return ListObjectGroupsResponse;
    })();

    v1.GetObjectGroupRequest = (() => {
      class GetObjectGroupRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetObjectGroupRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetObjectGroupRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.GetObjectGroupRequest) {
            return object;
          }
          const message = new $root.object_type.v1.GetObjectGroupRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.GetObjectGroupRequest';
        }
      }

      GetObjectGroupRequest.prototype.id = '';

      return GetObjectGroupRequest;
    })();

    v1.GetObjectGroupResponse = (() => {
      class GetObjectGroupResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetObjectGroupResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.group != null &&
            Object.hasOwnProperty.call(message, 'group')
          ) {
            $root.object_type.v1.ObjectGroup.encode(
              message.group,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetObjectGroupResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.group = $root.object_type.v1.ObjectGroup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.object_type.v1.GetObjectGroupResponse) {
            return object;
          }
          const message = new $root.object_type.v1.GetObjectGroupResponse();
          if (object.group != null) {
            if (typeof object.group !== 'object') {
              throw new TypeError(
                '.object_type.v1.GetObjectGroupResponse.group: object expected, but got ' +
                  typeof object.group
              );
            }
            message.group = $root.object_type.v1.ObjectGroup.fromObject(
              object.group
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.group = null;
          }
          let keys;
          if (message.group != null && message.hasOwnProperty('group')) {
            object.group = $root.object_type.v1.ObjectGroup.toObject(
              message.group,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'object_type.v1.GetObjectGroupResponse';
        }
      }

      GetObjectGroupResponse.prototype.group = null;

      return GetObjectGroupResponse;
    })();

    v1.ApplicationType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_APPLICATION_TYPE')] = 0;
      values[(valuesById[1] = 'ACTIVE_CAMPAIGN')] = 1;
      values[(valuesById[2] = 'ACUITY')] = 2;
      return values;
    })();

    v1.ObjectInstanceType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_OBJECT_INSTANCE_TYPE')] = 0;
      values[(valuesById[1] = 'ACTIVE_CAMPAIGN_DEAL')] = 1;
      values[(valuesById[2] = 'ACUITY_APPOINTMENT')] = 2;
      return values;
    })();

    return v1;
  })();

  return object_type;
})();

export const object_type = $root.object_type;
