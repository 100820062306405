import Link from 'next/link';
import { FC } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { IntegrationList } from '../IntegrationList';
import { LetteredAvatar } from '../LetteredAvatar';
import { SkeletonLoadable } from '../Skeleton';
import { Text } from '../Typography';
import { Timestamp } from '../Timestamp';
import { WorkflowCardProps } from './types';
import { WorkflowTag } from '../WorkflowTag/WorkflowTag';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { WorkflowStatus } from '../WorkflowStatus';

export const columnWidths = ['45%', '10%', '20%', '22.5%', '2.5%'];

export const WorkflowCard: FC<WorkflowCardProps> = ({
  id,
  name,
  description,
  tags,
  statuses,
  trigger,
  destinations,
  updatedAt,
  updatedBy,
  link,
  isLoading,
}) => {
  const theme = useTheme();

  return (
    <Link href={link}>
      <Flexbox
        data-testid={`workflow-card-${id}`}
        direction='row'
        backgroundColor={theme.colors.ui.card}
        borderColor={theme.colors.ui.divider}
        width='calc(100% - 0.25rem)'
        alignItems='center'
        borderType='border'
        borderStyle='solid'
        borderRadius={1}
        p={1.25}
        cursor='pointer'
      >
        <Flexbox
          direction='column'
          width={columnWidths[0]}
          height='auto'
          gap={0.5}
        >
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            height='auto'
            width='auto'
            gap={0.5}
          >
            <SkeletonLoadable
              isLoading={isLoading}
              width='15rem'
              height='1.5rem'
            >
              <Text tag='h4'>{name}</Text>
            </SkeletonLoadable>
            <Flexbox
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              height='auto'
              width='auto'
              gap={0.5}
            >
              {statuses.map((status, index) => (
                <WorkflowStatus key={index} status={status} />
              ))}
            </Flexbox>
          </Flexbox>

          <SkeletonLoadable isLoading={isLoading} width='15rem' height='1.5rem'>
            <Text tag='p2'>{description}</Text>
          </SkeletonLoadable>

          {!!tags.length && (
            <Flexbox justifyContent='flex-start' flexWrap='wrap' gap={0.25}>
              {tags.map((tag, index) => (
                <WorkflowTag key={index} name={tag} />
              ))}
            </Flexbox>
          )}
        </Flexbox>

        <Flexbox
          width={columnWidths[1]}
          data-testid='workflow-trigger'
          justifyContent='center'
          alignItems='center'
          height='auto'
        >
          <SkeletonLoadable
            isLoading={isLoading}
            width='1.5rem'
            height='1.5rem'
          >
            <Container pl={0.5}>
              <IntegrationList
                list={
                  trigger
                    ? [
                        {
                          name: trigger,
                        },
                      ]
                    : []
                }
              />
            </Container>
          </SkeletonLoadable>
        </Flexbox>

        <Flexbox
          width={columnWidths[2]}
          data-testid='workflow-destinations'
          justifyContent='center'
          alignItems='center'
          height='auto'
        >
          <SkeletonLoadable isLoading={isLoading} width='10rem' height='1.5rem'>
            <IntegrationList
              list={destinations.map((destination) => ({
                name: destination,
              }))}
            />
          </SkeletonLoadable>
        </Flexbox>

        <Flexbox
          justifyContent='flex-end'
          alignItems='center'
          width={columnWidths[3]}
          height='auto'
        >
          <SkeletonLoadable
            isLoading={isLoading}
            width='10rem'
            height='1.25rem'
          >
            <Timestamp
              tag='p2'
              color={theme.colors.text.muted}
              timestamp={updatedAt}
            />
          </SkeletonLoadable>
          <SkeletonLoadable
            isLoading={isLoading}
            width='1.25rem'
            height='1.25rem'
          >
            <LetteredAvatar
              height='1.25rem'
              name={updatedBy}
              tag='h6'
              width='1.25rem'
            />
          </SkeletonLoadable>
        </Flexbox>

        <Flexbox
          justifyContent='flex-end'
          width={columnWidths[4]}
          height='auto'
        >
          <Icon name='more' cursor='pointer' stroke={theme.colors.text.muted} />
        </Flexbox>
      </Flexbox>
    </Link>
  );
};
