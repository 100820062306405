import { MorfUser } from './types';

export const isAccessGranted = (
  user: MorfUser | undefined,
  organizationId: string,
  pathname: string
): boolean => {
  const allowFullAccessToMorfUser =
    user?.email?.includes('@morf.health') ?? false;

  const allowFullAccessToNemaStaging =
    user?.email === 'chipper@nemahealth.com' &&
    organizationId === '8f5752d2-b315-4c2b-9d34-77c1e352cb9b';

  const allowPartialAccessToProvidersPath = pathname.endsWith('/providers');

  return (
    allowFullAccessToMorfUser ||
    allowFullAccessToNemaStaging ||
    allowPartialAccessToProvidersPath
  );
};
