import { FC, memo } from 'react';
import { useTheme } from '@morf/theming';
import { SnapshotIconProps } from './types';
import { Tooltip } from '../../../../Tooltip';
import { Icon } from '../../../../Icon';
import { useHover } from '../../../../Hooks/useHover';

const SnapshotIcon: FC<SnapshotIconProps> = ({
  name,
  tooltipText,
  isActive = true,
  arrowDirection = 'center',
  ...props
}) => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  return (
    <Tooltip
      tooltipDirection='bottom'
      arrowDirection={arrowDirection}
      tooltipText={tooltipText}
      isActive={isActive}
    >
      <Icon
        cursor='pointer'
        name={name}
        size={1.25}
        stroke={
          isHovered ? theme.colors.main.primary.lighter : theme.colors.ui.dark
        }
        strokeWidth={1.75}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      />
    </Tooltip>
  );
};

export const MemoizedSnapshotIcon = memo(SnapshotIcon);
