import { FC, memo, useRef } from 'react';
import { LogTimelineProps } from './types';
import { Flexbox } from '../Flexbox';
import { LogTimelineRow } from '../LogTimelineRow';

const LogTimeline: FC<LogTimelineProps> = ({
  fetchNextPage,
  logTimelineRows,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerElement = containerRef.current;

  const handleScroll = () => {
    if (
      containerElement &&
      containerElement.scrollHeight - containerElement.scrollTop <=
        containerElement.clientHeight
    ) {
      fetchNextPage();
    }
  };

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='log-timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      overflow='scroll'
      gap={0}
      onScroll={handleScroll}
    >
      {logTimelineRows.map((logTimelineRow, index) => (
        <LogTimelineRow
          key={index}
          {...logTimelineRow}
          tooltipDirection={index <= 1 ? 'bottom' : 'top'}
        />
      ))}
    </Flexbox>
  );
};

export const MemoizedLogTimeline = memo(LogTimeline);
