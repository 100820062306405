import { values } from '@morf/proto/values_v1_ts_proto';

export const objectValueToString = (
  objectValue: values.v1.IValue['objectValue'] | null | undefined
): string => {
  if (objectValue) {
    return JSON.stringify(objectValue);
  }
  return 'n/a';
};
