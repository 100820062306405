'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { acuity as acuity$1 } from './acuity_v1_ts_proto';
import { healthie as healthie$1 } from './healthie_v1_ts_proto';
import { sources as sources$1 } from './sources_v1_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.error_events = (() => {
  const error_events = {};
  error_events.v1 = (() => {
    const v1 = {};
    v1.ErrorEventOrganization = (() => {
      class ErrorEventOrganization {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ErrorEventOrganization(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ErrorEventOrganization();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.error_events.v1.ErrorEventOrganization) {
            return object;
          }
          const message = new $root.error_events.v1.ErrorEventOrganization();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'error_events.v1.ErrorEventOrganization';
        }
      }

      ErrorEventOrganization.prototype.id = '';
      ErrorEventOrganization.prototype.name = '';

      return ErrorEventOrganization;
    })();

    v1.ErrorEvent = (() => {
      class ErrorEvent {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ErrorEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.source != null &&
            Object.hasOwnProperty.call(message, 'source')
          ) {
            $root.error_events.v1.ErrorEventSource.encode(
              message.source,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.timestamp != null &&
            Object.hasOwnProperty.call(message, 'timestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.timestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.severity != null &&
            Object.hasOwnProperty.call(message, 'severity')
          ) {
            writer.uint32(24).int32(message.severity);
          }
          if (
            message.responsibility != null &&
            Object.hasOwnProperty.call(message, 'responsibility')
          ) {
            writer.uint32(32).int32(message.responsibility);
          }
          if (
            message.organization != null &&
            Object.hasOwnProperty.call(message, 'organization')
          ) {
            $root.error_events.v1.ErrorEventOrganization.encode(
              message.organization,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.patientId != null &&
            Object.hasOwnProperty.call(message, 'patientId')
          ) {
            writer.uint32(50).string(message.patientId);
          }
          if (
            message.requestId != null &&
            Object.hasOwnProperty.call(message, 'requestId')
          ) {
            writer.uint32(58).string(message.requestId);
          }
          if (
            message.propertyName != null &&
            Object.hasOwnProperty.call(message, 'propertyName')
          ) {
            writer.uint32(66).string(message.propertyName);
          }
          if (
            message.message != null &&
            Object.hasOwnProperty.call(message, 'message')
          ) {
            writer.uint32(74).string(message.message);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ErrorEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.source = $root.error_events.v1.ErrorEventSource.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.timestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.severity = reader.int32();
                break;
              }
              case 4: {
                message.responsibility = reader.int32();
                break;
              }
              case 5: {
                message.organization =
                  $root.error_events.v1.ErrorEventOrganization.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.patientId = reader.string();
                break;
              }
              case 7: {
                message.requestId = reader.string();
                break;
              }
              case 8: {
                message.propertyName = reader.string();
                break;
              }
              case 9: {
                message.message = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.error_events.v1.ErrorEvent) {
            return object;
          }
          const message = new $root.error_events.v1.ErrorEvent();
          if (object.source != null) {
            if (typeof object.source !== 'object') {
              throw new TypeError(
                '.error_events.v1.ErrorEvent.source: object expected, but got ' +
                  typeof object.source
              );
            }
            message.source = $root.error_events.v1.ErrorEventSource.fromObject(
              object.source
            );
          }
          if (object.timestamp != null) {
            if (typeof object.timestamp !== 'object') {
              throw new TypeError(
                '.error_events.v1.ErrorEvent.timestamp: object expected, but got ' +
                  typeof object.timestamp
              );
            }
            message.timestamp = timing$1.v1.Timestamp.fromObject(
              object.timestamp
            );
          }
          if (object.severity != null) {
            switch (object.severity) {
              case 'SEVERITY_UNSPECIFIED':
              case 0: {
                message.severity = 0;
                break;
              }
              case 'LOW':
              case 1: {
                message.severity = 1;
                break;
              }
              case 'URGENT':
              case 2: {
                message.severity = 2;
                break;
              }
              case 'HIGHEST':
              case 3: {
                message.severity = 3;
                break;
              }
              default: {
                if (typeof object.severity == 'number') {
                  message.severity = object.severity;
                  break;
                }
                break;
              }
            }
          }
          if (object.responsibility != null) {
            switch (object.responsibility) {
              case 'RESPONSIBILITY_UNSPECIFIED':
              case 0: {
                message.responsibility = 0;
                break;
              }
              case 'MORF':
              case 1: {
                message.responsibility = 1;
                break;
              }
              case 'THIRD_PARTY':
              case 2: {
                message.responsibility = 2;
                break;
              }
              case 'CUSTOMER':
              case 3: {
                message.responsibility = 3;
                break;
              }
              case 'UNKNOWN':
              case 4: {
                message.responsibility = 4;
                break;
              }
              default: {
                if (typeof object.responsibility == 'number') {
                  message.responsibility = object.responsibility;
                  break;
                }
                break;
              }
            }
          }
          if (object.organization != null) {
            if (typeof object.organization !== 'object') {
              throw new TypeError(
                '.error_events.v1.ErrorEvent.organization: object expected, but got ' +
                  typeof object.organization
              );
            }
            message.organization =
              $root.error_events.v1.ErrorEventOrganization.fromObject(
                object.organization
              );
          }
          if (object.patientId != null) {
            message.patientId = String(object.patientId);
          }
          if (object.requestId != null) {
            message.requestId = String(object.requestId);
          }
          if (object.propertyName != null) {
            message.propertyName = String(object.propertyName);
          }
          if (object.message != null) {
            message.message = String(object.message);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.source = null;
            object.timestamp = null;
            object.severity =
              options.enums === String ? 'SEVERITY_UNSPECIFIED' : 0;
            object.responsibility =
              options.enums === String ? 'RESPONSIBILITY_UNSPECIFIED' : 0;
            object.organization = null;
            object.message = '';
          }
          let keys;
          if (message.source != null && message.hasOwnProperty('source')) {
            object.source = $root.error_events.v1.ErrorEventSource.toObject(
              message.source,
              options
            );
          }
          if (
            message.timestamp != null &&
            message.hasOwnProperty('timestamp')
          ) {
            object.timestamp = timing$1.v1.Timestamp.toObject(
              message.timestamp,
              options
            );
          }
          if (message.severity != null && message.hasOwnProperty('severity')) {
            object.severity =
              options.enums === String
                ? $root.error_events.v1.ErrorEventSeverity[message.severity] ===
                  undefined
                  ? message.severity
                  : $root.error_events.v1.ErrorEventSeverity[message.severity]
                : message.severity;
          }
          if (
            message.responsibility != null &&
            message.hasOwnProperty('responsibility')
          ) {
            object.responsibility =
              options.enums === String
                ? $root.error_events.v1.ErrorEventResponsibility[
                    message.responsibility
                  ] === undefined
                  ? message.responsibility
                  : $root.error_events.v1.ErrorEventResponsibility[
                      message.responsibility
                    ]
                : message.responsibility;
          }
          if (
            message.organization != null &&
            message.hasOwnProperty('organization')
          ) {
            object.organization =
              $root.error_events.v1.ErrorEventOrganization.toObject(
                message.organization,
                options
              );
          }
          if (
            message.patientId != null &&
            message.hasOwnProperty('patientId')
          ) {
            object.patientId = message.patientId;
          }
          if (
            message.requestId != null &&
            message.hasOwnProperty('requestId')
          ) {
            object.requestId = message.requestId;
          }
          if (
            message.propertyName != null &&
            message.hasOwnProperty('propertyName')
          ) {
            object.propertyName = message.propertyName;
          }
          if (message.message != null && message.hasOwnProperty('message')) {
            object.message = message.message;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'error_events.v1.ErrorEvent';
        }
      }

      ErrorEvent.prototype.source = null;
      ErrorEvent.prototype.timestamp = null;
      ErrorEvent.prototype.severity = 0;
      ErrorEvent.prototype.responsibility = 0;
      ErrorEvent.prototype.organization = null;
      ErrorEvent.prototype.patientId = null;
      ErrorEvent.prototype.requestId = null;
      ErrorEvent.prototype.propertyName = null;
      ErrorEvent.prototype.message = '';

      return ErrorEvent;
    })();

    v1.ErrorEventSource = (() => {
      class ErrorEventSource {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ErrorEventSource(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(8).int32(message.application);
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(16).int32(message.eventType);
          }
          if (
            message.healthieEventType != null &&
            Object.hasOwnProperty.call(message, 'healthieEventType')
          ) {
            writer.uint32(24).int32(message.healthieEventType);
          }
          if (
            message.acuityEventType != null &&
            Object.hasOwnProperty.call(message, 'acuityEventType')
          ) {
            writer.uint32(32).int32(message.acuityEventType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ErrorEventSource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.eventType = reader.int32();
                break;
              }
              case 3: {
                message.healthieEventType = reader.int32();
                break;
              }
              case 4: {
                message.acuityEventType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.error_events.v1.ErrorEventSource) {
            return object;
          }
          const message = new $root.error_events.v1.ErrorEventSource();
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE_SANDBOX':
              case 2: {
                message.application = 2;
                break;
              }
              case 'HEALTHIE_PRODUCTION':
              case 3: {
                message.application = 3;
                break;
              }
              case 'AXLE_HEALTH_DEVELOPMENT':
              case 4: {
                message.application = 4;
                break;
              }
              case 'AXLE_HEALTH_PRODUCTION':
              case 5: {
                message.application = 5;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 6: {
                message.application = 6;
                break;
              }
              case 'MORF':
              case 7: {
                message.application = 7;
                break;
              }
              case 'PUSH_JSON':
              case 8: {
                message.application = 8;
                break;
              }
              case 'ACUITY':
              case 9: {
                message.application = 9;
                break;
              }
              case 'VITAL_SANDBOX':
              case 10: {
                message.application = 10;
                break;
              }
              case 'VITAL_PRODUCTION':
              case 11: {
                message.application = 11;
                break;
              }
              case 'SEGMENT':
              case 12: {
                message.application = 12;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 13: {
                message.application = 13;
                break;
              }
              case 'INTAKEQ':
              case 14: {
                message.application = 14;
                break;
              }
              case 'CUSTOMER_IO':
              case 15: {
                message.application = 15;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.eventType != null) {
            switch (object.eventType) {
              case 'UNSPECIFIED_EVENT_TYPE':
              case 0: {
                message.eventType = 0;
                break;
              }
              case 'FORMSORT_FORM_RESPONSE_UPDATED':
              case 1: {
                message.eventType = 1;
                break;
              }
              case 'FORMSORT_FORM_UPDATED':
              case 2: {
                message.eventType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.eventType = 3;
                break;
              }
              case 'AXLE_HEALTH_LAB_ORDER':
              case 4: {
                message.eventType = 4;
                break;
              }
              case 'BUTTERFLY_LABS_LAB_ORDER':
              case 5: {
                message.eventType = 5;
                break;
              }
              case 'MIGRATION':
              case 6: {
                message.eventType = 6;
                break;
              }
              case 'MORF_API_CALL':
              case 7: {
                message.eventType = 7;
                break;
              }
              case 'MORF_APPOINTMENT_UPCOMING':
              case 8: {
                message.eventType = 8;
                break;
              }
              case 'MORF_APPOINTMENT_ENDED':
              case 9: {
                message.eventType = 9;
                break;
              }
              case 'CUSTOM_PAYLOAD':
              case 10: {
                message.eventType = 10;
                break;
              }
              case 'ACUITY_APPOINTMENT':
              case 11: {
                message.eventType = 11;
                break;
              }
              case 'VITAL_LAB_ORDER':
              case 12: {
                message.eventType = 12;
                break;
              }
              case 'SEGMENT_EVENT':
              case 13: {
                message.eventType = 13;
                break;
              }
              case 'ACTIVE_CAMPAIGN_WEBHOOK':
              case 14: {
                message.eventType = 14;
                break;
              }
              case 'INTAKEQ_APPOINTMENT':
              case 15: {
                message.eventType = 15;
                break;
              }
              case 'CUSTOMER_IO_EVENT':
              case 16: {
                message.eventType = 16;
                break;
              }
              default: {
                if (typeof object.eventType == 'number') {
                  message.eventType = object.eventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.healthieEventType != null) {
            switch (object.healthieEventType) {
              case 'UNSPECIFIED_ILLEGAL_HEALTHIE_EVENT_TYPE':
              case 0: {
                message.healthieEventType = 0;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_SCHEDULED':
              case 1: {
                message.healthieEventType = 1;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_UPDATED':
              case 2: {
                message.healthieEventType = 2;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_DELETED':
              case 3: {
                message.healthieEventType = 3;
                break;
              }
              case 'HEALTHIE_BILLING_ITEM_CREATED':
              case 4: {
                message.healthieEventType = 4;
                break;
              }
              case 'HEALTHIE_BILLING_ITEM_UPDATED':
              case 5: {
                message.healthieEventType = 5;
                break;
              }
              case 'HEALTHIE_CMS1500_CREATED':
              case 6: {
                message.healthieEventType = 6;
                break;
              }
              case 'HEALTHIE_CMS1500_UPDATED':
              case 7: {
                message.healthieEventType = 7;
                break;
              }
              case 'HEALTHIE_CMS1500_DELETED':
              case 8: {
                message.healthieEventType = 8;
                break;
              }
              case 'HEALTHIE_COMMENT_CREATED':
              case 9: {
                message.healthieEventType = 9;
                break;
              }
              case 'HEALTHIE_COMMENT_UPDATED':
              case 10: {
                message.healthieEventType = 10;
                break;
              }
              case 'HEALTHIE_COMMENT_DELETED':
              case 11: {
                message.healthieEventType = 11;
                break;
              }
              case 'HEALTHIE_CONVERSATION_MEMBERSHIP_VIEWED':
              case 12: {
                message.healthieEventType = 12;
                break;
              }
              case 'HEALTHIE_DOCUMENT_CREATED':
              case 13: {
                message.healthieEventType = 13;
                break;
              }
              case 'HEALTHIE_DOCUMENT_UPDATED':
              case 14: {
                message.healthieEventType = 14;
                break;
              }
              case 'HEALTHIE_DOCUMENT_DELETED':
              case 15: {
                message.healthieEventType = 15;
                break;
              }
              case 'HEALTHIE_ENTRY_CREATED':
              case 16: {
                message.healthieEventType = 16;
                break;
              }
              case 'HEALTHIE_ENTRY_UPDATED':
              case 17: {
                message.healthieEventType = 17;
                break;
              }
              case 'HEALTHIE_ENTRY_DELETED':
              case 18: {
                message.healthieEventType = 18;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_CREATED':
              case 19: {
                message.healthieEventType = 19;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_DELETED':
              case 20: {
                message.healthieEventType = 20;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_LOCKED':
              case 21: {
                message.healthieEventType = 21;
                break;
              }
              case 'HEALTHIE_FORM_ANSWER_GROUP_SIGNED':
              case 22: {
                message.healthieEventType = 22;
                break;
              }
              case 'HEALTHIE_GOAL_CREATED':
              case 23: {
                message.healthieEventType = 23;
                break;
              }
              case 'HEALTHIE_GOAL_UPDATED':
              case 24: {
                message.healthieEventType = 24;
                break;
              }
              case 'HEALTHIE_GOAL_DELETED':
              case 25: {
                message.healthieEventType = 25;
                break;
              }
              case 'HEALTHIE_GOAL_HISTORY_CREATED':
              case 26: {
                message.healthieEventType = 26;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_CREATED':
              case 27: {
                message.healthieEventType = 27;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_UPDATED':
              case 28: {
                message.healthieEventType = 28;
                break;
              }
              case 'HEALTHIE_INSURANCE_AUTHORIZATION_DELETED':
              case 29: {
                message.healthieEventType = 29;
                break;
              }
              case 'HEALTHIE_MESSAGE_CREATED':
              case 30: {
                message.healthieEventType = 30;
                break;
              }
              case 'HEALTHIE_MESSAGE_DELETED':
              case 31: {
                message.healthieEventType = 31;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_CREATED':
              case 32: {
                message.healthieEventType = 32;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_UPDATED':
              case 33: {
                message.healthieEventType = 33;
                break;
              }
              case 'HEALTHIE_METRIC_ENTRY_DELETED':
              case 34: {
                message.healthieEventType = 34;
                break;
              }
              case 'HEALTHIE_PATIENT_CREATED':
              case 35: {
                message.healthieEventType = 35;
                break;
              }
              case 'HEALTHIE_PATIENT_UPDATED':
              case 36: {
                message.healthieEventType = 36;
                break;
              }
              case 'HEALTHIE_POLICY_CREATED':
              case 37: {
                message.healthieEventType = 37;
                break;
              }
              case 'HEALTHIE_POLICY_UPDATED':
              case 38: {
                message.healthieEventType = 38;
                break;
              }
              case 'HEALTHIE_POLICY_DELETED':
              case 39: {
                message.healthieEventType = 39;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_CREATED':
              case 40: {
                message.healthieEventType = 40;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_UPDATED':
              case 41: {
                message.healthieEventType = 41;
                break;
              }
              case 'HEALTHIE_REQUESTED_FORM_COMPLETION_DELETED':
              case 42: {
                message.healthieEventType = 42;
                break;
              }
              case 'HEALTHIE_TASK_CREATED':
              case 43: {
                message.healthieEventType = 43;
                break;
              }
              case 'HEALTHIE_TASK_UPDATED':
              case 44: {
                message.healthieEventType = 44;
                break;
              }
              case 'HEALTHIE_TASK_DELETED':
              case 45: {
                message.healthieEventType = 45;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_MARKED_OCCURRED':
              case 46: {
                message.healthieEventType = 46;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_NO_SHOW':
              case 47: {
                message.healthieEventType = 47;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_RESCHEDULED':
              case 48: {
                message.healthieEventType = 48;
                break;
              }
              case 'HEALTHIE_APPOINTMENT_CANCELLED':
              case 49: {
                message.healthieEventType = 49;
                break;
              }
              default: {
                if (typeof object.healthieEventType == 'number') {
                  message.healthieEventType = object.healthieEventType;
                  break;
                }
                break;
              }
            }
          }
          if (object.acuityEventType != null) {
            switch (object.acuityEventType) {
              case 'UNSPECIFIED_ILLEGAL_ACUITY_EVENT_TYPE':
              case 0: {
                message.acuityEventType = 0;
                break;
              }
              case 'ACUITY_APPOINTMENT_SCHEDULED':
              case 1: {
                message.acuityEventType = 1;
                break;
              }
              case 'ACUITY_APPOINTMENT_RESCHEDULED':
              case 2: {
                message.acuityEventType = 2;
                break;
              }
              case 'ACUITY_APPOINTMENT_CANCELLED':
              case 3: {
                message.acuityEventType = 3;
                break;
              }
              case 'ACUITY_APPOINTMENT_UPDATED':
              case 4: {
                message.acuityEventType = 4;
                break;
              }
              case 'ACUITY_APPOINTMENT_NO_SHOW':
              case 5: {
                message.acuityEventType = 5;
                break;
              }
              default: {
                if (typeof object.acuityEventType == 'number') {
                  message.acuityEventType = object.acuityEventType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.eventType =
              options.enums === String ? 'UNSPECIFIED_EVENT_TYPE' : 0;
          }
          let keys;
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? sources$1.v1.SourceApplication[message.application] ===
                  undefined
                  ? message.application
                  : sources$1.v1.SourceApplication[message.application]
                : message.application;
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType =
              options.enums === String
                ? sources$1.v1.EventType[message.eventType] === undefined
                  ? message.eventType
                  : sources$1.v1.EventType[message.eventType]
                : message.eventType;
          }
          if (
            message.healthieEventType != null &&
            message.hasOwnProperty('healthieEventType')
          ) {
            object.healthieEventType =
              options.enums === String
                ? healthie$1.v1.HealthieEventType[message.healthieEventType] ===
                  undefined
                  ? message.healthieEventType
                  : healthie$1.v1.HealthieEventType[message.healthieEventType]
                : message.healthieEventType;
          }
          if (
            message.acuityEventType != null &&
            message.hasOwnProperty('acuityEventType')
          ) {
            object.acuityEventType =
              options.enums === String
                ? acuity$1.v1.AcuityEventType[message.acuityEventType] ===
                  undefined
                  ? message.acuityEventType
                  : acuity$1.v1.AcuityEventType[message.acuityEventType]
                : message.acuityEventType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'error_events.v1.ErrorEventSource';
        }
      }

      ErrorEventSource.prototype.application = 0;
      ErrorEventSource.prototype.eventType = 0;
      ErrorEventSource.prototype.healthieEventType = null;
      ErrorEventSource.prototype.acuityEventType = null;

      return ErrorEventSource;
    })();

    v1.ErrorEventSeverity = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'SEVERITY_UNSPECIFIED')] = 0;
      values[(valuesById[1] = 'LOW')] = 1;
      values[(valuesById[2] = 'URGENT')] = 2;
      values[(valuesById[3] = 'HIGHEST')] = 3;
      return values;
    })();

    v1.ErrorEventResponsibility = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'RESPONSIBILITY_UNSPECIFIED')] = 0;
      values[(valuesById[1] = 'MORF')] = 1;
      values[(valuesById[2] = 'THIRD_PARTY')] = 2;
      values[(valuesById[3] = 'CUSTOMER')] = 3;
      values[(valuesById[4] = 'UNKNOWN')] = 4;
      return values;
    })();

    return v1;
  })();

  return error_events;
})();

export const error_events = $root.error_events;
