import { css, styled } from '@morf/theming';
import { Flexbox } from '../Flexbox';
import { BadgeProps } from './types';

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Inactive = css(
  ({ theme }) => `
      background-color: ${theme.colors.ui.body};
      color: ${theme.colors.text.muted};
    `
);

const Alert = css(
  ({ theme }) => `
    background-color: ${hexToRgba(theme.colors.support.red.darkest, 0.1)};
    color: ${theme.colors.support.red.darkest};
      `
);

const Warning = css(
  ({ theme }) => `
    background-color:  ${hexToRgba(theme.colors.support.yellow.darkest, 0.1)};
    color: ${theme.colors.support.yellow.darkest};
      `
);

const Success = css(
  ({ theme }) => `
    background-color:  ${hexToRgba(theme.colors.support.green.darkest, 0.1)};
    color: ${theme.colors.support.green.darkest};
  `
);

const Live = css(
  ({ theme }) => `
    background-color:  ${hexToRgba(theme.colors.main.primary.darker, 0.1)};
    color:${theme.colors.main.primary.darker};
      `
);

const handleType = (type: BadgeProps['type']) => {
  switch (type) {
    case 'inactive':
      return Inactive;
    case 'alert':
      return Alert;
    case 'warning':
      return Warning;
    case 'success':
      return Success;
    case 'live':
      return Live;
  }
};

export const StyledBadge = styled(Flexbox)<{ type: BadgeProps['type'] }>`
  ${(props) => handleType(props.type)};
`;
