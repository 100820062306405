import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { IntegrationIcon } from '../IntegrationIcon';
import { LinkedAccountProps } from './types';
import { SkeletonLoadable } from '../Skeleton';
import { Text } from '../Typography';
import { Timestamp } from '../Timestamp';
import { berrySt } from '../IntegrationIcon/IntergrationIconMapping';
import { useTheme } from '@morf/theming';
import { useUrlParams } from '../../../apps/admin/components/helpers/useUrlParams';

const LinkedAccount: FC<LinkedAccountProps> = ({
  associatedAt,
  id,
  integrationIconName,
  isLoading,
  name,
  type = 'vertical',
  mergedIds,
}) => {
  const theme = useTheme();
  const { organizationId } = useUrlParams();

  const udpatedName =
    organizationId && berrySt.includes(organizationId) && name === 'Customer Io'
      ? 'Appointment Reminder System'
      : name;

  return (
    <Flexbox
      data-testid='linked-account'
      direction={type === 'vertical' ? 'column' : 'row'}
      alignItems={type === 'vertical' ? 'flex-start' : 'center'}
      justifyContent='flex-start'
      width='20rem'
      height='auto'
      backgroundColor={theme.colors.ui.card}
      gap={0.5}
    >
      <SkeletonLoadable isLoading={isLoading} height='2rem' width='2rem'>
        <IntegrationIcon name={integrationIconName} size={2} />
      </SkeletonLoadable>
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
      >
        <SkeletonLoadable isLoading={isLoading} height='1.25rem'>
          <Text tag='h5'>{udpatedName}</Text>
        </SkeletonLoadable>

        <SkeletonLoadable isLoading={isLoading} height='1.25rem' width='12rem'>
          <Text tag='p2' whiteSpace='nowrap'>
            {id}
          </Text>
        </SkeletonLoadable>
        <SkeletonLoadable isLoading={isLoading} height='1rem' width='12rem'>
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            height='auto'
            gap={0.25}
          >
            <Text tag='p3' color={theme.colors.text.muted}>
              Associated at:
            </Text>
            <Timestamp
              tag='p3'
              timestamp={associatedAt}
              color={theme.colors.text.muted}
            />
          </Flexbox>
        </SkeletonLoadable>
        {!!mergedIds.length && (
          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            gap={0.25}
            pt={1}
          >
            <Text tag='h6'>Merged IDs:</Text>
            {mergedIds.map(({ id, associatedAt }) => (
              <Flexbox
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                gap={0}
                height='auto'
                width='auto'
              >
                <Text tag='p2' whiteSpace='nowrap'>
                  {id}
                </Text>
                <Flexbox
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  height='auto'
                  gap={0.25}
                >
                  <Text tag='p3' color={theme.colors.text.muted}>
                    Associated at:
                  </Text>
                  <Timestamp
                    tag='p3'
                    timestamp={associatedAt}
                    color={theme.colors.text.muted}
                  />
                </Flexbox>
              </Flexbox>
            ))}
          </Flexbox>
        )}
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedLinkedAccount = memo(LinkedAccount);
