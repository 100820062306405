import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import rpcService from '@morf/server/browser-server';

export const getActionFieldObjectTypeIDs = async (
  request: workflows.v1.GetActionFieldObjectTypeIDsRequest
) => {
  try {
    return await rpcService.workflowsV1Service.getActionFieldObjectTypeIDs(
      request
    );
  } catch (error) {
    throw new Error('getActionFieldObjectTypeIDs: ' + error);
  }
};
