import { Container } from '../../../../Container';
import { FC, memo, useEffect, useState } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { InsetTable } from '../../../../InsetTable';
import { MemoizedProfileUpdateMergeEmailAddress } from './ProfileUpdateMergeEmailAddress/ProfileUpdateMergeEmailAddress';
import { MemoizedProfileUpdateMergePhoneNumber } from './ProfileUpdateMergePhoneNumber/ProfileUpdateMergePhoneNumber';
import { ProfileUpdateNodeProps } from '../types';
import { SelectValuePairCategory } from '../../DestinationActionNode/DestinationActionConfigure/SelectValuePairModal/types';
import { SelectValuePairModal } from '../../DestinationActionNode/DestinationActionConfigure/SelectValuePairModal';
import { columns } from './columns';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { formatProfileUpdateIdentifiers } from './formatProfileUpdateIdentifiers';
import { google } from '@morf/proto/checked_ts_proto';
import { handleNodeSave } from '../handleNodeSave';
import { useWorkflow } from '../../../../../../apps/admin/components/context/workflow/useWorkflow';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const ProfileUpdateIdentifiers: FC<ProfileUpdateNodeProps> = (node) => {
  const id = node.id;
  const {
    name,
    description,
    configuration,
    onAddNode,
    onUpdateNode,
    onCreateCustomProperty,
    isReadOnly,
    isRequired,
  } = node.data.value;

  const { nodes, setNodes } = useWorkflow();

  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<number | null>(null);

  const updateProfileProperties =
    configuration?.morfV1?.updateProfileProperties;

  const formattedIdentifiers = formatProfileUpdateIdentifiers(
    updateProfileProperties
  );

  const immutableSources = [
    updateProfileProperties?.emailAddressUpdateSource,
    updateProfileProperties?.phoneNumberUpdateSource,
    updateProfileProperties?.firstNameUpdateSource,
    updateProfileProperties?.lastNameUpdateSource,
  ];

  const allSources = immutableSources.concat(
    updateProfileProperties?.thirdPartyProfileIdUpdateSources?.map(
      (source) => source.source
    )
  );

  const selectedThirdPartyProfileIdRow =
    selectedRow !== null && selectedRow - immutableSources.length >= 0
      ? selectedRow - immutableSources.length
      : null;

  const isNewThirdPartyProfileIdCell =
    selectedThirdPartyProfileIdRow !== null &&
    selectedColumn === 0 &&
    !formattedIdentifiers[selectedRow!]?.param?.fieldName;

  const isSourceCell = selectedRow !== null && selectedColumn === 1;

  const selectedParam = isSourceCell
    ? new workflow_parameters.v1.DestinationActionParameter({
        static: new workflow_parameters.v1.StaticDestinationActionParameter({
          fieldName: 'identifier',
          fieldType:
            new workflow_parameters.v1.StaticDestinationActionParameterType({
              primitiveType: google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
            }),
          sensitivity: [],
          isRequired: false,
        }),
      })
    : null;

  const selectedSource =
    isSourceCell && allSources[selectedRow]
      ? new workflow_parameters.v1.DestinationActionParameterSource({
          ...allSources[selectedRow],
        })
      : null;

  const handleThirdPartyProfileIdSave = (
    updatedIdentifiers: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration.morfV1?.updateProfileProperties,
              thirdPartyProfileIdUpdateSources:
                configuration.morfV1?.updateProfileProperties?.thirdPartyProfileIdUpdateSources?.map(
                  (thirdPartyProfileIdUpdateSource, index) =>
                    index === selectedThirdPartyProfileIdRow
                      ? new destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping(
                          {
                            ...thirdPartyProfileIdUpdateSource,
                            type: updatedIdentifiers[0].profileIdentifier,
                          }
                        )
                      : thirdPartyProfileIdUpdateSource
                ),
            }),
        }),
      });

      handleNodeSave(destinationAction, id, nodes, setNodes, onUpdateNode);
    }
  };

  const handleSourceSave = (
    updatedSources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration.morfV1?.updateProfileProperties,
              thirdPartyProfileIdUpdateSources:
                configuration.morfV1?.updateProfileProperties?.thirdPartyProfileIdUpdateSources?.map(
                  (thirdPartyProfileIdUpdateSource, index) =>
                    index === selectedThirdPartyProfileIdRow
                      ? new destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping(
                          {
                            ...thirdPartyProfileIdUpdateSource,
                            source: updatedSources[0],
                          }
                        )
                      : thirdPartyProfileIdUpdateSource
                ),
              ...(selectedRow === 0 && {
                emailAddressUpdateSource: updatedSources[0],
              }),
              ...(selectedRow === 1 && {
                phoneNumberUpdateSource: updatedSources[0],
              }),
              ...(selectedRow === 2 && {
                firstNameUpdateSource: updatedSources[0],
              }),
              ...(selectedRow === 3 && {
                lastNameUpdateSource: updatedSources[0],
              }),
            }),
        }),
      });
      handleNodeSave(destinationAction, id, nodes, setNodes, onUpdateNode);
    }
  };

  const handleRowAdd = () => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration?.morfV1?.updateProfileProperties,
              thirdPartyProfileIdUpdateSources: [
                ...(configuration?.morfV1?.updateProfileProperties
                  ?.thirdPartyProfileIdUpdateSources || []),
                new destinationactions.morf.v1.UpdateProfileProperties.ThirdPartyProfileIDMapping(
                  {
                    type: undefined,
                    source:
                      new workflow_parameters.v1.DestinationActionParameterSource(
                        {
                          elidedValue: {},
                        }
                      ),
                  }
                ),
              ],
            }),
        }),
      });

      handleNodeSave(destinationAction, id, nodes, setNodes, onUpdateNode);
    }
  };

  const handleRowRemove = (rowId: number) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration?.morfV1?.updateProfileProperties,
              thirdPartyProfileIdUpdateSources:
                configuration?.morfV1?.updateProfileProperties?.thirdPartyProfileIdUpdateSources.filter(
                  (_, index) => index !== rowId - immutableSources.length
                ),
            }),
        }),
      });
      handleNodeSave(destinationAction, id, nodes, setNodes, onUpdateNode);
    }
  };

  const handleClose = () => setSelectedRow(null);

  useEffect(() => {
    if (isRequired) {
      onAddNode &&
        onAddNode({
          node: new workflows.v1.CreateWorkflowNodeRequest({
            id: id,
            name: name,
            description: description,
            destinationAction: configuration,
          }),
        });
    }
  }, [isRequired]);

  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <MemoizedProfileUpdateMergeEmailAddress {...node} />
      <MemoizedProfileUpdateMergePhoneNumber {...node} />
      <Container>
        <InsetTable
          columns={columns}
          data={formattedIdentifiers}
          emptyText='There are no profile identifiers!'
          enableRowSelection={!isReadOnly}
          isFetching={false}
          isLoading={false}
          selectedRow={selectedRow}
          onColumnClick={setSelectedColumn}
          onRowClick={setSelectedRow}
          onRowHover={setHoveredRow}
          totalCount={formattedIdentifiers.length}
          totalFetched={formattedIdentifiers.length}
          {...(!isReadOnly && { onRowAdd: handleRowAdd })}
          {...(hoveredRow !== null &&
            hoveredRow >= immutableSources.length && {
              onRowRemove: handleRowRemove,
            })}
        />
      </Container>

      {isNewThirdPartyProfileIdCell && onCreateCustomProperty && (
        <SelectValuePairModal
          onClick={handleThirdPartyProfileIdSave}
          onClose={handleClose}
          onCreateCustomProperty={onCreateCustomProperty}
          selectedSource={null}
          visibleCategories={[SelectValuePairCategory.Identifiers]}
        />
      )}

      {isSourceCell && onCreateCustomProperty && (
        <SelectValuePairModal
          onClick={handleSourceSave}
          onClose={handleClose}
          onCreateCustomProperty={onCreateCustomProperty}
          selectedParam={selectedParam}
          selectedSource={selectedSource}
        />
      )}
    </Flexbox>
  );
};

export const MemoizedProfileUpdateIdentifiers = memo(ProfileUpdateIdentifiers);
