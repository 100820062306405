'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { error_events as error_events$1 } from './error_events_v1_ts_proto';
import { event_snapshot as event_snapshot$1 } from './event_snapshot_v1_ts_proto';
import { pagination as pagination$1 } from './pagination_v1_ts_proto';
import { sources as sources$1 } from './sources_v1_ts_proto';
import { google as google$1 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.activity = (() => {
  const activity = {};
  activity.v1 = (() => {
    const v1 = {};
    v1.ListActivitiesRequest = (() => {
      class ListActivitiesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListActivitiesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.pagination != null &&
            Object.hasOwnProperty.call(message, 'pagination')
          ) {
            pagination$1.v1.Pagination.encode(
              message.pagination,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.activity.v1.ListActivitiesFilter.encode(
              message.filter,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListActivitiesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.pagination = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.filter = $root.activity.v1.ListActivitiesFilter.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.activity.v1.ListActivitiesRequest) {
            return object;
          }
          const message = new $root.activity.v1.ListActivitiesRequest();
          if (object.pagination != null) {
            if (typeof object.pagination !== 'object') {
              throw new TypeError(
                '.activity.v1.ListActivitiesRequest.pagination: object expected, but got ' +
                  typeof object.pagination
              );
            }
            message.pagination = pagination$1.v1.Pagination.fromObject(
              object.pagination
            );
          }
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.activity.v1.ListActivitiesRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter = $root.activity.v1.ListActivitiesFilter.fromObject(
              object.filter
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.pagination = null;
          }
          let keys;
          if (
            message.pagination != null &&
            message.hasOwnProperty('pagination')
          ) {
            object.pagination = pagination$1.v1.Pagination.toObject(
              message.pagination,
              options
            );
          }
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter = $root.activity.v1.ListActivitiesFilter.toObject(
              message.filter,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'activity.v1.ListActivitiesRequest';
        }
      }

      ListActivitiesRequest.prototype.pagination = null;
      ListActivitiesRequest.prototype.filter = null;

      return ListActivitiesRequest;
    })();

    v1.ListActivitiesResponse = (() => {
      class ListActivitiesResponse {
        constructor(properties) {
          this.activities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListActivitiesResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.activities != null &&
            Object.hasOwnProperty.call(message, 'activities')
          ) {
            for (const element of message.activities) {
              $root.activity.v1.ActivityResponse.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(16).uint64(message.total);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListActivitiesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.activities || !message.activities.length) {
                  message.activities = [];
                }
                message.activities.push(
                  $root.activity.v1.ActivityResponse.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.total = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.activity.v1.ListActivitiesResponse) {
            return object;
          }
          const message = new $root.activity.v1.ListActivitiesResponse();
          if (object.activities) {
            if (!Array.isArray(object.activities)) {
              throw new TypeError(
                '.activity.v1.ListActivitiesResponse.activities: array type expected, but got ' +
                  typeof object.activities
              );
            }
            message.activities = new Array(object.activities.length);
            for (let i = 0; i < object.activities.length; ++i) {
              if (typeof object.activities[i] !== 'object') {
                throw new TypeError(
                  '.activity.v1.ListActivitiesResponse.activities: object expected, but got ' +
                    typeof object.activities[i]
                );
              }
              message.activities[i] =
                $root.activity.v1.ActivityResponse.fromObject(
                  object.activities[i]
                );
            }
          }
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.activities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.activities && message.activities.length) {
            object.activities = new Array(message.activities.length);
            for (let i = 0; i < message.activities.length; ++i) {
              object.activities[i] =
                $root.activity.v1.ActivityResponse.toObject(
                  message.activities[i],
                  options
                );
            }
          }
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'activity.v1.ListActivitiesResponse';
        }
      }

      ListActivitiesResponse.prototype.activities = $util.emptyArray;
      ListActivitiesResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      return ListActivitiesResponse;
    })();

    v1.Activity = (() => {
      class Activity {
        get activity() {
          for (const key of ['webhookRequest', 'eventSnapshot', 'errorEvent']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set activity(name) {
          for (const key of ['webhookRequest', 'eventSnapshot', 'errorEvent']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Activity(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.webhookRequest != null &&
            Object.hasOwnProperty.call(message, 'webhookRequest')
          ) {
            sources$1.v1.WebhookRequest.encode(
              message.webhookRequest,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.eventSnapshot != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshot')
          ) {
            event_snapshot$1.v1.EventSnapshot.encode(
              message.eventSnapshot,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.errorEvent != null &&
            Object.hasOwnProperty.call(message, 'errorEvent')
          ) {
            error_events$1.v1.ErrorEvent.encode(
              message.errorEvent,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Activity();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.webhookRequest = sources$1.v1.WebhookRequest.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.eventSnapshot =
                  event_snapshot$1.v1.EventSnapshot.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.errorEvent = error_events$1.v1.ErrorEvent.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.activity.v1.Activity) {
            return object;
          }
          const message = new $root.activity.v1.Activity();
          if (object.webhookRequest != null) {
            if (typeof object.webhookRequest !== 'object') {
              throw new TypeError(
                '.activity.v1.Activity.webhookRequest: object expected, but got ' +
                  typeof object.webhookRequest
              );
            }
            message.webhookRequest = sources$1.v1.WebhookRequest.fromObject(
              object.webhookRequest
            );
          }
          if (object.eventSnapshot != null) {
            if (typeof object.eventSnapshot !== 'object') {
              throw new TypeError(
                '.activity.v1.Activity.eventSnapshot: object expected, but got ' +
                  typeof object.eventSnapshot
              );
            }
            message.eventSnapshot =
              event_snapshot$1.v1.EventSnapshot.fromObject(
                object.eventSnapshot
              );
          }
          if (object.errorEvent != null) {
            if (typeof object.errorEvent !== 'object') {
              throw new TypeError(
                '.activity.v1.Activity.errorEvent: object expected, but got ' +
                  typeof object.errorEvent
              );
            }
            message.errorEvent = error_events$1.v1.ErrorEvent.fromObject(
              object.errorEvent
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.webhookRequest != null &&
            message.hasOwnProperty('webhookRequest')
          ) {
            object.webhookRequest = sources$1.v1.WebhookRequest.toObject(
              message.webhookRequest,
              options
            );
            if (options.oneofs) {
              object.activity = 'webhookRequest';
            }
          }
          if (
            message.eventSnapshot != null &&
            message.hasOwnProperty('eventSnapshot')
          ) {
            object.eventSnapshot = event_snapshot$1.v1.EventSnapshot.toObject(
              message.eventSnapshot,
              options
            );
            if (options.oneofs) {
              object.activity = 'eventSnapshot';
            }
          }
          if (
            message.errorEvent != null &&
            message.hasOwnProperty('errorEvent')
          ) {
            object.errorEvent = error_events$1.v1.ErrorEvent.toObject(
              message.errorEvent,
              options
            );
            if (options.oneofs) {
              object.activity = 'errorEvent';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'activity.v1.Activity';
        }
      }

      Activity.prototype.webhookRequest = null;
      Activity.prototype.eventSnapshot = null;
      Activity.prototype.errorEvent = null;

      return Activity;
    })();

    v1.ActivityResponse = (() => {
      class ActivityResponse {
        constructor(properties) {
          this.customerProvidedPatientIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ActivityResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.activity != null &&
            Object.hasOwnProperty.call(message, 'activity')
          ) {
            $root.activity.v1.Activity.encode(
              message.activity,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.time != null &&
            Object.hasOwnProperty.call(message, 'time')
          ) {
            timing$1.v1.Timestamp.encode(
              message.time,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.customerProvidedPatientIds != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedPatientIds')
          ) {
            for (const element of message.customerProvidedPatientIds) {
              writer.uint32(26).string(element);
            }
          }
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(32).int32(message.application);
          }
          if (
            message.eventName != null &&
            Object.hasOwnProperty.call(message, 'eventName')
          ) {
            writer.uint32(42).string(message.eventName);
          }
          if (
            message.activityJson != null &&
            Object.hasOwnProperty.call(message, 'activityJson')
          ) {
            google$1.protobuf.Struct.encode(
              message.activityJson,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ActivityResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.activity = $root.activity.v1.Activity.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.time = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                if (
                  !message.customerProvidedPatientIds ||
                  !message.customerProvidedPatientIds.length
                ) {
                  message.customerProvidedPatientIds = [];
                }
                message.customerProvidedPatientIds.push(reader.string());
                break;
              }
              case 4: {
                message.application = reader.int32();
                break;
              }
              case 5: {
                message.eventName = reader.string();
                break;
              }
              case 6: {
                message.activityJson = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.activity.v1.ActivityResponse) {
            return object;
          }
          const message = new $root.activity.v1.ActivityResponse();
          if (object.activity != null) {
            if (typeof object.activity !== 'object') {
              throw new TypeError(
                '.activity.v1.ActivityResponse.activity: object expected, but got ' +
                  typeof object.activity
              );
            }
            message.activity = $root.activity.v1.Activity.fromObject(
              object.activity
            );
          }
          if (object.time != null) {
            if (typeof object.time !== 'object') {
              throw new TypeError(
                '.activity.v1.ActivityResponse.time: object expected, but got ' +
                  typeof object.time
              );
            }
            message.time = timing$1.v1.Timestamp.fromObject(object.time);
          }
          if (object.customerProvidedPatientIds) {
            if (!Array.isArray(object.customerProvidedPatientIds)) {
              throw new TypeError(
                '.activity.v1.ActivityResponse.customerProvidedPatientIds: array type expected, but got ' +
                  typeof object.customerProvidedPatientIds
              );
            }
            message.customerProvidedPatientIds = new Array(
              object.customerProvidedPatientIds.length
            );
            for (let i = 0; i < object.customerProvidedPatientIds.length; ++i) {
              message.customerProvidedPatientIds[i] = String(
                object.customerProvidedPatientIds[i]
              );
            }
          }
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_ACTIVITY_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.application = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.application = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.application = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.application = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.application = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.application = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.application = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.application = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.application = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.application = 11;
                break;
              }
              case 'CUSTOMERIO':
              case 12: {
                message.application = 12;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.eventName != null) {
            message.eventName = String(object.eventName);
          }
          if (object.activityJson != null) {
            if (typeof object.activityJson !== 'object') {
              throw new TypeError(
                '.activity.v1.ActivityResponse.activityJson: object expected, but got ' +
                  typeof object.activityJson
              );
            }
            message.activityJson = google$1.protobuf.Struct.fromObject(
              object.activityJson
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.customerProvidedPatientIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.activity = null;
            object.time = null;
            object.application =
              options.enums === String ? 'UNSPECIFIED_ACTIVITY_APPLICATION' : 0;
            object.eventName = '';
            object.activityJson = null;
          }
          let keys;
          if (message.activity != null && message.hasOwnProperty('activity')) {
            object.activity = $root.activity.v1.Activity.toObject(
              message.activity,
              options
            );
          }
          if (message.time != null && message.hasOwnProperty('time')) {
            object.time = timing$1.v1.Timestamp.toObject(message.time, options);
          }
          if (
            message.customerProvidedPatientIds &&
            message.customerProvidedPatientIds.length
          ) {
            object.customerProvidedPatientIds = new Array(
              message.customerProvidedPatientIds.length
            );
            for (
              let i = 0;
              i < message.customerProvidedPatientIds.length;
              ++i
            ) {
              object.customerProvidedPatientIds[i] =
                message.customerProvidedPatientIds[i];
            }
          }
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? $root.activity.v1.ActivityApplication[message.application] ===
                  undefined
                  ? message.application
                  : $root.activity.v1.ActivityApplication[message.application]
                : message.application;
          }
          if (
            message.eventName != null &&
            message.hasOwnProperty('eventName')
          ) {
            object.eventName = message.eventName;
          }
          if (
            message.activityJson != null &&
            message.hasOwnProperty('activityJson')
          ) {
            object.activityJson = google$1.protobuf.Struct.toObject(
              message.activityJson,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'activity.v1.ActivityResponse';
        }
      }

      ActivityResponse.prototype.activity = null;
      ActivityResponse.prototype.time = null;
      ActivityResponse.prototype.customerProvidedPatientIds = $util.emptyArray;
      ActivityResponse.prototype.application = 0;
      ActivityResponse.prototype.eventName = '';
      ActivityResponse.prototype.activityJson = null;

      return ActivityResponse;
    })();

    v1.ListActivitiesFilter = (() => {
      class ListActivitiesFilter {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListActivitiesFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerProvidedId != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedId')
          ) {
            writer.uint32(10).string(message.customerProvidedId);
          }
          if (
            message.fromTimestamp != null &&
            Object.hasOwnProperty.call(message, 'fromTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.fromTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.toTimestamp != null &&
            Object.hasOwnProperty.call(message, 'toTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.toTimestamp,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.eventNameContains != null &&
            Object.hasOwnProperty.call(message, 'eventNameContains')
          ) {
            writer.uint32(34).string(message.eventNameContains);
          }
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(40).int32(message.application);
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(50).string(message.eventSnapshotId);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(58).string(message.sourceId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListActivitiesFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 2: {
                message.fromTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.toTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.eventNameContains = reader.string();
                break;
              }
              case 5: {
                message.application = reader.int32();
                break;
              }
              case 6: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 7: {
                message.sourceId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.activity.v1.ListActivitiesFilter) {
            return object;
          }
          const message = new $root.activity.v1.ListActivitiesFilter();
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.fromTimestamp != null) {
            if (typeof object.fromTimestamp !== 'object') {
              throw new TypeError(
                '.activity.v1.ListActivitiesFilter.fromTimestamp: object expected, but got ' +
                  typeof object.fromTimestamp
              );
            }
            message.fromTimestamp = timing$1.v1.Timestamp.fromObject(
              object.fromTimestamp
            );
          }
          if (object.toTimestamp != null) {
            if (typeof object.toTimestamp !== 'object') {
              throw new TypeError(
                '.activity.v1.ListActivitiesFilter.toTimestamp: object expected, but got ' +
                  typeof object.toTimestamp
              );
            }
            message.toTimestamp = timing$1.v1.Timestamp.fromObject(
              object.toTimestamp
            );
          }
          if (object.eventNameContains != null) {
            message.eventNameContains = String(object.eventNameContains);
          }
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_ACTIVITY_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.application = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.application = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.application = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.application = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.application = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.application = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.application = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.application = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.application = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.application = 11;
                break;
              }
              case 'CUSTOMERIO':
              case 12: {
                message.application = 12;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.customerProvidedId != null &&
            message.hasOwnProperty('customerProvidedId')
          ) {
            object.customerProvidedId = message.customerProvidedId;
          }
          if (
            message.fromTimestamp != null &&
            message.hasOwnProperty('fromTimestamp')
          ) {
            object.fromTimestamp = timing$1.v1.Timestamp.toObject(
              message.fromTimestamp,
              options
            );
          }
          if (
            message.toTimestamp != null &&
            message.hasOwnProperty('toTimestamp')
          ) {
            object.toTimestamp = timing$1.v1.Timestamp.toObject(
              message.toTimestamp,
              options
            );
          }
          if (
            message.eventNameContains != null &&
            message.hasOwnProperty('eventNameContains')
          ) {
            object.eventNameContains = message.eventNameContains;
          }
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? $root.activity.v1.ActivityApplication[message.application] ===
                  undefined
                  ? message.application
                  : $root.activity.v1.ActivityApplication[message.application]
                : message.application;
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'activity.v1.ListActivitiesFilter';
        }
      }

      ListActivitiesFilter.prototype.customerProvidedId = null;
      ListActivitiesFilter.prototype.fromTimestamp = null;
      ListActivitiesFilter.prototype.toTimestamp = null;
      ListActivitiesFilter.prototype.eventNameContains = null;
      ListActivitiesFilter.prototype.application = null;
      ListActivitiesFilter.prototype.eventSnapshotId = null;
      ListActivitiesFilter.prototype.sourceId = null;

      return ListActivitiesFilter;
    })();

    v1.ActivityApplication = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ACTIVITY_APPLICATION')] = 0;
      values[(valuesById[1] = 'FORMSORT')] = 1;
      values[(valuesById[2] = 'HEALTHIE')] = 2;
      values[(valuesById[3] = 'AXLE_HEALTH')] = 3;
      values[(valuesById[4] = 'BUTTERFLY_LABS')] = 4;
      values[(valuesById[5] = 'MORF')] = 5;
      values[(valuesById[6] = 'PUSH_JSON')] = 6;
      values[(valuesById[7] = 'ACUITY')] = 7;
      values[(valuesById[8] = 'VITAL')] = 8;
      values[(valuesById[9] = 'SEGMENT')] = 9;
      values[(valuesById[10] = 'ACTIVE_CAMPAIGN')] = 10;
      values[(valuesById[11] = 'INTAKEQ')] = 11;
      values[(valuesById[12] = 'CUSTOMERIO')] = 12;
      return values;
    })();

    return v1;
  })();

  return activity;
})();

export const activity = $root.activity;
