import _ from 'lodash';
import { Container } from '../../../../Container';
import { FC, memo, useState } from 'react';
import { FetchActionNodeProps } from '../types';
import { Flexbox } from '../../../../Flexbox';
import { Input } from '../../../../Input';
import { InsetTable } from '../../../../InsetTable';
import { RequiredIndicator } from '../../../../RequiredIndicator';
import { SelectValuePairModal } from '../../DestinationActionNode/DestinationActionConfigure/SelectValuePairModal';
import { Text } from '../../../../Typography';
import { columns } from '../../DestinationActionNode/DestinationActionConfigure/columns';
import { extractAction } from '../../../CustomEdge/AddEdge/extractAction';
import { formatDestinationAction } from '../../DestinationActionNode/DestinationActionConfigure/formatDestinationAction';
import { getVisibleCategories } from '../../DestinationActionNode/DestinationActionConfigure/getVisibileCategories';
import { google as googlecelvalues } from '@morf/proto/cel_values_ts_proto';
import { google } from '@morf/proto/empty_ts_proto';
import { handleNodeSave } from '../handleNodeSave';
import { useTheme } from '@morf/theming';
import { useWorkflow } from '../../../../../../apps/admin/components/context/workflow/useWorkflow';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { sortKeys } from '../../DestinationActionNode/DestinationActionConfigure/sortKeys';

const FetchActionConfigure: FC<FetchActionNodeProps> = ({ data, id }) => {
  const theme = useTheme();
  const [selectedColumn, setSelectedColumn] = useState<number | null>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedSubRow, setSelectedSubRow] = useState<number | null>(null);

  const { nodes, setNodes } = useWorkflow();

  const {
    configuration,
    onAddNode,
    onUpdateNode,
    onCreateCustomProperty,
    isReadOnly,
    isRequired,
  } = data.value;

  const [updatedResultObjectKey, setUpdatedResultObjectKey] = useState(
    configuration?.resultObjectKey
  );

  const { applicationType, applicationValue, actionType, actionValue } =
    extractAction(configuration);

  const selectedParam: workflow_parameters.v1.DestinationActionParameter | null =
    selectedRow !== null ? actionValue?.params[selectedRow] : null;

  const matchingDynamicConfig: workflow_parameters.v1.DynamicDestinationActionParameterConfig =
    actionValue.configs.find(
      (config: workflow_parameters.v1.DestinationActionParameterConfig) =>
        config.dynamicConfig?.parameter?.fieldName ===
        selectedParam?.dynamic?.fieldName
    )?.dynamicConfig;

  const matchingStaticConfig: workflow_parameters.v1.StaticDestinationActionParameterConfig =
    actionValue.configs.find(
      (config: workflow_parameters.v1.DestinationActionParameterConfig) =>
        config.staticConfig?.parameter?.fieldName ===
        selectedParam?.static?.fieldName
    )?.staticConfig;

  const isSourceCell =
    (selectedRow !== null || selectedSubRow !== null) && selectedColumn === 1;

  const isMapTypeKeyCell =
    selectedSubRow !== null &&
    selectedParam?.dynamic?.elementFieldType?.mapType &&
    selectedColumn === 0;

  const selectedElementSource =
    matchingDynamicConfig?.mapSource?.elementSources &&
    matchingDynamicConfig?.mapSource?.elementSources &&
    Object.entries(matchingDynamicConfig?.mapSource?.elementSources)
      .sort(([keyA], [keyB]) => sortKeys(keyA, keyB))
      .find((_, index) => index === selectedSubRow);

  const selectedKeySource =
    matchingDynamicConfig?.mapSource?.keySources &&
    Object.entries(matchingDynamicConfig?.mapSource?.keySources)
      .sort(([keyA], [keyB]) => sortKeys(keyA, keyB))
      .find((_, index) => index === selectedSubRow);

  const selectedMapTypeKey =
    selectedKeySource?.[1] ||
    new workflow_parameters.v1.DestinationActionParameterSource({
      constantValue: new googlecelvalues.api.expr.v1alpha1.Value({
        stringValue: selectedElementSource?.[0],
      }),
    });

  const selectedSource = (() => {
    if (selectedRow === null) return null;

    if (matchingDynamicConfig?.arraySource) {
      return matchingDynamicConfig?.arraySource;
    }

    if (matchingDynamicConfig?.objectSource) {
      return matchingDynamicConfig?.objectSource;
    }

    if (matchingDynamicConfig?.listSource && selectedSubRow !== null) {
      return matchingDynamicConfig?.listSource?.sources[selectedSubRow];
    }

    if (matchingDynamicConfig?.mapSource && selectedSubRow !== null) {
      return selectedElementSource?.[1];
    }
    return matchingStaticConfig?.source;
  })() as workflow_parameters.v1.DestinationActionParameterSource | null;

  const handleKeySave = (
    updatedSources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    if (
      configuration &&
      applicationType &&
      applicationValue &&
      actionType &&
      actionValue
    ) {
      const updatedSource = updatedSources[0];

      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        [applicationType]: {
          ...applicationValue,
          [actionType]: {
            ...actionValue,
            configs: actionValue.configs.map(
              (
                config: workflow_parameters.v1.DestinationActionParameterConfig
              ) => {
                const hasMatchingDynamicConfig =
                  _.get(selectedParam, 'dynamic.fieldName') ===
                  _.get(config, 'dynamicConfig.parameter.fieldName');

                if (
                  hasMatchingDynamicConfig &&
                  config.dynamicConfig &&
                  config.dynamicConfig.mapSource
                ) {
                  const entriesElementSources: [
                    string,
                    workflow_parameters.v1.DestinationActionParameterSource
                  ][] = Object.entries(
                    config.dynamicConfig?.mapSource?.elementSources || {}
                  ).sort(([keyA], [keyB]) => sortKeys(keyA, keyB));

                  const entriesKeySources: [
                    string,
                    workflow_parameters.v1.DestinationActionParameterSource
                  ][] = entriesElementSources.map((elementSource, index) => {
                    const keyIndex = index.toString();
                    const keyElementSource = elementSource[0];

                    const matchingKeySource =
                      config.dynamicConfig?.mapSource?.keySources?.[
                        keyElementSource
                      ];

                    const matchingKeyContext =
                      config.dynamicConfig?.mapSource?.keyContext?.[
                        keyElementSource
                      ];

                    if (index === selectedSubRow) {
                      return [keyIndex, updatedSource];
                    } else if (matchingKeySource) {
                      return [keyIndex, matchingKeySource];
                    } else if (matchingKeyContext) {
                      return [
                        keyIndex,
                        new workflow_parameters.v1.DestinationActionParameterSource(
                          {
                            objectTypeId: matchingKeyContext.objectTypeId,
                          }
                        ),
                      ];
                    } else {
                      return [
                        keyIndex,
                        new workflow_parameters.v1.DestinationActionParameterSource(
                          {
                            constantValue:
                              new googlecelvalues.api.expr.v1alpha1.Value({
                                stringValue: keyElementSource,
                              }),
                          }
                        ),
                      ];
                    }
                  });

                  return new workflow_parameters.v1.DestinationActionParameterConfig(
                    {
                      dynamicConfig:
                        new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                          {
                            parameter: selectedParam?.dynamic,
                            mapSource:
                              new workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource(
                                {
                                  elementSources: Object.fromEntries(
                                    entriesElementSources.map(
                                      ([_key, value], index) => [
                                        index.toString(),
                                        value,
                                      ]
                                    )
                                  ),
                                  keySources:
                                    Object.fromEntries(entriesKeySources),
                                  keyContext: undefined,
                                }
                              ),
                          }
                        ),
                    }
                  );
                }
                return config;
              }
            ),
          },
        },
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleSourceSave = (
    updatedSources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    if (
      configuration &&
      applicationType &&
      applicationValue &&
      actionType &&
      actionValue
    ) {
      const updatedSource = updatedSources[0];

      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        resultObjectKey: updatedResultObjectKey,
        [applicationType]: {
          ...applicationValue,
          [actionType]: {
            ...actionValue,
            configs: actionValue.params
              .map(
                (
                  param: workflow_parameters.v1.DestinationActionParameter,
                  i: number
                ) => {
                  if (param.static) {
                    const matchingStaticConfig: workflow_parameters.v1.StaticDestinationActionParameterConfig =
                      actionValue?.configs?.find(
                        (
                          config: workflow_parameters.v1.DestinationActionParameterConfig
                        ) =>
                          config.staticConfig?.parameter?.fieldName ===
                          param.static?.fieldName
                      )?.staticConfig;

                    const staticConfig =
                      i === selectedRow
                        ? new workflow_parameters.v1.StaticDestinationActionParameterConfig(
                            {
                              parameter: param?.static,
                              source: updatedSource,
                            }
                          )
                        : matchingStaticConfig;

                    if (staticConfig) {
                      return new workflow_parameters.v1.DestinationActionParameterConfig(
                        {
                          staticConfig: staticConfig,
                        }
                      );
                    }
                  }

                  if (param.dynamic) {
                    const matchingDynamicConfig: workflow_parameters.v1.DynamicDestinationActionParameterConfig =
                      actionValue?.configs?.find(
                        (
                          config: workflow_parameters.v1.DestinationActionParameterConfig
                        ) =>
                          config.dynamicConfig?.parameter?.fieldName ===
                          param.dynamic?.fieldName
                      )?.dynamicConfig;

                    if (param.dynamic.elementFieldType?.listType) {
                      if (
                        !matchingDynamicConfig?.listSource &&
                        updatedSource &&
                        (updatedSource.eventPayloadFieldLookup ||
                          updatedSource.calculatedValue)
                      ) {
                        const dynamicConfig =
                          i === selectedRow
                            ? new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                                {
                                  parameter: param?.dynamic,
                                  arraySource:
                                    new workflow_parameters.v1.DynamicDestinationActionParameterConfig.ArraySource(
                                      updatedSource
                                    ),
                                }
                              )
                            : matchingDynamicConfig;

                        if (dynamicConfig) {
                          return new workflow_parameters.v1.DestinationActionParameterConfig(
                            {
                              dynamicConfig: dynamicConfig,
                            }
                          );
                        }
                      } else {
                        const dynamicConfig =
                          i === selectedRow
                            ? new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                                {
                                  parameter: param?.dynamic,
                                  listSource:
                                    new workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource(
                                      {
                                        sources: matchingDynamicConfig
                                          ?.listSource?.sources.length
                                          ? matchingDynamicConfig?.listSource?.sources.map(
                                              (source, i) => {
                                                if (i === selectedSubRow) {
                                                  return updatedSource;
                                                }
                                                return source;
                                              }
                                            )
                                          : updatedSources,
                                      }
                                    ),
                                }
                              )
                            : matchingDynamicConfig;

                        if (dynamicConfig) {
                          return new workflow_parameters.v1.DestinationActionParameterConfig(
                            {
                              dynamicConfig: dynamicConfig,
                            }
                          );
                        }
                      }
                    }
                    if (param.dynamic.elementFieldType?.mapType) {
                      if (
                        !matchingDynamicConfig?.mapSource &&
                        updatedSource &&
                        (updatedSource.eventPayloadFieldLookup ||
                          updatedSource.calculatedValue)
                      ) {
                        const dynamicConfig =
                          i === selectedRow
                            ? new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                                {
                                  parameter: param?.dynamic,
                                  objectSource:
                                    new workflow_parameters.v1.DynamicDestinationActionParameterConfig.ObjectSource(
                                      updatedSource
                                    ),
                                }
                              )
                            : matchingDynamicConfig;

                        if (dynamicConfig) {
                          return new workflow_parameters.v1.DestinationActionParameterConfig(
                            {
                              dynamicConfig: dynamicConfig,
                            }
                          );
                        }
                      } else {
                        const uniqueId = '0';

                        const entriesElementSources: [
                          string,
                          workflow_parameters.v1.DestinationActionParameterSource
                        ][] = Object.entries(
                          matchingDynamicConfig?.mapSource?.elementSources || {
                            [uniqueId]: updatedSource,
                          }
                        )
                          .sort(([keyA], [keyB]) => sortKeys(keyA, keyB))
                          .map((elementSource, index) => {
                            if (index === selectedSubRow) {
                              const [key, _value] = elementSource;
                              return [key, updatedSource];
                            }
                            return elementSource;
                          });

                        const entriesKeySources: [
                          string,
                          workflow_parameters.v1.DestinationActionParameterSource
                        ][] = entriesElementSources.map(
                          (elementSource, index) => {
                            const keyIndex = index.toString();
                            const keyElementSource = elementSource[0];
                            const valueElementSource = elementSource[1];

                            const matchingKeySource =
                              matchingDynamicConfig?.mapSource?.keySources?.[
                                keyElementSource
                              ];

                            const matchingKeyContext =
                              matchingDynamicConfig?.mapSource?.keyContext?.[
                                keyElementSource
                              ];

                            if (
                              keyIndex === uniqueId &&
                              valueElementSource?.elidedValue
                            ) {
                              return [
                                keyIndex,
                                new workflow_parameters.v1.DestinationActionParameterSource(
                                  {
                                    elidedValue: new google.protobuf.Empty({}),
                                  }
                                ),
                              ];
                            } else if (matchingKeySource) {
                              return [keyIndex, matchingKeySource];
                            } else if (matchingKeyContext) {
                              return [
                                keyIndex,
                                new workflow_parameters.v1.DestinationActionParameterSource(
                                  {
                                    objectTypeId:
                                      matchingKeyContext.objectTypeId,
                                  }
                                ),
                              ];
                            } else {
                              return [
                                keyIndex,
                                new workflow_parameters.v1.DestinationActionParameterSource(
                                  {
                                    constantValue:
                                      new googlecelvalues.api.expr.v1alpha1.Value(
                                        {
                                          stringValue: keyElementSource,
                                        }
                                      ),
                                  }
                                ),
                              ];
                            }
                          }
                        );

                        const dynamicConfig =
                          i === selectedRow
                            ? new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                                {
                                  parameter: param?.dynamic,
                                  mapSource:
                                    new workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource(
                                      {
                                        elementSources: Object.fromEntries(
                                          entriesElementSources.map(
                                            ([_key, value], index) => [
                                              index.toString(),
                                              value,
                                            ]
                                          )
                                        ),
                                        keySources:
                                          Object.fromEntries(entriesKeySources),
                                        keyContext: undefined,
                                      }
                                    ),
                                }
                              )
                            : matchingDynamicConfig;

                        if (dynamicConfig) {
                          return new workflow_parameters.v1.DestinationActionParameterConfig(
                            {
                              dynamicConfig: dynamicConfig,
                            }
                          );
                        }
                      }
                    }
                  } else return null;
                }
              )
              .filter(Boolean),
          },
        },
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleSubRowAdd = (rowId: number) => {
    if (
      configuration &&
      applicationType &&
      applicationValue &&
      actionType &&
      actionValue
    ) {
      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        [applicationType]: {
          ...applicationValue,
          [actionType]: {
            ...actionValue,
            configs: actionValue.configs.map(
              (
                config: workflow_parameters.v1.DestinationActionParameterConfig
              ) => {
                const selectedParam: workflow_parameters.v1.DestinationActionParameter =
                  actionValue?.params[rowId];

                const hasMatchingDynamicConfig =
                  _.get(selectedParam, 'dynamic.fieldName') ===
                  _.get(config, 'dynamicConfig.parameter.fieldName');

                if (hasMatchingDynamicConfig) {
                  if (config.dynamicConfig?.listSource) {
                    return new workflow_parameters.v1.DestinationActionParameterConfig(
                      {
                        dynamicConfig:
                          new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                            {
                              parameter: selectedParam.dynamic,
                              listSource: {
                                sources: [
                                  ...(config.dynamicConfig?.listSource
                                    ?.sources || []),
                                  new workflow_parameters.v1.DestinationActionParameterSource(
                                    {
                                      elidedValue: new google.protobuf.Empty(
                                        {}
                                      ),
                                    }
                                  ),
                                ],
                              },
                            }
                          ),
                      }
                    );
                  }

                  if (config.dynamicConfig?.mapSource) {
                    const uniqueId = Object.entries(
                      config.dynamicConfig?.mapSource?.elementSources || {}
                    ).length.toString();

                    const entriesElementSources: [
                      string,
                      workflow_parameters.v1.DestinationActionParameterSource
                    ][] = Object.entries({
                      ...config.dynamicConfig?.mapSource?.elementSources,
                      [uniqueId]:
                        new workflow_parameters.v1.DestinationActionParameterSource(
                          {
                            elidedValue: new google.protobuf.Empty({}),
                          }
                        ),
                    }).sort(([keyA], [keyB]) => sortKeys(keyA, keyB));

                    const entriesKeySources: [
                      string,
                      workflow_parameters.v1.DestinationActionParameterSource
                    ][] = entriesElementSources.map((elementSource, index) => {
                      const keyIndex = index.toString();
                      const keyElementSource = elementSource[0];

                      const matchingKeySource =
                        config.dynamicConfig?.mapSource?.keySources?.[
                          keyElementSource
                        ];

                      const matchingKeyContext =
                        config.dynamicConfig?.mapSource?.keyContext?.[
                          keyElementSource
                        ];

                      if (keyIndex === uniqueId) {
                        return [
                          keyIndex,
                          new workflow_parameters.v1.DestinationActionParameterSource(
                            {
                              elidedValue: new google.protobuf.Empty({}),
                            }
                          ),
                        ];
                      } else if (matchingKeySource) {
                        return [keyIndex, matchingKeySource];
                      } else if (matchingKeyContext) {
                        return [
                          keyIndex,
                          new workflow_parameters.v1.DestinationActionParameterSource(
                            {
                              objectTypeId: matchingKeyContext.objectTypeId,
                            }
                          ),
                        ];
                      } else {
                        return [
                          keyIndex,
                          new workflow_parameters.v1.DestinationActionParameterSource(
                            {
                              constantValue:
                                new googlecelvalues.api.expr.v1alpha1.Value({
                                  stringValue: keyElementSource,
                                }),
                            }
                          ),
                        ];
                      }
                    });

                    return new workflow_parameters.v1.DestinationActionParameterConfig(
                      {
                        dynamicConfig:
                          new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                            {
                              parameter: selectedParam.dynamic,
                              mapSource:
                                new workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource(
                                  {
                                    elementSources: Object.fromEntries(
                                      entriesElementSources.map(
                                        ([_key, value], index) => [
                                          index.toString(),
                                          value,
                                        ]
                                      )
                                    ),
                                    keySources:
                                      Object.fromEntries(entriesKeySources),
                                    keyContext:
                                      config.dynamicConfig?.mapSource
                                        ?.keyContext,
                                  }
                                ),
                            }
                          ),
                      }
                    );
                  }
                }
                return config;
              }
            ),
          },
        },
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const handleSubRowRemove = (rowId: number, subRowId: number) => {
    if (
      configuration &&
      applicationType &&
      applicationValue &&
      actionType &&
      actionValue
    ) {
      const fetchAction = new workflows.v1.WorkflowFetchAction({
        ...configuration,
        [applicationType]: {
          ...applicationValue,
          [actionType]: {
            ...actionValue,
            configs: actionValue.configs
              .map(
                (
                  config: workflow_parameters.v1.DestinationActionParameterConfig
                ) => {
                  const selectedParam: workflow_parameters.v1.DestinationActionParameter =
                    actionValue?.params[rowId];

                  const hasMatchingDynamicConfig =
                    _.get(selectedParam, 'dynamic.fieldName') ===
                    _.get(config, 'dynamicConfig.parameter.fieldName');

                  if (hasMatchingDynamicConfig) {
                    if (config.dynamicConfig?.listSource) {
                      const updatedSources =
                        config.dynamicConfig?.listSource?.sources.filter(
                          (_source, i) => i !== subRowId
                        );

                      if (updatedSources?.length) {
                        return new workflow_parameters.v1.DestinationActionParameterConfig(
                          {
                            dynamicConfig:
                              new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                                {
                                  parameter: selectedParam?.dynamic,
                                  listSource:
                                    new workflow_parameters.v1.DynamicDestinationActionParameterConfig.ListSource(
                                      {
                                        sources: updatedSources,
                                      }
                                    ),
                                }
                              ),
                          }
                        );
                      } else return null;
                    }

                    if (config.dynamicConfig?.mapSource) {
                      const entriesElementSources = Object.entries(
                        config.dynamicConfig?.mapSource?.elementSources || {}
                      )
                        .sort(([keyA], [keyB]) => sortKeys(keyA, keyB))
                        .filter((_, index) => index !== subRowId);

                      const entriesKeySources: [
                        string,
                        workflow_parameters.v1.DestinationActionParameterSource
                      ][] = entriesElementSources.map(
                        (elementSource, index) => {
                          const keyIndex = index.toString();
                          const keyElementSource = elementSource[0];

                          const matchingKeySource =
                            config.dynamicConfig?.mapSource?.keySources?.[
                              keyElementSource
                            ];

                          const matchingKeyContext =
                            config.dynamicConfig?.mapSource?.keyContext?.[
                              keyElementSource
                            ];

                          if (matchingKeySource) {
                            return [keyIndex, matchingKeySource];
                          } else if (matchingKeyContext) {
                            return [
                              keyIndex,
                              new workflow_parameters.v1.DestinationActionParameterSource(
                                {
                                  objectTypeId: matchingKeyContext.objectTypeId,
                                }
                              ),
                            ];
                          } else {
                            return [
                              keyIndex,
                              new workflow_parameters.v1.DestinationActionParameterSource(
                                {
                                  constantValue:
                                    new googlecelvalues.api.expr.v1alpha1.Value(
                                      {
                                        stringValue: keyElementSource,
                                      }
                                    ),
                                }
                              ),
                            ];
                          }
                        }
                      );

                      if (entriesElementSources?.length) {
                        return new workflow_parameters.v1.DestinationActionParameterConfig(
                          {
                            dynamicConfig:
                              new workflow_parameters.v1.DynamicDestinationActionParameterConfig(
                                {
                                  parameter: selectedParam?.dynamic,
                                  mapSource:
                                    new workflow_parameters.v1.DynamicDestinationActionParameterConfig.MapSource(
                                      {
                                        elementSources: Object.fromEntries(
                                          entriesElementSources.map(
                                            ([_key, value], index) => [
                                              index.toString(),
                                              value,
                                            ]
                                          )
                                        ),
                                        keySources:
                                          Object.fromEntries(entriesKeySources),
                                        keyContext: undefined,
                                      }
                                    ),
                                }
                              ),
                          }
                        );
                      } else return null;
                    }
                  }
                  return config;
                }
              )
              .filter(Boolean),
          },
        },
      });

      handleNodeSave(
        data,
        fetchAction,
        id,
        isRequired,
        nodes,
        setNodes,
        onAddNode,
        onUpdateNode
      );
    }
  };

  const formattedData = formatDestinationAction(configuration);

  const handleClose = () => {
    setSelectedRow(null);
    setSelectedSubRow(null);
  };

  return (
    <Flexbox direction='column' justifyContent='flex-start'>
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0.25}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          data-testid='uniqueness-conflict-resolution'
          height='auto'
          gap={0.5}
        >
          <Flexbox
            direction='column'
            alignItems='flex-start'
            justifyContent='center'
            height='auto'
            gap={0}
          >
            <Text tag='h5'>Object Name</Text>
            <Text tag='p2' color={theme.colors.text.muted}>
              What do you want to call this object?
            </Text>
          </Flexbox>
          <Input
            id='object-name'
            height='2.25rem'
            value={updatedResultObjectKey}
            onChange={(e) => setUpdatedResultObjectKey(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && updatedResultObjectKey) {
                handleSourceSave([]);
              }
            }}
            readOnly={isReadOnly}
          />
        </Flexbox>
        {!updatedResultObjectKey && <RequiredIndicator />}
      </Flexbox>
      <Container height='calc(100% - 3.5rem)'>
        <InsetTable
          columns={columns}
          data={formattedData}
          emptyText='There are no configurations!'
          enableRowSelection={!isReadOnly}
          isFetching={false}
          isLoading={false}
          onColumnClick={setSelectedColumn}
          onRowClick={setSelectedRow}
          onSubRowClick={setSelectedSubRow}
          selectedRow={selectedRow}
          totalCount={formattedData.length}
          totalFetched={formattedData.length}
          {...(!isReadOnly && {
            onSubRowAdd: handleSubRowAdd,
            onSubRowRemove: handleSubRowRemove,
          })}
        />
      </Container>
      {isMapTypeKeyCell && onCreateCustomProperty && (
        <SelectValuePairModal
          onClick={handleKeySave}
          onClose={handleClose}
          onCreateCustomProperty={onCreateCustomProperty}
          selectedParam={selectedParam}
          selectedSource={selectedMapTypeKey}
        />
      )}

      {isSourceCell && onCreateCustomProperty && (
        <SelectValuePairModal
          onClick={handleSourceSave}
          onClose={handleClose}
          onCreateCustomProperty={onCreateCustomProperty}
          selectedParam={selectedParam}
          selectedSource={selectedSource}
          visibleCategories={getVisibleCategories(
            selectedParam,
            selectedSubRow
          )}
        />
      )}
    </Flexbox>
  );
};

export const MemoizedFetchActionConfigure = memo(FetchActionConfigure);
