import { AddRow } from '../../AddRow';
import { ExtendedColumnDef } from '../../types';
import { Flexbox } from '../../../Flexbox';
import { RowData, flexRender } from '@tanstack/react-table';
import { StyledHeadCell, StyledHeadCellContent } from './TableHeadCell.css';
import { TableHeadCellProps } from './types';
import { Text } from '../../../Typography';
import { memo } from 'react';

const HeadCell: <RowData>(
  props: TableHeadCellProps<RowData>
) => JSX.Element = ({
  headCellBackgroundColor,
  headCellColor,
  headCellHeight,
  header,
  isLastCell,
  isScrollable,
  onRowAdd,
}) => {
  const isPinned = !!header.column.getIsPinned();
  const columnDef = header.column.columnDef as ExtendedColumnDef<RowData>;
  const width = isLastCell ? '100%' : `${header.column.getSize() / 16}rem`;

  return (
    <StyledHeadCell
      key={header.id}
      data-testid='head-cell'
      borderType={columnDef.headCellBorderType}
      headCellBackgroundColor={headCellBackgroundColor}
      headCellHeight={headCellHeight}
      headCellPl={columnDef.headCellPl}
      headCellPr={columnDef.headCellPr}
      isPinned={isPinned}
      isScrollable={isScrollable}
    >
      <StyledHeadCellContent
        justifyContent='space-between'
        alignItems='center'
        isPinned={isPinned}
        isScrollable={isScrollable}
        width={width}
      >
        <Flexbox
          direction='row'
          justify-content='flex-start'
          alignItems='center'
          width='auto'
        >
          <Text tag='h5' color={headCellColor} whiteSpace='nowrap'>
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </Text>
        </Flexbox>

        {isLastCell && onRowAdd && <AddRow onClick={onRowAdd} />}
      </StyledHeadCellContent>
    </StyledHeadCell>
  );
};
export const TableHeadCell = memo(HeadCell) as typeof HeadCell;
