import { FC, useState } from 'react';
import { PatientIdProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { Tooltip } from '../Tooltip';
import { useTheme } from '@morf/theming';
import { Icon } from '../Icon';
import { Container } from '../Container';
import { CopyToClipboard } from '../CopyToClipboard';

export const PatientId: FC<PatientIdProps> = ({ patientId, href }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flexbox
      justifyContent='flex-start'
      alignItems='center'
      width='auto'
      height='auto'
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={(e) => e.stopPropagation()}
      gap={0.25}
      maxWidth='16rem'
    >
      <Container width={isHovered ? '13rem' : '16rem'}>
        <Text tag='p2' whiteSpace='nowrap'>
          {patientId}
        </Text>
      </Container>
      {isHovered && patientId !== 'n/a' && (
        <Flexbox
          gap={0.25}
          width='auto'
          justifyContent='center'
          alignItems='center'
        >
          <CopyToClipboard
            label='Copy Patient Id'
            text={patientId}
            tooltipDirection='top'
            arrowDirection='center'
            tooltipWidth='5.5rem'
          />
          <Tooltip
            tooltipText='Go to Profile'
            tooltipDirection='top'
            arrowDirection='center'
            tooltipWidth='4.5rem'
          >
            <a href={href} target='_blank'>
              <Icon
                name='patient-arrow'
                stroke={theme.colors.main.primary.darker}
                size={1.25}
                cursor='pointer'
              />
            </a>
          </Tooltip>
        </Flexbox>
      )}
    </Flexbox>
  );
};
