import rpcService from '@morf/server/browser-server';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const listObjectTypeIDs = async (
  request: workflows.v1.ListObjectTypeIDsRequest
) => {
  try {
    return await rpcService.workflowsV1Service.listObjectTypeIDs(request);
  } catch (error) {
    throw new Error('listObjectTypeIDs: ' + error);
  }
};
