import axios, { AxiosResponse } from 'axios';
import { RevokeRequest, RevokeResponse } from 'stytch/types/lib/b2b/sessions';

export const revokeStytchSession = async (requestData: RevokeRequest): Promise<
  AxiosResponse<RevokeResponse>
> => {
  try {
    return await axios.post<RevokeResponse>(
      '/api/revokeStytchSession',
      requestData
    );
  } catch (error) {
    throw new Error('revokeStytchSession: ' + error);
  }
};
