import { styled } from '@morf/theming';
import { Flexbox } from '../../../../Flexbox';
import { ContainerProps } from '../../../../Container';
import { borderStyles } from '../../../../Container/Container.css';

export const StyledCell = styled.td<{
  borderType: ContainerProps['borderType'];
  cellPt: ContainerProps['pt'];
  cellPb: ContainerProps['pb'];
  cellPl: ContainerProps['pl'];
  cellPr: ContainerProps['pr'];
  isFirstRow: boolean;
  isPinned: boolean;
  isScrollable: boolean;
}>`
  ${borderStyles}
  padding-top: ${(props) => `${props.cellPt}rem`};
  padding-bottom: ${(props) => `${props.cellPb}rem`};
  padding-left: ${(props) => `${props.cellPl}rem`};
  padding-right: ${(props) => `${props.cellPr}rem`};

  ${({ isFirstRow }) => isFirstRow && ` border-top: none;`};

  ${({ isPinned, isScrollable }) =>
    isPinned &&
    isScrollable &&
    `
    position: sticky;
    z-index:  var(--table-cell-pinned-index);
    background-color: inherit;
    left: 0;
`};
`;

export const StyledCellContent = styled(Flexbox)<{
  isPinned: boolean;
  isScrollable: boolean;
}>`
  ${({ isPinned, isScrollable }) =>
    isPinned &&
    isScrollable &&
    `::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0px;
      bottom: 0;
      width: 15px;
      box-shadow: 15px 0px 20px rgba(0,0,0,0.05);
      z-index: -1;
    }
`};
`;
