import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { ParameterVariant } from '../ParameterVariant';
import { SourceConfigProps } from '../Workflow/types';

const SourceConfig: FC<SourceConfigProps> = ({
  fieldName = 'no value',
  fieldType,
  integrationIconName,
  isRequired = false,
  sensitivity,
  tagType = 'disabled',
}) => {
  const theme = useTheme();
  const emptyField = fieldName === 'no value';

  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      data-testid='source-config'
      cursor='pointer'
      gap={0.25}
    >
      <ParameterVariant
        id={fieldName}
        type={isRequired && emptyField ? 'required' : tagType}
        label={isRequired && emptyField ? 'Required' : fieldName}
        integrationIconName={integrationIconName}
      />

      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
        gap={0.25}
      >
        {fieldType && (
          <Text tag='p3' color={theme.colors.text.muted}>
            {fieldType}
          </Text>
        )}

        {sensitivity && (
          <Text tag='p3' color={theme.colors.text.muted}>
            {sensitivity}
          </Text>
        )}
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedSourceConfig = memo(SourceConfig);
