import { FontSizes, FontWeights, LineHeights } from '../Theme';

const RedShades = {
  darkest: '#FD4B4B',
  darker: '#FD5E5E',
  dark: '#FD7272',
  normal: '#FE8686',
  light: '#FEA4A4',
  lighter: '#FEB8B8',
  lightest: '#FFE6E6',
};

const GreenShades = {
  darkest: '#0FB0A7',
  darker: '#1EC8BE',
  dark: '#51D9D1',
  normal: '#93ECE6',
  light: '#A3F5F0',
  lighter: '#BFF8F4',
  lightest: '#DAFBF9',
};

const YellowShades = {
  darkest: '#FFC940',
  darker: '#FBD36A',
  dark: '#FCD87E',
  normal: '#FCDE92',
  light: '#FFE299',
  lighter: '#FFEBB8',
  lightest: '#FFF3D6',
};

const UIColors = {
  dark: '#8285AC',
  muted: '#C7C9DE',
  body: '#F6F8FA',
  divider: '#E7E8F1',
  card: '#FFFFFF',
};

const TextColors = {
  hover: '#FFFFFF',
  muted: '#999BAF',
  disabled: '#D9DAE3',
  inverse: '#FFFFFF',
};

export const defaultColors = {
  support: {
    red: RedShades,
    yellow: YellowShades,
    green: GreenShades,
  },
  ui: UIColors,
  text: {
    ...TextColors,
  },
  avatar: [],
};

export const defaultH1 = {
  decoration: 'none',
  family: 'apercu-bold',
  letterSpacing: '0rem',
  lineHeight: LineHeights['2.25'],
  size: FontSizes['2'],
  weight: FontWeights.bold,
};

export const defaultH2 = {
  decoration: 'none',
  family: 'apercu-bold',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.75'],
  size: FontSizes['1.5'],
  weight: FontWeights.bold,
};

export const defaultH3 = {
  decoration: 'none',
  family: 'apercu-bold',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.5'],
  size: FontSizes['1.125'],
  weight: FontWeights.bold,
};

export const defaultH4 = {
  decoration: 'none',
  family: 'apercu-bold',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.375'],
  size: FontSizes['1'],
  weight: FontWeights.bold,
};

export const defaultH5 = {
  decoration: 'none',
  family: 'apercu-bold',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.25'],
  size: FontSizes['0.875'],
  weight: FontWeights.bold,
};

export const defaultH6 = {
  decoration: 'none',
  family: 'apercu-medium',
  letterSpacing: '2%',
  lineHeight: LineHeights['0.875'],
  size: FontSizes['0.75'],
  weight: FontWeights.bold,
};

export const defaultP1 = {
  decoration: 'none',
  family: 'apercu-regular',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.375'],
  size: FontSizes['1'],
  weight: FontWeights.normal,
};

export const defaultP2 = {
  decoration: 'none',
  family: 'apercu-regular',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.25'],
  size: FontSizes['0.875'],
  weight: FontWeights.normal,
};

export const defaultP3 = {
  decoration: 'none',
  family: 'apercu-regular',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.125'],
  size: FontSizes['0.75'],
  weight: FontWeights.normal,
};

export const defaultP4 = {
  decoration: 'none',
  family: 'apercu-regular',
  letterSpacing: '0rem',
  lineHeight: LineHeights['0.875'],
  size: FontSizes['0.5'],
  weight: FontWeights.normal,
};

export const defaultSubtitle = {
  decoration: 'none',
  family: 'apercu-regular',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.75'],
  size: FontSizes['1.125'],
  weight: FontWeights.normal,
};

export const defaultLongform = {
  decoration: 'none',
  family: 'apercu-regular',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.5'],
  size: FontSizes['1'],
  weight: FontWeights.normal,
};

export const defaultLink = {
  decoration: 'none',
  family: 'apercu-medium',
  letterSpacing: '0rem',
  lineHeight: LineHeights['1.25'],
  size: FontSizes['0.875'],
  weight: FontWeights.bold,
};

export const defaultMaxNrOfSlots = {
  xxl: '15',
  xl: '15',
  lg: '10',
  md: '10',
  sm: '5',
  xs: '5',
};
