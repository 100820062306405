import { AddRowProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';
import { useTheme } from '@morf/theming';

const AddRow: FC<AddRowProps> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='add-row'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      height='auto'
      width='auto'
      cursor='pointer'
      onClick={onClick}
      gap={0.25}
    >
      <Icon
        name='plus-circle'
        stroke={theme.colors.main.primary.darker}
        size={1.25}
        strokeWidth={1.75}
      />
      <Text tag='p2' color={theme.colors.main.primary.darker}>
        Add Row
      </Text>
    </Flexbox>
  );
};

export const MemoizedAddRow = memo(AddRow);
