import { ParamSourceConfigProps } from '../../../types';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { formatDestinationActionParameterSource } from '../../../../Helpers/formatDestinationActionParameterSource';
import { formatSensitivities } from '../../../../Helpers/formatSensitivities';

export const formatProfileUpdateProperties = (
  data: destinationactions.morf.v1.UpdateProfileProperties | null | undefined
): ParamSourceConfigProps[] => {
  const formattedProfileUpdateProperties: ParamSourceConfigProps[] = [];

  data?.propertyUpdateSources?.map(
    (
      propertyMapping: destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping
    ) => {
      const { property, source } = propertyMapping;
      const groupingKey = property?.propertyName;

      const propertiesMappingsWithSamePropertyName =
        data.propertyUpdateSources.filter(
          (source) => source.property?.propertyName === groupingKey
        );
      const isObject = propertiesMappingsWithSamePropertyName.length > 1;
      const isNewGroupingKey = !formattedProfileUpdateProperties.some(
        (prop) => prop.param.fieldName === groupingKey
      );

      if (isNewGroupingKey) {
        formattedProfileUpdateProperties.push({
          param: {
            fieldName: property?.propertyName,
            tagType: property?.isMorfDefault ? 'morf' : 'custom',
            fieldType: isObject ? 'object' : property?.valueType?.type,
            sensitivity: isObject
              ? formatSensitivities(
                  Array.from(
                    new Set(
                      propertiesMappingsWithSamePropertyName.flatMap(
                        (updateSource) =>
                          updateSource.property?.sensitivities || []
                      )
                    )
                  )
                )
              : formatSensitivities(property?.sensitivities),
          },
          source: isObject
            ? null
            : formatDestinationActionParameterSource(source),
          subRows: isObject
            ? propertiesMappingsWithSamePropertyName.map(
                ({ property, source }) => ({
                  param: {
                    fieldName: property?.celExpressionStr || '',
                    fieldType: property?.valueType?.type,
                    sensitivity: formatSensitivities(property?.sensitivities),
                  },
                  source: formatDestinationActionParameterSource(source),
                  subRows: [],
                })
              )
            : [],
        });
      }
    }
  );
  return formattedProfileUpdateProperties;
};
