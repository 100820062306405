import { ActiveCampaign } from './Icons/ActiveCampaign';
import { Acuity } from './Icons/Acuity';
import { AxleHealth } from './Icons/AxleHealth';
import { ButterflyLabs } from './Icons/ButterflyLabs';
import { CustomerIO } from './Icons/CustomerIO';
import { Facebook } from './Icons/Facebook';
import { Formsort } from './Icons/Formsort';
import { Freshdesk } from './Icons/Freshdesk';
import { Google } from './Icons/Google';
import { Healthie } from './Icons/Healthie';
import { Hubspot } from './Icons/Hubspot';
import { IntakeQ } from './Icons/IntakeQ';
import { Intercom } from './Icons/Intercom';
import { Morf } from './Icons/Morf';
import { ReactNode, useMemo } from 'react';
import { Recurly } from './Icons/Recurly';
import { SanaBenefits } from './Icons/SanaBenefits';
import { Segment } from './Icons/Segment';
import { Slack } from './Icons/Slack';
import { Spruce } from './Icons/Spruce';
import { Vital } from './Icons/Vital';
import { useUrlParams } from '../../../apps/admin/components/helpers/useUrlParams';

export const berrySt = [
  '11c3e69d-38fc-4fe3-9ddb-2ce302fdf011',
  '59ed784b-b598-4fae-9141-ea02180093e4',
];

export const IntegrationIconMapping = (): { [key: string]: ReactNode } => {
  const { organizationId } = useUrlParams();

  return useMemo(
    () => ({
      acuity: <Acuity />,
      'active-campaign': <ActiveCampaign />,
      'axle-health': <AxleHealth />,
      'butterfly-labs': <ButterflyLabs />,
      customer: <Morf />,
      'customer-io':
        organizationId && berrySt.includes(organizationId) ? (
          <Morf />
        ) : (
          <CustomerIO />
        ),
      facebook: <Facebook />,
      formsort: <Formsort />,
      freshdesk: <Freshdesk />,
      google: <Google />,
      healthie: <Healthie />,
      hubspot: <Hubspot />,
      intakeq: <IntakeQ />,
      intercom: <Intercom />,
      morf: <Morf />,
      'push-json': <Morf />,
      'sana-benefits': <SanaBenefits />,
      recurly: <Recurly />,
      segment: <Segment />,
      spruce: <Spruce />,
      slack: <Slack />,
      vital: <Vital />,
    }),
    [organizationId]
  );
};
