import moment from 'moment';
import { TimeRangeFormProps, AbsoluteTimeframe } from './types';
import { Button } from '../../Button';
import { Container } from '../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Input } from '../../Input';
import { Text } from '../../Typography';
import { useForm } from 'react-hook-form';
import { StyledForm } from './TimeRangeForm.css';
import { timestampPattern } from './timestampPattern';
import { convertTimestampToRFC3339 } from './convertTimestamToRFC339';
import { convertRFC3339ToTimestamp } from './convertRFC339ToTimestamp';

const TimeRangeForm: FC<TimeRangeFormProps> = ({
  timeframe,
  setPlaceholder,
  setShowTimeRangeForm,
  setShowTimeframes,
  setTimeframe,
}) => {
  const { register, handleSubmit, formState } = useForm<AbsoluteTimeframe>({
    mode: 'onChange',
  });

  const onSubmit = (data: AbsoluteTimeframe) => {
    setTimeframe({
      startTimestamp: convertRFC3339ToTimestamp(data.startTimestamp),
      endTimestamp: convertRFC3339ToTimestamp(data.endTimestamp),
    });
    setPlaceholder(
      moment(data.startTimestamp).format('YYYY-MM-DD HH:mm:ss') +
        ' - ' +
        moment(data.endTimestamp).format('YYYY-MM-DD HH:mm:ss')
    );
    setShowTimeframes(false);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Flexbox
        data-testid='time-range-form'
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
      >
        <Container height='auto' borderType='borderBottom'>
          <Text tag='h5'>Time Range</Text>
        </Container>

        <Flexbox direction='column' height='auto' gap={0.25}>
          <Text tag='p2'>Start timestamp</Text>
          <Input
            id='startTimestamp'
            height='2.5rem'
            width='calc(100% - 0.25rem)'
            placeholder='2024-05-05T20:01:45.758Z'
            value={convertTimestampToRFC3339(timeframe.startTimestamp)}
            {...register('startTimestamp', {
              required: true,
              pattern: {
                value: timestampPattern,
                message: 'Invalid timestamp format',
              },
            })}
          />
        </Flexbox>

        <Flexbox direction='column' height='auto' gap={0.25}>
          <Text tag='p2'>End timestamp</Text>
          <Input
            id='endTimestamp'
            height='2.5rem'
            width='calc(100% - 0.25rem)'
            placeholder='2024-05-08T20:01:45.758Z'
            value={convertTimestampToRFC3339(timeframe.endTimestamp)}
            {...register('endTimestamp', {
              required: true,
              pattern: {
                value: timestampPattern,
                message: 'Invalid timestamp format',
              },
            })}
          />
        </Flexbox>

        <Flexbox
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          height='auto'
        >
          <Button
            variant='tertiary'
            type='button'
            onClick={() => setShowTimeRangeForm(false)}
          >
            <Flexbox
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              gap={0.25}
            >
              <Icon name='arrow-left' size={1} strokeWidth={1.75} />
              <Text tag='p2' color='inherit'>
                Back
              </Text>
            </Flexbox>
          </Button>
          <Button
            variant='primary'
            type='submit'
            isDisabled={!formState.isValid}
          >
            <Text tag='p2' color='inherit'>
              Apply
            </Text>
          </Button>
        </Flexbox>
      </Flexbox>
    </StyledForm>
  );
};

export const MemoizedTimeRangeForm = memo(TimeRangeForm);
