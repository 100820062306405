import { memo, useEffect, useRef, useState } from 'react';
import { DropdownOption } from './DropdownOption';
import { AddOption } from './AddOption';
import { DropdownInput } from './DropdownInput';
import { DropdownProps, Option } from './types';
import { StyledDropdown } from './Dropdown.css';
import { Input } from '../Input';
import { useTheme } from '@morf/theming';
import { Icon } from '../Icon';
import { useClickOutside } from '../Hooks/useClickOutside';
import { Flexbox } from '../Flexbox';
import { convertRemToPixel } from '../Helpers/convertRemToPixel';
import { Container } from '../Container';
import { includesLowerCase } from '../Helpers/includesLowercase';

const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  defaultOptions = [],
  options: initialOptions,
  borderType = 'border',
  onChange,
  addable = false,
  searchable = false,
  clearable = false,
  multiSelect = false,
  height = '2.5rem',
  iconName,
  iconSize,
  sortOptions = true,
  readOnly = false,
}) => {
  const theme = useTheme();
  const optionsMaxHeight = '16rem';
  const optionsContainerHeight = `${parseFloat(height) + 0.5}rem`;

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>(initialOptions);
  const [selectedOptions, setSelectedOptions] =
    useState<Option[]>(defaultOptions);
  const [searchInput, setSearchInput] = useState<string>('');

  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, (e) => {
    setShowOptions(false);
  });

  const handleOptionSelect = (option: Option) => {
    const updatedSelectedOptions = multiSelect
      ? isSelected(option)
        ? selectedOptions.filter(
            (selectedOption) => selectedOption.value !== option.value
          )
        : [...selectedOptions, option]
      : [option];

    setSelectedOptions(updatedSelectedOptions);
    !multiSelect && setShowOptions(false);
    if (onChange) {
      onChange(updatedSelectedOptions);
    }
  };

  let filteredOptions = options.filter((option) =>
    includesLowerCase(option.label, searchInput)
  );

  if (sortOptions) {
    filteredOptions = filteredOptions.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  }

  const isSelected = (option: Option) => {
    return selectedOptions.some(
      (selectedOption) =>
        selectedOption.value.toLowerCase() === option.value.toLowerCase()
    );
  };

  const optionsPositionAboveInput =
    dropdownRef.current &&
    window.innerHeight - dropdownRef.current.getBoundingClientRect().bottom <
      convertRemToPixel(optionsMaxHeight);

  const handleAddNewOption = (newOption: Option) => {
    setOptions([...options, newOption]);
    handleOptionSelect(newOption);
  };

  return (
    <StyledDropdown
      ref={dropdownRef}
      data-testid='dropdown'
      borderRadius={theme.input.borderRadius}
      backgroundColor={theme.colors.ui.card}
      borderType={borderType}
      borderStyle='solid'
      borderWidth={0.0625}
      showOptions={showOptions}
      borderColor={
        showOptions ? theme.colors.main.primary.darker : theme.colors.ui.divider
      }
      height={height}
    >
      <DropdownInput
        placeholder={placeholder}
        selectedOptions={selectedOptions}
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        onChange={handleOptionSelect}
        multiSelect={multiSelect}
        iconName={iconName}
        iconSize={iconSize}
        readOnly={readOnly}
      />

      {showOptions && (
        <Flexbox
          data-testid='options-container'
          direction='column'
          borderRadius={theme.input.borderRadius}
          backgroundColor={theme.colors.ui.card}
          height='auto'
          maxHeight={optionsMaxHeight}
          position='absolute'
          top={optionsPositionAboveInput ? 'auto' : optionsContainerHeight}
          bottom={optionsPositionAboveInput ? optionsContainerHeight : 'auto'}
          overflowY='scroll'
          shadow={'base'}
          p={0.5}
          gap={0}
        >
          {searchable && (
            <Container pb={0.5}>
              <Input
                id='search-input'
                value={searchInput}
                clearable={clearable}
                height={height}
                onChange={(e) => setSearchInput(e.target.value)}
                leftElement={
                  <Icon
                    name='search'
                    size={1.25}
                    stroke={theme.colors.text.muted}
                    cursor='pointer'
                  />
                }
                autoFocus
              />
            </Container>
          )}

          {filteredOptions.map((option, index) => (
            <DropdownOption
              key={index}
              option={option}
              isSelected={isSelected(option)}
              onChange={handleOptionSelect}
              multiSelect={multiSelect}
              readOnly={readOnly}
            />
          ))}

          {addable && !filteredOptions.length && !readOnly && (
            <AddOption value={searchInput} onClick={handleAddNewOption} />
          )}
        </Flexbox>
      )}
    </StyledDropdown>
  );
};

export const MemoizedDropdown = memo(Dropdown);
