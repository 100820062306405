import { NodeType } from '../types';
import { event_payload } from '@morf/proto/event_payload_v2_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { formsort } from '@morf/proto/formsort_v1_ts_proto';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { object_category } from '@morf/proto/object_category_v1_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export const formsortValidTriggerEventsResponse =
  new workflows.v1.GetValidTriggerEventsResponse({
    validTriggerEvents: {
      sourceApplicationObjectCategories: [
        {
          objectCategoryEventPayloads: [
            {
              eventPayloadEventTypes: [
                {
                  validPayloadEventTypes: new event_payload.v2.EventTypes({
                    formsortEventTypes: { eventTypes: [1] },
                  }),
                  payloadName: 'formsort_form_response',
                  payloadStub: new event_payload.v2.EventPayload({
                    formsortEventPayload:
                      new event_payload.v2.FormsortEventPayload({
                        formsortFormResponse:
                          new formsort.v1.FormsortFormResponse({}),
                      }),
                  }),
                },
              ],
              objectCategory: 1,
            },
          ],
          sourceApplication: 1,
          sourceAlreadyExists: true,
        },
      ],
    },
  });

export const mockObjectTypeIdsResponse =
  new workflows.v1.ListObjectTypeIDsResponse({
    ids: [
      new workflow_parameters.v1.ObjectTypeID({
        id: 'campaign-004',
        label: 'campaign-004',
      }),
      new workflow_parameters.v1.ObjectTypeID({
        id: 'intake_prod_id',
        label: 'intake_prod',
      }),
      new workflow_parameters.v1.ObjectTypeID({
        id: 'scheduling-in-formsort',
        label: 'scheduling-in-formsort',
      }),
    ],
  });

export const triggerNode = {
  id: 'trigger-node',
  type: NodeType.TriggerNode,
  data: {
    value: {
      title: 'Trigger',
      integrationIconName: 'formsort',
      name: 'Formsort Forms',
      configuration: new workflows.v1.Trigger({
        sourceApplication:
          source_application.v2.SourceApplication.Application.FORMSORT,
        objectCategory: object_category.v1.ObjectCategory.Category.FORM,
        triggerEvents: new event_payload.v2.EventTypes({
          formsortEventTypes: {
            eventTypes: [formsort.v1.FormsortEventType.FORMSORT_STEP_COMPLETED],
          },
        }),
        objectTypeFilters: ['campaign-004'],
        extraFilter: new workflows.v1.WorkflowFilter({
          celExpressionStr: '',
        }),
        eventPayloadStub: null,
        uniquenessConflictResolution: {
          conflictResolution:
            workflows.v1.WorkflowUniquenessConflictResolution.ConflictResolution
              .SKIP_NEW,
          scope:
            workflows.v1.WorkflowUniquenessConflictResolution.ConflictScope
              .WORKFLOW,
        },
      }),
    },
  },
  position: { x: 0, y: 0 },
};
