import { ColumnType } from '../../../../Table';
import { ParamSourceConfigProps } from '../../../types';
import { ParameterConfig } from '../../../../ParameterConfig';
import { ProfileUpdateIdentifiers } from './types';
import { SourceConfig } from '../../../../SourceConfig';

export const columns: ColumnType<ParamSourceConfigProps> = [
  {
    id: 'param',
    accessorKey: 'param',
    header: 'Profile Ids',
    enablePinning: false,
    cell: (row) => {
      const param = row.getValue<ParamSourceConfigProps['param']>();
      return <ParameterConfig {...param} />;
    },
    cellBorderType: 'borderTop',
    cellPb: 0.5,
    cellPl: 0.5,
    cellPr: 0.5,
    cellPt: 0.5,
    headCellBorderType: 'borderBottom',
    headCellPl: 0.5,
    headCellPr: 0.5,
    size: 240,
  },
  {
    id: 'source',
    accessorKey: 'source',
    header: 'Source event fields',
    cell: (row) => {
      const source = row.getValue<ProfileUpdateIdentifiers['source']>();
      return <SourceConfig {...source} />;
    },
    cellBorderType: 'borderTopLeft',
    cellPb: 0.5,
    cellPl: 0.5,
    cellPr: 0.5,
    cellPt: 0.5,
    headCellBorderType: 'borderBottom',
    headCellPl: 0.5,
    headCellPr: 0.5,
    size: 300,
  },
];
