import _ from 'lodash';
import { FC, memo, useState } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Input } from '../../Input';
import { IntegrationIcon } from '../../IntegrationIcon';
import { NodePanelProps } from './types';
import { NodeType } from '../../Workflow/types';
import { Tab, Tabs } from '../../Tabs';
import { Text } from '../../Typography';
import { convertToTitleCase } from '../../Helpers/convertToTitleCase';
import { iconNameNodeTypeMap } from '../../Workflow/CustomNode/constants';
import { useTheme } from '@morf/theming';
import { useWorkflow } from '../../../../apps/admin/components/context/workflow/useWorkflow';

const NodePanel: FC<NodePanelProps> = ({
  node,
  onClose,
  onUpdateDescription,
  onUpdateName,
  tabs,
}) => {
  const theme = useTheme();
  const { nodes, setNodes } = useWorkflow();

  const type = node.type! as NodeType;

  const { name, description, integrationIconName, isReadOnly, isRequired } =
    node.data.value;

  const [selectedTab, setSelectedTab] = useState(0);

  const [editName, setEditName] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);
  const isReadOnlyName =
    isReadOnly ||
    type === NodeType.TriggerNode ||
    type === NodeType.ProfileLookupNode;

  const [editDescription, setEditDescription] = useState(false);
  const [updatedDescription, setUpdatedDescription] = useState(description);

  const handleNameUpdate = () => {
    if (isRequired) {
      const updatedNodes = nodes.map((n) => {
        if (n.id === node.id) {
          return {
            ...n,
            data: {
              ...n.data,
              value: {
                ...n.data.value,
                name: updatedName,
              },
            },
          };
        }
        return n;
      });
      setNodes(updatedNodes);
    } else {
      onUpdateName &&
        onUpdateName({
          name: updatedName,
        });
    }
  };

  const handleDescriptionUpdate = () => {
    if (isRequired) {
      const updatedNodes = nodes.map((n) => {
        if (n.id === node.id) {
          return {
            ...n,
            data: {
              ...n.data,
              value: {
                ...n.data.value,
                description: updatedDescription,
              },
            },
          };
        }
        return n;
      });
      setNodes(updatedNodes);
    } else {
      onUpdateDescription &&
        onUpdateDescription({
          description: updatedDescription,
        });
    }
  };

  return (
    <Flexbox
      key={node.id}
      data-testid='workflow-panel'
      direction='column'
      justifyContent='flex-start'
      backgroundColor={theme.colors.ui.card}
      gap={0}
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        height='auto'
        gap={1.25}
        pt={1.25}
      >
        <Flexbox
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          height='auto'
          px={1.25}
        >
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={0.25}
          >
            {integrationIconName && (
              <IntegrationIcon name={integrationIconName} />
            )}
            {iconNameNodeTypeMap[type] && !integrationIconName && (
              <Icon
                name={iconNameNodeTypeMap[type]!}
                stroke={theme.colors.text.muted}
                size={1.25}
              />
            )}
            <Text tag='p2' color={theme.colors.text.muted}>
              {node.data.value.title}
            </Text>
          </Flexbox>
          <Icon
            name='close'
            stroke={theme.colors.text.muted}
            onClick={onClose}
            cursor='pointer'
          />
        </Flexbox>

        <Flexbox
          direction='column'
          justifyContent='flex-start'
          height='auto'
          gap={0}
          px={0.75}
        >
          <Input
            id='name'
            value={name}
            tag='h2'
            borderColor={
              editName && !isReadOnlyName
                ? theme.colors.ui.divider
                : theme.colors.ui.card
            }
            onChange={(e) => setUpdatedName(e.target.value)}
            onMouseEnter={() => setEditName(true)}
            onMouseLeave={() => setEditName(false)}
            readOnly={isReadOnlyName}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && updatedName) {
                handleNameUpdate();
              }
            }}
            p={0.5}
          />
          {type !== NodeType.TriggerNode &&
            type !== NodeType.ProfileLookupNode && (
              <Input
                id='description'
                placeholder='Add description'
                value={description}
                borderColor={
                  editDescription && !isReadOnly
                    ? theme.colors.ui.divider
                    : theme.colors.ui.card
                }
                onChange={(e) => setUpdatedDescription(e.target.value)}
                onMouseEnter={() => setEditDescription(true)}
                onMouseLeave={() => setEditDescription(false)}
                color={theme.colors.ui.dark}
                readOnly={isReadOnly}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && updatedDescription) {
                    handleDescriptionUpdate();
                  }
                }}
                p={0.5}
              />
            )}
        </Flexbox>
      </Flexbox>
      {_.isEqual(tabs.length, 1) ? (
        <Flexbox p={1.25} overflow='scroll'>
          {tabs[0].children}
        </Flexbox>
      ) : (
        <Tabs
          initialValue={selectedTab}
          onChange={(selectedIndex) => setSelectedTab(selectedIndex)}
          pt={0.5}
          height='3rem'
        >
          {tabs.map((tab, index) => {
            return (
              <Tab key={index} label={tab.name} p={1.25}>
                {tab.children}
              </Tab>
            );
          })}
        </Tabs>
      )}
    </Flexbox>
  );
};

export const MemoizedNodePanel = memo(NodePanel);
