'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { pagination as pagination$1 } from './pagination_v1_ts_proto';
import { sensitivity as sensitivity$1 } from './sensitivity_v1_ts_proto';
import { google as google$1 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { values as values$1 } from './values_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.patients = (() => {
  const patients = {};
  patients.v1 = (() => {
    const v1 = {};
    v1.CreatePatientRequest = (() => {
      class CreatePatientRequest {
        constructor(properties) {
          this.applicationIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerProvidedId != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedId')
          ) {
            writer.uint32(10).string(message.customerProvidedId);
          }
          if (
            message.applicationIds != null &&
            Object.hasOwnProperty.call(message, 'applicationIds')
          ) {
            for (const element of message.applicationIds) {
              $root.patients.v1.CreatePatientApplicationId.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 2: {
                if (!message.applicationIds || !message.applicationIds.length) {
                  message.applicationIds = [];
                }
                message.applicationIds.push(
                  $root.patients.v1.CreatePatientApplicationId.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.CreatePatientRequest) {
            return object;
          }
          const message = new $root.patients.v1.CreatePatientRequest();
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.applicationIds) {
            if (!Array.isArray(object.applicationIds)) {
              throw new TypeError(
                '.patients.v1.CreatePatientRequest.applicationIds: array type expected, but got ' +
                  typeof object.applicationIds
              );
            }
            message.applicationIds = new Array(object.applicationIds.length);
            for (let i = 0; i < object.applicationIds.length; ++i) {
              if (typeof object.applicationIds[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.CreatePatientRequest.applicationIds: object expected, but got ' +
                    typeof object.applicationIds[i]
                );
              }
              message.applicationIds[i] =
                $root.patients.v1.CreatePatientApplicationId.fromObject(
                  object.applicationIds[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.applicationIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.customerProvidedId = '';
          }
          let keys;
          if (
            message.customerProvidedId != null &&
            message.hasOwnProperty('customerProvidedId')
          ) {
            object.customerProvidedId = message.customerProvidedId;
          }
          if (message.applicationIds && message.applicationIds.length) {
            object.applicationIds = new Array(message.applicationIds.length);
            for (let i = 0; i < message.applicationIds.length; ++i) {
              object.applicationIds[i] =
                $root.patients.v1.CreatePatientApplicationId.toObject(
                  message.applicationIds[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.CreatePatientRequest';
        }
      }

      CreatePatientRequest.prototype.customerProvidedId = '';
      CreatePatientRequest.prototype.applicationIds = $util.emptyArray;

      return CreatePatientRequest;
    })();

    v1.CreatePatientApplicationId = (() => {
      class CreatePatientApplicationId {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientApplicationId(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.idOnApplication != null &&
            Object.hasOwnProperty.call(message, 'idOnApplication')
          ) {
            writer.uint32(10).string(message.idOnApplication);
          }
          if (
            message.applicationType != null &&
            Object.hasOwnProperty.call(message, 'applicationType')
          ) {
            writer.uint32(16).int32(message.applicationType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientApplicationId();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.idOnApplication = reader.string();
                break;
              }
              case 2: {
                message.applicationType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.CreatePatientApplicationId) {
            return object;
          }
          const message = new $root.patients.v1.CreatePatientApplicationId();
          if (object.idOnApplication != null) {
            message.idOnApplication = String(object.idOnApplication);
          }
          if (object.applicationType != null) {
            switch (object.applicationType) {
              case 'UNSPECIFIED_PATIENT_APPLICATION':
              case 0: {
                message.applicationType = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.applicationType = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.applicationType = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.applicationType = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.applicationType = 4;
                break;
              }
              case 'RECURLY':
              case 5: {
                message.applicationType = 5;
                break;
              }
              case 'INTERCOM':
              case 6: {
                message.applicationType = 6;
                break;
              }
              case 'SANA_BENEFITS':
              case 7: {
                message.applicationType = 7;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 8: {
                message.applicationType = 8;
                break;
              }
              case 'VITAL':
              case 9: {
                message.applicationType = 9;
                break;
              }
              case 'SEGMENT':
              case 10: {
                message.applicationType = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.applicationType = 11;
                break;
              }
              default: {
                if (typeof object.applicationType == 'number') {
                  message.applicationType = object.applicationType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.idOnApplication = '';
            object.applicationType =
              options.enums === String ? 'UNSPECIFIED_PATIENT_APPLICATION' : 0;
          }
          let keys;
          if (
            message.idOnApplication != null &&
            message.hasOwnProperty('idOnApplication')
          ) {
            object.idOnApplication = message.idOnApplication;
          }
          if (
            message.applicationType != null &&
            message.hasOwnProperty('applicationType')
          ) {
            object.applicationType =
              options.enums === String
                ? $root.patients.v1.PatientApplication[
                    message.applicationType
                  ] === undefined
                  ? message.applicationType
                  : $root.patients.v1.PatientApplication[
                      message.applicationType
                    ]
                : message.applicationType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.CreatePatientApplicationId';
        }
      }

      CreatePatientApplicationId.prototype.idOnApplication = '';
      CreatePatientApplicationId.prototype.applicationType = 0;

      return CreatePatientApplicationId;
    })();

    v1.CreatePatientResponse = (() => {
      class CreatePatientResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.patient != null &&
            Object.hasOwnProperty.call(message, 'patient')
          ) {
            $root.patients.v1.Patient.encode(
              message.patient,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.patient = $root.patients.v1.Patient.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.CreatePatientResponse) {
            return object;
          }
          const message = new $root.patients.v1.CreatePatientResponse();
          if (object.patient != null) {
            if (typeof object.patient !== 'object') {
              throw new TypeError(
                '.patients.v1.CreatePatientResponse.patient: object expected, but got ' +
                  typeof object.patient
              );
            }
            message.patient = $root.patients.v1.Patient.fromObject(
              object.patient
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.patient = null;
          }
          let keys;
          if (message.patient != null && message.hasOwnProperty('patient')) {
            object.patient = $root.patients.v1.Patient.toObject(
              message.patient,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.CreatePatientResponse';
        }
      }

      CreatePatientResponse.prototype.patient = null;

      return CreatePatientResponse;
    })();

    v1.Patient = (() => {
      class Patient {
        constructor(properties) {
          this.applicationIds = [];
          this.morfProperties = {};
          this.properties = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Patient(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.customerProvidedId != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedId')
          ) {
            writer.uint32(18).string(message.customerProvidedId);
          }
          if (
            message.applicationIds != null &&
            Object.hasOwnProperty.call(message, 'applicationIds')
          ) {
            for (const element of message.applicationIds) {
              $root.patients.v1.PatientApplicationId.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.morfProperties != null &&
            Object.hasOwnProperty.call(message, 'morfProperties')
          ) {
            for (const key of Object.keys(message.morfProperties)) {
              writer.uint32(42).fork().uint32(10).string(key);
              $root.patients.v1.PatientPropertyCurrentValue.encode(
                message.morfProperties[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          if (
            message.properties != null &&
            Object.hasOwnProperty.call(message, 'properties')
          ) {
            for (const key of Object.keys(message.properties)) {
              writer.uint32(50).fork().uint32(10).string(key);
              $root.patients.v1.PatientPropertyCurrentValue.encode(
                message.properties[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Patient();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 3: {
                if (!message.applicationIds || !message.applicationIds.length) {
                  message.applicationIds = [];
                }
                message.applicationIds.push(
                  $root.patients.v1.PatientApplicationId.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 4: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                if (message.morfProperties === $util.emptyObject)
                  message.morfProperties = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value =
                        $root.patients.v1.PatientPropertyCurrentValue.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                  }
                  message.morfProperties[key] = value;
                }
                break;
              }
              case 6: {
                if (message.properties === $util.emptyObject)
                  message.properties = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value =
                        $root.patients.v1.PatientPropertyCurrentValue.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                  }
                  message.properties[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.Patient) {
            return object;
          }
          const message = new $root.patients.v1.Patient();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.applicationIds) {
            if (!Array.isArray(object.applicationIds)) {
              throw new TypeError(
                '.patients.v1.Patient.applicationIds: array type expected, but got ' +
                  typeof object.applicationIds
              );
            }
            message.applicationIds = new Array(object.applicationIds.length);
            for (let i = 0; i < object.applicationIds.length; ++i) {
              if (typeof object.applicationIds[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.Patient.applicationIds: object expected, but got ' +
                    typeof object.applicationIds[i]
                );
              }
              message.applicationIds[i] =
                $root.patients.v1.PatientApplicationId.fromObject(
                  object.applicationIds[i]
                );
            }
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.patients.v1.Patient.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.morfProperties) {
            if (typeof object.morfProperties !== 'object') {
              throw new TypeError(
                '.patients.v1.Patient.morfProperties: object expected, but got ' +
                  typeof object.morfProperties
              );
            }
            message.morfProperties = {};
            for (
              let keys = Object.keys(object.morfProperties), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.morfProperties[keys[i]] !== 'object') {
                throw new TypeError(
                  '.patients.v1.Patient.value: object expected, but got ' +
                    typeof object.morfProperties[keys[i]]
                );
              }
              message.morfProperties[keys[i]] =
                $root.patients.v1.PatientPropertyCurrentValue.fromObject(
                  object.morfProperties[keys[i]]
                );
            }
          }
          if (object.properties) {
            if (typeof object.properties !== 'object') {
              throw new TypeError(
                '.patients.v1.Patient.properties: object expected, but got ' +
                  typeof object.properties
              );
            }
            message.properties = {};
            for (
              let keys = Object.keys(object.properties), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.properties[keys[i]] !== 'object') {
                throw new TypeError(
                  '.patients.v1.Patient.value: object expected, but got ' +
                    typeof object.properties[keys[i]]
                );
              }
              message.properties[keys[i]] =
                $root.patients.v1.PatientPropertyCurrentValue.fromObject(
                  object.properties[keys[i]]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.applicationIds = [];
          }
          if (options.objects || options.defaults) {
            object.morfProperties = {};
            object.properties = {};
          }
          if (options.defaults) {
            object.id = '';
            object.customerProvidedId = '';
            object.updatedAt = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.customerProvidedId != null &&
            message.hasOwnProperty('customerProvidedId')
          ) {
            object.customerProvidedId = message.customerProvidedId;
          }
          if (message.applicationIds && message.applicationIds.length) {
            object.applicationIds = new Array(message.applicationIds.length);
            for (let i = 0; i < message.applicationIds.length; ++i) {
              object.applicationIds[i] =
                $root.patients.v1.PatientApplicationId.toObject(
                  message.applicationIds[i],
                  options
                );
            }
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.morfProperties &&
            (keys = Object.keys(message.morfProperties)).length
          ) {
            object.morfProperties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.morfProperties[keys[i]] =
                $root.patients.v1.PatientPropertyCurrentValue.toObject(
                  message.morfProperties[keys[i]],
                  options
                );
            }
          }
          if (
            message.properties &&
            (keys = Object.keys(message.properties)).length
          ) {
            object.properties = {};
            for (let i = 0; i < keys.length; ++i) {
              object.properties[keys[i]] =
                $root.patients.v1.PatientPropertyCurrentValue.toObject(
                  message.properties[keys[i]],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.Patient';
        }
      }

      Patient.prototype.id = '';
      Patient.prototype.customerProvidedId = '';
      Patient.prototype.applicationIds = $util.emptyArray;
      Patient.prototype.updatedAt = null;
      Patient.prototype.morfProperties = $util.emptyObject;
      Patient.prototype.properties = $util.emptyObject;

      return Patient;
    })();

    v1.PatientPropertyCurrentValue = (() => {
      class PatientPropertyCurrentValue {
        constructor(properties) {
          this.fields = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertyCurrentValue(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            values$1.v1.Value.encode(
              message.value,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.time != null &&
            Object.hasOwnProperty.call(message, 'time')
          ) {
            timing$1.v1.Timestamp.encode(
              message.time,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.propertyId != null &&
            Object.hasOwnProperty.call(message, 'propertyId')
          ) {
            writer.uint32(26).string(message.propertyId);
          }
          if (
            message.fields != null &&
            Object.hasOwnProperty.call(message, 'fields')
          ) {
            for (const key of Object.keys(message.fields)) {
              writer.uint32(34).fork().uint32(10).string(key);
              $root.patients.v1.PatientPropertyCurrentValue.encode(
                message.fields[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertyCurrentValue();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.value = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.time = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.propertyId = reader.string();
                break;
              }
              case 4: {
                if (message.fields === $util.emptyObject) message.fields = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value =
                        $root.patients.v1.PatientPropertyCurrentValue.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                  }
                  message.fields[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.PatientPropertyCurrentValue) {
            return object;
          }
          const message = new $root.patients.v1.PatientPropertyCurrentValue();
          if (object.value != null) {
            if (typeof object.value !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientPropertyCurrentValue.value: object expected, but got ' +
                  typeof object.value
              );
            }
            message.value = values$1.v1.Value.fromObject(object.value);
          }
          if (object.time != null) {
            if (typeof object.time !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientPropertyCurrentValue.time: object expected, but got ' +
                  typeof object.time
              );
            }
            message.time = timing$1.v1.Timestamp.fromObject(object.time);
          }
          if (object.propertyId != null) {
            message.propertyId = String(object.propertyId);
          }
          if (object.fields) {
            if (typeof object.fields !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientPropertyCurrentValue.fields: object expected, but got ' +
                  typeof object.fields
              );
            }
            message.fields = {};
            for (
              let keys = Object.keys(object.fields), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.fields[keys[i]] !== 'object') {
                throw new TypeError(
                  '.patients.v1.PatientPropertyCurrentValue.value: object expected, but got ' +
                    typeof object.fields[keys[i]]
                );
              }
              message.fields[keys[i]] =
                $root.patients.v1.PatientPropertyCurrentValue.fromObject(
                  object.fields[keys[i]]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
            object.fields = {};
          }
          if (options.defaults) {
            object.time = null;
            object.propertyId = '';
          }
          let keys;
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = values$1.v1.Value.toObject(message.value, options);
          }
          if (message.time != null && message.hasOwnProperty('time')) {
            object.time = timing$1.v1.Timestamp.toObject(message.time, options);
          }
          if (
            message.propertyId != null &&
            message.hasOwnProperty('propertyId')
          ) {
            object.propertyId = message.propertyId;
          }
          if (message.fields && (keys = Object.keys(message.fields)).length) {
            object.fields = {};
            for (let i = 0; i < keys.length; ++i) {
              object.fields[keys[i]] =
                $root.patients.v1.PatientPropertyCurrentValue.toObject(
                  message.fields[keys[i]],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.PatientPropertyCurrentValue';
        }
      }

      PatientPropertyCurrentValue.prototype.value = null;
      PatientPropertyCurrentValue.prototype.time = null;
      PatientPropertyCurrentValue.prototype.propertyId = '';
      PatientPropertyCurrentValue.prototype.fields = $util.emptyObject;

      return PatientPropertyCurrentValue;
    })();

    v1.PatientApplicationId = (() => {
      class PatientApplicationId {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientApplicationId(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.idOnApplication != null &&
            Object.hasOwnProperty.call(message, 'idOnApplication')
          ) {
            writer.uint32(10).string(message.idOnApplication);
          }
          if (
            message.applicationType != null &&
            Object.hasOwnProperty.call(message, 'applicationType')
          ) {
            writer.uint32(16).int32(message.applicationType);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientApplicationId();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.idOnApplication = reader.string();
                break;
              }
              case 2: {
                message.applicationType = reader.int32();
                break;
              }
              case 3: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.PatientApplicationId) {
            return object;
          }
          const message = new $root.patients.v1.PatientApplicationId();
          if (object.idOnApplication != null) {
            message.idOnApplication = String(object.idOnApplication);
          }
          if (object.applicationType != null) {
            switch (object.applicationType) {
              case 'UNSPECIFIED_PATIENT_APPLICATION':
              case 0: {
                message.applicationType = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.applicationType = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.applicationType = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.applicationType = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.applicationType = 4;
                break;
              }
              case 'RECURLY':
              case 5: {
                message.applicationType = 5;
                break;
              }
              case 'INTERCOM':
              case 6: {
                message.applicationType = 6;
                break;
              }
              case 'SANA_BENEFITS':
              case 7: {
                message.applicationType = 7;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 8: {
                message.applicationType = 8;
                break;
              }
              case 'VITAL':
              case 9: {
                message.applicationType = 9;
                break;
              }
              case 'SEGMENT':
              case 10: {
                message.applicationType = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.applicationType = 11;
                break;
              }
              default: {
                if (typeof object.applicationType == 'number') {
                  message.applicationType = object.applicationType;
                  break;
                }
                break;
              }
            }
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientApplicationId.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientApplicationId.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.idOnApplication = '';
            object.applicationType =
              options.enums === String ? 'UNSPECIFIED_PATIENT_APPLICATION' : 0;
            object.updatedAt = null;
            object.createdAt = null;
          }
          let keys;
          if (
            message.idOnApplication != null &&
            message.hasOwnProperty('idOnApplication')
          ) {
            object.idOnApplication = message.idOnApplication;
          }
          if (
            message.applicationType != null &&
            message.hasOwnProperty('applicationType')
          ) {
            object.applicationType =
              options.enums === String
                ? $root.patients.v1.PatientApplication[
                    message.applicationType
                  ] === undefined
                  ? message.applicationType
                  : $root.patients.v1.PatientApplication[
                      message.applicationType
                    ]
                : message.applicationType;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.PatientApplicationId';
        }
      }

      PatientApplicationId.prototype.idOnApplication = '';
      PatientApplicationId.prototype.applicationType = 0;
      PatientApplicationId.prototype.updatedAt = null;
      PatientApplicationId.prototype.createdAt = null;

      return PatientApplicationId;
    })();

    v1.PatientApplicationIdLookup = (() => {
      class PatientApplicationIdLookup {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientApplicationIdLookup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.idOnApplication != null &&
            Object.hasOwnProperty.call(message, 'idOnApplication')
          ) {
            writer.uint32(10).string(message.idOnApplication);
          }
          if (
            message.applicationType != null &&
            Object.hasOwnProperty.call(message, 'applicationType')
          ) {
            writer.uint32(16).int32(message.applicationType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientApplicationIdLookup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.idOnApplication = reader.string();
                break;
              }
              case 2: {
                message.applicationType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.PatientApplicationIdLookup) {
            return object;
          }
          const message = new $root.patients.v1.PatientApplicationIdLookup();
          if (object.idOnApplication != null) {
            message.idOnApplication = String(object.idOnApplication);
          }
          if (object.applicationType != null) {
            switch (object.applicationType) {
              case 'UNSPECIFIED_PATIENT_APPLICATION':
              case 0: {
                message.applicationType = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.applicationType = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.applicationType = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.applicationType = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.applicationType = 4;
                break;
              }
              case 'RECURLY':
              case 5: {
                message.applicationType = 5;
                break;
              }
              case 'INTERCOM':
              case 6: {
                message.applicationType = 6;
                break;
              }
              case 'SANA_BENEFITS':
              case 7: {
                message.applicationType = 7;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 8: {
                message.applicationType = 8;
                break;
              }
              case 'VITAL':
              case 9: {
                message.applicationType = 9;
                break;
              }
              case 'SEGMENT':
              case 10: {
                message.applicationType = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.applicationType = 11;
                break;
              }
              default: {
                if (typeof object.applicationType == 'number') {
                  message.applicationType = object.applicationType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.idOnApplication = '';
            object.applicationType =
              options.enums === String ? 'UNSPECIFIED_PATIENT_APPLICATION' : 0;
          }
          let keys;
          if (
            message.idOnApplication != null &&
            message.hasOwnProperty('idOnApplication')
          ) {
            object.idOnApplication = message.idOnApplication;
          }
          if (
            message.applicationType != null &&
            message.hasOwnProperty('applicationType')
          ) {
            object.applicationType =
              options.enums === String
                ? $root.patients.v1.PatientApplication[
                    message.applicationType
                  ] === undefined
                  ? message.applicationType
                  : $root.patients.v1.PatientApplication[
                      message.applicationType
                    ]
                : message.applicationType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.PatientApplicationIdLookup';
        }
      }

      PatientApplicationIdLookup.prototype.idOnApplication = '';
      PatientApplicationIdLookup.prototype.applicationType = 0;

      return PatientApplicationIdLookup;
    })();

    v1.PatientIdLookup = (() => {
      class PatientIdLookup {
        get id() {
          for (const key of [
            'morfId',
            'customerProvidedId',
            'patientApplicationId',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set id(name) {
          for (const key of [
            'morfId',
            'customerProvidedId',
            'patientApplicationId',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientIdLookup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.morfId != null &&
            Object.hasOwnProperty.call(message, 'morfId')
          ) {
            writer.uint32(10).string(message.morfId);
          }
          if (
            message.customerProvidedId != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedId')
          ) {
            writer.uint32(18).string(message.customerProvidedId);
          }
          if (
            message.patientApplicationId != null &&
            Object.hasOwnProperty.call(message, 'patientApplicationId')
          ) {
            $root.patients.v1.PatientApplicationIdLookup.encode(
              message.patientApplicationId,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientIdLookup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.morfId = reader.string();
                break;
              }
              case 2: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 3: {
                message.patientApplicationId =
                  $root.patients.v1.PatientApplicationIdLookup.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.PatientIdLookup) {
            return object;
          }
          const message = new $root.patients.v1.PatientIdLookup();
          if (object.morfId != null) {
            message.morfId = String(object.morfId);
          }
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.patientApplicationId != null) {
            if (typeof object.patientApplicationId !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientIdLookup.patientApplicationId: object expected, but got ' +
                  typeof object.patientApplicationId
              );
            }
            message.patientApplicationId =
              $root.patients.v1.PatientApplicationIdLookup.fromObject(
                object.patientApplicationId
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.morfId != null && message.hasOwnProperty('morfId')) {
            object.morfId = message.morfId;
            if (options.oneofs) {
              object.id = 'morfId';
            }
          }
          if (
            message.customerProvidedId != null &&
            message.hasOwnProperty('customerProvidedId')
          ) {
            object.customerProvidedId = message.customerProvidedId;
            if (options.oneofs) {
              object.id = 'customerProvidedId';
            }
          }
          if (
            message.patientApplicationId != null &&
            message.hasOwnProperty('patientApplicationId')
          ) {
            object.patientApplicationId =
              $root.patients.v1.PatientApplicationIdLookup.toObject(
                message.patientApplicationId,
                options
              );
            if (options.oneofs) {
              object.id = 'patientApplicationId';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.PatientIdLookup';
        }
      }

      PatientIdLookup.prototype.morfId = '';
      PatientIdLookup.prototype.customerProvidedId = '';
      PatientIdLookup.prototype.patientApplicationId = null;

      return PatientIdLookup;
    })();

    v1.GetPatientRequest = (() => {
      class GetPatientRequest {
        constructor(properties) {
          this.morfPropertyLookups = [];
          this.propertyLookups = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            $root.patients.v1.PatientIdLookup.encode(
              message.id,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.morfPropertyLookups != null &&
            Object.hasOwnProperty.call(message, 'morfPropertyLookups')
          ) {
            for (const element of message.morfPropertyLookups) {
              writer.uint32(18).string(element);
            }
          }
          if (
            message.propertyLookups != null &&
            Object.hasOwnProperty.call(message, 'propertyLookups')
          ) {
            for (const element of message.propertyLookups) {
              writer.uint32(26).string(element);
            }
          }
          if (
            message.fetchAllProperties != null &&
            Object.hasOwnProperty.call(message, 'fetchAllProperties')
          ) {
            writer.uint32(32).bool(message.fetchAllProperties);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = $root.patients.v1.PatientIdLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                if (
                  !message.morfPropertyLookups ||
                  !message.morfPropertyLookups.length
                ) {
                  message.morfPropertyLookups = [];
                }
                message.morfPropertyLookups.push(reader.string());
                break;
              }
              case 3: {
                if (
                  !message.propertyLookups ||
                  !message.propertyLookups.length
                ) {
                  message.propertyLookups = [];
                }
                message.propertyLookups.push(reader.string());
                break;
              }
              case 4: {
                message.fetchAllProperties = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.GetPatientRequest) {
            return object;
          }
          const message = new $root.patients.v1.GetPatientRequest();
          if (object.id != null) {
            if (typeof object.id !== 'object') {
              throw new TypeError(
                '.patients.v1.GetPatientRequest.id: object expected, but got ' +
                  typeof object.id
              );
            }
            message.id = $root.patients.v1.PatientIdLookup.fromObject(
              object.id
            );
          }
          if (object.morfPropertyLookups) {
            if (!Array.isArray(object.morfPropertyLookups)) {
              throw new TypeError(
                '.patients.v1.GetPatientRequest.morfPropertyLookups: array type expected, but got ' +
                  typeof object.morfPropertyLookups
              );
            }
            message.morfPropertyLookups = new Array(
              object.morfPropertyLookups.length
            );
            for (let i = 0; i < object.morfPropertyLookups.length; ++i) {
              message.morfPropertyLookups[i] = String(
                object.morfPropertyLookups[i]
              );
            }
          }
          if (object.propertyLookups) {
            if (!Array.isArray(object.propertyLookups)) {
              throw new TypeError(
                '.patients.v1.GetPatientRequest.propertyLookups: array type expected, but got ' +
                  typeof object.propertyLookups
              );
            }
            message.propertyLookups = new Array(object.propertyLookups.length);
            for (let i = 0; i < object.propertyLookups.length; ++i) {
              message.propertyLookups[i] = String(object.propertyLookups[i]);
            }
          }
          if (object.fetchAllProperties != null) {
            message.fetchAllProperties = Boolean(object.fetchAllProperties);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.morfPropertyLookups = [];
            object.propertyLookups = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = null;
            object.fetchAllProperties = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = $root.patients.v1.PatientIdLookup.toObject(
              message.id,
              options
            );
          }
          if (
            message.morfPropertyLookups &&
            message.morfPropertyLookups.length
          ) {
            object.morfPropertyLookups = new Array(
              message.morfPropertyLookups.length
            );
            for (let i = 0; i < message.morfPropertyLookups.length; ++i) {
              object.morfPropertyLookups[i] = message.morfPropertyLookups[i];
            }
          }
          if (message.propertyLookups && message.propertyLookups.length) {
            object.propertyLookups = new Array(message.propertyLookups.length);
            for (let i = 0; i < message.propertyLookups.length; ++i) {
              object.propertyLookups[i] = message.propertyLookups[i];
            }
          }
          if (
            message.fetchAllProperties != null &&
            message.hasOwnProperty('fetchAllProperties')
          ) {
            object.fetchAllProperties = message.fetchAllProperties;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.GetPatientRequest';
        }
      }

      GetPatientRequest.prototype.id = null;
      GetPatientRequest.prototype.morfPropertyLookups = $util.emptyArray;
      GetPatientRequest.prototype.propertyLookups = $util.emptyArray;
      GetPatientRequest.prototype.fetchAllProperties = false;

      return GetPatientRequest;
    })();

    v1.GetPatientPropertiesRequest = (() => {
      class GetPatientPropertiesRequest {
        constructor(properties) {
          this.property = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientPropertiesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            $root.patients.v1.PatientIdLookup.encode(
              message.id,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.property != null &&
            Object.hasOwnProperty.call(message, 'property')
          ) {
            for (const element of message.property) {
              writer.uint32(18).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientPropertiesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = $root.patients.v1.PatientIdLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                if (!message.property || !message.property.length) {
                  message.property = [];
                }
                message.property.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.GetPatientPropertiesRequest) {
            return object;
          }
          const message = new $root.patients.v1.GetPatientPropertiesRequest();
          if (object.id != null) {
            if (typeof object.id !== 'object') {
              throw new TypeError(
                '.patients.v1.GetPatientPropertiesRequest.id: object expected, but got ' +
                  typeof object.id
              );
            }
            message.id = $root.patients.v1.PatientIdLookup.fromObject(
              object.id
            );
          }
          if (object.property) {
            if (!Array.isArray(object.property)) {
              throw new TypeError(
                '.patients.v1.GetPatientPropertiesRequest.property: array type expected, but got ' +
                  typeof object.property
              );
            }
            message.property = new Array(object.property.length);
            for (let i = 0; i < object.property.length; ++i) {
              message.property[i] = String(object.property[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.property = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = $root.patients.v1.PatientIdLookup.toObject(
              message.id,
              options
            );
          }
          if (message.property && message.property.length) {
            object.property = new Array(message.property.length);
            for (let i = 0; i < message.property.length; ++i) {
              object.property[i] = message.property[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.GetPatientPropertiesRequest';
        }
      }

      GetPatientPropertiesRequest.prototype.id = null;
      GetPatientPropertiesRequest.prototype.property = $util.emptyArray;

      return GetPatientPropertiesRequest;
    })();

    v1.ListPatientsRequest = (() => {
      class ListPatientsRequest {
        constructor(properties) {
          this.morfPropertyLookups = [];
          this.propertyLookups = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.pagination != null &&
            Object.hasOwnProperty.call(message, 'pagination')
          ) {
            pagination$1.v1.Pagination.encode(
              message.pagination,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.morfPropertyLookups != null &&
            Object.hasOwnProperty.call(message, 'morfPropertyLookups')
          ) {
            for (const element of message.morfPropertyLookups) {
              writer.uint32(18).string(element);
            }
          }
          if (
            message.propertyLookups != null &&
            Object.hasOwnProperty.call(message, 'propertyLookups')
          ) {
            for (const element of message.propertyLookups) {
              writer.uint32(26).string(element);
            }
          }
          if (
            message.fetchAllProperties != null &&
            Object.hasOwnProperty.call(message, 'fetchAllProperties')
          ) {
            writer.uint32(32).bool(message.fetchAllProperties);
          }
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.patients.v1.ListPatientsFilter.encode(
              message.filter,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.pagination = pagination$1.v1.Pagination.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                if (
                  !message.morfPropertyLookups ||
                  !message.morfPropertyLookups.length
                ) {
                  message.morfPropertyLookups = [];
                }
                message.morfPropertyLookups.push(reader.string());
                break;
              }
              case 3: {
                if (
                  !message.propertyLookups ||
                  !message.propertyLookups.length
                ) {
                  message.propertyLookups = [];
                }
                message.propertyLookups.push(reader.string());
                break;
              }
              case 4: {
                message.fetchAllProperties = reader.bool();
                break;
              }
              case 5: {
                message.filter = $root.patients.v1.ListPatientsFilter.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.ListPatientsRequest) {
            return object;
          }
          const message = new $root.patients.v1.ListPatientsRequest();
          if (object.pagination != null) {
            if (typeof object.pagination !== 'object') {
              throw new TypeError(
                '.patients.v1.ListPatientsRequest.pagination: object expected, but got ' +
                  typeof object.pagination
              );
            }
            message.pagination = pagination$1.v1.Pagination.fromObject(
              object.pagination
            );
          }
          if (object.morfPropertyLookups) {
            if (!Array.isArray(object.morfPropertyLookups)) {
              throw new TypeError(
                '.patients.v1.ListPatientsRequest.morfPropertyLookups: array type expected, but got ' +
                  typeof object.morfPropertyLookups
              );
            }
            message.morfPropertyLookups = new Array(
              object.morfPropertyLookups.length
            );
            for (let i = 0; i < object.morfPropertyLookups.length; ++i) {
              message.morfPropertyLookups[i] = String(
                object.morfPropertyLookups[i]
              );
            }
          }
          if (object.propertyLookups) {
            if (!Array.isArray(object.propertyLookups)) {
              throw new TypeError(
                '.patients.v1.ListPatientsRequest.propertyLookups: array type expected, but got ' +
                  typeof object.propertyLookups
              );
            }
            message.propertyLookups = new Array(object.propertyLookups.length);
            for (let i = 0; i < object.propertyLookups.length; ++i) {
              message.propertyLookups[i] = String(object.propertyLookups[i]);
            }
          }
          if (object.fetchAllProperties != null) {
            message.fetchAllProperties = Boolean(object.fetchAllProperties);
          }
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.patients.v1.ListPatientsRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter = $root.patients.v1.ListPatientsFilter.fromObject(
              object.filter
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.morfPropertyLookups = [];
            object.propertyLookups = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.pagination = null;
            object.fetchAllProperties = false;
          }
          let keys;
          if (
            message.pagination != null &&
            message.hasOwnProperty('pagination')
          ) {
            object.pagination = pagination$1.v1.Pagination.toObject(
              message.pagination,
              options
            );
          }
          if (
            message.morfPropertyLookups &&
            message.morfPropertyLookups.length
          ) {
            object.morfPropertyLookups = new Array(
              message.morfPropertyLookups.length
            );
            for (let i = 0; i < message.morfPropertyLookups.length; ++i) {
              object.morfPropertyLookups[i] = message.morfPropertyLookups[i];
            }
          }
          if (message.propertyLookups && message.propertyLookups.length) {
            object.propertyLookups = new Array(message.propertyLookups.length);
            for (let i = 0; i < message.propertyLookups.length; ++i) {
              object.propertyLookups[i] = message.propertyLookups[i];
            }
          }
          if (
            message.fetchAllProperties != null &&
            message.hasOwnProperty('fetchAllProperties')
          ) {
            object.fetchAllProperties = message.fetchAllProperties;
          }
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter = $root.patients.v1.ListPatientsFilter.toObject(
              message.filter,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.ListPatientsRequest';
        }
      }

      ListPatientsRequest.prototype.pagination = null;
      ListPatientsRequest.prototype.morfPropertyLookups = $util.emptyArray;
      ListPatientsRequest.prototype.propertyLookups = $util.emptyArray;
      ListPatientsRequest.prototype.fetchAllProperties = false;
      ListPatientsRequest.prototype.filter = null;

      return ListPatientsRequest;
    })();

    v1.ListPatientsFilter = (() => {
      class ListPatientsFilter {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientsFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            $root.patients.v1.PatientIdLookup.encode(
              message.id,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientsFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = $root.patients.v1.PatientIdLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.ListPatientsFilter) {
            return object;
          }
          const message = new $root.patients.v1.ListPatientsFilter();
          if (object.id != null) {
            if (typeof object.id !== 'object') {
              throw new TypeError(
                '.patients.v1.ListPatientsFilter.id: object expected, but got ' +
                  typeof object.id
              );
            }
            message.id = $root.patients.v1.PatientIdLookup.fromObject(
              object.id
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = $root.patients.v1.PatientIdLookup.toObject(
              message.id,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.ListPatientsFilter';
        }
      }

      ListPatientsFilter.prototype.id = null;

      return ListPatientsFilter;
    })();

    v1.GetPatientResponse = (() => {
      class GetPatientResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.patient != null &&
            Object.hasOwnProperty.call(message, 'patient')
          ) {
            $root.patients.v1.Patient.encode(
              message.patient,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.patient = $root.patients.v1.Patient.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.GetPatientResponse) {
            return object;
          }
          const message = new $root.patients.v1.GetPatientResponse();
          if (object.patient != null) {
            if (typeof object.patient !== 'object') {
              throw new TypeError(
                '.patients.v1.GetPatientResponse.patient: object expected, but got ' +
                  typeof object.patient
              );
            }
            message.patient = $root.patients.v1.Patient.fromObject(
              object.patient
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.patient = null;
          }
          let keys;
          if (message.patient != null && message.hasOwnProperty('patient')) {
            object.patient = $root.patients.v1.Patient.toObject(
              message.patient,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.GetPatientResponse';
        }
      }

      GetPatientResponse.prototype.patient = null;

      return GetPatientResponse;
    })();

    v1.ListPatientsResponse = (() => {
      class ListPatientsResponse {
        constructor(properties) {
          this.patients = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListPatientsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.patients != null &&
            Object.hasOwnProperty.call(message, 'patients')
          ) {
            for (const element of message.patients) {
              $root.patients.v1.Patient.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(16).uint64(message.total);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListPatientsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.patients || !message.patients.length) {
                  message.patients = [];
                }
                message.patients.push(
                  $root.patients.v1.Patient.decode(reader, reader.uint32())
                );
                break;
              }
              case 2: {
                message.total = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.ListPatientsResponse) {
            return object;
          }
          const message = new $root.patients.v1.ListPatientsResponse();
          if (object.patients) {
            if (!Array.isArray(object.patients)) {
              throw new TypeError(
                '.patients.v1.ListPatientsResponse.patients: array type expected, but got ' +
                  typeof object.patients
              );
            }
            message.patients = new Array(object.patients.length);
            for (let i = 0; i < object.patients.length; ++i) {
              if (typeof object.patients[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.ListPatientsResponse.patients: object expected, but got ' +
                    typeof object.patients[i]
                );
              }
              message.patients[i] = $root.patients.v1.Patient.fromObject(
                object.patients[i]
              );
            }
          }
          if (object.total != null) {
            if ($util.Long) {
              (message.total = $util.Long.fromValue(
                object.total
              )).unsigned = true;
            } else if (typeof object.total === 'string') {
              message.total = parseInt(object.total, 10);
            } else if (typeof object.total === 'number') {
              message.total = object.total;
            } else if (typeof object.total === 'object') {
              message.total = new $util.LongBits(
                object.total.low >>> 0,
                object.total.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.patients = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.total =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.total = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.patients && message.patients.length) {
            object.patients = new Array(message.patients.length);
            for (let i = 0; i < message.patients.length; ++i) {
              object.patients[i] = $root.patients.v1.Patient.toObject(
                message.patients[i],
                options
              );
            }
          }
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total =
              typeof message.total === 'number'
                ? options.longs === String
                  ? String(message.total)
                  : message.total
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.total)
                : options.longs === Number
                ? new $util.LongBits(
                    message.total.low >>> 0,
                    message.total.high >>> 0
                  ).toNumber(true)
                : message.total;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.ListPatientsResponse';
        }
      }

      ListPatientsResponse.prototype.patients = $util.emptyArray;
      ListPatientsResponse.prototype.total = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      return ListPatientsResponse;
    })();

    v1.UpdatePatientRequest = (() => {
      class UpdatePatientRequest {
        constructor(properties) {
          this.upsertApplicationIds = [];
          this.deleteApplicationIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            $root.patients.v1.PatientIdLookup.encode(
              message.id,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.customerProvidedId != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedId')
          ) {
            writer.uint32(18).string(message.customerProvidedId);
          }
          if (
            message.upsertApplicationIds != null &&
            Object.hasOwnProperty.call(message, 'upsertApplicationIds')
          ) {
            for (const element of message.upsertApplicationIds) {
              $root.patients.v1.UpsertPatientApplicationId.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          if (
            message.deleteApplicationIds != null &&
            Object.hasOwnProperty.call(message, 'deleteApplicationIds')
          ) {
            for (const element of message.deleteApplicationIds) {
              $root.patients.v1.DeletePatientApplicationId.encode(
                element,
                writer.uint32(34).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = $root.patients.v1.PatientIdLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.customerProvidedId = reader.string();
                break;
              }
              case 3: {
                if (
                  !message.upsertApplicationIds ||
                  !message.upsertApplicationIds.length
                ) {
                  message.upsertApplicationIds = [];
                }
                message.upsertApplicationIds.push(
                  $root.patients.v1.UpsertPatientApplicationId.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 4: {
                if (
                  !message.deleteApplicationIds ||
                  !message.deleteApplicationIds.length
                ) {
                  message.deleteApplicationIds = [];
                }
                message.deleteApplicationIds.push(
                  $root.patients.v1.DeletePatientApplicationId.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.UpdatePatientRequest) {
            return object;
          }
          const message = new $root.patients.v1.UpdatePatientRequest();
          if (object.id != null) {
            if (typeof object.id !== 'object') {
              throw new TypeError(
                '.patients.v1.UpdatePatientRequest.id: object expected, but got ' +
                  typeof object.id
              );
            }
            message.id = $root.patients.v1.PatientIdLookup.fromObject(
              object.id
            );
          }
          if (object.customerProvidedId != null) {
            message.customerProvidedId = String(object.customerProvidedId);
          }
          if (object.upsertApplicationIds) {
            if (!Array.isArray(object.upsertApplicationIds)) {
              throw new TypeError(
                '.patients.v1.UpdatePatientRequest.upsertApplicationIds: array type expected, but got ' +
                  typeof object.upsertApplicationIds
              );
            }
            message.upsertApplicationIds = new Array(
              object.upsertApplicationIds.length
            );
            for (let i = 0; i < object.upsertApplicationIds.length; ++i) {
              if (typeof object.upsertApplicationIds[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.UpdatePatientRequest.upsertApplicationIds: object expected, but got ' +
                    typeof object.upsertApplicationIds[i]
                );
              }
              message.upsertApplicationIds[i] =
                $root.patients.v1.UpsertPatientApplicationId.fromObject(
                  object.upsertApplicationIds[i]
                );
            }
          }
          if (object.deleteApplicationIds) {
            if (!Array.isArray(object.deleteApplicationIds)) {
              throw new TypeError(
                '.patients.v1.UpdatePatientRequest.deleteApplicationIds: array type expected, but got ' +
                  typeof object.deleteApplicationIds
              );
            }
            message.deleteApplicationIds = new Array(
              object.deleteApplicationIds.length
            );
            for (let i = 0; i < object.deleteApplicationIds.length; ++i) {
              if (typeof object.deleteApplicationIds[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.UpdatePatientRequest.deleteApplicationIds: object expected, but got ' +
                    typeof object.deleteApplicationIds[i]
                );
              }
              message.deleteApplicationIds[i] =
                $root.patients.v1.DeletePatientApplicationId.fromObject(
                  object.deleteApplicationIds[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.upsertApplicationIds = [];
            object.deleteApplicationIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = $root.patients.v1.PatientIdLookup.toObject(
              message.id,
              options
            );
          }
          if (
            message.customerProvidedId != null &&
            message.hasOwnProperty('customerProvidedId')
          ) {
            object.customerProvidedId = message.customerProvidedId;
          }
          if (
            message.upsertApplicationIds &&
            message.upsertApplicationIds.length
          ) {
            object.upsertApplicationIds = new Array(
              message.upsertApplicationIds.length
            );
            for (let i = 0; i < message.upsertApplicationIds.length; ++i) {
              object.upsertApplicationIds[i] =
                $root.patients.v1.UpsertPatientApplicationId.toObject(
                  message.upsertApplicationIds[i],
                  options
                );
            }
          }
          if (
            message.deleteApplicationIds &&
            message.deleteApplicationIds.length
          ) {
            object.deleteApplicationIds = new Array(
              message.deleteApplicationIds.length
            );
            for (let i = 0; i < message.deleteApplicationIds.length; ++i) {
              object.deleteApplicationIds[i] =
                $root.patients.v1.DeletePatientApplicationId.toObject(
                  message.deleteApplicationIds[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.UpdatePatientRequest';
        }
      }

      UpdatePatientRequest.prototype.id = null;
      UpdatePatientRequest.prototype.customerProvidedId = null;
      UpdatePatientRequest.prototype.upsertApplicationIds = $util.emptyArray;
      UpdatePatientRequest.prototype.deleteApplicationIds = $util.emptyArray;

      return UpdatePatientRequest;
    })();

    v1.UpdatePatientResponse = (() => {
      class UpdatePatientResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.patient != null &&
            Object.hasOwnProperty.call(message, 'patient')
          ) {
            $root.patients.v1.Patient.encode(
              message.patient,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.patient = $root.patients.v1.Patient.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.UpdatePatientResponse) {
            return object;
          }
          const message = new $root.patients.v1.UpdatePatientResponse();
          if (object.patient != null) {
            if (typeof object.patient !== 'object') {
              throw new TypeError(
                '.patients.v1.UpdatePatientResponse.patient: object expected, but got ' +
                  typeof object.patient
              );
            }
            message.patient = $root.patients.v1.Patient.fromObject(
              object.patient
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.patient = null;
          }
          let keys;
          if (message.patient != null && message.hasOwnProperty('patient')) {
            object.patient = $root.patients.v1.Patient.toObject(
              message.patient,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.UpdatePatientResponse';
        }
      }

      UpdatePatientResponse.prototype.patient = null;

      return UpdatePatientResponse;
    })();

    v1.DeletePatientRequest = (() => {
      class DeletePatientRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeletePatientRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            $root.patients.v1.PatientIdLookup.encode(
              message.id,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeletePatientRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = $root.patients.v1.PatientIdLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.DeletePatientRequest) {
            return object;
          }
          const message = new $root.patients.v1.DeletePatientRequest();
          if (object.id != null) {
            if (typeof object.id !== 'object') {
              throw new TypeError(
                '.patients.v1.DeletePatientRequest.id: object expected, but got ' +
                  typeof object.id
              );
            }
            message.id = $root.patients.v1.PatientIdLookup.fromObject(
              object.id
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = $root.patients.v1.PatientIdLookup.toObject(
              message.id,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.DeletePatientRequest';
        }
      }

      DeletePatientRequest.prototype.id = null;

      return DeletePatientRequest;
    })();

    v1.DeletePatientResponse = (() => {
      class DeletePatientResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeletePatientResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeletePatientResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.DeletePatientResponse) {
            return object;
          }
          const message = new $root.patients.v1.DeletePatientResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.DeletePatientResponse';
        }
      }

      return DeletePatientResponse;
    })();

    v1.UpsertPatientApplicationId = (() => {
      class UpsertPatientApplicationId {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpsertPatientApplicationId(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.newIdOnApplication != null &&
            Object.hasOwnProperty.call(message, 'newIdOnApplication')
          ) {
            writer.uint32(10).string(message.newIdOnApplication);
          }
          if (
            message.applicationType != null &&
            Object.hasOwnProperty.call(message, 'applicationType')
          ) {
            writer.uint32(16).int32(message.applicationType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpsertPatientApplicationId();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.newIdOnApplication = reader.string();
                break;
              }
              case 2: {
                message.applicationType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.UpsertPatientApplicationId) {
            return object;
          }
          const message = new $root.patients.v1.UpsertPatientApplicationId();
          if (object.newIdOnApplication != null) {
            message.newIdOnApplication = String(object.newIdOnApplication);
          }
          if (object.applicationType != null) {
            switch (object.applicationType) {
              case 'UNSPECIFIED_PATIENT_APPLICATION':
              case 0: {
                message.applicationType = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.applicationType = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.applicationType = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.applicationType = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.applicationType = 4;
                break;
              }
              case 'RECURLY':
              case 5: {
                message.applicationType = 5;
                break;
              }
              case 'INTERCOM':
              case 6: {
                message.applicationType = 6;
                break;
              }
              case 'SANA_BENEFITS':
              case 7: {
                message.applicationType = 7;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 8: {
                message.applicationType = 8;
                break;
              }
              case 'VITAL':
              case 9: {
                message.applicationType = 9;
                break;
              }
              case 'SEGMENT':
              case 10: {
                message.applicationType = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.applicationType = 11;
                break;
              }
              default: {
                if (typeof object.applicationType == 'number') {
                  message.applicationType = object.applicationType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.newIdOnApplication = '';
            object.applicationType =
              options.enums === String ? 'UNSPECIFIED_PATIENT_APPLICATION' : 0;
          }
          let keys;
          if (
            message.newIdOnApplication != null &&
            message.hasOwnProperty('newIdOnApplication')
          ) {
            object.newIdOnApplication = message.newIdOnApplication;
          }
          if (
            message.applicationType != null &&
            message.hasOwnProperty('applicationType')
          ) {
            object.applicationType =
              options.enums === String
                ? $root.patients.v1.PatientApplication[
                    message.applicationType
                  ] === undefined
                  ? message.applicationType
                  : $root.patients.v1.PatientApplication[
                      message.applicationType
                    ]
                : message.applicationType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.UpsertPatientApplicationId';
        }
      }

      UpsertPatientApplicationId.prototype.newIdOnApplication = '';
      UpsertPatientApplicationId.prototype.applicationType = 0;

      return UpsertPatientApplicationId;
    })();

    v1.DeletePatientApplicationId = (() => {
      class DeletePatientApplicationId {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeletePatientApplicationId(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.applicationType != null &&
            Object.hasOwnProperty.call(message, 'applicationType')
          ) {
            writer.uint32(8).int32(message.applicationType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeletePatientApplicationId();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.applicationType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.DeletePatientApplicationId) {
            return object;
          }
          const message = new $root.patients.v1.DeletePatientApplicationId();
          if (object.applicationType != null) {
            switch (object.applicationType) {
              case 'UNSPECIFIED_PATIENT_APPLICATION':
              case 0: {
                message.applicationType = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.applicationType = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.applicationType = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.applicationType = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.applicationType = 4;
                break;
              }
              case 'RECURLY':
              case 5: {
                message.applicationType = 5;
                break;
              }
              case 'INTERCOM':
              case 6: {
                message.applicationType = 6;
                break;
              }
              case 'SANA_BENEFITS':
              case 7: {
                message.applicationType = 7;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 8: {
                message.applicationType = 8;
                break;
              }
              case 'VITAL':
              case 9: {
                message.applicationType = 9;
                break;
              }
              case 'SEGMENT':
              case 10: {
                message.applicationType = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.applicationType = 11;
                break;
              }
              default: {
                if (typeof object.applicationType == 'number') {
                  message.applicationType = object.applicationType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.applicationType =
              options.enums === String ? 'UNSPECIFIED_PATIENT_APPLICATION' : 0;
          }
          let keys;
          if (
            message.applicationType != null &&
            message.hasOwnProperty('applicationType')
          ) {
            object.applicationType =
              options.enums === String
                ? $root.patients.v1.PatientApplication[
                    message.applicationType
                  ] === undefined
                  ? message.applicationType
                  : $root.patients.v1.PatientApplication[
                      message.applicationType
                    ]
                : message.applicationType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.DeletePatientApplicationId';
        }
      }

      DeletePatientApplicationId.prototype.applicationType = 0;

      return DeletePatientApplicationId;
    })();

    v1.GetPatientPropertyHistoryRequest = (() => {
      class GetPatientPropertyHistoryRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientPropertyHistoryRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            $root.patients.v1.PatientIdLookup.encode(
              message.id,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.propertyId != null &&
            Object.hasOwnProperty.call(message, 'propertyId')
          ) {
            writer.uint32(18).string(message.propertyId);
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(24).uint32(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientPropertyHistoryRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = $root.patients.v1.PatientIdLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.propertyId = reader.string();
                break;
              }
              case 3: {
                message.limit = reader.uint32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.patients.v1.GetPatientPropertyHistoryRequest
          ) {
            return object;
          }
          const message =
            new $root.patients.v1.GetPatientPropertyHistoryRequest();
          if (object.id != null) {
            if (typeof object.id !== 'object') {
              throw new TypeError(
                '.patients.v1.GetPatientPropertyHistoryRequest.id: object expected, but got ' +
                  typeof object.id
              );
            }
            message.id = $root.patients.v1.PatientIdLookup.fromObject(
              object.id
            );
          }
          if (object.propertyId != null) {
            message.propertyId = String(object.propertyId);
          }
          if (object.limit != null) {
            message.limit = object.limit >>> 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = null;
            object.propertyId = '';
            object.limit = 0;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = $root.patients.v1.PatientIdLookup.toObject(
              message.id,
              options
            );
          }
          if (
            message.propertyId != null &&
            message.hasOwnProperty('propertyId')
          ) {
            object.propertyId = message.propertyId;
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit = message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.GetPatientPropertyHistoryRequest';
        }
      }

      GetPatientPropertyHistoryRequest.prototype.id = null;
      GetPatientPropertyHistoryRequest.prototype.propertyId = '';
      GetPatientPropertyHistoryRequest.prototype.limit = 0;

      return GetPatientPropertyHistoryRequest;
    })();

    v1.GetPatientPropertyHistoryResponse = (() => {
      class GetPatientPropertyHistoryResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetPatientPropertyHistoryResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.history != null &&
            Object.hasOwnProperty.call(message, 'history')
          ) {
            $root.patients.v1.PatientPropertyHistory.encode(
              message.history,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetPatientPropertyHistoryResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.history =
                  $root.patients.v1.PatientPropertyHistory.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.patients.v1.GetPatientPropertyHistoryResponse
          ) {
            return object;
          }
          const message =
            new $root.patients.v1.GetPatientPropertyHistoryResponse();
          if (object.history != null) {
            if (typeof object.history !== 'object') {
              throw new TypeError(
                '.patients.v1.GetPatientPropertyHistoryResponse.history: object expected, but got ' +
                  typeof object.history
              );
            }
            message.history =
              $root.patients.v1.PatientPropertyHistory.fromObject(
                object.history
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.history = null;
          }
          let keys;
          if (message.history != null && message.hasOwnProperty('history')) {
            object.history = $root.patients.v1.PatientPropertyHistory.toObject(
              message.history,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'patients.v1.GetPatientPropertyHistoryResponse'
          );
        }
      }

      GetPatientPropertyHistoryResponse.prototype.history = null;

      return GetPatientPropertyHistoryResponse;
    })();

    v1.CreatePatientPropertySnapshotRequest = (() => {
      class CreatePatientPropertySnapshotRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertySnapshotRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            $root.patients.v1.PatientIdLookup.encode(
              message.id,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.lookup != null &&
            Object.hasOwnProperty.call(message, 'lookup')
          ) {
            $root.patients.v1.PatientPropertyLookup.encode(
              message.lookup,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            values$1.v1.Value.encode(
              message.value,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(34).string(message.eventSnapshotId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertySnapshotRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = $root.patients.v1.PatientIdLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.lookup = $root.patients.v1.PatientPropertyLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.value = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.eventSnapshotId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.patients.v1.CreatePatientPropertySnapshotRequest
          ) {
            return object;
          }
          const message =
            new $root.patients.v1.CreatePatientPropertySnapshotRequest();
          if (object.id != null) {
            if (typeof object.id !== 'object') {
              throw new TypeError(
                '.patients.v1.CreatePatientPropertySnapshotRequest.id: object expected, but got ' +
                  typeof object.id
              );
            }
            message.id = $root.patients.v1.PatientIdLookup.fromObject(
              object.id
            );
          }
          if (object.lookup != null) {
            if (typeof object.lookup !== 'object') {
              throw new TypeError(
                '.patients.v1.CreatePatientPropertySnapshotRequest.lookup: object expected, but got ' +
                  typeof object.lookup
              );
            }
            message.lookup = $root.patients.v1.PatientPropertyLookup.fromObject(
              object.lookup
            );
          }
          if (object.value != null) {
            if (typeof object.value !== 'object') {
              throw new TypeError(
                '.patients.v1.CreatePatientPropertySnapshotRequest.value: object expected, but got ' +
                  typeof object.value
              );
            }
            message.value = values$1.v1.Value.fromObject(object.value);
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = null;
            object.lookup = null;
            object.value = null;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = $root.patients.v1.PatientIdLookup.toObject(
              message.id,
              options
            );
          }
          if (message.lookup != null && message.hasOwnProperty('lookup')) {
            object.lookup = $root.patients.v1.PatientPropertyLookup.toObject(
              message.lookup,
              options
            );
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = values$1.v1.Value.toObject(message.value, options);
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'patients.v1.CreatePatientPropertySnapshotRequest'
          );
        }
      }

      CreatePatientPropertySnapshotRequest.prototype.id = null;
      CreatePatientPropertySnapshotRequest.prototype.lookup = null;
      CreatePatientPropertySnapshotRequest.prototype.value = null;
      CreatePatientPropertySnapshotRequest.prototype.eventSnapshotId = null;

      return CreatePatientPropertySnapshotRequest;
    })();

    v1.CreatePatientPropertySnapshotResponse = (() => {
      class CreatePatientPropertySnapshotResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertySnapshotResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.created != null &&
            Object.hasOwnProperty.call(message, 'created')
          ) {
            writer.uint32(8).bool(message.created);
          }
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            values$1.v1.Value.encode(
              message.value,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertySnapshotResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.created = reader.bool();
                break;
              }
              case 2: {
                message.value = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.patients.v1.CreatePatientPropertySnapshotResponse
          ) {
            return object;
          }
          const message =
            new $root.patients.v1.CreatePatientPropertySnapshotResponse();
          if (object.created != null) {
            message.created = Boolean(object.created);
          }
          if (object.value != null) {
            if (typeof object.value !== 'object') {
              throw new TypeError(
                '.patients.v1.CreatePatientPropertySnapshotResponse.value: object expected, but got ' +
                  typeof object.value
              );
            }
            message.value = values$1.v1.Value.fromObject(object.value);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.created = false;
            object.value = null;
          }
          let keys;
          if (message.created != null && message.hasOwnProperty('created')) {
            object.created = message.created;
          }
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = values$1.v1.Value.toObject(message.value, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'patients.v1.CreatePatientPropertySnapshotResponse'
          );
        }
      }

      CreatePatientPropertySnapshotResponse.prototype.created = false;
      CreatePatientPropertySnapshotResponse.prototype.value = null;

      return CreatePatientPropertySnapshotResponse;
    })();

    v1.PatientPropertyLookup = (() => {
      class PatientPropertyLookup {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertyLookup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.nested != null &&
            Object.hasOwnProperty.call(message, 'nested')
          ) {
            $root.patients.v1.PatientPropertyLookup.encode(
              message.nested,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertyLookup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.nested = $root.patients.v1.PatientPropertyLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.PatientPropertyLookup) {
            return object;
          }
          const message = new $root.patients.v1.PatientPropertyLookup();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.nested != null) {
            if (typeof object.nested !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientPropertyLookup.nested: object expected, but got ' +
                  typeof object.nested
              );
            }
            message.nested = $root.patients.v1.PatientPropertyLookup.fromObject(
              object.nested
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.nested != null && message.hasOwnProperty('nested')) {
            object.nested = $root.patients.v1.PatientPropertyLookup.toObject(
              message.nested,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.PatientPropertyLookup';
        }
      }

      PatientPropertyLookup.prototype.name = '';
      PatientPropertyLookup.prototype.nested = null;

      return PatientPropertyLookup;
    })();

    v1.PatientPropertyHistory = (() => {
      class PatientPropertyHistory {
        constructor(properties) {
          this.values = [];
          this.fields = {};
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertyHistory(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.values != null &&
            Object.hasOwnProperty.call(message, 'values')
          ) {
            for (const element of message.values) {
              $root.patients.v1.PatientPropertySnapshot.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.fields != null &&
            Object.hasOwnProperty.call(message, 'fields')
          ) {
            for (const key of Object.keys(message.fields)) {
              writer.uint32(18).fork().uint32(10).string(key);
              $root.patients.v1.PatientPropertyHistory.encode(
                message.fields[key],
                writer.uint32(18).fork()
              )
                .ldelim()
                .ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertyHistory();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.values || !message.values.length) {
                  message.values = [];
                }
                message.values.push(
                  $root.patients.v1.PatientPropertySnapshot.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                if (message.fields === $util.emptyObject) message.fields = {};
                let end2 = reader.uint32() + reader.pos;
                key = '';
                value = null;
                while (reader.pos < end2) {
                  let tag2 = reader.uint32();
                  switch (tag2 >>> 3) {
                    case 1: {
                      key = reader.string();
                      break;
                    }
                    case 2: {
                      value = $root.patients.v1.PatientPropertyHistory.decode(
                        reader,
                        reader.uint32()
                      );
                      break;
                    }
                  }
                  message.fields[key] = value;
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.PatientPropertyHistory) {
            return object;
          }
          const message = new $root.patients.v1.PatientPropertyHistory();
          if (object.values) {
            if (!Array.isArray(object.values)) {
              throw new TypeError(
                '.patients.v1.PatientPropertyHistory.values: array type expected, but got ' +
                  typeof object.values
              );
            }
            message.values = new Array(object.values.length);
            for (let i = 0; i < object.values.length; ++i) {
              if (typeof object.values[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.PatientPropertyHistory.values: object expected, but got ' +
                    typeof object.values[i]
                );
              }
              message.values[i] =
                $root.patients.v1.PatientPropertySnapshot.fromObject(
                  object.values[i]
                );
            }
          }
          if (object.fields) {
            if (typeof object.fields !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientPropertyHistory.fields: object expected, but got ' +
                  typeof object.fields
              );
            }
            message.fields = {};
            for (
              let keys = Object.keys(object.fields), i = 0;
              i < keys.length;
              ++i
            ) {
              if (typeof object.fields[keys[i]] !== 'object') {
                throw new TypeError(
                  '.patients.v1.PatientPropertyHistory.value: object expected, but got ' +
                    typeof object.fields[keys[i]]
                );
              }
              message.fields[keys[i]] =
                $root.patients.v1.PatientPropertyHistory.fromObject(
                  object.fields[keys[i]]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.values = [];
          }
          if (options.objects || options.defaults) {
            object.fields = {};
          }
          if (options.defaults) {
          }
          let keys;
          if (message.values && message.values.length) {
            object.values = new Array(message.values.length);
            for (let i = 0; i < message.values.length; ++i) {
              object.values[i] =
                $root.patients.v1.PatientPropertySnapshot.toObject(
                  message.values[i],
                  options
                );
            }
          }
          if (message.fields && (keys = Object.keys(message.fields)).length) {
            object.fields = {};
            for (let i = 0; i < keys.length; ++i) {
              object.fields[keys[i]] =
                $root.patients.v1.PatientPropertyHistory.toObject(
                  message.fields[keys[i]],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.PatientPropertyHistory';
        }
      }

      PatientPropertyHistory.prototype.values = $util.emptyArray;
      PatientPropertyHistory.prototype.fields = $util.emptyObject;

      return PatientPropertyHistory;
    })();

    v1.PatientPropertySnapshot = (() => {
      class PatientPropertySnapshot {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertySnapshot(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.value != null &&
            Object.hasOwnProperty.call(message, 'value')
          ) {
            values$1.v1.Value.encode(
              message.value,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.time != null &&
            Object.hasOwnProperty.call(message, 'time')
          ) {
            timing$1.v1.Timestamp.encode(
              message.time,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertySnapshot();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.value = values$1.v1.Value.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.time = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.PatientPropertySnapshot) {
            return object;
          }
          const message = new $root.patients.v1.PatientPropertySnapshot();
          if (object.value != null) {
            if (typeof object.value !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientPropertySnapshot.value: object expected, but got ' +
                  typeof object.value
              );
            }
            message.value = values$1.v1.Value.fromObject(object.value);
          }
          if (object.time != null) {
            if (typeof object.time !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientPropertySnapshot.time: object expected, but got ' +
                  typeof object.time
              );
            }
            message.time = timing$1.v1.Timestamp.fromObject(object.time);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.value = null;
            object.time = null;
          }
          let keys;
          if (message.value != null && message.hasOwnProperty('value')) {
            object.value = values$1.v1.Value.toObject(message.value, options);
          }
          if (message.time != null && message.hasOwnProperty('time')) {
            object.time = timing$1.v1.Timestamp.toObject(message.time, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.PatientPropertySnapshot';
        }
      }

      PatientPropertySnapshot.prototype.value = null;
      PatientPropertySnapshot.prototype.time = null;

      return PatientPropertySnapshot;
    })();

    v1.PatientProperty = (() => {
      class PatientProperty {
        constructor(properties) {
          this.sensitivityAnnotations = [];
          this.fields = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientProperty(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            values$1.v1.ValueType.encode(
              message.type,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.morfType != null &&
            Object.hasOwnProperty.call(message, 'morfType')
          ) {
            $root.patients.v1.MorfPatientPropertyType.encode(
              message.morfType,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.sensitivityAnnotations != null &&
            Object.hasOwnProperty.call(message, 'sensitivityAnnotations')
          ) {
            for (const element of message.sensitivityAnnotations) {
              sensitivity$1.v1.Sensitivity.encode(
                element,
                writer.uint32(50).fork()
              ).ldelim();
            }
          }
          if (
            message.fields != null &&
            Object.hasOwnProperty.call(message, 'fields')
          ) {
            for (const element of message.fields) {
              $root.patients.v1.PatientProperty.encode(
                element,
                writer.uint32(58).fork()
              ).ldelim();
            }
          }
          if (
            message.metadata != null &&
            Object.hasOwnProperty.call(message, 'metadata')
          ) {
            google$1.protobuf.Struct.encode(
              message.metadata,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(74).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientProperty();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.type = values$1.v1.ValueType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.morfType =
                  $root.patients.v1.MorfPatientPropertyType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                if (
                  !message.sensitivityAnnotations ||
                  !message.sensitivityAnnotations.length
                ) {
                  message.sensitivityAnnotations = [];
                }
                message.sensitivityAnnotations.push(
                  sensitivity$1.v1.Sensitivity.decode(reader, reader.uint32())
                );
                break;
              }
              case 7: {
                if (!message.fields || !message.fields.length) {
                  message.fields = [];
                }
                message.fields.push(
                  $root.patients.v1.PatientProperty.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 8: {
                message.metadata = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.PatientProperty) {
            return object;
          }
          const message = new $root.patients.v1.PatientProperty();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientProperty.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type = values$1.v1.ValueType.fromObject(object.type);
          }
          if (object.morfType != null) {
            if (typeof object.morfType !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientProperty.morfType: object expected, but got ' +
                  typeof object.morfType
              );
            }
            message.morfType =
              $root.patients.v1.MorfPatientPropertyType.fromObject(
                object.morfType
              );
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientProperty.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.sensitivityAnnotations) {
            if (!Array.isArray(object.sensitivityAnnotations)) {
              throw new TypeError(
                '.patients.v1.PatientProperty.sensitivityAnnotations: array type expected, but got ' +
                  typeof object.sensitivityAnnotations
              );
            }
            message.sensitivityAnnotations = new Array(
              object.sensitivityAnnotations.length
            );
            for (let i = 0; i < object.sensitivityAnnotations.length; ++i) {
              if (typeof object.sensitivityAnnotations[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.PatientProperty.sensitivityAnnotations: object expected, but got ' +
                    typeof object.sensitivityAnnotations[i]
                );
              }
              message.sensitivityAnnotations[i] =
                sensitivity$1.v1.Sensitivity.fromObject(
                  object.sensitivityAnnotations[i]
                );
            }
          }
          if (object.fields) {
            if (!Array.isArray(object.fields)) {
              throw new TypeError(
                '.patients.v1.PatientProperty.fields: array type expected, but got ' +
                  typeof object.fields
              );
            }
            message.fields = new Array(object.fields.length);
            for (let i = 0; i < object.fields.length; ++i) {
              if (typeof object.fields[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.PatientProperty.fields: object expected, but got ' +
                    typeof object.fields[i]
                );
              }
              message.fields[i] = $root.patients.v1.PatientProperty.fromObject(
                object.fields[i]
              );
            }
          }
          if (object.metadata != null) {
            if (typeof object.metadata !== 'object') {
              throw new TypeError(
                '.patients.v1.PatientProperty.metadata: object expected, but got ' +
                  typeof object.metadata
              );
            }
            message.metadata = google$1.protobuf.Struct.fromObject(
              object.metadata
            );
          }
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sensitivityAnnotations = [];
            object.fields = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.type = null;
            object.morfType = null;
            object.timing = null;
            object.metadata = null;
            object.id = '';
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = values$1.v1.ValueType.toObject(message.type, options);
          }
          if (message.morfType != null && message.hasOwnProperty('morfType')) {
            object.morfType =
              $root.patients.v1.MorfPatientPropertyType.toObject(
                message.morfType,
                options
              );
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (
            message.sensitivityAnnotations &&
            message.sensitivityAnnotations.length
          ) {
            object.sensitivityAnnotations = new Array(
              message.sensitivityAnnotations.length
            );
            for (let i = 0; i < message.sensitivityAnnotations.length; ++i) {
              object.sensitivityAnnotations[i] =
                sensitivity$1.v1.Sensitivity.toObject(
                  message.sensitivityAnnotations[i],
                  options
                );
            }
          }
          if (message.fields && message.fields.length) {
            object.fields = new Array(message.fields.length);
            for (let i = 0; i < message.fields.length; ++i) {
              object.fields[i] = $root.patients.v1.PatientProperty.toObject(
                message.fields[i],
                options
              );
            }
          }
          if (message.metadata != null && message.hasOwnProperty('metadata')) {
            object.metadata = google$1.protobuf.Struct.toObject(
              message.metadata,
              options
            );
          }
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.PatientProperty';
        }
      }

      PatientProperty.prototype.name = '';
      PatientProperty.prototype.description = null;
      PatientProperty.prototype.type = null;
      PatientProperty.prototype.morfType = null;
      PatientProperty.prototype.timing = null;
      PatientProperty.prototype.sensitivityAnnotations = $util.emptyArray;
      PatientProperty.prototype.fields = $util.emptyArray;
      PatientProperty.prototype.metadata = null;
      PatientProperty.prototype.id = '';

      return PatientProperty;
    })();

    v1.MorfPatientPropertyType = (() => {
      class MorfPatientPropertyType {
        constructor(properties) {
          this.defaultSensitivities = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new MorfPatientPropertyType(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.fhirIndexingPath != null &&
            Object.hasOwnProperty.call(message, 'fhirIndexingPath')
          ) {
            writer.uint32(26).string(message.fhirIndexingPath);
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.defaultSensitivities != null &&
            Object.hasOwnProperty.call(message, 'defaultSensitivities')
          ) {
            for (const element of message.defaultSensitivities) {
              sensitivity$1.v1.Sensitivity.encode(
                element,
                writer.uint32(42).fork()
              ).ldelim();
            }
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            values$1.v1.ValueType.encode(
              message.type,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new MorfPatientPropertyType();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                message.fhirIndexingPath = reader.string();
                break;
              }
              case 4: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                if (
                  !message.defaultSensitivities ||
                  !message.defaultSensitivities.length
                ) {
                  message.defaultSensitivities = [];
                }
                message.defaultSensitivities.push(
                  sensitivity$1.v1.Sensitivity.decode(reader, reader.uint32())
                );
                break;
              }
              case 6: {
                message.type = values$1.v1.ValueType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.patients.v1.MorfPatientPropertyType) {
            return object;
          }
          const message = new $root.patients.v1.MorfPatientPropertyType();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.fhirIndexingPath != null) {
            message.fhirIndexingPath = String(object.fhirIndexingPath);
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.patients.v1.MorfPatientPropertyType.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.defaultSensitivities) {
            if (!Array.isArray(object.defaultSensitivities)) {
              throw new TypeError(
                '.patients.v1.MorfPatientPropertyType.defaultSensitivities: array type expected, but got ' +
                  typeof object.defaultSensitivities
              );
            }
            message.defaultSensitivities = new Array(
              object.defaultSensitivities.length
            );
            for (let i = 0; i < object.defaultSensitivities.length; ++i) {
              if (typeof object.defaultSensitivities[i] !== 'object') {
                throw new TypeError(
                  '.patients.v1.MorfPatientPropertyType.defaultSensitivities: object expected, but got ' +
                    typeof object.defaultSensitivities[i]
                );
              }
              message.defaultSensitivities[i] =
                sensitivity$1.v1.Sensitivity.fromObject(
                  object.defaultSensitivities[i]
                );
            }
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.patients.v1.MorfPatientPropertyType.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type = values$1.v1.ValueType.fromObject(object.type);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.defaultSensitivities = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.timing = null;
            object.type = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.fhirIndexingPath != null &&
            message.hasOwnProperty('fhirIndexingPath')
          ) {
            object.fhirIndexingPath = message.fhirIndexingPath;
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (
            message.defaultSensitivities &&
            message.defaultSensitivities.length
          ) {
            object.defaultSensitivities = new Array(
              message.defaultSensitivities.length
            );
            for (let i = 0; i < message.defaultSensitivities.length; ++i) {
              object.defaultSensitivities[i] =
                sensitivity$1.v1.Sensitivity.toObject(
                  message.defaultSensitivities[i],
                  options
                );
            }
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = values$1.v1.ValueType.toObject(message.type, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'patients.v1.MorfPatientPropertyType';
        }
      }

      MorfPatientPropertyType.prototype.name = '';
      MorfPatientPropertyType.prototype.description = null;
      MorfPatientPropertyType.prototype.fhirIndexingPath = null;
      MorfPatientPropertyType.prototype.timing = null;
      MorfPatientPropertyType.prototype.defaultSensitivities = $util.emptyArray;
      MorfPatientPropertyType.prototype.type = null;

      return MorfPatientPropertyType;
    })();

    v1.PatientApplication = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_PATIENT_APPLICATION')] = 0;
      values[(valuesById[1] = 'FORMSORT')] = 1;
      values[(valuesById[2] = 'HEALTHIE')] = 2;
      values[(valuesById[3] = 'AXLE_HEALTH')] = 3;
      values[(valuesById[4] = 'BUTTERFLY_LABS')] = 4;
      values[(valuesById[5] = 'RECURLY')] = 5;
      values[(valuesById[6] = 'INTERCOM')] = 6;
      values[(valuesById[7] = 'SANA_BENEFITS')] = 7;
      values[(valuesById[8] = 'ACTIVE_CAMPAIGN')] = 8;
      values[(valuesById[9] = 'VITAL')] = 9;
      values[(valuesById[10] = 'SEGMENT')] = 10;
      values[(valuesById[11] = 'INTAKEQ')] = 11;
      return values;
    })();

    v1.ExternalPatientApplicationIDSystem = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNDEFINED_ILLEGAL')] = 0;
      values[(valuesById[1] = 'LIFEFORCE_STAGING')] = 1;
      values[(valuesById[2] = 'LIFEFORCE_PRODUCTION')] = 2;
      return values;
    })();

    v1.PatientsService = (() => {
      class PatientsService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new PatientsService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (PatientsService.prototype.createPatient = function createPatient(
          request,
          callback
        ) {
          return this.rpcCall(
            createPatient,
            $root.patients.v1.CreatePatientRequest,
            $root.patients.v1.CreatePatientResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'CreatePatient' }
      );

      Object.defineProperty(
        (PatientsService.prototype.getPatient = function getPatient(
          request,
          callback
        ) {
          return this.rpcCall(
            getPatient,
            $root.patients.v1.GetPatientRequest,
            $root.patients.v1.GetPatientResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'GetPatient' }
      );

      Object.defineProperty(
        (PatientsService.prototype.getPatientProperties =
          function getPatientProperties(request, callback) {
            return this.rpcCall(
              getPatientProperties,
              $root.patients.v1.GetPatientPropertiesRequest,
              google$1.protobuf.Struct,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetPatientProperties' }
      );

      Object.defineProperty(
        (PatientsService.prototype.listPatients = function listPatients(
          request,
          callback
        ) {
          return this.rpcCall(
            listPatients,
            $root.patients.v1.ListPatientsRequest,
            $root.patients.v1.ListPatientsResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListPatients' }
      );

      Object.defineProperty(
        (PatientsService.prototype.updatePatient = function updatePatient(
          request,
          callback
        ) {
          return this.rpcCall(
            updatePatient,
            $root.patients.v1.UpdatePatientRequest,
            $root.patients.v1.UpdatePatientResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'UpdatePatient' }
      );

      Object.defineProperty(
        (PatientsService.prototype.deletePatient = function deletePatient(
          request,
          callback
        ) {
          return this.rpcCall(
            deletePatient,
            $root.patients.v1.DeletePatientRequest,
            $root.patients.v1.DeletePatientResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'DeletePatient' }
      );

      Object.defineProperty(
        (PatientsService.prototype.getPatientPropertyHistory =
          function getPatientPropertyHistory(request, callback) {
            return this.rpcCall(
              getPatientPropertyHistory,
              $root.patients.v1.GetPatientPropertyHistoryRequest,
              $root.patients.v1.GetPatientPropertyHistoryResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetPatientPropertyHistory' }
      );

      Object.defineProperty(
        (PatientsService.prototype.createPatientPropertySnapshot =
          function createPatientPropertySnapshot(request, callback) {
            return this.rpcCall(
              createPatientPropertySnapshot,
              $root.patients.v1.CreatePatientPropertySnapshotRequest,
              $root.patients.v1.CreatePatientPropertySnapshotResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreatePatientPropertySnapshot' }
      );

      return PatientsService;
    })();

    return v1;
  })();

  return patients;
})();

export const patients = $root.patients;
