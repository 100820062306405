import { StyledSubRow } from './InsetTableSubRow.css';
import { memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { InsetTableSubRowProps } from './types';

const SubRow: <RowData>(
  props: InsetTableSubRowProps<RowData>
) => JSX.Element = ({ row, children }): JSX.Element => {
  const isSubRow = !!row.row.getParentRow();

  return (
    <Flexbox
      justifyContent='flex-start'
      alignItems='center'
      gap={0}
      pl={isSubRow ? 2 : 0}
    >
      {isSubRow ? (
        <StyledSubRow justifyContent='flex-start' alignItems='center' gap={0}>
          {children}
        </StyledSubRow>
      ) : (
        children
      )}
    </Flexbox>
  );
};

export const InsetTableSubRow = memo(SubRow) as typeof SubRow;
