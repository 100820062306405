'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.order_by = (() => {
  const order_by = {};
  order_by.v1 = (() => {
    const v1 = {};
    v1.OrderByDirection = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ORDER_BY_DIRECTION')] = 0;
      values[(valuesById[1] = 'ASC')] = 1;
      values[(valuesById[2] = 'DESC')] = 2;
      return values;
    })();

    return v1;
  })();

  return order_by;
})();

export const order_by = $root.order_by;
