'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.field_lookup = (() => {
  const field_lookup = {};
  field_lookup.v1 = (() => {
    const v1 = {};
    v1.FieldLookup = (() => {
      class FieldLookup {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FieldLookup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fieldName != null &&
            Object.hasOwnProperty.call(message, 'fieldName')
          ) {
            writer.uint32(10).string(message.fieldName);
          }
          if (
            message.nestedLookup != null &&
            Object.hasOwnProperty.call(message, 'nestedLookup')
          ) {
            $root.field_lookup.v1.FieldLookup.encode(
              message.nestedLookup,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FieldLookup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldName = reader.string();
                break;
              }
              case 2: {
                message.nestedLookup = $root.field_lookup.v1.FieldLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.field_lookup.v1.FieldLookup) {
            return object;
          }
          const message = new $root.field_lookup.v1.FieldLookup();
          if (object.fieldName != null) {
            message.fieldName = String(object.fieldName);
          }
          if (object.nestedLookup != null) {
            if (typeof object.nestedLookup !== 'object') {
              throw new TypeError(
                '.field_lookup.v1.FieldLookup.nestedLookup: object expected, but got ' +
                  typeof object.nestedLookup
              );
            }
            message.nestedLookup = $root.field_lookup.v1.FieldLookup.fromObject(
              object.nestedLookup
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldName = '';
          }
          let keys;
          if (
            message.fieldName != null &&
            message.hasOwnProperty('fieldName')
          ) {
            object.fieldName = message.fieldName;
          }
          if (
            message.nestedLookup != null &&
            message.hasOwnProperty('nestedLookup')
          ) {
            object.nestedLookup = $root.field_lookup.v1.FieldLookup.toObject(
              message.nestedLookup,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'field_lookup.v1.FieldLookup';
        }
      }

      FieldLookup.prototype.fieldName = '';
      FieldLookup.prototype.nestedLookup = null;

      return FieldLookup;
    })();

    return v1;
  })();

  return field_lookup;
})();

export const field_lookup = $root.field_lookup;
