import { styled } from '@morf/theming';
import { Flexbox } from '../../../Flexbox';
import { ContainerProps } from '../../../Container';
import { borderStyles } from '../../../Container/Container.css';
import { TableHeadCellStyling } from './types';

export const StyledHeadCell = styled.th<
  {
    borderType: ContainerProps['borderType'];
    headCellPl: ContainerProps['pl'];
    headCellPr: ContainerProps['pr'];
    isPinned?: boolean;
    isScrollable?: boolean;
  } & TableHeadCellStyling
>`
  top: 0;
  position: sticky;
  z-index: var(--table-header-cell-index);

  ${borderStyles}
  background-color: ${(props) => props.headCellBackgroundColor};
  height: ${(props) => props.headCellHeight};
  padding-left: ${(props) => `${props.headCellPl}rem`};
  padding-right: ${(props) => `${props.headCellPr}rem`};

  ${({ isPinned, isScrollable }) =>
    isPinned &&
    isScrollable &&
    `
      z-index: var(--table-header-cell-pinned-index);
      left: 0;
      `};
`;

export const StyledHeadCellContent = styled(Flexbox)<{
  isPinned?: boolean;
  isScrollable?: boolean;
}>`
  ${({ isPinned, isScrollable }) =>
    isPinned &&
    isScrollable &&
    `::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0px;
        bottom: 0;
        width: 15px;
        box-shadow: 15px 0px 20px rgba(0,0,0,0.05);
        z-index: -1;
      }
      `};
`;
