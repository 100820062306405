import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { ProfileHeaderProps } from './types';
import { SkeletonLoadable } from '../Skeleton';
import { capitalizeFirstLetter } from '../Helpers/capitalizeFirstLetter';

const ProfileHeader: FC<ProfileHeaderProps> = ({
  fullName,
  email,
  id,
  type,
  isLoading,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      data-testid='profile-header'
      height='auto'
      backgroundColor={theme.colors.ui.card}
      gap={0}
      pt={1.25}
      px={1.25}
    >
      <SkeletonLoadable isLoading={isLoading} height='2rem' width='10rem'>
        <Text tag='h2'>{fullName}</Text>
      </SkeletonLoadable>
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
        gap={0.5}
      >
        <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
          <Text tag='h5'>{capitalizeFirstLetter(type)}</Text>
        </SkeletonLoadable>
        <Text tag='h5' color={theme.colors.text.disabled}>
          •
        </Text>
        <SkeletonLoadable isLoading={isLoading} height='1.5rem' width='10rem'>
          <Text tag='h5'>{id}</Text>
        </SkeletonLoadable>
        {email && (
          <>
            <Text tag='h5' color={theme.colors.text.disabled}>
              •
            </Text>
            <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
              <Text tag='h5'>{email}</Text>
            </SkeletonLoadable>
          </>
        )}
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedProfileHeader = memo(ProfileHeader);
