import '../public/fonts/fonts.css';
import 'allotment/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'reactflow/dist/base.css';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';
import FontPreload from '../lib/font-preload';
import { AppProps } from 'next/app';
import { ErrorBoundary, Toast } from '@morf/ui';
import { Id, toast, ToastContainer } from 'react-toastify';
import { MORF_ORG_ID } from '@morf/constants';
import { PrivateRoute } from '../components/privateRoute';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StytchProvider } from '@stytch/nextjs';
import { TimezoneProvider } from '@/components/context/timezone/timezoneProvider';
import { WorkflowProvider } from '../components/context/workflow/workflowProvider';
import { createStytchUIClient } from '@stytch/nextjs/ui';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { morf, ThemeContainer } from '@morf/theming';
import { useRouter } from 'next/router';
import { useUrlParams } from '@/components/helpers/useUrlParams';
import { useVisibilityChange } from '@/components/helpers/useVisibilityChange';
import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const publicRoutes = [
  '/authenticate',
  '/login',
  '/sessionExpired',
  '/unauthorized',
];

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

export default function AdminApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { organizationId } = useUrlParams();
  const { pathname } = router;
  const isPublicRoute = publicRoutes.includes(pathname);

  useVisibilityChange(organizationId);

  const [showDevtools, setShowDevtools] = useState(false);
  const loadingToastIdRef = useRef<Id | null>(null);

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  const stytchToken = process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN;
  if (!stytchToken) {
    throw new Error(
      'Missing NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN environment variable'
    );
  }
  const stytch = createStytchUIClient(stytchToken);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: mutationCache,
        defaultOptions: {
          queries: {
            retry: false,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  );

  const mutationCache = new MutationCache({
    onMutate: () => {
      loadingToastIdRef.current = toast.loading(
        <Toast
          message='Request in progress...'
          icon='arrow-path'
          type='inactive'
        />
      );
    },
    onError: (error, _variables, _context, _mutation) => {
      if (loadingToastIdRef.current) {
        toast.update(loadingToastIdRef.current, {
          render: (
            <Toast
              message='Request failed!'
              description={(error as Error).message}
              icon='x-circle'
              type='alert'
            />
          ),
          isLoading: false,
          autoClose: 5000,
        });
      }
    },
    onSuccess: (_data, _variables, _context, _mutation) => {
      if (loadingToastIdRef.current) {
        toast.update(loadingToastIdRef.current, {
          render: (
            <Toast
              message='Request was successful!'
              icon='check-circle'
              type='success'
            />
          ),
          isLoading: false,
          autoClose: 5000,
        });
      }
    },
  });

  useEffect(() => {
    const morf_organization_id = Cookies.get(MORF_ORG_ID);
    if (!isPublicRoute && !pathname.includes('organizations')) {
      if (morf_organization_id) {
        router.push(`/organizations/${morf_organization_id}`);
      } else {
        router.push('/login');
      }
    }
  }, []);

  return (
    <>
      <div id='modal'></div>
      <ThemeContainer theme={morf}>
        <StytchProvider stytch={stytch}>
          <WorkflowProvider>
            <TimezoneProvider>
              <QueryClientProvider client={queryClient}>
                <ToastContainer
                  position='top-center'
                  closeButton={false}
                  hideProgressBar={true}
                />

                <ReactQueryDevtools initialIsOpen />

                {showDevtools && (
                  <Suspense fallback={null}>
                    <ReactQueryDevtoolsProduction />
                  </Suspense>
                )}

                <FontPreload />
                <div id='admin'>
                  {isPublicRoute ? (
                    <Component {...pageProps} />
                  ) : (
                    organizationId && (
                      <PrivateRoute organizationId={organizationId}>
                        <ErrorBoundary logError={Sentry.captureException}>
                          <Component {...pageProps} />
                        </ErrorBoundary>
                      </PrivateRoute>
                    )
                  )}
                </div>
              </QueryClientProvider>
            </TimezoneProvider>
          </WorkflowProvider>
        </StytchProvider>
      </ThemeContainer>
    </>
  );
}
