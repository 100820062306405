import { Story, Meta } from '@morf/storybook';
import { MemoizedLinkSnapshotIcon } from './LinkSnapshotIcon';
import { LinkSnapshotIconProps } from './types';

export default {
  title: 'UI/Snapshot/SnapshotIcon/LinkSnapshotIcon',
  component: MemoizedLinkSnapshotIcon,
} as Meta;

const Template: Story<LinkSnapshotIconProps> = (args) => (
  <MemoizedLinkSnapshotIcon {...args} />
);

export const LinkSnapshotIcon = Template.bind({});
LinkSnapshotIcon.args = {
  name: 'tools',
  link: '/link',
  tooltipText: 'View snapshot configuration',
};
