import { FC, memo } from 'react';
import { TabsProps } from './types';
import { StyledTabButton } from './Tabs.css';
import { Text } from '../Typography';
import { Flexbox } from '../Flexbox';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';

const Tabs: FC<TabsProps> = ({
  children,
  initialValue,
  onChange,
  ...props
}) => {
  const theme = useTheme();
  const isSelected = (index: number) => index === initialValue;

  const tabs = children.map(
    (child, index) =>
      child && (
        <StyledTabButton
          data-testid={`tab-${index}`}
          key={index}
          isSelected={isSelected(index)}
          onClick={(e) => {
            e.preventDefault();
            onChange(index);
          }}
        >
          <Text tag={isSelected(index) ? 'h4' : 'p1'} color='inherit'>
            {child.props.label}
          </Text>
        </StyledTabButton>
      )
  );

  const tabContent = children.filter((_child, index) => isSelected(index));

  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      gap={0}
    >
      <Flexbox
        justifyContent='flex-start'
        backgroundColor={theme.colors.ui.card}
        borderType='borderBottom'
        overflowX='scroll'
        height='3rem'
        gap={0}
        {...props}
      >
        {tabs}
      </Flexbox>
      <Container overflow='scroll' data-testid='tab-content'>
        {tabContent}
      </Container>
    </Flexbox>
  );
};

export const MemoizedTabs = memo(Tabs);
