'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { condition_expr as condition_expr$1 } from './condition_expr_v1_ts_proto';
import { field_lookup as field_lookup$1 } from './field_lookup_v1_ts_proto';
import { patients as patients$1 } from './patients_v1_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { values as values$1 } from './values_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.event_config = (() => {
  const event_config = {};
  event_config.v1 = (() => {
    const v1 = {};
    v1.EventConfig = (() => {
      class EventConfig {
        constructor(properties) {
          this.patientPropertyMappings = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventConfig(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.condition != null &&
            Object.hasOwnProperty.call(message, 'condition')
          ) {
            condition_expr$1.v1.ConditionExpr.encode(
              message.condition,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.patientIdFieldLookups != null &&
            Object.hasOwnProperty.call(message, 'patientIdFieldLookups')
          ) {
            $root.event_config.v1.PatientIdFieldLookups.encode(
              message.patientIdFieldLookups,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.behaviorForPatientNotMatched != null &&
            Object.hasOwnProperty.call(message, 'behaviorForPatientNotMatched')
          ) {
            writer.uint32(40).int32(message.behaviorForPatientNotMatched);
          }
          if (
            message.patientPropertyMappings != null &&
            Object.hasOwnProperty.call(message, 'patientPropertyMappings')
          ) {
            for (const element of message.patientPropertyMappings) {
              $root.event_config.v1.PatientPropertyMappingResponse.encode(
                element,
                writer.uint32(50).fork()
              ).ldelim();
            }
          }
          if (
            message.doNotCreateNewObjectTypes != null &&
            Object.hasOwnProperty.call(message, 'doNotCreateNewObjectTypes')
          ) {
            writer.uint32(56).bool(message.doNotCreateNewObjectTypes);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventConfig();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.condition = condition_expr$1.v1.ConditionExpr.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.patientIdFieldLookups =
                  $root.event_config.v1.PatientIdFieldLookups.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.behaviorForPatientNotMatched = reader.int32();
                break;
              }
              case 6: {
                if (
                  !message.patientPropertyMappings ||
                  !message.patientPropertyMappings.length
                ) {
                  message.patientPropertyMappings = [];
                }
                message.patientPropertyMappings.push(
                  $root.event_config.v1.PatientPropertyMappingResponse.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 7: {
                message.doNotCreateNewObjectTypes = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.EventConfig) {
            return object;
          }
          const message = new $root.event_config.v1.EventConfig();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.condition != null) {
            if (typeof object.condition !== 'object') {
              throw new TypeError(
                '.event_config.v1.EventConfig.condition: object expected, but got ' +
                  typeof object.condition
              );
            }
            message.condition = condition_expr$1.v1.ConditionExpr.fromObject(
              object.condition
            );
          }
          if (object.patientIdFieldLookups != null) {
            if (typeof object.patientIdFieldLookups !== 'object') {
              throw new TypeError(
                '.event_config.v1.EventConfig.patientIdFieldLookups: object expected, but got ' +
                  typeof object.patientIdFieldLookups
              );
            }
            message.patientIdFieldLookups =
              $root.event_config.v1.PatientIdFieldLookups.fromObject(
                object.patientIdFieldLookups
              );
          }
          if (object.behaviorForPatientNotMatched != null) {
            switch (object.behaviorForPatientNotMatched) {
              case 'UNSPECIFIED':
              case 0: {
                message.behaviorForPatientNotMatched = 0;
                break;
              }
              case 'CREATE':
              case 1: {
                message.behaviorForPatientNotMatched = 1;
                break;
              }
              case 'ERROR_OUT':
              case 2: {
                message.behaviorForPatientNotMatched = 2;
                break;
              }
              case 'SKIP':
              case 3: {
                message.behaviorForPatientNotMatched = 3;
                break;
              }
              default: {
                if (typeof object.behaviorForPatientNotMatched == 'number') {
                  message.behaviorForPatientNotMatched =
                    object.behaviorForPatientNotMatched;
                  break;
                }
                break;
              }
            }
          }
          if (object.patientPropertyMappings) {
            if (!Array.isArray(object.patientPropertyMappings)) {
              throw new TypeError(
                '.event_config.v1.EventConfig.patientPropertyMappings: array type expected, but got ' +
                  typeof object.patientPropertyMappings
              );
            }
            message.patientPropertyMappings = new Array(
              object.patientPropertyMappings.length
            );
            for (let i = 0; i < object.patientPropertyMappings.length; ++i) {
              if (typeof object.patientPropertyMappings[i] !== 'object') {
                throw new TypeError(
                  '.event_config.v1.EventConfig.patientPropertyMappings: object expected, but got ' +
                    typeof object.patientPropertyMappings[i]
                );
              }
              message.patientPropertyMappings[i] =
                $root.event_config.v1.PatientPropertyMappingResponse.fromObject(
                  object.patientPropertyMappings[i]
                );
            }
          }
          if (object.doNotCreateNewObjectTypes != null) {
            message.doNotCreateNewObjectTypes = Boolean(
              object.doNotCreateNewObjectTypes
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.patientPropertyMappings = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.patientIdFieldLookups = null;
            object.behaviorForPatientNotMatched =
              options.enums === String ? 'UNSPECIFIED' : 0;
            object.doNotCreateNewObjectTypes = false;
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.condition != null &&
            message.hasOwnProperty('condition')
          ) {
            object.condition = condition_expr$1.v1.ConditionExpr.toObject(
              message.condition,
              options
            );
          }
          if (
            message.patientIdFieldLookups != null &&
            message.hasOwnProperty('patientIdFieldLookups')
          ) {
            object.patientIdFieldLookups =
              $root.event_config.v1.PatientIdFieldLookups.toObject(
                message.patientIdFieldLookups,
                options
              );
          }
          if (
            message.behaviorForPatientNotMatched != null &&
            message.hasOwnProperty('behaviorForPatientNotMatched')
          ) {
            object.behaviorForPatientNotMatched =
              options.enums === String
                ? $root.event_config.v1.BehaviorForPatientNotMatched[
                    message.behaviorForPatientNotMatched
                  ] === undefined
                  ? message.behaviorForPatientNotMatched
                  : $root.event_config.v1.BehaviorForPatientNotMatched[
                      message.behaviorForPatientNotMatched
                    ]
                : message.behaviorForPatientNotMatched;
          }
          if (
            message.patientPropertyMappings &&
            message.patientPropertyMappings.length
          ) {
            object.patientPropertyMappings = new Array(
              message.patientPropertyMappings.length
            );
            for (let i = 0; i < message.patientPropertyMappings.length; ++i) {
              object.patientPropertyMappings[i] =
                $root.event_config.v1.PatientPropertyMappingResponse.toObject(
                  message.patientPropertyMappings[i],
                  options
                );
            }
          }
          if (
            message.doNotCreateNewObjectTypes != null &&
            message.hasOwnProperty('doNotCreateNewObjectTypes')
          ) {
            object.doNotCreateNewObjectTypes =
              message.doNotCreateNewObjectTypes;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.EventConfig';
        }
      }

      EventConfig.prototype.id = '';
      EventConfig.prototype.condition = null;
      EventConfig.prototype.patientIdFieldLookups = null;
      EventConfig.prototype.behaviorForPatientNotMatched = 0;
      EventConfig.prototype.patientPropertyMappings = $util.emptyArray;
      EventConfig.prototype.doNotCreateNewObjectTypes = false;

      return EventConfig;
    })();

    v1.UpdateEventConfig = (() => {
      class UpdateEventConfig {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateEventConfig(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.condition != null &&
            Object.hasOwnProperty.call(message, 'condition')
          ) {
            condition_expr$1.v1.ConditionExpr.encode(
              message.condition,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.patientIdFieldLookups != null &&
            Object.hasOwnProperty.call(message, 'patientIdFieldLookups')
          ) {
            $root.event_config.v1.PatientIdFieldLookups.encode(
              message.patientIdFieldLookups,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.behaviorForPatientNotMatched != null &&
            Object.hasOwnProperty.call(message, 'behaviorForPatientNotMatched')
          ) {
            writer.uint32(24).int32(message.behaviorForPatientNotMatched);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateEventConfig();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.condition = condition_expr$1.v1.ConditionExpr.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.patientIdFieldLookups =
                  $root.event_config.v1.PatientIdFieldLookups.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.behaviorForPatientNotMatched = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.UpdateEventConfig) {
            return object;
          }
          const message = new $root.event_config.v1.UpdateEventConfig();
          if (object.condition != null) {
            if (typeof object.condition !== 'object') {
              throw new TypeError(
                '.event_config.v1.UpdateEventConfig.condition: object expected, but got ' +
                  typeof object.condition
              );
            }
            message.condition = condition_expr$1.v1.ConditionExpr.fromObject(
              object.condition
            );
          }
          if (object.patientIdFieldLookups != null) {
            if (typeof object.patientIdFieldLookups !== 'object') {
              throw new TypeError(
                '.event_config.v1.UpdateEventConfig.patientIdFieldLookups: object expected, but got ' +
                  typeof object.patientIdFieldLookups
              );
            }
            message.patientIdFieldLookups =
              $root.event_config.v1.PatientIdFieldLookups.fromObject(
                object.patientIdFieldLookups
              );
          }
          if (object.behaviorForPatientNotMatched != null) {
            switch (object.behaviorForPatientNotMatched) {
              case 'UNSPECIFIED':
              case 0: {
                message.behaviorForPatientNotMatched = 0;
                break;
              }
              case 'CREATE':
              case 1: {
                message.behaviorForPatientNotMatched = 1;
                break;
              }
              case 'ERROR_OUT':
              case 2: {
                message.behaviorForPatientNotMatched = 2;
                break;
              }
              case 'SKIP':
              case 3: {
                message.behaviorForPatientNotMatched = 3;
                break;
              }
              default: {
                if (typeof object.behaviorForPatientNotMatched == 'number') {
                  message.behaviorForPatientNotMatched =
                    object.behaviorForPatientNotMatched;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.condition != null &&
            message.hasOwnProperty('condition')
          ) {
            object.condition = condition_expr$1.v1.ConditionExpr.toObject(
              message.condition,
              options
            );
          }
          if (
            message.patientIdFieldLookups != null &&
            message.hasOwnProperty('patientIdFieldLookups')
          ) {
            object.patientIdFieldLookups =
              $root.event_config.v1.PatientIdFieldLookups.toObject(
                message.patientIdFieldLookups,
                options
              );
          }
          if (
            message.behaviorForPatientNotMatched != null &&
            message.hasOwnProperty('behaviorForPatientNotMatched')
          ) {
            object.behaviorForPatientNotMatched =
              options.enums === String
                ? $root.event_config.v1.BehaviorForPatientNotMatched[
                    message.behaviorForPatientNotMatched
                  ] === undefined
                  ? message.behaviorForPatientNotMatched
                  : $root.event_config.v1.BehaviorForPatientNotMatched[
                      message.behaviorForPatientNotMatched
                    ]
                : message.behaviorForPatientNotMatched;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.UpdateEventConfig';
        }
      }

      UpdateEventConfig.prototype.condition = null;
      UpdateEventConfig.prototype.patientIdFieldLookups = null;
      UpdateEventConfig.prototype.behaviorForPatientNotMatched = null;

      return UpdateEventConfig;
    })();

    v1.PatientPropertySource = (() => {
      class PatientPropertySource {
        get source() {
          for (const key of ['fieldLookup', 'calculation']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set source(name) {
          for (const key of ['fieldLookup', 'calculation']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertySource(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fieldLookup != null &&
            Object.hasOwnProperty.call(message, 'fieldLookup')
          ) {
            field_lookup$1.v1.FieldLookup.encode(
              message.fieldLookup,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.calculation != null &&
            Object.hasOwnProperty.call(message, 'calculation')
          ) {
            $root.event_config.v1.FieldCalculation.encode(
              message.calculation,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertySource();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldLookup = field_lookup$1.v1.FieldLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.calculation =
                  $root.event_config.v1.FieldCalculation.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.PatientPropertySource) {
            return object;
          }
          const message = new $root.event_config.v1.PatientPropertySource();
          if (object.fieldLookup != null) {
            if (typeof object.fieldLookup !== 'object') {
              throw new TypeError(
                '.event_config.v1.PatientPropertySource.fieldLookup: object expected, but got ' +
                  typeof object.fieldLookup
              );
            }
            message.fieldLookup = field_lookup$1.v1.FieldLookup.fromObject(
              object.fieldLookup
            );
          }
          if (object.calculation != null) {
            if (typeof object.calculation !== 'object') {
              throw new TypeError(
                '.event_config.v1.PatientPropertySource.calculation: object expected, but got ' +
                  typeof object.calculation
              );
            }
            message.calculation =
              $root.event_config.v1.FieldCalculation.fromObject(
                object.calculation
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.fieldLookup != null &&
            message.hasOwnProperty('fieldLookup')
          ) {
            object.fieldLookup = field_lookup$1.v1.FieldLookup.toObject(
              message.fieldLookup,
              options
            );
            if (options.oneofs) {
              object.source = 'fieldLookup';
            }
          }
          if (
            message.calculation != null &&
            message.hasOwnProperty('calculation')
          ) {
            object.calculation =
              $root.event_config.v1.FieldCalculation.toObject(
                message.calculation,
                options
              );
            if (options.oneofs) {
              object.source = 'calculation';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.PatientPropertySource';
        }
      }

      PatientPropertySource.prototype.fieldLookup = null;
      PatientPropertySource.prototype.calculation = null;

      return PatientPropertySource;
    })();

    v1.PatientPropertyMapping = (() => {
      class PatientPropertyMapping {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertyMapping(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.source != null &&
            Object.hasOwnProperty.call(message, 'source')
          ) {
            $root.event_config.v1.PatientPropertySource.encode(
              message.source,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.propertyId != null &&
            Object.hasOwnProperty.call(message, 'propertyId')
          ) {
            writer.uint32(18).string(message.propertyId);
          }
          if (
            message.disabled != null &&
            Object.hasOwnProperty.call(message, 'disabled')
          ) {
            writer.uint32(24).bool(message.disabled);
          }
          if (
            message.passthrough != null &&
            Object.hasOwnProperty.call(message, 'passthrough')
          ) {
            writer.uint32(32).bool(message.passthrough);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertyMapping();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.source =
                  $root.event_config.v1.PatientPropertySource.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.propertyId = reader.string();
                break;
              }
              case 3: {
                message.disabled = reader.bool();
                break;
              }
              case 4: {
                message.passthrough = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.PatientPropertyMapping) {
            return object;
          }
          const message = new $root.event_config.v1.PatientPropertyMapping();
          if (object.source != null) {
            if (typeof object.source !== 'object') {
              throw new TypeError(
                '.event_config.v1.PatientPropertyMapping.source: object expected, but got ' +
                  typeof object.source
              );
            }
            message.source =
              $root.event_config.v1.PatientPropertySource.fromObject(
                object.source
              );
          }
          if (object.propertyId != null) {
            message.propertyId = String(object.propertyId);
          }
          if (object.disabled != null) {
            message.disabled = Boolean(object.disabled);
          }
          if (object.passthrough != null) {
            message.passthrough = Boolean(object.passthrough);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.source = null;
            object.propertyId = '';
            object.disabled = false;
            object.passthrough = false;
          }
          let keys;
          if (message.source != null && message.hasOwnProperty('source')) {
            object.source =
              $root.event_config.v1.PatientPropertySource.toObject(
                message.source,
                options
              );
          }
          if (
            message.propertyId != null &&
            message.hasOwnProperty('propertyId')
          ) {
            object.propertyId = message.propertyId;
          }
          if (message.disabled != null && message.hasOwnProperty('disabled')) {
            object.disabled = message.disabled;
          }
          if (
            message.passthrough != null &&
            message.hasOwnProperty('passthrough')
          ) {
            object.passthrough = message.passthrough;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.PatientPropertyMapping';
        }
      }

      PatientPropertyMapping.prototype.source = null;
      PatientPropertyMapping.prototype.propertyId = '';
      PatientPropertyMapping.prototype.disabled = false;
      PatientPropertyMapping.prototype.passthrough = false;

      return PatientPropertyMapping;
    })();

    v1.PatientPropertyMappingResponse = (() => {
      class PatientPropertyMappingResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientPropertyMappingResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.source != null &&
            Object.hasOwnProperty.call(message, 'source')
          ) {
            $root.event_config.v1.PatientPropertySource.encode(
              message.source,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.property != null &&
            Object.hasOwnProperty.call(message, 'property')
          ) {
            patients$1.v1.PatientProperty.encode(
              message.property,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.disabled != null &&
            Object.hasOwnProperty.call(message, 'disabled')
          ) {
            writer.uint32(24).bool(message.disabled);
          }
          if (
            message.passthrough != null &&
            Object.hasOwnProperty.call(message, 'passthrough')
          ) {
            writer.uint32(32).bool(message.passthrough);
          }
          if (
            message.mappingId != null &&
            Object.hasOwnProperty.call(message, 'mappingId')
          ) {
            writer.uint32(42).string(message.mappingId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientPropertyMappingResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.source =
                  $root.event_config.v1.PatientPropertySource.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.property = patients$1.v1.PatientProperty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.disabled = reader.bool();
                break;
              }
              case 4: {
                message.passthrough = reader.bool();
                break;
              }
              case 5: {
                message.mappingId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.event_config.v1.PatientPropertyMappingResponse
          ) {
            return object;
          }
          const message =
            new $root.event_config.v1.PatientPropertyMappingResponse();
          if (object.source != null) {
            if (typeof object.source !== 'object') {
              throw new TypeError(
                '.event_config.v1.PatientPropertyMappingResponse.source: object expected, but got ' +
                  typeof object.source
              );
            }
            message.source =
              $root.event_config.v1.PatientPropertySource.fromObject(
                object.source
              );
          }
          if (object.property != null) {
            if (typeof object.property !== 'object') {
              throw new TypeError(
                '.event_config.v1.PatientPropertyMappingResponse.property: object expected, but got ' +
                  typeof object.property
              );
            }
            message.property = patients$1.v1.PatientProperty.fromObject(
              object.property
            );
          }
          if (object.disabled != null) {
            message.disabled = Boolean(object.disabled);
          }
          if (object.passthrough != null) {
            message.passthrough = Boolean(object.passthrough);
          }
          if (object.mappingId != null) {
            message.mappingId = String(object.mappingId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.source = null;
            object.property = null;
            object.disabled = false;
            object.passthrough = false;
            object.mappingId = '';
          }
          let keys;
          if (message.source != null && message.hasOwnProperty('source')) {
            object.source =
              $root.event_config.v1.PatientPropertySource.toObject(
                message.source,
                options
              );
          }
          if (message.property != null && message.hasOwnProperty('property')) {
            object.property = patients$1.v1.PatientProperty.toObject(
              message.property,
              options
            );
          }
          if (message.disabled != null && message.hasOwnProperty('disabled')) {
            object.disabled = message.disabled;
          }
          if (
            message.passthrough != null &&
            message.hasOwnProperty('passthrough')
          ) {
            object.passthrough = message.passthrough;
          }
          if (
            message.mappingId != null &&
            message.hasOwnProperty('mappingId')
          ) {
            object.mappingId = message.mappingId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'event_config.v1.PatientPropertyMappingResponse'
          );
        }
      }

      PatientPropertyMappingResponse.prototype.source = null;
      PatientPropertyMappingResponse.prototype.property = null;
      PatientPropertyMappingResponse.prototype.disabled = false;
      PatientPropertyMappingResponse.prototype.passthrough = false;
      PatientPropertyMappingResponse.prototype.mappingId = '';

      return PatientPropertyMappingResponse;
    })();

    v1.CreatePatientPropertyMappingRequest = (() => {
      class CreatePatientPropertyMappingRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertyMappingRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventConfigId != null &&
            Object.hasOwnProperty.call(message, 'eventConfigId')
          ) {
            writer.uint32(10).string(message.eventConfigId);
          }
          if (
            message.mapping != null &&
            Object.hasOwnProperty.call(message, 'mapping')
          ) {
            $root.event_config.v1.PatientPropertyMapping.encode(
              message.mapping,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertyMappingRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventConfigId = reader.string();
                break;
              }
              case 2: {
                message.mapping =
                  $root.event_config.v1.PatientPropertyMapping.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.event_config.v1.CreatePatientPropertyMappingRequest
          ) {
            return object;
          }
          const message =
            new $root.event_config.v1.CreatePatientPropertyMappingRequest();
          if (object.eventConfigId != null) {
            message.eventConfigId = String(object.eventConfigId);
          }
          if (object.mapping != null) {
            if (typeof object.mapping !== 'object') {
              throw new TypeError(
                '.event_config.v1.CreatePatientPropertyMappingRequest.mapping: object expected, but got ' +
                  typeof object.mapping
              );
            }
            message.mapping =
              $root.event_config.v1.PatientPropertyMapping.fromObject(
                object.mapping
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventConfigId = '';
            object.mapping = null;
          }
          let keys;
          if (
            message.eventConfigId != null &&
            message.hasOwnProperty('eventConfigId')
          ) {
            object.eventConfigId = message.eventConfigId;
          }
          if (message.mapping != null && message.hasOwnProperty('mapping')) {
            object.mapping =
              $root.event_config.v1.PatientPropertyMapping.toObject(
                message.mapping,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'event_config.v1.CreatePatientPropertyMappingRequest'
          );
        }
      }

      CreatePatientPropertyMappingRequest.prototype.eventConfigId = '';
      CreatePatientPropertyMappingRequest.prototype.mapping = null;

      return CreatePatientPropertyMappingRequest;
    })();

    v1.CreatePatientPropertyMappingResponse = (() => {
      class CreatePatientPropertyMappingResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreatePatientPropertyMappingResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventConfigId != null &&
            Object.hasOwnProperty.call(message, 'eventConfigId')
          ) {
            writer.uint32(10).string(message.eventConfigId);
          }
          if (
            message.mapping != null &&
            Object.hasOwnProperty.call(message, 'mapping')
          ) {
            $root.event_config.v1.PatientPropertyMappingResponse.encode(
              message.mapping,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreatePatientPropertyMappingResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventConfigId = reader.string();
                break;
              }
              case 2: {
                message.mapping =
                  $root.event_config.v1.PatientPropertyMappingResponse.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.event_config.v1.CreatePatientPropertyMappingResponse
          ) {
            return object;
          }
          const message =
            new $root.event_config.v1.CreatePatientPropertyMappingResponse();
          if (object.eventConfigId != null) {
            message.eventConfigId = String(object.eventConfigId);
          }
          if (object.mapping != null) {
            if (typeof object.mapping !== 'object') {
              throw new TypeError(
                '.event_config.v1.CreatePatientPropertyMappingResponse.mapping: object expected, but got ' +
                  typeof object.mapping
              );
            }
            message.mapping =
              $root.event_config.v1.PatientPropertyMappingResponse.fromObject(
                object.mapping
              );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.event_config.v1.CreatePatientPropertyMappingResponse.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.event_config.v1.CreatePatientPropertyMappingResponse.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventConfigId = '';
            object.mapping = null;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (
            message.eventConfigId != null &&
            message.hasOwnProperty('eventConfigId')
          ) {
            object.eventConfigId = message.eventConfigId;
          }
          if (message.mapping != null && message.hasOwnProperty('mapping')) {
            object.mapping =
              $root.event_config.v1.PatientPropertyMappingResponse.toObject(
                message.mapping,
                options
              );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'event_config.v1.CreatePatientPropertyMappingResponse'
          );
        }
      }

      CreatePatientPropertyMappingResponse.prototype.eventConfigId = '';
      CreatePatientPropertyMappingResponse.prototype.mapping = null;
      CreatePatientPropertyMappingResponse.prototype.createdAt = null;
      CreatePatientPropertyMappingResponse.prototype.updatedAt = null;

      return CreatePatientPropertyMappingResponse;
    })();

    v1.UpdatePatientPropertyMappingRequest = (() => {
      class UpdatePatientPropertyMappingRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientPropertyMappingRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventConfigId != null &&
            Object.hasOwnProperty.call(message, 'eventConfigId')
          ) {
            writer.uint32(10).string(message.eventConfigId);
          }
          if (
            message.mappingId != null &&
            Object.hasOwnProperty.call(message, 'mappingId')
          ) {
            writer.uint32(18).string(message.mappingId);
          }
          if (
            message.mapping != null &&
            Object.hasOwnProperty.call(message, 'mapping')
          ) {
            $root.event_config.v1.PatientPropertyMapping.encode(
              message.mapping,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientPropertyMappingRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventConfigId = reader.string();
                break;
              }
              case 2: {
                message.mappingId = reader.string();
                break;
              }
              case 3: {
                message.mapping =
                  $root.event_config.v1.PatientPropertyMapping.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.event_config.v1.UpdatePatientPropertyMappingRequest
          ) {
            return object;
          }
          const message =
            new $root.event_config.v1.UpdatePatientPropertyMappingRequest();
          if (object.eventConfigId != null) {
            message.eventConfigId = String(object.eventConfigId);
          }
          if (object.mappingId != null) {
            message.mappingId = String(object.mappingId);
          }
          if (object.mapping != null) {
            if (typeof object.mapping !== 'object') {
              throw new TypeError(
                '.event_config.v1.UpdatePatientPropertyMappingRequest.mapping: object expected, but got ' +
                  typeof object.mapping
              );
            }
            message.mapping =
              $root.event_config.v1.PatientPropertyMapping.fromObject(
                object.mapping
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventConfigId = '';
            object.mappingId = '';
            object.mapping = null;
          }
          let keys;
          if (
            message.eventConfigId != null &&
            message.hasOwnProperty('eventConfigId')
          ) {
            object.eventConfigId = message.eventConfigId;
          }
          if (
            message.mappingId != null &&
            message.hasOwnProperty('mappingId')
          ) {
            object.mappingId = message.mappingId;
          }
          if (message.mapping != null && message.hasOwnProperty('mapping')) {
            object.mapping =
              $root.event_config.v1.PatientPropertyMapping.toObject(
                message.mapping,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'event_config.v1.UpdatePatientPropertyMappingRequest'
          );
        }
      }

      UpdatePatientPropertyMappingRequest.prototype.eventConfigId = '';
      UpdatePatientPropertyMappingRequest.prototype.mappingId = '';
      UpdatePatientPropertyMappingRequest.prototype.mapping = null;

      return UpdatePatientPropertyMappingRequest;
    })();

    v1.UpdatePatientPropertyMappingResponse = (() => {
      class UpdatePatientPropertyMappingResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdatePatientPropertyMappingResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventConfigId != null &&
            Object.hasOwnProperty.call(message, 'eventConfigId')
          ) {
            writer.uint32(10).string(message.eventConfigId);
          }
          if (
            message.mapping != null &&
            Object.hasOwnProperty.call(message, 'mapping')
          ) {
            $root.event_config.v1.PatientPropertyMappingResponse.encode(
              message.mapping,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdatePatientPropertyMappingResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventConfigId = reader.string();
                break;
              }
              case 2: {
                message.mapping =
                  $root.event_config.v1.PatientPropertyMappingResponse.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.event_config.v1.UpdatePatientPropertyMappingResponse
          ) {
            return object;
          }
          const message =
            new $root.event_config.v1.UpdatePatientPropertyMappingResponse();
          if (object.eventConfigId != null) {
            message.eventConfigId = String(object.eventConfigId);
          }
          if (object.mapping != null) {
            if (typeof object.mapping !== 'object') {
              throw new TypeError(
                '.event_config.v1.UpdatePatientPropertyMappingResponse.mapping: object expected, but got ' +
                  typeof object.mapping
              );
            }
            message.mapping =
              $root.event_config.v1.PatientPropertyMappingResponse.fromObject(
                object.mapping
              );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.event_config.v1.UpdatePatientPropertyMappingResponse.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.event_config.v1.UpdatePatientPropertyMappingResponse.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventConfigId = '';
            object.mapping = null;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (
            message.eventConfigId != null &&
            message.hasOwnProperty('eventConfigId')
          ) {
            object.eventConfigId = message.eventConfigId;
          }
          if (message.mapping != null && message.hasOwnProperty('mapping')) {
            object.mapping =
              $root.event_config.v1.PatientPropertyMappingResponse.toObject(
                message.mapping,
                options
              );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'event_config.v1.UpdatePatientPropertyMappingResponse'
          );
        }
      }

      UpdatePatientPropertyMappingResponse.prototype.eventConfigId = '';
      UpdatePatientPropertyMappingResponse.prototype.mapping = null;
      UpdatePatientPropertyMappingResponse.prototype.createdAt = null;
      UpdatePatientPropertyMappingResponse.prototype.updatedAt = null;

      return UpdatePatientPropertyMappingResponse;
    })();

    v1.DeletePatientPropertyMappingRequest = (() => {
      class DeletePatientPropertyMappingRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeletePatientPropertyMappingRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventConfigId != null &&
            Object.hasOwnProperty.call(message, 'eventConfigId')
          ) {
            writer.uint32(10).string(message.eventConfigId);
          }
          if (
            message.mappingId != null &&
            Object.hasOwnProperty.call(message, 'mappingId')
          ) {
            writer.uint32(18).string(message.mappingId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeletePatientPropertyMappingRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventConfigId = reader.string();
                break;
              }
              case 2: {
                message.mappingId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.event_config.v1.DeletePatientPropertyMappingRequest
          ) {
            return object;
          }
          const message =
            new $root.event_config.v1.DeletePatientPropertyMappingRequest();
          if (object.eventConfigId != null) {
            message.eventConfigId = String(object.eventConfigId);
          }
          if (object.mappingId != null) {
            message.mappingId = String(object.mappingId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventConfigId = '';
            object.mappingId = '';
          }
          let keys;
          if (
            message.eventConfigId != null &&
            message.hasOwnProperty('eventConfigId')
          ) {
            object.eventConfigId = message.eventConfigId;
          }
          if (
            message.mappingId != null &&
            message.hasOwnProperty('mappingId')
          ) {
            object.mappingId = message.mappingId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'event_config.v1.DeletePatientPropertyMappingRequest'
          );
        }
      }

      DeletePatientPropertyMappingRequest.prototype.eventConfigId = '';
      DeletePatientPropertyMappingRequest.prototype.mappingId = '';

      return DeletePatientPropertyMappingRequest;
    })();

    v1.DeletePatientPropertyMappingResponse = (() => {
      class DeletePatientPropertyMappingResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DeletePatientPropertyMappingResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventConfigId != null &&
            Object.hasOwnProperty.call(message, 'eventConfigId')
          ) {
            writer.uint32(10).string(message.eventConfigId);
          }
          if (
            message.mapping != null &&
            Object.hasOwnProperty.call(message, 'mapping')
          ) {
            $root.event_config.v1.PatientPropertyMappingResponse.encode(
              message.mapping,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DeletePatientPropertyMappingResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventConfigId = reader.string();
                break;
              }
              case 2: {
                message.mapping =
                  $root.event_config.v1.PatientPropertyMappingResponse.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.event_config.v1.DeletePatientPropertyMappingResponse
          ) {
            return object;
          }
          const message =
            new $root.event_config.v1.DeletePatientPropertyMappingResponse();
          if (object.eventConfigId != null) {
            message.eventConfigId = String(object.eventConfigId);
          }
          if (object.mapping != null) {
            if (typeof object.mapping !== 'object') {
              throw new TypeError(
                '.event_config.v1.DeletePatientPropertyMappingResponse.mapping: object expected, but got ' +
                  typeof object.mapping
              );
            }
            message.mapping =
              $root.event_config.v1.PatientPropertyMappingResponse.fromObject(
                object.mapping
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventConfigId = '';
            object.mapping = null;
          }
          let keys;
          if (
            message.eventConfigId != null &&
            message.hasOwnProperty('eventConfigId')
          ) {
            object.eventConfigId = message.eventConfigId;
          }
          if (message.mapping != null && message.hasOwnProperty('mapping')) {
            object.mapping =
              $root.event_config.v1.PatientPropertyMappingResponse.toObject(
                message.mapping,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'event_config.v1.DeletePatientPropertyMappingResponse'
          );
        }
      }

      DeletePatientPropertyMappingResponse.prototype.eventConfigId = '';
      DeletePatientPropertyMappingResponse.prototype.mapping = null;

      return DeletePatientPropertyMappingResponse;
    })();

    v1.FieldCalculation = (() => {
      class FieldCalculation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FieldCalculation(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.operator != null &&
            Object.hasOwnProperty.call(message, 'operator')
          ) {
            writer.uint32(8).int32(message.operator);
          }
          if (
            message.lhs != null &&
            Object.hasOwnProperty.call(message, 'lhs')
          ) {
            $root.event_config.v1.FieldCalculationOperand.encode(
              message.lhs,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.rhs != null &&
            Object.hasOwnProperty.call(message, 'rhs')
          ) {
            $root.event_config.v1.FieldCalculationOperand.encode(
              message.rhs,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FieldCalculation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.operator = reader.int32();
                break;
              }
              case 2: {
                message.lhs =
                  $root.event_config.v1.FieldCalculationOperand.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.rhs =
                  $root.event_config.v1.FieldCalculationOperand.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.FieldCalculation) {
            return object;
          }
          const message = new $root.event_config.v1.FieldCalculation();
          if (object.operator != null) {
            switch (object.operator) {
              case 'UNSPECIFIED_OPERATOR':
              case 0: {
                message.operator = 0;
                break;
              }
              case 'ADD':
              case 1: {
                message.operator = 1;
                break;
              }
              default: {
                if (typeof object.operator == 'number') {
                  message.operator = object.operator;
                  break;
                }
                break;
              }
            }
          }
          if (object.lhs != null) {
            if (typeof object.lhs !== 'object') {
              throw new TypeError(
                '.event_config.v1.FieldCalculation.lhs: object expected, but got ' +
                  typeof object.lhs
              );
            }
            message.lhs =
              $root.event_config.v1.FieldCalculationOperand.fromObject(
                object.lhs
              );
          }
          if (object.rhs != null) {
            if (typeof object.rhs !== 'object') {
              throw new TypeError(
                '.event_config.v1.FieldCalculation.rhs: object expected, but got ' +
                  typeof object.rhs
              );
            }
            message.rhs =
              $root.event_config.v1.FieldCalculationOperand.fromObject(
                object.rhs
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.operator =
              options.enums === String ? 'UNSPECIFIED_OPERATOR' : 0;
            object.lhs = null;
            object.rhs = null;
          }
          let keys;
          if (message.operator != null && message.hasOwnProperty('operator')) {
            object.operator =
              options.enums === String
                ? $root.event_config.v1.FieldCalculationOperator[
                    message.operator
                  ] === undefined
                  ? message.operator
                  : $root.event_config.v1.FieldCalculationOperator[
                      message.operator
                    ]
                : message.operator;
          }
          if (message.lhs != null && message.hasOwnProperty('lhs')) {
            object.lhs = $root.event_config.v1.FieldCalculationOperand.toObject(
              message.lhs,
              options
            );
          }
          if (message.rhs != null && message.hasOwnProperty('rhs')) {
            object.rhs = $root.event_config.v1.FieldCalculationOperand.toObject(
              message.rhs,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.FieldCalculation';
        }
      }

      FieldCalculation.prototype.operator = 0;
      FieldCalculation.prototype.lhs = null;
      FieldCalculation.prototype.rhs = null;

      return FieldCalculation;
    })();

    v1.FieldCalculationOperand = (() => {
      class FieldCalculationOperand {
        get operand() {
          for (const key of ['fieldLookup', 'calculation']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set operand(name) {
          for (const key of ['fieldLookup', 'calculation']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FieldCalculationOperand(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fieldLookup != null &&
            Object.hasOwnProperty.call(message, 'fieldLookup')
          ) {
            field_lookup$1.v1.FieldLookup.encode(
              message.fieldLookup,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.calculation != null &&
            Object.hasOwnProperty.call(message, 'calculation')
          ) {
            $root.event_config.v1.FieldCalculation.encode(
              message.calculation,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FieldCalculationOperand();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldLookup = field_lookup$1.v1.FieldLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.calculation =
                  $root.event_config.v1.FieldCalculation.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.FieldCalculationOperand) {
            return object;
          }
          const message = new $root.event_config.v1.FieldCalculationOperand();
          if (object.fieldLookup != null) {
            if (typeof object.fieldLookup !== 'object') {
              throw new TypeError(
                '.event_config.v1.FieldCalculationOperand.fieldLookup: object expected, but got ' +
                  typeof object.fieldLookup
              );
            }
            message.fieldLookup = field_lookup$1.v1.FieldLookup.fromObject(
              object.fieldLookup
            );
          }
          if (object.calculation != null) {
            if (typeof object.calculation !== 'object') {
              throw new TypeError(
                '.event_config.v1.FieldCalculationOperand.calculation: object expected, but got ' +
                  typeof object.calculation
              );
            }
            message.calculation =
              $root.event_config.v1.FieldCalculation.fromObject(
                object.calculation
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.fieldLookup != null &&
            message.hasOwnProperty('fieldLookup')
          ) {
            object.fieldLookup = field_lookup$1.v1.FieldLookup.toObject(
              message.fieldLookup,
              options
            );
            if (options.oneofs) {
              object.operand = 'fieldLookup';
            }
          }
          if (
            message.calculation != null &&
            message.hasOwnProperty('calculation')
          ) {
            object.calculation =
              $root.event_config.v1.FieldCalculation.toObject(
                message.calculation,
                options
              );
            if (options.oneofs) {
              object.operand = 'calculation';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.FieldCalculationOperand';
        }
      }

      FieldCalculationOperand.prototype.fieldLookup = null;
      FieldCalculationOperand.prototype.calculation = null;

      return FieldCalculationOperand;
    })();

    v1.PayloadMappings = (() => {
      class PayloadMappings {
        constructor(properties) {
          this.payloadFieldMappings = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PayloadMappings(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.payloadFieldMappings != null &&
            Object.hasOwnProperty.call(message, 'payloadFieldMappings')
          ) {
            for (const element of message.payloadFieldMappings) {
              $root.event_config.v1.PayloadFieldMapping.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PayloadMappings();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.payloadFieldMappings ||
                  !message.payloadFieldMappings.length
                ) {
                  message.payloadFieldMappings = [];
                }
                message.payloadFieldMappings.push(
                  $root.event_config.v1.PayloadFieldMapping.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.PayloadMappings) {
            return object;
          }
          const message = new $root.event_config.v1.PayloadMappings();
          if (object.payloadFieldMappings) {
            if (!Array.isArray(object.payloadFieldMappings)) {
              throw new TypeError(
                '.event_config.v1.PayloadMappings.payloadFieldMappings: array type expected, but got ' +
                  typeof object.payloadFieldMappings
              );
            }
            message.payloadFieldMappings = new Array(
              object.payloadFieldMappings.length
            );
            for (let i = 0; i < object.payloadFieldMappings.length; ++i) {
              if (typeof object.payloadFieldMappings[i] !== 'object') {
                throw new TypeError(
                  '.event_config.v1.PayloadMappings.payloadFieldMappings: object expected, but got ' +
                    typeof object.payloadFieldMappings[i]
                );
              }
              message.payloadFieldMappings[i] =
                $root.event_config.v1.PayloadFieldMapping.fromObject(
                  object.payloadFieldMappings[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.payloadFieldMappings = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.payloadFieldMappings &&
            message.payloadFieldMappings.length
          ) {
            object.payloadFieldMappings = new Array(
              message.payloadFieldMappings.length
            );
            for (let i = 0; i < message.payloadFieldMappings.length; ++i) {
              object.payloadFieldMappings[i] =
                $root.event_config.v1.PayloadFieldMapping.toObject(
                  message.payloadFieldMappings[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.PayloadMappings';
        }
      }

      PayloadMappings.prototype.payloadFieldMappings = $util.emptyArray;

      return PayloadMappings;
    })();

    v1.PayloadFieldMapping = (() => {
      class PayloadFieldMapping {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PayloadFieldMapping(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.source != null &&
            Object.hasOwnProperty.call(message, 'source')
          ) {
            $root.event_config.v1.PatientPropertySource.encode(
              message.source,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.fieldType != null &&
            Object.hasOwnProperty.call(message, 'fieldType')
          ) {
            values$1.v1.ValueType.encode(
              message.fieldType,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PayloadFieldMapping();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.source =
                  $root.event_config.v1.PatientPropertySource.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.fieldType = values$1.v1.ValueType.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.PayloadFieldMapping) {
            return object;
          }
          const message = new $root.event_config.v1.PayloadFieldMapping();
          if (object.source != null) {
            if (typeof object.source !== 'object') {
              throw new TypeError(
                '.event_config.v1.PayloadFieldMapping.source: object expected, but got ' +
                  typeof object.source
              );
            }
            message.source =
              $root.event_config.v1.PatientPropertySource.fromObject(
                object.source
              );
          }
          if (object.fieldType != null) {
            if (typeof object.fieldType !== 'object') {
              throw new TypeError(
                '.event_config.v1.PayloadFieldMapping.fieldType: object expected, but got ' +
                  typeof object.fieldType
              );
            }
            message.fieldType = values$1.v1.ValueType.fromObject(
              object.fieldType
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.source = null;
            object.fieldType = null;
          }
          let keys;
          if (message.source != null && message.hasOwnProperty('source')) {
            object.source =
              $root.event_config.v1.PatientPropertySource.toObject(
                message.source,
                options
              );
          }
          if (
            message.fieldType != null &&
            message.hasOwnProperty('fieldType')
          ) {
            object.fieldType = values$1.v1.ValueType.toObject(
              message.fieldType,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.PayloadFieldMapping';
        }
      }

      PayloadFieldMapping.prototype.source = null;
      PayloadFieldMapping.prototype.fieldType = null;

      return PayloadFieldMapping;
    })();

    v1.PatientIdFieldLookup = (() => {
      class PatientIdFieldLookup {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientIdFieldLookup(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fieldLookup != null &&
            Object.hasOwnProperty.call(message, 'fieldLookup')
          ) {
            field_lookup$1.v1.FieldLookup.encode(
              message.fieldLookup,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.patientApplication != null &&
            Object.hasOwnProperty.call(message, 'patientApplication')
          ) {
            writer.uint32(16).int32(message.patientApplication);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientIdFieldLookup();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.fieldLookup = field_lookup$1.v1.FieldLookup.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.patientApplication = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.PatientIdFieldLookup) {
            return object;
          }
          const message = new $root.event_config.v1.PatientIdFieldLookup();
          if (object.fieldLookup != null) {
            if (typeof object.fieldLookup !== 'object') {
              throw new TypeError(
                '.event_config.v1.PatientIdFieldLookup.fieldLookup: object expected, but got ' +
                  typeof object.fieldLookup
              );
            }
            message.fieldLookup = field_lookup$1.v1.FieldLookup.fromObject(
              object.fieldLookup
            );
          }
          if (object.patientApplication != null) {
            switch (object.patientApplication) {
              case 'UNSPECIFIED_PATIENT_APPLICATION':
              case 0: {
                message.patientApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.patientApplication = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.patientApplication = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.patientApplication = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.patientApplication = 4;
                break;
              }
              case 'RECURLY':
              case 5: {
                message.patientApplication = 5;
                break;
              }
              case 'INTERCOM':
              case 6: {
                message.patientApplication = 6;
                break;
              }
              case 'SANA_BENEFITS':
              case 7: {
                message.patientApplication = 7;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 8: {
                message.patientApplication = 8;
                break;
              }
              case 'VITAL':
              case 9: {
                message.patientApplication = 9;
                break;
              }
              case 'SEGMENT':
              case 10: {
                message.patientApplication = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.patientApplication = 11;
                break;
              }
              default: {
                if (typeof object.patientApplication == 'number') {
                  message.patientApplication = object.patientApplication;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldLookup = null;
            object.patientApplication =
              options.enums === String ? 'UNSPECIFIED_PATIENT_APPLICATION' : 0;
          }
          let keys;
          if (
            message.fieldLookup != null &&
            message.hasOwnProperty('fieldLookup')
          ) {
            object.fieldLookup = field_lookup$1.v1.FieldLookup.toObject(
              message.fieldLookup,
              options
            );
          }
          if (
            message.patientApplication != null &&
            message.hasOwnProperty('patientApplication')
          ) {
            object.patientApplication =
              options.enums === String
                ? patients$1.v1.PatientApplication[
                    message.patientApplication
                  ] === undefined
                  ? message.patientApplication
                  : patients$1.v1.PatientApplication[message.patientApplication]
                : message.patientApplication;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.PatientIdFieldLookup';
        }
      }

      PatientIdFieldLookup.prototype.fieldLookup = null;
      PatientIdFieldLookup.prototype.patientApplication = 0;

      return PatientIdFieldLookup;
    })();

    v1.PatientIdFieldLookups = (() => {
      class PatientIdFieldLookups {
        constructor(properties) {
          this.applicationIdLookups = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new PatientIdFieldLookups(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.customerProvidedIdLookup != null &&
            Object.hasOwnProperty.call(message, 'customerProvidedIdLookup')
          ) {
            field_lookup$1.v1.FieldLookup.encode(
              message.customerProvidedIdLookup,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.applicationIdLookups != null &&
            Object.hasOwnProperty.call(message, 'applicationIdLookups')
          ) {
            for (const element of message.applicationIdLookups) {
              $root.event_config.v1.PatientIdFieldLookup.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          if (
            message.emailPropertyValueLookup != null &&
            Object.hasOwnProperty.call(message, 'emailPropertyValueLookup')
          ) {
            field_lookup$1.v1.FieldLookup.encode(
              message.emailPropertyValueLookup,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new PatientIdFieldLookups();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.customerProvidedIdLookup =
                  field_lookup$1.v1.FieldLookup.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                if (
                  !message.applicationIdLookups ||
                  !message.applicationIdLookups.length
                ) {
                  message.applicationIdLookups = [];
                }
                message.applicationIdLookups.push(
                  $root.event_config.v1.PatientIdFieldLookup.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 3: {
                message.emailPropertyValueLookup =
                  field_lookup$1.v1.FieldLookup.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.event_config.v1.PatientIdFieldLookups) {
            return object;
          }
          const message = new $root.event_config.v1.PatientIdFieldLookups();
          if (object.customerProvidedIdLookup != null) {
            if (typeof object.customerProvidedIdLookup !== 'object') {
              throw new TypeError(
                '.event_config.v1.PatientIdFieldLookups.customerProvidedIdLookup: object expected, but got ' +
                  typeof object.customerProvidedIdLookup
              );
            }
            message.customerProvidedIdLookup =
              field_lookup$1.v1.FieldLookup.fromObject(
                object.customerProvidedIdLookup
              );
          }
          if (object.applicationIdLookups) {
            if (!Array.isArray(object.applicationIdLookups)) {
              throw new TypeError(
                '.event_config.v1.PatientIdFieldLookups.applicationIdLookups: array type expected, but got ' +
                  typeof object.applicationIdLookups
              );
            }
            message.applicationIdLookups = new Array(
              object.applicationIdLookups.length
            );
            for (let i = 0; i < object.applicationIdLookups.length; ++i) {
              if (typeof object.applicationIdLookups[i] !== 'object') {
                throw new TypeError(
                  '.event_config.v1.PatientIdFieldLookups.applicationIdLookups: object expected, but got ' +
                    typeof object.applicationIdLookups[i]
                );
              }
              message.applicationIdLookups[i] =
                $root.event_config.v1.PatientIdFieldLookup.fromObject(
                  object.applicationIdLookups[i]
                );
            }
          }
          if (object.emailPropertyValueLookup != null) {
            if (typeof object.emailPropertyValueLookup !== 'object') {
              throw new TypeError(
                '.event_config.v1.PatientIdFieldLookups.emailPropertyValueLookup: object expected, but got ' +
                  typeof object.emailPropertyValueLookup
              );
            }
            message.emailPropertyValueLookup =
              field_lookup$1.v1.FieldLookup.fromObject(
                object.emailPropertyValueLookup
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.applicationIdLookups = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.customerProvidedIdLookup != null &&
            message.hasOwnProperty('customerProvidedIdLookup')
          ) {
            object.customerProvidedIdLookup =
              field_lookup$1.v1.FieldLookup.toObject(
                message.customerProvidedIdLookup,
                options
              );
          }
          if (
            message.applicationIdLookups &&
            message.applicationIdLookups.length
          ) {
            object.applicationIdLookups = new Array(
              message.applicationIdLookups.length
            );
            for (let i = 0; i < message.applicationIdLookups.length; ++i) {
              object.applicationIdLookups[i] =
                $root.event_config.v1.PatientIdFieldLookup.toObject(
                  message.applicationIdLookups[i],
                  options
                );
            }
          }
          if (
            message.emailPropertyValueLookup != null &&
            message.hasOwnProperty('emailPropertyValueLookup')
          ) {
            object.emailPropertyValueLookup =
              field_lookup$1.v1.FieldLookup.toObject(
                message.emailPropertyValueLookup,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'event_config.v1.PatientIdFieldLookups';
        }
      }

      PatientIdFieldLookups.prototype.customerProvidedIdLookup = null;
      PatientIdFieldLookups.prototype.applicationIdLookups = $util.emptyArray;
      PatientIdFieldLookups.prototype.emailPropertyValueLookup = null;

      return PatientIdFieldLookups;
    })();

    v1.BehaviorForPatientNotMatched = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED')] = 0;
      values[(valuesById[1] = 'CREATE')] = 1;
      values[(valuesById[2] = 'ERROR_OUT')] = 2;
      values[(valuesById[3] = 'SKIP')] = 3;
      return values;
    })();

    v1.FieldCalculationOperator = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_OPERATOR')] = 0;
      values[(valuesById[1] = 'ADD')] = 1;
      return values;
    })();

    return v1;
  })();

  return event_config;
})();

export const event_config = $root.event_config;
