import { active_campaign } from '@morf/proto/active_campaign_v1_ts_proto';
import { acuity } from '@morf/proto/acuity_v1_ts_proto';
import { axle_health } from '@morf/proto/axle_health_v1_ts_proto';
import { butterfly_labs } from '@morf/proto/butterfly_labs_v1_ts_proto';
import { convertToTitleCase } from '../../../../../Helpers/convertToTitleCase';
import { customerio } from '@morf/proto/customerio_v1_ts_proto';
import { formatEnumToOptions } from '../../../../../Helpers/formatEnumToOptions';
import { formsort } from '@morf/proto/formsort_v1_ts_proto';
import { healthie } from '@morf/proto/healthie_v1_ts_proto';
import { intakeq } from '@morf/proto/intakeq_v1_ts_proto';
import { morf_appointment } from '@morf/proto/morf_appointment_v1_ts_proto';
import { push_json } from '@morf/proto/push_json_v1_ts_proto';
import { segment } from '@morf/proto/segment_v1_ts_proto';
import { vital } from '@morf/proto/vital_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { object_category } from '@morf/proto/object_category_v1_ts_proto';

export const formatEventTypes = (
  validTriggerEvents: workflows.v1.ValidTriggerEvents | null | undefined,
  sourceApplication:
    | source_application.v2.SourceApplication.Application
    | undefined,
  objectCategory: object_category.v1.ObjectCategory.Category | undefined,
  selectedEventTypes: string[]
) => {
  const validPayloadEventTypes =
    validTriggerEvents?.sourceApplicationObjectCategories
      .find(
        (sourceApplicationObjectCategory) =>
          sourceApplicationObjectCategory.sourceApplication ===
          sourceApplication
      )
      ?.objectCategoryEventPayloads.find(
        (objectCategoryEventPayload) =>
          objectCategoryEventPayload.objectCategory === objectCategory
      )
      ?.eventPayloadEventTypes.flatMap(
        (eventPayloadEventType) => eventPayloadEventType.validPayloadEventTypes
      ) || [];

  const eventType = validPayloadEventTypes[0];
  let validTypes: number[];
  let enumToUse;

  switch (eventType?.type) {
    case 'formsortEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.formsortEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.formsortEventTypes?.eventTypes || []);
      enumToUse = formsort.v1.FormsortEventType;
      break;
    case 'healthieEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.healthieEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.healthieEventTypes?.eventTypes || []);
      enumToUse = healthie.v1.HealthieEventType;
      break;
    case 'axleHealthEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.axleHealthEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.axleHealthEventTypes?.eventTypes || []);

      enumToUse = axle_health.v1.AxleHealthEventType;
      break;
    case 'butterflyLabsEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.butterflyLabsEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.butterflyLabsEventTypes?.eventTypes || []);
      enumToUse = butterfly_labs.v1.ButterflyLabsEventType;
      break;
    case 'vitalEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.vitalEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.vitalEventTypes?.eventTypes || []);
      enumToUse = vital.v1.VitalEventType;
      break;
    case 'acuityEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.acuityEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.acuityEventTypes?.eventTypes || []);
      enumToUse = acuity.v1.AcuityEventType;
      break;
    case 'segmentEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.segmentEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.segmentEventTypes?.eventTypes || []);
      enumToUse = segment.v1.SegmentEventType;
      break;
    case 'activeCampaignEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.activeCampaignEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.activeCampaignEventTypes?.eventTypes || []);
      enumToUse = active_campaign.v1.ActiveCampaignEventType;
      break;
    case 'morfAppointmentEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.morfAppointmentEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.morfAppointmentEventTypes?.eventTypes || []);
      enumToUse = morf_appointment.v1.MorfAppointmentEventType;
      break;
    case 'intakeqEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.intakeqEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.intakeqEventTypes?.eventTypes || []);
      enumToUse = intakeq.v1.IntakeQEventType;
      break;
    case 'pushJsonEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.pushJsonEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.pushJsonEventTypes?.eventTypes || []);
      enumToUse = push_json.v1.PushJsonEventType;
      break;
    case 'customerIoEventTypes':
      validTypes = validPayloadEventTypes
        .filter((e) =>
          selectedEventTypes.some((type) =>
            e?.customerIoEventTypes?.eventTypes.includes(parseInt(type))
          )
        )
        .flatMap((e) => e?.customerIoEventTypes?.eventTypes || []);
      enumToUse = customerio.v1.CustomerIoEventType;
      break;
    default:
      validTypes = [];
      break;
  }

  return enumToUse
    ? formatEnumToOptions(enumToUse)
        .filter(({ value }) => validTypes.includes(parseInt(value)))
        .map(({ value, label }) => ({
          value,
          label: convertToTitleCase(label),
        }))
    : [];
};
