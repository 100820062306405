import _ from 'lodash';
import { DropdownInputIcon } from '../../Dropdown/DropdownInput/DropdownInputIcon';
import { FC, memo } from 'react';
import { FilterChipProps } from './types';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Typography';
import { useTheme } from '@morf/theming';

const FilterChip: FC<FilterChipProps> = ({
  name,
  onClick,
  showOptions,
  value,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid={_.toLower(name) + '-filter-chip'}
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      width='auto'
      height='auto'
      cursor='pointer'
      onClick={onClick}
      backgroundColor={theme.colors.main.light.light}
      borderType='border'
      borderColor={theme.colors.main.primary.darker}
      borderRadius={0.75}
      gap={0.25}
      py={0.125}
      px={0.25}
    >
      <Text tag='h6' color={theme.colors.main.primary.darker}>
        {name}:
      </Text>

      {value && (
        <Text tag='p3' color={theme.colors.main.primary.darker}>
          {value}
        </Text>
      )}

      <DropdownInputIcon
        iconName='chevron'
        iconSize={0.75}
        showOptions={showOptions}
      />
    </Flexbox>
  );
};

export const MemoizedFilterChip = memo(FilterChip);
