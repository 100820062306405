import moment from 'moment';
import { DropdownInputIcon } from '../Dropdown/DropdownInput/DropdownInputIcon';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { StyledDropdown } from '../Dropdown/Dropdown.css';
import { Text } from '../Typography';
import { TimeRangeForm } from './TimeRangeForm';
import { TimeRangeOption } from './TimeRangeOption';
import { TimestampOption } from './TimestampOption';
import { TimestampPickerProps } from './types';
import { useTheme } from '@morf/theming';
import { timeframeOptions } from './timeframeOptions';
import { useClickOutside } from '../Hooks/useClickOutside';

const TimestampPicker: FC<TimestampPickerProps> = ({
  setTimeframe,
  timeframe,
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [placeholder, setPlaceholder] = useState<string>('');
  const [showTimeframes, setShowTimeframes] = useState<boolean>(false);
  const [showTimeRangeForm, setShowTimeRangeForm] = useState<boolean>(false);

  useEffect(() => {
    if (timeframe && !showTimeRangeForm && timeframe.startTimestamp) {
      const diffHours = moment().diff(
        moment.unix(timeframe.startTimestamp.seconds.toNumber()),
        'hours'
      );
      const selectedOption = timeframeOptions.find(
        (option) => option.hours === diffHours
      );
      setPlaceholder(selectedOption ? selectedOption.label : '');
    }
  }, [timeframe]);

  useClickOutside(ref, () => {
    setShowTimeframes(false);
  });

  return (
    <StyledDropdown
      ref={ref}
      data-testid='timestamp-picker'
      borderRadius={theme.input.borderRadius}
      backgroundColor={theme.colors.ui.card}
      showOptions={showTimeframes}
      borderColor={
        showTimeframes
          ? theme.colors.main.primary.darker
          : theme.colors.ui.divider
      }
      borderType='border'
      borderStyle='solid'
      borderWidth={0.0625}
      height='2.5rem'
      onClick={(e) => e.stopPropagation()}
    >
      <Flexbox
        data-testid='timestamp-input'
        justifyContent='space-between'
        alignItems='center'
        onClick={() => setShowTimeframes(true)}
        cursor='pointer'
        gap={0.5}
        p={0.5}
      >
        <Text tag='h6'>{placeholder}</Text>
        <DropdownInputIcon
          showOptions={showTimeframes}
          iconName='chevron'
          iconSize={1.25}
        />
      </Flexbox>

      {showTimeframes && (
        <Flexbox
          direction='row'
          borderRadius={theme.input.borderRadius}
          backgroundColor={theme.colors.ui.card}
          position='absolute'
          minHeight='15rem'
          maxHeight='15rem'
          overflow='scroll'
          top='3rem'
          left='0rem'
          shadow='base'
          p={0.5}
          gap={0}
        >
          {showTimeRangeForm ? (
            <TimeRangeForm
              timeframe={timeframe}
              setPlaceholder={setPlaceholder}
              setShowTimeRangeForm={setShowTimeRangeForm}
              setShowTimeframes={setShowTimeframes}
              setTimeframe={setTimeframe}
            />
          ) : (
            <Flexbox direction='column' gap={0} pr={0.25}>
              {timeframeOptions.map((option) => (
                <TimestampOption
                  key={option.label}
                  isSelected={placeholder === option.label}
                  label={option.label}
                  setPlaceholder={setPlaceholder}
                  setShowTimeframes={setShowTimeframes}
                  setTimeframe={setTimeframe}
                  value={option.hours}
                />
              ))}
              <TimeRangeOption
                isSelected={showTimeRangeForm}
                onClick={() => setShowTimeRangeForm(true)}
              />
            </Flexbox>
          )}
        </Flexbox>
      )}
    </StyledDropdown>
  );
};

export const MemoizedTimestampPicker = memo(TimestampPicker);
