import { Container } from '../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';
import { TimeRangeOptionProps } from './types';
import { useTheme } from '@morf/theming';

const TimeRangeOption: FC<TimeRangeOptionProps> = ({ isSelected, onClick }) => {
  const theme = useTheme();

  return (
    <Container
      data-testid='time-range-option'
      backgroundColor={isSelected ? theme.colors.ui.body : theme.colors.ui.card}
      borderRadius={theme.input.borderRadius}
      hoverBackgroundColor={theme.colors.ui.body}
      onClick={onClick}
      px={0.5}
      py={0.25}
      cursor='pointer'
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Text tag='p2'>Time Range</Text>
        <Icon
          name='chevron-right'
          strokeWidth={1.5}
          size={1}
          stroke={theme.colors.ui.dark}
        />
      </Flexbox>
    </Container>
  );
};

export const MemoizedTimeRangeOption = memo(TimeRangeOption);
