import { FC, useState } from 'react';
import { TooltipProps } from './types';
import { StyledTooltip } from './Tooltip.css';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { Text } from '../Typography';

export const Tooltip: FC<TooltipProps> = ({
  children,
  tooltipText,
  isActive = true,
  containerWidth = 'auto',
  tooltipWidth = 'max-content',
  tooltipDirection = 'top',
  arrowDirection = 'left',
  type,
  getHoverState,
}) => {
  const theme = useTheme();
  const defaultType = type || theme.tooltip.type;
  const backgroundColor =
    defaultType === 'dark'
      ? theme.colors.main.dark.darkest
      : theme.colors.main.light.normal;
  const color =
    defaultType === 'dark' ? theme.colors.text.inverse : theme.colors.text.body;

  const [isHovered, setIsHovered] = useState(false);

  const setHoverState = (value: boolean) => {
    setIsHovered(value);
    if (getHoverState) {
      getHoverState(value);
    }
  };

  return (
    <Container position='relative' width='auto' height='auto'>
      <Container
        data-testid='tooltip-container'
        onMouseOver={() => setHoverState(true)}
        onTouchStart={() => setHoverState(true)}
        onMouseOut={() => setHoverState(false)}
        onTouchEnd={() => setHoverState(false)}
        onClick={() => setHoverState(false)}
        width={containerWidth}
      >
        {children}
      </Container>
      {isActive && isHovered && (
        <StyledTooltip
          data-testid='tooltip'
          tooltipDirection={tooltipDirection}
          arrowDirection={arrowDirection}
          backgroundColor={backgroundColor}
          width={tooltipWidth}
        >
          <Text tag='p3' color={color}>
            {tooltipText}
          </Text>
        </StyledTooltip>
      )}
    </Container>
  );
};
