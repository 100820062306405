import { FC, memo } from 'react';
import { useTheme } from '@morf/theming';
import { EventPayloadExamplePanelProps } from './types';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';
import { Payload } from '../../Payload';
import { formatStruct } from '../../Helpers/formatStruct';

const EventPayloadExamplePanel: FC<EventPayloadExamplePanelProps> = ({
  eventPayloadExample,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='event-payload-example-panel'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      gap={0}
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='2.75rem'
        borderType='borderBottom'
        backgroundColor={theme.colors.ui.card}
        px={1}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={0.5}
        >
          <Icon name='code-bracket' stroke={theme.colors.ui.dark} size={1} />
          <Text tag='p2' color={theme.colors.ui.dark}>
            Event Payload
          </Text>
        </Flexbox>

        <Icon
          name='close'
          cursor='pointer'
          stroke={theme.colors.ui.dark}
          size={1.25}
          onClick={onClose}
        />
      </Flexbox>

      <Payload payload={formatStruct(eventPayloadExample)} />
    </Flexbox>
  );
};

export const MemoizedEventPayloadExamplePanel = memo(EventPayloadExamplePanel);
