import { TableBodyProps } from './types';
import { TableRow } from './TableRow';
import { Fragment, memo, useState } from 'react';

const Body: <RowData>(props: TableBodyProps<RowData>) => JSX.Element = ({
  cellBackgroundColor,
  cellHeight,
  cellHoveredBackgroundColor,
  cellHoveredBorderColor,
  cellSelectedBackgroundColor,
  cellSelectedBorderColor,
  isLoading,
  isScrollable,
  onColumnClick,
  onRowClick,
  onRowHover,
  onRowRemove,
  onSubRowAdd,
  onSubRowClick,
  onSubRowRemove,
  parentCellHeight,
  rows,
  subCellHeight,
  subRowComponent,
}) => {
  const [hoveredRowId, setHoveredRowId] = useState<number | null>();

  const handleRowMouseEnter = (rowId: number) => {
    onRowHover && onRowHover(rowId);
    setHoveredRowId(rowId);
  };

  const handleRowMouseLeave = () => {
    onRowHover && onRowHover(null);
    setHoveredRowId(null);
  };

  return (
    <tbody data-testid='body-group'>
      {rows.map((row, index) => {
        const rowId = parseInt(row.id);
        const isSubRow = row.getParentRow();
        const isExpanded = row.getIsExpanded();
        const isHovered = hoveredRowId === rowId;
        const commonProps = {
          cellBackgroundColor,
          cellHeight,
          cellHoveredBackgroundColor,
          cellHoveredBorderColor,
          cellSelectedBackgroundColor,
          cellSelectedBorderColor,
          firstRowId: rows[0].id,
          isHovered,
          isLoading,
          isScrollable,
          onColumnClick,
          onMouseEnter: () => handleRowMouseEnter(rowId),
          onMouseLeave: handleRowMouseLeave,
          onRowClick,
          onRowRemove,
          onSubRowAdd,
          onSubRowClick,
          onSubRowRemove,
          parentCellHeight,
          row,
          subCellHeight,
        };

        return (
          <Fragment key={index}>
            {subRowComponent ? (
              <>
                {!isSubRow && <TableRow {...commonProps} />}
                {isExpanded && subRowComponent(row)}
              </>
            ) : (
              <TableRow {...commonProps} />
            )}
          </Fragment>
        );
      })}
    </tbody>
  );
};

export const TableBody = memo(Body) as typeof Body;
