import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { SnapshotActionsProps } from './types';
import { RetrySnapshotIcon } from './SnapshotIcon';
import { SnapshotIcon } from './SnapshotIcon';
import { LinkSnapshotIcon } from './SnapshotIcon/LinkSnapshotIcon/LinkSnapshotIcon.stories';
import { LinkSnapshotIconProps } from './SnapshotIcon/LinkSnapshotIcon/types';

const SnapshotActions: FC<SnapshotActionsProps> = ({
  goToTemporalLink,
  onRetryBranch,
  onRetryDestinationAction,
  onRetryEventSnapshot,
  onRetryWorkflow,
  openInNewTabLink,
  viewExecutionLink,
  viewNodeConfigurationLink,
  viewProfileLink,
}) => {
  const icons = [
    {
      name: 'conditional',
      tooltipText: 'Go to temporal',
      link: goToTemporalLink,
    },
    {
      name: 'patient-arrow',
      tooltipText: 'View profile',
      link: viewProfileLink,
    },
    {
      name: 'arrows-flow-right',
      tooltipText: 'View execution',
      link: viewExecutionLink,
    },
    {
      name: 'tools',
      tooltipText: 'View node configuration',
      link: viewNodeConfigurationLink,
    },
    {
      name: 'arrow-out',
      tooltipText: 'Open in new tab',
      link: openInNewTabLink,
    },
  ].filter((icon): icon is LinkSnapshotIconProps => !!icon.link);

  return (
    <Flexbox
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      width='auto'
      px={1}
      py={0.75}
      gap={0.75}
    >
      {icons.map((icon, index) => (
        <LinkSnapshotIcon
          key={index}
          {...icon}
          arrowDirection={index === icons.length - 1 ? 'right' : 'center'}
        />
      ))}
      {onRetryDestinationAction || onRetryEventSnapshot || onRetryBranch ? (
        <RetrySnapshotIcon
          onRetryDestinationAction={onRetryDestinationAction}
          onRetryWorkflow={onRetryWorkflow}
          onRetryBranch={onRetryBranch}
          onRetryEventSnapshot={onRetryEventSnapshot}
        />
      ) : (
        <SnapshotIcon
          name='refresh'
          tooltipText='Retry Workflow'
          onClick={onRetryWorkflow}
          arrowDirection='right'
        />
      )}
    </Flexbox>
  );
};

export const MemoizedSnapshotActions = memo(SnapshotActions);
