import { values } from '@morf/proto/values_v1_ts_proto';
import { numericValueToString } from './numericValueToString';

export const numericRangeValueToString = (
  numericRangeValue: values.v1.IValue['numericRangeValue'] | null | undefined
): string => {
  if (numericRangeValue) {
    const minimumBound = numericValueToString(numericRangeValue.minimumBound);
    const maximumBound = numericValueToString(numericRangeValue.maximumBound);
    return `${minimumBound} - ${maximumBound}`;
  }
  return 'n/a';
};
