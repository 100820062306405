import { ParamSourceConfigProps } from '../../../types';
import { convertToKebabCase } from '../../../../Helpers/convertToKebabCase';
import { convertToTitleCase } from '../../../../Helpers/convertToTitleCase';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { formatDestinationActionParameterSource } from '../../../../Helpers/formatDestinationActionParameterSource';
import { thirdparties } from '@morf/proto/thirdparties_v1_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export const formatProfileUpdateIdentifiers = (
  data: destinationactions.morf.v1.UpdateProfileProperties | null | undefined
): ParamSourceConfigProps[] => {
  if (!data) {
    return [];
  }

  const mapThirdPartyId = (
    type: thirdparties.v1.ThirdPartyIdType,
    source:
      | workflow_parameters.v1.DestinationActionParameterSource
      | null
      | undefined
  ): ParamSourceConfigProps => {
    return {
      param: {
        ...(type && {
          fieldName: convertToTitleCase(thirdparties.v1.ThirdPartyIdType[type]),
          integrationIconName: convertToKebabCase(
            thirdparties.v1.ThirdPartyIdType[type]
          ),
        }),
        tagType: type ? 'identifier' : 'disabled',
        fieldType: 'ID',
      },
      source: formatDestinationActionParameterSource(source),
    };
  };

  return [
    {
      param: { fieldName: 'Email address', fieldType: 'ID' },
      source: formatDestinationActionParameterSource(
        data.emailAddressUpdateSource
      ),
    },
    {
      param: { fieldName: 'Phone number', fieldType: 'ID' },
      source: formatDestinationActionParameterSource(
        data.phoneNumberUpdateSource
      ),
    },
    {
      param: { fieldName: 'First name', fieldType: 'ID' },
      source: formatDestinationActionParameterSource(
        data.firstNameUpdateSource
      ),
    },
    {
      param: { fieldName: 'Last name', fieldType: 'ID' },
      source: formatDestinationActionParameterSource(data.lastNameUpdateSource),
    },
    ...(data.thirdPartyProfileIdUpdateSources || []).map(({ type, source }) =>
      mapThirdPartyId(type, source)
    ),
  ];
};
