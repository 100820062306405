import { values } from '@morf/proto/values_v1_ts_proto';

export const rawBytesValueToString = (
  rawBytesValue: values.v1.IValue['rawBytesValue'] | null | undefined
): string => {
  if (rawBytesValue) {
    return btoa(String.fromCharCode(...rawBytesValue));
  }
  return 'n/a';
};
