import { FC, memo } from 'react';
import { LinkSnapshotIconProps } from './types';
import { SnapshotIcon } from '..';

const LinkSnapshotIcon: FC<LinkSnapshotIconProps> = ({ link, ...props }) => {
  return (
    <a href={link} target='_blank'>
      <SnapshotIcon {...props} />
    </a>
  );
};

export const MemoizedLinkSnapshotIcon = memo(LinkSnapshotIcon);
