import { organization } from '@morf/proto/organization_v1_ts_proto';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';

export enum SelectValuePairCategory {
  Identifiers = 'Identifiers',
  MorfProperties = 'Morf properties',
  CustomProperties = 'Custom properties',
  PayloadField = 'Payload field',
  CalculatedValue = 'Calculated value',
  ConstantValue = 'Static value',
  ElidedValue = 'Elided value',
  MorfEventType = 'Morf event type',
  BuildObject = 'Build object',
  BuildArray = 'Build array',
  ObjectTypeIds = 'Object type ids',
}

export type SelectValuePairModalProps = {
  eventPayloadFieldSchemas?: workflow_parameters.v1.WorkflowEventPayloadFieldSchema[];
  includeEmail?: boolean;
  includePhone?: boolean;
  onClick: (
    sources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => void;
  onClose: () => void;
  onCreateCustomProperty: (
    data: organization.v1.CreateProfilePropertyRequest
  ) => void;
  selectedParam?: workflow_parameters.v1.DestinationActionParameter | null;
  selectedSource: workflow_parameters.v1.DestinationActionParameterSource | null;
  visibleCategories?: SelectValuePairCategory[];
};
